import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData {
  availabilityType;
}
@Component({
  selector: 'app-confirm-change',
  templateUrl: './confirm-change-availability.component.html',
  styleUrls: ['./confirm-change-availability.component.scss'],
})
export class ConfirmChangeAvailabilityComponent implements OnInit {
  availabilityType: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit() {
    this.availabilityType = this.data.availabilityType
      ? this.data.availabilityType
      : 'availability';
  }
}
