//ANGULAR AND PLUGINS
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { environment } from '../environments/environment';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
registerLocaleData(localeNl);
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DateAdapter } from '@angular/material/core';
import {
  MatFormFieldModule,
  MatFormFieldDefaultOptions,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBadgeModule } from '@angular/material/badge';
import { MatRadioModule } from '@angular/material/radio';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatExpansionModule } from '@angular/material/expansion';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { LoginComponent } from './auth/login/login.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { AngularFireModule } from '@angular/fire/compat';
import { REGION } from '@angular/fire/compat/functions';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard';
import { CustomDateAdapter } from './custom-date-adapter';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  CalendarModule,
  DateAdapter as AngularCalendarDateAdapter,
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AgmCoreModule } from '@agm/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ConstantsService } from './common/services/constants.service';
import { MatTabsModule } from '@angular/material/tabs';
import { autoFocusDirective } from './directives/onloadfocus.directive';
import { MatChipsModule } from '@angular/material/chips';
import { FileSaverModule } from 'ngx-filesaver';

//COMPONENTS
import { PlannerComponent } from './planner/planner.component';
import { UsersComponent } from './users/users.component';
import { FrequentAddressComponent } from '../app/settings/frequent-address/frequent-address.component';
import { AuditComponent } from '../app/settings/audit/audit.component';
import { CharacteristicsComponent } from '../app/settings/characteristics/characteristics.component';
import { SettingsComponent } from './settings/general-settings/settings.component';
import { VehiclesComponent } from './settings/vehicles/vehicles.component';
import { TariffsComponent } from './settings/tariffs/tariffs.component';
import { AdminUsersComponent } from './admin-users/admin-users.component';
import { SelectingOrganisationComponent } from './selecting-organisation/selecting-organisation.component';
import { AvailabilityDriverComponent } from './availability-driver/availability-driver.component';
import { DayPartsComponent } from './settings/day-parts/day-parts.component';
import { AvailabilityComponent } from './availability-driver/tabs/availability/availability.component';
import { ExceptionsComponent } from './availability-driver/tabs/exceptions/exceptions.component';
import { AvailabilityVehiclesComponent } from './availability-driver/tabs/availability-vehicles/availability-vehicles.component';
import { PlanningComponent } from './availability-driver/tabs/planning/planning.component';
import { PhoneNumbersComponent } from './settings/phone-numbers/phone-numbers.component';
import { AdminToolsComponent } from './settings/admin-tools/admin-tools.component';
import { VehicleGroupsComponent } from './settings/vehicle-groups/vehicle-groups.component';
import { CreateSepaForOrgsComponent } from './settings/admin-tools/tools/create-sepa-for-orgs/create-sepa-for-orgs.component';
import { SetupPaymentMethodsComponent } from './settings/admin-tools/tools/setup-payment-methods/setup-payment-methods.component';
import { DebitOrdersComponent } from './settings/debit-orders/debit-orders.component';

//DIALOGS
import { ChangeCreditsComponent } from './users/dialogs/change-credits/change-credits.component';
import { UserComponent } from './users/dialogs/user/user.component';
import { CreditsHistoryComponent } from './users/dialogs/credits-history/credits-history.component';
import { ChangeHiddenStatusComponent } from './users/dialogs/change-hidden-status/change-hidden-status.component';
import { DeleteUserComponent } from './users/dialogs/delete-user/delete-user.component';
import { RideDetailsComponent } from './planner/dialogs/ride-details/ride-details.component';
import { EditRideComponent } from './planner/dialogs/edit-ride/edit-ride.component';
import { CopyRideComponent } from './planner/dialogs/copy-ride/copy-ride.component';
import { UserRideComponent } from './planner/dialogs/edit-ride/dialogs/user-ride/user-ride.component';
import { EditAddressComponent } from '../app/settings/frequent-address/dialogs/edit-address/edit-address.component';
import { DeleteRideComponent } from './planner/dialogs/edit-ride/dialogs/delete-ride/delete-ride.component';
import { ImportExportComponent } from './settings/dialogs/import-export/import-export.component';
import { CreateEditVehiclesComponent } from './settings/vehicles/dialogs/create-edit-vehicles/create-edit-vehicles.component';
import { WarningDialog } from './global-dialogs/warning/warning.component';
import { DeleteRidesComponent } from './settings/admin-tools/dialog/delete-rides/delete-rides.component';
import { AdjustPasswordComponent } from './settings/admin-tools/dialog/adjust-password/adjust-password.component';
import { CreateEditTariffComponent } from './settings/tariffs/dialogs/create-edit-tariff/create-edit-tariff.component';
import { AdminUserComponent } from './admin-users/dialogs/user/user.component';
import { CopyUpdateComponent } from './planner/dialogs/copy-update/copy-update.component';
import { ConfirmChangeComponent } from './planner/dialogs/confirm-change/confirm-change.component';
import { UpsertDaypartComponent } from './settings/day-parts/dialogs/upsert-daypart/upsert-daypart.component';
import { AddExceptionComponent } from './availability-driver/tabs/exceptions/dialog/add-exception/add-exception.component';
import { PlanDaypartComponent } from './planner/dialogs/plan-daypart/plan-daypart.component';
import { DaypartRideComponent } from './availability-driver/tabs/planning/dialog/daypart-ride/daypart-ride.component';
import { CopyDaypartComponent } from './planner/dialogs/copy-daypart/copy-daypart.component';
import { DefaultTariffWarningComponent } from './settings/tariffs/dialogs/create-edit-tariff/dialogs/default-tariff-warning/default-tariff-warning.component';
import { CreateEditPhoneNumbersComponent } from './settings/phone-numbers/dialogs/create-edit-phone-numbers/create-edit-phone-numbers.component';
import { RideEditsComponent } from './planner/dialogs/ride-edits/ride-edits.component';
import { ExportRidesComponent } from './settings/dialogs/export-rides/export-rides.component';
import { CreateEditCharacteristicComponent } from './settings/characteristics/dialogs/create-edit-characteristics/create-edit-characteristics.component';
import { ConfirmChangeAvailabilityComponent } from './availability-driver/dialogs/confirm-change-availability/confirm-change-availability.component';
import { ImportExportUsersComponent } from './users/dialogs/import-export-users/import-export-users.component';
import { CreateEditVehicleGroupsComponent } from './settings/vehicle-groups/dialogs/create-edit-vehicle-groups/create-edit-vehicle-groups.component';
import { CreateSepaComponent } from './users/dialogs/create-sepa/create-sepa.component';
import { NotifyUsersRideDialog } from './planner/dialogs/edit-ride/dialogs/notify-users-ride/notify-users-ride.component';
import { AddUserToOrgsComponent } from './settings/admin-tools/dialog/add-user-to-orgs/add-user-to-orgs.component';
import { ManagePaidFunctionalityComponent } from './settings/admin-tools/dialog/manage-paid-functionality/manage-paid-functionality.component';

// POSSIBLY USELESS
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { SetOrganisationComponent } from './set-organisation/set-organisation.component';
import { DeletedRidesComponent } from './deleted-rides/deleted-rides.component';
import { DeletedRideInformationComponent } from './deleted-rides/dialogs/deleted-ride-information/deleted-ride-information.component';
import { ExportDeletedRidesComponent } from './deleted-rides/dialogs/export-deleted-rides/export-deleted-rides.component';
import { NewOrganisationComponent } from './settings/admin-tools/dialog/new-organisation/new-organisation.component';

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};
@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    LoginComponent,
    PlannerComponent,
    UsersComponent,
    UserComponent,
    AdminUsersComponent,
    AdminUserComponent,
    ChangeCreditsComponent,
    CreditsHistoryComponent,
    ChangeHiddenStatusComponent,
    DeleteUserComponent,
    RideDetailsComponent,
    EditRideComponent,
    CopyRideComponent,
    UserRideComponent,
    FrequentAddressComponent,
    EditAddressComponent,
    AuditComponent,
    CharacteristicsComponent,
    DeleteRideComponent,
    SettingsComponent,
    VehiclesComponent,
    ImportExportComponent,
    CreateEditVehiclesComponent,
    CreateEditCharacteristicComponent,
    WarningDialog,
    DeleteRidesComponent,
    AdjustPasswordComponent,
    TariffsComponent,
    CreateEditTariffComponent,
    CopyUpdateComponent,
    ConfirmChangeComponent,
    SelectingOrganisationComponent,
    AvailabilityDriverComponent,
    DayPartsComponent,
    UpsertDaypartComponent,
    AvailabilityComponent,
    ExceptionsComponent,
    AddExceptionComponent,
    AvailabilityVehiclesComponent,
    PlanDaypartComponent,
    PlanningComponent,
    DaypartRideComponent,
    autoFocusDirective,
    CopyDaypartComponent,
    DefaultTariffWarningComponent,
    PhoneNumbersComponent,
    CreateEditPhoneNumbersComponent,
    RideEditsComponent,
    ExportRidesComponent,
    AdminToolsComponent,
    ConfirmChangeAvailabilityComponent,
    ImportExportUsersComponent,
    FileUploadComponent,
    VehicleGroupsComponent,
    CreateEditVehicleGroupsComponent,
    SetupPaymentMethodsComponent,
    CreateSepaComponent,
    CreateSepaForOrgsComponent,
    NotifyUsersRideDialog,
    AddUserToOrgsComponent,
    ManagePaidFunctionalityComponent,
    DebitOrdersComponent,
    SetOrganisationComponent,
    DeletedRidesComponent,
    DeletedRideInformationComponent,
    ExportDeletedRidesComponent,
    NewOrganisationComponent,
  ],
  imports: [
    AppRoutingModule,
    NgxCsvParserModule,
    CalendarModule.forRoot({
      provide: AngularCalendarDateAdapter,
      useFactory: adapterFactory,
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCojBc1zNDl7y-xJA4J_ML6t2azl_C7eOU',
      libraries: ['places'],
    }),
    InlineSVGModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // MatMomentDateModule,
    // MomentDateAdapter,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireAuthGuardModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    DragDropModule,
    MatSidenavModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatRadioModule,
    MatMenuModule,
    MatRippleModule,
    MatCardModule,
    MatCheckboxModule,
    MatBadgeModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatCardModule,
    MatExpansionModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgxMatSelectSearchModule,
    MatTableModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatAutocompleteModule,
    ColorPickerModule,
    MatTabsModule,
    MatChipsModule,
    FileSaverModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'nl' },
    ConstantsService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance,
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
    },
    { provide: REGION, useValue: 'europe-west1' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
