import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  collection,
  getDocs,
  setDoc,
  doc,
  query,
  where,
  deleteDoc,
} from 'firebase/firestore';
import { MatDialogRef } from '@angular/material/dialog';
import { Setting } from 'src/app/interfaces';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
@Component({
  selector: 'app-add-user-to-orgs',
  templateUrl: './add-user-to-orgs.component.html',
  styleUrls: ['./add-user-to-orgs.component.scss'],
})
export class AddUserToOrgsComponent implements OnInit {
  superUserForm: UntypedFormGroup;
  orgs = [];
  superUsers = [];

  constructor(
    private fb: UntypedFormBuilder,
    public db: AngularFirestore,
    public dialogRef: MatDialogRef<AddUserToOrgsComponent>
  ) {}

  async ngOnInit(): Promise<void> {
    this.superUserForm = this.fb.group({
      user: ['', Validators.required],
    });
    await this.getOrgs();
    await this.getSuperUsers();
    console.log('this.orgs', this.orgs);
    console.log('superUsers', this.superUsers);
    this.superUserForm.get('user').valueChanges.subscribe((value) => {
      this.resetCheckboxes();
      value.organisations.forEach((orgInUser) => {
        if (this.orgs.find((org) => org.id === orgInUser.id)) {
          const index = this.orgs.indexOf(
            this.orgs.find((org) => org.id === orgInUser.id)
          );
          this.orgs[index]['checked'] = true;
        }
      });
    });
  }

  async getOrgs() {
    const orgDocs = await getDocs(
      collection(this.db.firestore, `organisations`)
    );

    orgDocs.forEach((doc) => {
      const docData = doc.data() as Setting;
      const orgData = { ...docData, id: doc.id, checked: false };
      this.orgs.push(orgData);
    });

    this.orgs.sort((a, b) => a.name.localeCompare(b.name));

    this.orgs.forEach((org) => {
      this.superUserForm.addControl(
        org.id,
        new UntypedFormControl(org.checked)
      );
    });
  }

  async getSuperUsers() {
    const q = query(
      collection(this.db.firestore, `users`),
      where('rights', '==', 'admin')
    );
    const superUserDocs = await getDocs(q);
    superUserDocs.forEach((doc) => {
      const docData = doc.data();
      const superUserData = { ...docData, id: doc.id };
      this.superUsers.push(superUserData);
    });

    this.superUsers.sort((a, b) => a.name.localeCompare(b.name));
  }

  orgChecked(event: MatCheckboxChange, value: any) {
    const index = this.orgs.indexOf(value);
    this.orgs[index]['checked'] = event.checked;
    console.log(this.orgs);
  }

  resetCheckboxes() {
    this.orgs.forEach((org) => {
      org.checked = false;
    });
  }

  getSelectedOrgs() {
    const selectedOrgs = [];
    this.orgs.forEach((org) => {
      if (org.checked) {
        selectedOrgs.push({ accountType: 'admin', id: org.id, name: org.name });
      }
    });
    return selectedOrgs;
  }

  getNonSelectedORgs() {
    const nonSelectedOrgs = [];
    this.orgs.forEach((org) => {
      if (!org.checked) {
        nonSelectedOrgs.push({
          accountType: 'admin',
          id: org.id,
          name: org.name,
        });
      }
    });
    return nonSelectedOrgs;
  }

  async save() {
    const selectedOrgs = this.getSelectedOrgs();
    const nonSelectedOrgs = this.getNonSelectedORgs();
    const userId = this.superUserForm.value.user.id;
    const promises: any[] = [];
    try {
      promises.push(
        await setDoc(
          doc(this.db.firestore, `users/${userId}`),
          {
            organisations: selectedOrgs,
          },
          { merge: true }
        )
      );

      selectedOrgs.forEach(async (org) => {
        promises.push(
          await setDoc(
            doc(this.db.firestore, `/organisations/${org.id}/users/${userId}`),
            {
              accountType: 'admin',
              email: this.superUserForm.value.user.email,
              name: this.superUserForm.value.user.name,
              superuser: true,
            },
            { merge: true }
          )
        );
      });

      nonSelectedOrgs.forEach(async (org) => {
        promises.push(
          await deleteDoc(
            doc(this.db.firestore, `/organisations/${org.id}/users/${userId}`)
          )
        );
      });
    } catch (error) {
      console.log(error);
    }
    await Promise.all(promises);
    this.dialogRef.close();
  }
}
