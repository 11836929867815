import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { PublicSettings, Setting, User } from '../interfaces';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  @ViewChild('sidenav', { static: false }) sidenav: any;
  userData: User;
  accountType: string;
  organisations: any[] = [];
  organisation: any;
  paidFunctionalities: any = {
    usesDriverPlanning: false,
    usesSEPASystem: false,
    usesDriverRemarksInApp: false,
    usesUserNotificationSystem: false,
  };
  showDriverPlanning: boolean;
  ignoreDriverStatus: boolean;
  characteristicsStatus: boolean;
  publicSettingsLogo: string;
  shouldReload: boolean;
  publicSettingsPlanningMenuImage: string;

  constructor(public db: AngularFirestore, private route: Router) {}

  async ngOnInit() {
    onAuthStateChanged(getAuth(), async (user) => {
      if (user) {
        this.userData = {
          ...((
            await getDoc(doc(this.db.firestore, `users/${user.uid}`))
          ).data() as User),
          id: user.uid,
        };
        this.organisations = this.userData.organisations.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          } else {
            return -1;
          }
        });

        this.organisation = this.organisations.find((organisation) => {
          return organisation.id === this.userData.selectedOrgId;
        });

        if (
          this.userData.selectedOrgId &&
          this.userData.selectedOrgId !== localStorage.getItem('orgId')
        ) {
          await this.setOrganisation(this.organisation);
        }

        if (this.organisation) {
          await this.setPaidFunctionalities();

          const orgUserData = (
            await getDoc(
              doc(
                this.db.firestore,
                `organisations/${this.userData.selectedOrgId}/users/${this.userData.id}`
              )
            )
          ).data() as User;

          if (orgUserData.accountType) {
            this.accountType = orgUserData.accountType;

            const settings = (
              await getDoc(
                doc(
                  this.db.firestore,
                  `organisations/${this.userData.selectedOrgId}/settings/general`
                )
              )
            ).data() as Setting;

            this.ignoreDriverStatus = settings.ignoreDriverStatus;
            this.characteristicsStatus = settings.characteristicsStatus;
            await this.saveOrgInfoLocal(this.userData.selectedOrgId);
          } else {
            // Log user out if he has no rights for the planning environment.
            this.logout();
          }
        }
        this.showDriverPlanning = this.setShowDriverPlanning();
      }
      if (this.shouldReload) {
        window.location.reload();
      }
    });
  }

  async saveOrgInfoLocal(orgId: string) {
    const publicSettings = (
      await getDoc(
        doc(this.db.firestore, `organisations/${orgId}/settings/public`)
      )
    ).data() as PublicSettings;

    if (
      publicSettings.primaryColor !== localStorage.getItem('primaryColor') ||
      publicSettings.accentColor !== localStorage.getItem('accentColor') ||
      publicSettings.banner !== localStorage.getItem('banner') ||
      publicSettings.logo !== localStorage.getItem('logo') ||
      (publicSettings.name &&
        publicSettings.name !== localStorage.getItem('orgName'))
    ) {
      console.log('orgId:', orgId);
      console.log(
        publicSettings.primaryColor,
        localStorage.getItem('primaryColor')
      );
      console.log(
        publicSettings.primaryColor !== localStorage.getItem('primaryColor')
      );
      console.log(
        publicSettings.accentColor !== localStorage.getItem('accentColor')
      );
      console.log(publicSettings.banner !== localStorage.getItem('banner'));
      console.log(publicSettings.logo !== localStorage.getItem('logo'));
      // this.shouldReload = true;
    }
    localStorage.setItem('primaryColor', publicSettings.primaryColor);
    localStorage.setItem('accentColor', publicSettings.accentColor);
    localStorage.setItem('banner', publicSettings.banner);
    localStorage.setItem('logo', publicSettings.logo);
    localStorage.setItem('orgName', publicSettings.name ?? 'Buurtvervoer');

    this.publicSettingsLogo = publicSettings.logo;
    this.publicSettingsPlanningMenuImage = publicSettings.planningMenuImage;
    const favicon: HTMLLinkElement = document.querySelector('#favicon');

    favicon.href = publicSettings.favicon;
  }

  async setOrganisation(org, pressedButton?: boolean) {
    await updateDoc(doc(this.db.firestore, `users/${this.userData.id}`), {
      selectedOrgId: org.id,
    });
    localStorage.setItem('orgId', org.id);
    localStorage.setItem('accType', org.accountType);
    if (this.accountType === 'driver') {
      if (this.route.url === '/availability') {
        this.shouldReload = true;
      } else {
        this.route.navigate(['/availability']);
      }
    } else {
      this.shouldReload = true;
    }
    await this.saveOrgInfoLocal(org.id);
    if (pressedButton && this.shouldReload) {
      window.location.reload();
    }
  }

  async setPaidFunctionalities() {
    const paidFunctionalitiesData = (
      await getDoc(
        doc(this.db.firestore, `organisations/${this.organisation.id}`)
      )
    ).data().paidFunctionalities;

    if (paidFunctionalitiesData) {
      for (const [functionality, value] of Object.entries(
        paidFunctionalitiesData
      )) {
        localStorage.setItem(functionality, `${value}`);
        this.paidFunctionalities[functionality] = value;
      }
    }
  }

  setShowDriverPlanning(): boolean {
    if (
      this.ignoreDriverStatus &&
      this.paidFunctionalities.usesDriverPlanning
    ) {
      return false;
    } else if (
      !this.ignoreDriverStatus &&
      this.paidFunctionalities.usesDriverPlanning
    ) {
      return true;
    } else if (
      this.ignoreDriverStatus &&
      !this.paidFunctionalities.usesDriverPlanning
    ) {
      return false;
    } else if (
      !this.ignoreDriverStatus &&
      !this.paidFunctionalities.usesDriverPlanning
    ) {
      return false;
    }
    return true;
  }

  async logout() {
    signOut(getAuth())
      .then(() => {
        localStorage.removeItem('accType');

        for (const [functionality, value] of Object.entries(
          this.paidFunctionalities
        )) {
          localStorage.removeItem(functionality);
        }
        return this.route.navigate(['/login']);
      })
      .catch((error) => {
        console.log('logoutError', error);
      });
  }
}
