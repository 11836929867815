import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { Observable, of } from 'rxjs';
import { Icon, RideEdits } from 'src/app/interfaces';

export interface DialogData {
  deletedRide: any;
}
@Component({
  selector: 'app-deleted-ride-information',
  templateUrl: './deleted-ride-information.component.html',
  styleUrls: ['./deleted-ride-information.component.scss']
})
export class DeletedRideInformationComponent implements OnInit {
  organisationId = localStorage.getItem('orgId');
  deletedRide: any;
  details: boolean = false;
  users: Array<any>;
  icons: Observable<Icon[]>;
  iconsCollection: AngularFirestoreCollection<Icon>;
  allEdits: Observable<RideEdits[]>;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public db: AngularFirestore,
  ) { }

  ngOnInit(): void {
    this.deletedRide = this.data.deletedRide;
    if (this.deletedRide.rideData.users) {
      Object.keys(this.deletedRide.rideData.users).forEach((key) => {
        this.deletedRide.rideData.users[key].id = key;
      });
      this.users = Object.values(this.deletedRide.rideData.users).sort((x: any, y: any) => {
        return x.order - y.order;
      });
    }

    let iconsData: Icon[] = [];
    const iconsQuery = query(
      collection(
        this.db.firestore,
        `organisations/${this.organisationId}/icons`
      ),
      orderBy('name', 'asc')
    );
    getDocs(iconsQuery).then((docs) => {
      docs.forEach((doc) => {
        const data = doc.data() as Icon;
        data['id'] = doc.id;
        iconsData.push(data);
      });
    });
    this.icons = of(iconsData);

    let allEditsData: RideEdits[] = [];
    const allEditsQuery = query(
      collection(
        this.db.firestore,
        `eventLogs/${this.organisationId}/deletedRides/${this.deletedRide.rideData.id}/edits`
      ),
      orderBy('date', 'desc')
    )
    getDocs(allEditsQuery).then((docs) => {
      docs.forEach((doc) => {
        const data = doc.data() as RideEdits;
        data['id'] = doc.id;
        allEditsData.push(data);
      });
    });
    this.allEdits = of(allEditsData);
  }
}
