<form
  mat-dialog-content
  fxLayout="column"
  [formGroup]="vehicleForm"
  autocomplete="off"
>
  <mat-form-field>
    <mat-label>Voertuignaam</mat-label>
    <input
      placeholder="Voertuignaam"
      formControlName="name"
      autocomplete="off"
      type="text"
      matInput
      required
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Omschrijving</mat-label>
    <input
      placeholder="Omschrijving"
      type="text"
      formControlName="description"
      matInput
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Aantal zitplaatsen</mat-label>
    <input
      type="number"
      placeholder="Zitplaatsen"
      formControlName="seats"
      matInput
      required
      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
      oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Agendakleur</mat-label>
    <input
      matInput
      placeholder="#ffffff"
      readonly="true"
      formControlName="color"
      [value]="color"
      required
      #ignoredInput
      [cpIgnoredElements]="[ignoredButton, ignoredInput]"
      [(cpToggle)]="toggle"
      [(colorPicker)]="color"
      [cpAlphaChannel]="'disabled'"
      [cpOKButton]="true"
      [cpPosition]="'top'"
      [cpCancelButton]="true"
      [cpCancelButtonClass]="'mat-stroked-button'"
      [cpOKButtonClass]="'mat-stroked-button'"
      [cpCancelButtonText]="'Annuleren'"
    />
    <button
      matSuffix
      mat-icon-button
      #ignoredButton
      type="button"
      (click)="toggle = !toggle"
    >
      <mat-icon
        *ngIf="color !== '#ffffff'"
        [ngStyle]="{
          color: color
        }"
        >circle
      </mat-icon>
      <mat-icon *ngIf="color === '#ffffff'">colorize </mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Volgordenummer</mat-label>
    <input
      type="number"
      placeholder="Volgorde Nummer"
      formControlName="sortingNumber"
      matInput
      required
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Rit leeg achtergrond</mat-label>
    <input
      matInput
      placeholder="#efefef"
      readonly="true"
      formControlName="backgroundColorEmpty"
      [value]="backgroundColorEmpty"
      #ignoredInput
      [cpIgnoredElements]="[ignoredButton, ignoredInput]"
      [(cpToggle)]="toggle2"
      [(colorPicker)]="backgroundColorEmpty"
      [cpAlphaChannel]="'disabled'"
      [cpOKButton]="true"
      [cpPosition]="'top'"
      [cpCancelButton]="true"
      [cpCancelButtonClass]="'mat-stroked-button'"
      [cpOKButtonClass]="'mat-stroked-button'"
      [cpCancelButtonText]="'Annuleren'"
    />
    <button
      matSuffix
      mat-icon-button
      #ignoredButton
      type="button"
      (click)="toggle2 = !toggle2"
    >
      <mat-icon
        *ngIf="backgroundColorEmpty !== '#efefef'"
        [ngStyle]="{
          color: backgroundColorEmpty
        }"
        >circle
      </mat-icon>
      <mat-icon *ngIf="backgroundColorEmpty === '#efefef'">colorize </mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Rit vol achtergrond</mat-label>
    <input
      matInput
      placeholder="#efefef"
      readonly="true"
      formControlName="backgroundColorFull"
      [value]="backgroundColorFull"
      #ignoredInput
      [cpIgnoredElements]="[ignoredButton, ignoredInput]"
      [(cpToggle)]="toggle3"
      [(colorPicker)]="backgroundColorFull"
      [cpAlphaChannel]="'disabled'"
      [cpOKButton]="true"
      [cpPosition]="'top'"
      [cpCancelButton]="true"
      [cpCancelButtonClass]="'mat-stroked-button'"
      [cpOKButtonClass]="'mat-stroked-button'"
      [cpCancelButtonText]="'Annuleren'"
    />
    <button
      matSuffix
      mat-icon-button
      #ignoredButton
      type="button"
      (click)="toggle3 = !toggle3"
    >
      <mat-icon
        *ngIf="backgroundColorFull !== '#efefef'"
        [ngStyle]="{
          color: backgroundColorFull
        }"
        >circle
      </mat-icon>
      <mat-icon *ngIf="backgroundColorFull === '#efefef'">colorize </mat-icon>
    </button>
  </mat-form-field>
  <mat-slide-toggle
    [disabled]="totalActive >= this.maxTotalVehicle && !isActive"
    formControlName="active"
  >
    Actief
  </mat-slide-toggle>
</form>

<div
  mat-dialog-actions
  fxLayout="row wrap"
  fxLayoutAlign="end center"
  fxLayoutGap="6px"
>
  <button
    mat-button
    type="button"
    (click)="close()"
  >
    Annuleren
  </button>
  <button
    mat-raised-button
    type="submit"
    color="primary"
    (click)="onSubmit()"
    [disabled]="vehicleForm.invalid"
  >
    Opslaan
  </button>
</div>
