<div class="wrapper">
  <mat-card>
    <mat-card-content>
      <mat-list role="list">
        <h3>Je mag me plannen voor de volgende voertuigen</h3>
        <mat-list-item
          fxLayout="row"
          fxLayoutAlign="space-between center"
          *ngFor="let vehicle of vehicles"
        >
          <div class="vehicles" fxLayout="column">
            <div
              fxLayout="row"
              class="vehicle-data"
              fxLayoutAlign="space-between center"
            >
              <div class="availibility-vehicles">
                <h4>{{ vehicle.name }}</h4>
                <h5 *ngIf="vehicle.description" class="description">
                  {{ vehicle.description }}
                </h5>
                <h6>Zitplaatsen: {{ vehicle.seats }}</h6>
              </div>
              <div fxFlexAlign="center">
                <mat-slide-toggle
                  (change)="toggleChange($event, vehicle.id)"
                  [disabled]="isDriver && !driverCanModify"
                  [checked]="vehicle.checked"
                >
                </mat-slide-toggle>
              </div>
            </div>
            <mat-divider></mat-divider>
          </div>
        </mat-list-item>
        <mat-list-item *ngIf="vehicles.length === 0">
          <p>Er zijn nog voertuigen aangemaakt.</p>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>
</div>
