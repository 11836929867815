import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { VehiclesComponent } from '../../vehicles/vehicles.component';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
} from '@angular/forms';
@Component({
  selector: 'app-import-export',
  templateUrl: './import-export.component.html',
  styleUrls: ['./import-export.component.scss'],
})
export class ImportExportComponent implements OnInit {
  transferDataGroup: UntypedFormGroup;
  selectedOption: {
    name: string;
    value: string;
  };
  options: any[] = [
    { name: 'Exporteren', value: 'Export', checked: true },
    { name: 'Importeren', value: 'Import', checked: false },
  ];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<VehiclesComponent>,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    console.log('data', this.data);
    this.selectedOption = {
      name: 'Exporteren',
      value: 'Export',
    };

    this.transferDataGroup = this.fb.group({
      type: [this.selectedOption.value, Validators.required],
      startFrom: [new Date(new Date().setMonth(new Date().getMonth() - 1))],
    });
    this.transferDataGroup.controls.type.valueChanges.subscribe((changed) => {
      console.log('changed', changed);
      if (changed.value === 'Export') {
        this.selectedOption = { name: 'Exporteren', value: 'Export' };
      } else {
        this.selectedOption = { name: 'Importeren', value: 'Import' };
      }
      console.log('this.selectedOption', this.selectedOption);
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  save() {
    const saveObj = {
      type: this.selectedOption.value,
    } as any;
    if (this.transferDataGroup.value.startFrom) {
      saveObj.startFrom = this.transferDataGroup.value.startFrom;
    } else if (
      !this.transferDataGroup.value.startFrom &&
      this.data &&
      this.data.showDate
    ) {
      saveObj.startFrom = new Date(
        new Date().setMonth(new Date().getMonth() - 1)
      ); // if no date is selected we will go for 1 month of rides
    }
    console.log('saveObj', saveObj);
    this.dialogRef.close(saveObj);
  }
}
