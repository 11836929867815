import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { getDocs, collection, setDoc, doc } from 'firebase/firestore';
import { Setting } from 'src/app/interfaces';

@Component({
  selector: 'app-manage-paid-functionality',
  templateUrl: './manage-paid-functionality.component.html',
  styleUrls: ['./manage-paid-functionality.component.scss'],
})
export class ManagePaidFunctionalityComponent implements OnInit {
  orgsForm: UntypedFormGroup;
  orgs: any[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    public db: AngularFirestore,
    public dialogRef: MatDialogRef<ManagePaidFunctionalityComponent>
  ) {}

  async ngOnInit(): Promise<void> {
    this.orgsForm = this.fb.group({
      org: ['', Validators.required],
      usesDriverPlanning: [false],
      usesSEPASystem: [false],
      usesDriverRemarksInApp: [false],
      usesUserNotificationSystem: [false],
    });
    await this.getOrgs();
    this.orgsForm.get('org').valueChanges.subscribe((value) => {
      this.resetPaidFunctionalities();
      this.setPaidFunctionalities(value);
    });
  }

  async save() {
    const orgId = this.orgsForm.value.org.id;
    const paidFunctionalities = this.getPaidFunctionalities();

    await setDoc(
      doc(this.db.firestore, `organisations/${orgId}`),
      {
        paidFunctionalities: paidFunctionalities,
      },
      { merge: true }
    );
    if (!paidFunctionalities.usesSEPASystem) {
      await this.setSepaEnabledToFalse(orgId);
    }
    if (!paidFunctionalities.usesDriverRemarksInApp) {
      await this.setDriversAddRemarkInAppToFalse(orgId);
    }
    if (!paidFunctionalities.usesUserNotificationSystem) {
      await this.setUsesUserNotificationSystem(orgId);
    }
    location.reload();
  }

  async setSepaEnabledToFalse(orgId: string) {
    await setDoc(
      doc(this.db.firestore, `organisations/${orgId}/settings/public`),
      {
        sepaEnabled: false,
      },
      { merge: true }
    );
    await setDoc(
      doc(this.db.firestore, `payment-configs/${orgId}`),
      {
        sepa_enabled: false,
      },
      { merge: true }
    );
  }

  async setDriversAddRemarkInAppToFalse(orgId: string) {
    await setDoc(
      doc(this.db.firestore, `organisations/${orgId}/settings/general`),
      {
        driversAddRemarkInApp: false,
      },
      { merge: true }
    );
  }

  async setUsesUserNotificationSystem(orgId: string) {
    await setDoc(
      doc(this.db.firestore, `organisations/${orgId}/settings/general`),
      {
        emailNotificationsParticipants: false,
        smsNotificationsParticipants: false,
      },
      { merge: true }
    );
    await setDoc(
      doc(this.db.firestore, `organisations/${orgId}/settings/public`),
      {
        emailNotificationsParticipants: false,
        smsNotificationsParticipants: false,
      },
      { merge: true }
    );
  }

  getPaidFunctionalities() {
    return {
      usesDriverPlanning: this.orgsForm.value.usesDriverPlanning,
      usesSEPASystem: this.orgsForm.value.usesSEPASystem,
      usesDriverRemarksInApp: this.orgsForm.value.usesDriverRemarksInApp,
      usesUserNotificationSystem:
        this.orgsForm.value.usesUserNotificationSystem,
    };
  }

  resetPaidFunctionalities() {
    this.orgsForm.controls.usesDriverPlanning.setValue(false);
    this.orgsForm.controls.usesSEPASystem.setValue(false);
    this.orgsForm.controls.usesDriverRemarksInApp.setValue(false);
    this.orgsForm.controls.usesUserNotificationSystem.setValue(false);
  }

  setPaidFunctionalities(org: any) {
    console.log(org);
    if (org.paidFunctionalities) {
      this.orgsForm.patchValue(org.paidFunctionalities);
    }
  }

  async getOrgs() {
    const orgDocs = await getDocs(
      collection(this.db.firestore, `organisations`)
    );

    orgDocs.forEach((doc) => {
      this.orgs.push({ ...(doc.data() as Setting), id: doc.id });
    });

    this.orgs.sort((a, b) => a.name.localeCompare(b.name));
  }
}
