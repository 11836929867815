import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { AddExceptionComponent } from './dialog/add-exception/add-exception.component';

@Component({
  selector: 'app-exceptions',
  templateUrl: './exceptions.component.html',
  styleUrls: ['./exceptions.component.scss'],
})
export class ExceptionsComponent implements OnInit {
  exceptions: any[] = []; // dont use a interface because timestams are sh*t :)))
  organisationId = localStorage.getItem('orgId');
  userId: string;
  userIdFromAdmin: string;

  @Input() set getUserId(userId: string) {
    this.userIdFromAdmin = userId ? userId : null;
    this.getException();
    // console.log('value', value);
  }
  @Input() isDriver: boolean;
  @Input() driverCanModify: boolean;

  constructor(
    private db: AngularFirestore,
    private auth: AngularFireAuth,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getException();
  }

  deleteException(index: number) {
    console.log('index', index);
    // this.exceptions.splice(index, 1);
    const tempArray = this.exceptions;
    this.exceptions = [];
    console.log('this.exceptions', this.exceptions);
    tempArray.forEach((exception, indexForEach) => {
      if (index !== indexForEach) {
        this.exceptions.push(exception);
      }
    });
    console.log('this.exceptions', this.exceptions);
    this.db
      .doc(`organisations/${this.organisationId}/users/${this.userId}`)
      .set(
        {
          exceptions: this.exceptions,
        },
        { merge: true }
      );
  }

  getException() {
    this.auth.authState.subscribe(async (user) => {
      this.userId = this.userIdFromAdmin ? this.userIdFromAdmin : user.uid;
      const exceptionRef = this.db.doc(
        `organisations/${this.organisationId}/users/${this.userId}`
      );
      const userData = exceptionRef.valueChanges();
      userData.subscribe((user: any) => {
        this.exceptions = []; // clear array for double data
        if (user.exceptions) {
          user.exceptions.forEach((exception, index) => {
            const newObj = {} as any;
            newObj.dateTo = exception.dateTo.toDate();
            newObj.dateFrom = exception.dateFrom.toDate();
            if (newObj.dateTo < new Date()) {
              // prevent old dates being pushed
              return;
            }
            newObj.index = index;
            newObj.name = exception.name;
            this.exceptions.push(newObj);
          });

          this.exceptions = this.exceptions.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return <any>new Date(a.dateFrom) - <any>new Date(b.dateFrom);
          });
          console.log('exceptions', this.exceptions);
        }
      });
    });
  }

  openAddExceptionDialog() {
    this.dialog.open(AddExceptionComponent, {
      width: '475px',
      data: {
        userId: this.userId,
      },
    });
  }

  get getUserId() {
    return this.userIdFromAdmin;
  }
}
