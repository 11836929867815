<div class="ride-dialog">
  <div mat-dialog-content>
    <form
      *ngIf="!userSelected"
      class="filters"
      [formGroup]="filterForm"
    >
      <mat-chip-list class="chips">
        <mat-chip
          class="chip"
          color="primary"
          (click)="setFilter(filterOption.Name)"
          [selected]="this.filterForm.value.filter === filterOption.Name"
        >
          <mat-icon *ngIf="this.filterForm.value.filter === filterOption.Name">
            check
          </mat-icon>
          Naam
        </mat-chip>
        <mat-chip
          class="chip"
          color="primary"
          (click)="setFilter(filterOption.Email)"
          [selected]="this.filterForm.value.filter === filterOption.Email"
        >
          <mat-icon *ngIf="this.filterForm.value.filter === filterOption.Email">
            check
          </mat-icon>
          E-mail
        </mat-chip>
        <mat-chip
          class="chip"
          color="primary"
          (click)="setFilter(filterOption.Address)"
          [selected]="this.filterForm.value.filter === filterOption.Address"
        >
          <mat-icon
            *ngIf="this.filterForm.value.filter === filterOption.Address"
          >
            check
          </mat-icon>
          Adres
        </mat-chip>
        <mat-chip
          class="chip"
          color="primary"
          (click)="setFilter(filterOption.PhoneNumber)"
          [selected]="this.filterForm.value.filter === filterOption.PhoneNumber"
        >
          <mat-icon
            *ngIf="this.filterForm.value.filter === filterOption.PhoneNumber"
          >
            check
          </mat-icon>
          Telefoonnummer
        </mat-chip>
      </mat-chip-list>
    </form>
    <div *ngIf="!userSelected">
      <mat-form-field class="search">
        <mat-label>Zoeken</mat-label>
        <input
          #userSearch
          matInput
          cdkFocusInitial
          (keyup.enter)="select(searchQuery)"
          [(ngModel)]="searchQuery"
          type="search"
          (ngModelChange)="onFilterChange('search', $event, searchQuery)"
          [matAutocomplete]="userAutocomplete"
          matAutocompletePosition="below"
          [ngClass]="{ searching: autoCompleteOpen }"
        />
      </mat-form-field>
      <mat-autocomplete
        #userAutocomplete="matAutocomplete"
        (opened)="autoCompleteOpen = true"
        (closed)="autoCompleteOpen = false"
      >
        <mat-option
          (click)="select(user)"
          *ngFor="let user of filteredUsers | async"
          [value]="user"
          class="multilines"
        >
          <div class="align-text-to-bottom">
            <div class="flex-one">
              <p *ngIf="user.streetName">
                <span class="bold">{{ user.name }} ({{ user.credits }})</span>
                {{ user.streetName }} {{ user.houseNumber
                }}{{
                  user.houseNumberAddition
                    ? '-' + user.houseNumberAddition
                    : ''
                }}, {{ user.postal }}
                {{ user.city }}
              </p>
              <p *ngIf="!user.streetName">
                <span class="bold">{{ user.name }} ({{ user.credits }})</span>
              </p>
              <div>
                <span *ngIf="user.phoneNumber">{{ user.phoneNumber }}</span>
                <span *ngIf="user.phoneNumber && user.email">,</span>
                <span *ngIf="user.email"> {{ user.email }}</span>
              </div>
            </div>
            <span
              *ngIf="
                user.paymentFailed || (!user.currentPlan && user.credits < 5)
              "
              matTooltip="{{
                user.paymentFailed
                  ? 'Er is een fout opgetreden bij het betalen.'
                  : 'De gebruiker heeft minder dan 5 credits'
              }}"
              [matTooltipPosition]="'above'"
            >
              <mat-icon class="warn-icon">error</mat-icon>
            </span>
          </div>
        </mat-option>
        <mat-option
          class="more"
          *ngIf="potentialItems"
          [value]="searchQuery"
          routerLink="/filter"
          routerLinkActive="active"
          [queryParams]="{ q: searchQuery }"
        >
          Om meer resultaten te zien zoek specifieker.
        </mat-option>
      </mat-autocomplete>
    </div>
    <form
      *ngIf="userSelected"
      fxLayout="column"
      [formGroup]="userForm"
    >
      <mat-label class="title">{{ user['title'] }}</mat-label>
      <div
        class="icons"
        fxLayout="row"
      >
        <div
          class="icon"
          [ngClass]="{ active: user[icon.id] === true }"
          [matTooltip]="icon.name"
          *ngFor="let icon of data.icons"
        >
          <mat-icon *ngIf="icon.iconType == 'material'">
            {{ icon.icon }}</mat-icon
          >
          <div
            class="mat-icon"
            *ngIf="icon.iconType == 'custom'"
            [inlineSVG]="'/assets/icons/' + icon.icon + '.svg'"
          ></div>
        </div>
      </div>
      <div
        class="icon-toggles"
        *ngFor="let icon of data.icons; let i = index"
      >
        <mat-slide-toggle
          (change)="onIconChange(icon.id, $event)"
          *ngIf="icon.editableInRide"
          formControlName="{{ icon.id }}"
        >
          {{ icon.name }}
        </mat-slide-toggle>
      </div>
      <!-- <mat-checkbox formControlName="companion">Neemt een begleider mee</mat-checkbox> -->
      <mat-form-field class="type-select">
        <mat-label>Van</mat-label>
        <mat-select
          formControlName="fromType"
          required
          (selectionChange)="onTypeChange('from', $event)"
        >
          <mat-option
            *ngFor="let type of locationTypes"
            [value]="type.val"
          >
            {{ type.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="!fromHidden">
        <mat-label>Adres</mat-label>
        <input
          formControlName="from"
          placeholder="mijn straat 1, mijn dorp"
          type="text"
          matInput
          required
          [matAutocomplete]="fromAuto"
        />
        <mat-autocomplete
          autoActiveFirstOption
          #fromAuto="matAutocomplete"
        >
          <mat-option
            *ngFor="let option of predictionFrom | async"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field *ngIf="!standardFromHidden">
        <mat-label>Adres</mat-label>
        <mat-select
          formControlName="from"
          required
        >
          <mat-form-field
            class="full-width"
            appearance="standard"
          >
            <input
              type="search"
              formControlName="fromSearch"
              placeholder="Zoekfunctie"
              matInput
              autocomplete="off"
            />
            <button
              mat-button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="clearInput('from')"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-option
            *ngFor="let address of addressesFrom | async"
            [value]="address.id"
            >{{ address.name }},
            {{ address.address }}
          </mat-option>
          <mat-option *ngIf="nothingFound">
            <p>Geen resultaten gevonden</p>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="!recentFromHidden">
        <mat-label>Adres</mat-label>
        <mat-select
          formControlName="from"
          required
        >
          <mat-option
            *ngFor="let destination of recentDestinations"
            [value]="destination"
          >
            {{ destination }}
          </mat-option>
          <mat-option *ngIf="recentDestinations.length === 0">
            <p>Geen ritten bekend</p>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="type-select">
        <mat-label>Naar</mat-label>
        <mat-select
          formControlName="toType"
          required
          (selectionChange)="onTypeChange('to', $event)"
        >
          <mat-option
            *ngFor="let type of locationTypes"
            [value]="type.val"
          >
            {{ type.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="!toHidden">
        <mat-label>Adres</mat-label>
        <input
          formControlName="to"
          placeholder="mijn straat 1, mijn dorp"
          type="text"
          matInput
          required
          [matAutocomplete]="toAuto"
        />
        <mat-autocomplete
          autoActiveFirstOption
          #toAuto="matAutocomplete"
        >
          <mat-option
            *ngFor="let option of predictionTo | async"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field *ngIf="!standardToHidden">
        <mat-label>Adres</mat-label>
        <mat-select
          formControlName="to"
          required
        >
          <mat-form-field
            class="full-width"
            appearance="standard"
          >
            <input
              type="search"
              formControlName="toSearch"
              placeholder="Zoekfunctie"
              matInput
              autocomplete="off"
            />
            <button
              mat-button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="clearInput('to')"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-option
            *ngFor="let address of addressesTo | async"
            [value]="address.id"
            >{{ address.name }},
            {{ address.address }}
          </mat-option>
          <mat-option *ngIf="nothingFound">
            <p>Geen resultaten gevonden</p>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="!recentToHidden">
        <mat-label>Adres</mat-label>
        <mat-select
          formControlName="to"
          required
        >
          <mat-option
            *ngFor="let destination of recentDestinations"
            [value]="destination"
          >
            {{ destination }}
          </mat-option>
          <mat-option *ngIf="recentDestinations.length === 0">
            <p>Geen ritten bekend</p>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="planningComments">
        <mat-label>Opmerkingen voor planner</mat-label>
        <textarea
          formControlName="remarks"
          matInput
        ></textarea>
      </mat-form-field>
      <mat-form-field *ngIf="driverComments">
        <mat-label>Opmerkingen voor chauffeur</mat-label>
        <textarea
          formControlName="commentsForDriver"
          matInput
        ></textarea>
      </mat-form-field>
      <mat-form-field *ngIf="showRideProduct">
        <mat-label>Aangepaste ritprijs</mat-label>
        <mat-select formControlName="rideProduct">
          <mat-option
            style="font-style: italic"
            [value]="'none'"
            >Geen
          </mat-option>
          <mat-option
            *ngFor="let product of products | async"
            [value]="product.id"
            >{{ product.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions>
    <button
      mat-button
      [mat-dialog-close]="false"
    >
      Annuleren
    </button>
    <button
      mat-raised-button
      [disabled]="!userForm.valid"
      (click)="save()"
      color="primary"
    >
      Opslaan
    </button>
  </div>
</div>
