import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateFilter, Month, Year } from 'src/app/interfaces';
import moment from 'moment';

@Component({
  selector: 'app-export-deleted-rides',
  templateUrl: './export-deleted-rides.component.html',
  styleUrls: ['./export-deleted-rides.component.scss']
})
export class ExportDeletedRidesComponent implements OnInit {
  exportForm: UntypedFormGroup;

  selectedFilter: string;
  years: Year[] = [];
  months: Month[] = [
    { value: 0, viewValue: 'Januari' },
    { value: 1, viewValue: 'Februari' },
    { value: 2, viewValue: 'Maart' },
    { value: 3, viewValue: 'April' },
    { value: 4, viewValue: 'Mei' },
    { value: 5, viewValue: 'Juni' },
    { value: 6, viewValue: 'Juli' },
    { value: 7, viewValue: 'Augustus' },
    { value: 8, viewValue: 'September' },
    { value: 9, viewValue: 'Oktober' },
    { value: 10, viewValue: 'November' },
    { value: 11, viewValue: 'December' },
  ];
  dateFilter: DateFilter[] = [
    { value: 'year', viewValue: 'Jaar' },
    { value: 'month', viewValue: 'Maand' },
    { value: 'week', viewValue: 'Week' },
    { value: 'day', viewValue: 'Dag' },
  ];
  selectedYear: number;
  selectedMonth: number;
  selectedWeek: number;
  selectedDay: any;

  weeks: number[] = [];
  days: number[] = [];
  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ExportDeletedRidesComponent>,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.exportForm = this.fb.group({
      period: [, [Validators.required]],
    });

    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
    const currentWeek = parseInt(moment(date).format('W'));
    const years: Year[] = [];
    let yearInStep = currentYear;
    for (let step = 0; step < 10; step++) {
      years.push({ value: yearInStep, viewValue: yearInStep.toString() });
      yearInStep = yearInStep - 1;
    }
    this.years = years;
    this.years.forEach((year) => {
      // console.log(year.value === currentYear);
      if (year.value === currentYear) {
        this.selectedYear = year.value;
      }
    });
    this.months.forEach((month) => {
      // console.log(month.value === currentMonth);
      if (month.value === currentMonth) {
        this.selectedMonth = month.value;
      }
    });
    for (let step = 1; step < 54; step++) {
      this.weeks.push(step);
      if (step === currentWeek) {
        this.selectedWeek = step;
      }
      // yearInStep = yearInStep - 1;
    }
    for (let step = 1; step < 8; step++) {
      this.days.push(step);
      // yearInStep = yearInStep - 1;
    }
  }

  close() {
    if (!this.selectedFilter) {
      return this.snackbar.open('Selecteer een filter', 'X', {
        duration: 5000,
      });
    }
    let valueToExport = {};
    valueToExport['type'] = this.selectedFilter;
    switch (this.selectedFilter) {
      case 'day':
        valueToExport['day'] = this.selectedDay.getDate();
        valueToExport['month'] = this.selectedDay.getMonth();
        valueToExport['year'] = this.selectedDay.getFullYear();
        break;
      case 'week':
        valueToExport['week'] = `${this.selectedYear}-${this.selectedWeek}`;
        break;
      case 'month':
        valueToExport['month'] = this.selectedMonth;
        valueToExport['year'] = this.selectedYear;
        break;
      case 'year':
        valueToExport['year'] = this.selectedYear;
        break;
    }
    if (!valueToExport) {
      return this.snackbar.open('Vul een waarde in', 'X', {
        duration: 5000,
      });
    }
    this.dialogRef.close(valueToExport);
  }
}
