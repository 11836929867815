<div>
  <form
    fxLayout="column"
    (ngSubmit)="onSubmit()"
    [formGroup]="characteristicForm"
    autocomplete="off"
  >
    <mat-form-field>
      <mat-label>Kenmerknaam</mat-label>
      <input
        placeholder="Kenmerknaam"
        formControlName="name"
        autocomplete="off"
        type="text"
        matInput
        required
        (keydown.space)="$event.preventDefault()"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Omschrijving</mat-label>
      <input
        placeholder="Omschrijving"
        type="text"
        formControlName="description"
        matInput
      />
    </mat-form-field>
    <mat-slide-toggle formControlName="active"> Actief </mat-slide-toggle>
    <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="6px">
      <button mat-button type="button" (click)="close()">Annuleren</button>
      <button
        mat-raised-button
        type="submit"
        color="primary"
        [disabled]="characteristicForm.invalid"
      >
        Opslaan
      </button>
    </div>
  </form>
</div>
