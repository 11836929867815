<app-nav-bar></app-nav-bar>
<div class="header" fxLayout="row" fxLayoutAlign=" center">
  <div class="h-title" fxFlex>
    <mat-icon>date_range</mat-icon>
    <span>Dagdelen</span>
  </div>
</div>
<div class="wrapper room-below-card">
  <mat-card>
    <mat-card-header> </mat-card-header>
    <mat-card-content>
      <mat-list role="list">
        <h3 *ngIf="newDayparts.length === 0 && loading === false">
          Er zijn geen dagdelen ingesteld
        </h3>
        <mat-list-item
          *ngFor="let daypart of newDayparts"
          matRipple
          (click)="editDaypart(daypart, daypart.id)"
        >
          <div class="startTime" fxLayout="column">
            <h4>{{ daypart.name }}</h4>
            <div
              fxLayout="row"
              class="dayparts"
              fxLayoutAlign="space-between center"
            >
              <div fxLayout="row" class="time">
                <h5>
                  Van:
                  {{ daypart.startHour }}:{{ daypart.startMin }}
                </h5>
                <h5>
                  Tot:
                  {{ daypart.endHour }}:{{ daypart.endMin }}
                </h5>
                <!-- <h5 *ngIf="daypart.name">({{ daypart.name }})</h5> -->
              </div>

              <div class="button-group" fxLayoutAlign="end center">
                <button
                  mat-icon-button
                  (click)="
                    $event.stopPropagation(); editDaypart(daypart, daypart.id)
                  "
                >
                  <mat-icon> edit </mat-icon>
                </button>

                <button
                  mat-icon-button
                  (click)="$event.stopPropagation(); removeDaypart(daypart.id)"
                >
                  <mat-icon> delete </mat-icon>
                </button>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>
</div>
<button mat-fab class="fab-fixed" color="primary" (click)="addDayPart()">
  <mat-icon>add</mat-icon>
</button>
