import { Component, Inject, OnInit } from '@angular/core';
import { UserId, PaymentMethodsConfig } from 'src/app/interfaces';
import { getDateString } from 'src/app/globals';
import { PaymentMethod } from 'src/app/enums';
import { SepaCustomer } from 'src/app/users/dialogs/create-sepa/sepa-interface';
import { createSepaData } from 'src/app/users/dialogs/create-sepa/sepa-helpers';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileSaverService } from 'ngx-filesaver';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  increment,
  addDoc,
  collection,
  doc,
  runTransaction,
  setDoc,
  getDoc,
} from 'firebase/firestore';
import moment from 'moment';
import sha1 from 'crypto-js/sha1';
import sha256 from 'crypto-js/sha256';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';

export interface DialogData {
  users: UserId[];
  plannerEmail: string;
}

@Component({
  selector: 'app-create-sepa',
  templateUrl: './create-sepa.component.html',
  styleUrls: ['./create-sepa.component.scss'],
})
export class CreateSepaComponent implements OnInit {
  customerForm: UntypedFormGroup;
  loading: boolean = false;
  loadingData: boolean = true;
  organisationId = localStorage.getItem('orgId');
  customers: SepaCustomer[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    public db: AngularFirestore,
    public dialogRef: MatDialogRef<CreateSepaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _FileSaverService: FileSaverService
  ) {}

  ngOnInit(): void {
    this.customerForm = this.fb.group({});
    this.data.users.forEach((user) => {
      if (
        !user.inactive &&
        user.paymentMethod == PaymentMethod.SEPA &&
        user.iban && // Makes sure the required values for sepa creation exist
        user.sepaMandateId && // Makes sure the required values for sepa creation exist
        user.sepaSignatureDate && // Makes sure the required values for sepa creation exist
        user.sepaPath && // Makes sure the required values for sepa creation exist
        user.currentPlan &&
        user.currentPlan.automaticLimit > user.credits
      ) {
        this.customers.push({
          id: user.id,
          name: user.name,
          iban: user.iban,
          mandateId: user.sepaMandateId,
          dateOfSignature: getDateString(user.sepaSignatureDate.toDate()),
          amount: user.currentPlan.automaticAmount,
          credits: user.currentPlan.automaticCredits,
          checked: true,
        });
        this.customerForm.addControl(user.id, new UntypedFormControl(true));
      }
    });
    console.log('customers', this.customers);
    this.loadingData = false;
  }

  customerChecked(event: MatCheckboxChange, value: any) {
    const index = this.customers.indexOf(value);
    this.customers[index]['checked'] = event.checked;
  }

  async createSepa() {
    this.loading = true;
    const paymentMethodsConfigRef = doc(
      this.db.firestore,
      `payment-configs/${this.organisationId}`
    );
    const paymentMethodsConfig = (
      await getDoc(paymentMethodsConfigRef)
    ).data() as PaymentMethodsConfig;
    const dateNow = new Date();
    const paymentReferenceId = moment(dateNow).format('DD-MM-YYYY.HH:MM:SS');
    const creditorName = paymentMethodsConfig.creditor_name;
    const creditorCountry = paymentMethodsConfig.creditor_country;
    const creditorAddress = paymentMethodsConfig.creditor_address;
    const creditorMunicipality = paymentMethodsConfig.creditor_municipality;
    const creditorPostal = paymentMethodsConfig.creditor_postal;
    const creditorIban = paymentMethodsConfig.creditor_iban;
    const creditorId = paymentMethodsConfig.creditor_id;
    const creditorBic = paymentMethodsConfig.creditor_bic;
    let totalAmountToEarn = 0;

    const promises = [];
    const checkedCustomers: SepaCustomer[] = [];

    this.customers.forEach((user) => {
      if (user.checked) {
        const userRef = doc(this.db.firestore, `users/${user.id}`);
        const userOrgRef = doc(
          this.db.firestore,
          `organisations/${this.organisationId}/users/${user.id}`
        );
        promises.push(
          runTransaction(this.db.firestore, async (transaction) => {
            const userDoc = await transaction.get(userOrgRef);
            if (userDoc.exists) {
              const oldCredits = userDoc.data().credits || 0;
              const newCredits = oldCredits + user.credits;
              transaction.update(userOrgRef, {
                credits: newCredits,
              });
            }
          })
        );
        const paymentsRef = collection(
          this.db.firestore,
          `organisations/${this.organisationId}/payments`
        );
        promises.push(
          addDoc(paymentsRef, {
            userId: user.id,
            userRef: userRef,
            date: new Date(),
            creditsAdded: user.credits,
            description: 'Sepa incasso gemaakt',
            status: 'paid',
            type: 'sepa',
            actionBy: this.data.plannerEmail,
          })
        );
        checkedCustomers.push({
          name: user.name,
          iban: user.iban,
          mandateId: user.mandateId,
          dateOfSignature: user.dateOfSignature,
          amount: user.amount,
          credits: user.credits,
        });
        totalAmountToEarn = totalAmountToEarn + user.amount;
      }
    });
    const sepaData = await createSepaData(
      paymentReferenceId,
      dateNow,
      checkedCustomers,
      creditorName,
      creditorCountry,
      creditorAddress,
      creditorMunicipality,
      creditorPostal,
      creditorIban,
      creditorId,
      creditorBic
    );
    console.log('sepaData', sepaData);
    this.createXmlFile(
      paymentReferenceId,
      sepaData,
      `${paymentReferenceId}.xml`
    );
    const yearMonthDocId = moment(dateNow).format('YYYY-MM');
    promises.unshift(
      setDoc(
        doc(
          this.db.firestore,
          `/statistics/${this.organisationId}/sepa-earnings/${yearMonthDocId}`
        ),
        {
          period: yearMonthDocId,
          totalAmountToEarned: increment(totalAmountToEarn),
          totalTransactions: increment(this.customers.length),
          sepaGenerated: increment(1),
        },
        { merge: true }
      )
    );
    promises.unshift(
      setDoc(
        doc(
          this.db.firestore,
          `/organisations/${this.organisationId}/paymentReferences/${paymentReferenceId}`
        ),
        {
          date: dateNow,
          customers: this.customers,
          totalAmountToEarn: totalAmountToEarn,
          creditorName: paymentMethodsConfig.creditor_name,
          creditorCountry: paymentMethodsConfig.creditor_country,
          creditorAddress: paymentMethodsConfig.creditor_address,
          creditorMunicipality: paymentMethodsConfig.creditor_municipality,
          creditorPostal: paymentMethodsConfig.creditor_postal,
          creditorIban: paymentMethodsConfig.creditor_iban,
          creditorId: paymentMethodsConfig.creditor_id,
          creditorBic: paymentMethodsConfig.creditor_bic,
          createdBy: this.data.plannerEmail,
        },
        { merge: true }
      )
    );
    await Promise.all(promises);
    this.loading = false;
    this.dialogRef.close();
  }

  createXmlFile(paymentReferenceId: string, data: string, fileName: string) {
    const checkSumSha1 = sha1(data);
    const checkSumSha256 = sha256(data);
    this._FileSaverService.save(new Blob([data]), fileName);
    const checkSumString = `Checksum SHA-1: ${checkSumSha1}\nChecksum SHA-256: ${checkSumSha256}`;
    this._FileSaverService.save(
      new Blob([checkSumString]),
      `Checksum ${paymentReferenceId}.txt`
    );
  }

  checkboxChecker() {
    let result: boolean = false;
    this.customers.forEach((customer) => {
      if (this.customerForm.controls[customer.id].value) {
        result = true;
      }
    });
    return result;
  }
}
