<div class="wrapper">
  <mat-card>
    <mat-card-content>
      <mat-selection-list role="list" [multiple]="false">
        <h3>
          Klik op een dagdeel waarvoor jij bent ingepland om de details van je
          rit(ten) te zien.
        </h3>
        <mat-list-option
          fxLayout="row"
          fxLayoutAlign="space-between center"
          *ngFor="let driverDaypart of driverDayparts"
          (click)="openDaypartRideDialog(driverDaypart)"
          (keyup.enter)="openDaypartRideDialog(driverDaypart)"
        >
          <div class="startTime" fxLayout="column">
            <h4>{{ driverDaypart.name }}</h4>
          <div
            fxLayout="row"
            class="driver-planned-dayparts"
            fxLayoutAlign="space-between center"
          >
            <div fxLayout="column" class="planned-rides-driver" [ngStyle]="{ 'border-left-color': driverDaypart.vehicle.calendarStyle.color }">
              <h5 style="margin-top:0px !important;">
                Datum: {{ driverDaypart.day }},
                {{ driverDaypart.date | date: 'mediumDate' }}
              </h5>
              <div fxLayout="row">
                <h5>
                  Van:
                  {{ driverDaypart.startHour }}:{{ driverDaypart.startMin }}
                </h5>
                <h5>
                  Tot:
                  {{ driverDaypart.endHour }}:{{ driverDaypart.endMin }}
                </h5>
              </div>
              <div fxLayout="row">
                <h5 *ngIf="driverDaypart.vehicle">Voertuig: {{ driverDaypart.vehicle.name }}</h5>
              </div>
              <!-- <h5 *ngIf="daypart.name">({{ daypart.name }})</h5> -->
            </div>
          </div>
        </div>
        <!-- <mat-divider></mat-divider> -->
        </mat-list-option>
        <p *ngIf="driverDayparts.length === 0 && loading === false">
          Je bent nog nergens voor ingepland
        </p>
      </mat-selection-list>
    </mat-card-content>
  </mat-card>
</div>
