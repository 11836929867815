<h1 mat-dialog-title>Exporteren ritten</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Selecteer filter</mat-label>
    <mat-select [(ngModel)]="selectedFilter">
      <mat-option
        *ngFor="let filter of dateFilter"
        [value]="filter.value"
        >{{ filter.viewValue }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field
    *ngIf="
      selectedFilter == 'year' ||
      selectedFilter == 'month' ||
      selectedFilter == 'week'
    "
  >
    <mat-label>Jaar</mat-label>
    <mat-select [(ngModel)]="selectedYear">
      <mat-option
        *ngFor="let year of years"
        [value]="year.value"
        >{{ year.viewValue }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="selectedFilter == 'month'">
    <mat-label>Maand</mat-label>
    <mat-select [(ngModel)]="selectedMonth">
      <mat-option
        *ngFor="let month of months"
        [value]="month.value"
        >{{ month.viewValue }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="selectedFilter == 'week'">
    <mat-label>Week</mat-label>
    <mat-select [(ngModel)]="selectedWeek">
      <mat-option
        *ngFor="let week of weeks"
        [value]="week"
        >{{ week }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="selectedFilter == 'day'">
    <mat-label>Kies een datum</mat-label>
    <input
      matInput
      [matDatepicker]="picker"
      [(ngModel)]="selectedDay"
    />
    <mat-datepicker-toggle
      matSuffix
      [for]="picker"
    ></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <mat-slide-toggle [(ngModel)]="exportFromAndTo"
    >Exporteer ophaal- en bestemmingsadres</mat-slide-toggle
  >
</div>
<div
  mat-dialog-actions
  align="end"
>
  <button
    mat-button
    mat-dialog-close
  >
    ANNULEREN
  </button>
  <button
    mat-raised-button
    color="primary"
    (click)="close()"
  >
    EXPORTEER
  </button>
</div>
