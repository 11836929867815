<app-nav-bar></app-nav-bar>
<div
  class="users"
  fxLayout="column"
>
  <div class="header">
    <div class="header-internals">
      <div class="header-title">
        <mat-icon>people</mat-icon>
        <div>Deelnemers</div>
      </div>
      <div class="header-button-search">
        <button
          class="header-button sepa-button"
          mat-raised-button
          (click)="requestSepaCreation()"
          *ngIf="publicSettings?.sepaEnabled && shouldShowSepaButton"
        >
          <mat-icon>euro</mat-icon>
          Incasso aanmaken
        </button>

        <button
          class="header-button"
          mat-raised-button
          (click)="importExportUsers()"
        >
          <mat-icon>import_export</mat-icon>
          Exporteren/Importeren
        </button>
        <form [formGroup]="filterForm">
          <div class="search">
            <mat-form-field color="accent">
              <mat-label class="placeholder">Zoeken</mat-label>
              <input
                matInput
                type="search"
                autocomplete="off"
                formControlName="search"
                [(ngModel)]="searchQuery"
              />
              <button
                mat-icon-button
                matSuffix
                (click)="clearSearch()"
              >
                <mat-icon>clear</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
  </div>
  <ng-template #loading>
    <div
      id="loading"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
  <mat-card
    class="room-below-card"
    *ngIf="visibleUsers | async as users; else loading"
  >
    <div class="title">
      <h3>
        Alle deelnemers <span>({{ totalUsers }})</span>
      </h3>
      <h4>Filter</h4>
    </div>
    <form
      class="filters"
      [formGroup]="filterForm"
    >
      <mat-chip-list class="chips">
        <mat-chip
          class="chip"
          color="primary"
          [selected]="this.filterForm.value.filter === filterOption.NoCards"
          (click)="setFilter(filterOption.NoCards)"
        >
          <mat-icon
            *ngIf="this.filterForm.value.filter === filterOption.NoCards"
          >
            check
          </mat-icon>
          Zonder kaart
        </mat-chip>
        <mat-chip
          class="chip"
          color="primary"
          [selected]="this.filterForm.value.filter === filterOption.NoPlan"
          (click)="setFilter(filterOption.NoPlan)"
        >
          <mat-icon
            *ngIf="this.filterForm.value.filter === filterOption.NoPlan"
          >
            check
          </mat-icon>
          Zonder betaalplan
        </mat-chip>
        <mat-chip
          class="chip"
          color="primary"
          [selected]="
            this.filterForm.value.filter &&
            this.filterForm.value.filter !== filterOption.NoCards &&
            this.filterForm.value.filter !== filterOption.NoPlan
          "
          [matMenuTriggerFor]="additionalFiltersMenu"
        >
          <mat-icon
            *ngIf="
              this.filterForm.value.filter &&
              this.filterForm.value.filter !== filterOption.NoCards &&
              this.filterForm.value.filter !== filterOption.NoPlan
            "
          >
            check
          </mat-icon>
          Meldingen
          <mat-icon class="trailing">arrow_drop_down</mat-icon>
        </mat-chip>

        <mat-menu #additionalFiltersMenu="matMenu">
          <button
            [class.active-list-item]="
              this.filterForm.value.filter === filterOption.WarningLowCredits
            "
            mat-menu-item
            (click)="setFilter(filterOption.WarningLowCredits)"
          >
            Te weinig credits
          </button>
          <button
            [class.active-list-item]="
              this.filterForm.value.filter === filterOption.WarningPaymentError
            "
            mat-menu-item
            (click)="setFilter(filterOption.WarningPaymentError)"
          >
            Fout tijdens betalen
          </button>
          <button
            *ngIf="publicSettings.sepaEnabled"
            [class.active-list-item]="
              this.filterForm.value.filter ===
              filterOption.WarningSendSepaMandate
            "
            mat-menu-item
            (click)="setFilter(filterOption.WarningSendSepaMandate)"
          >
            Verstuur SEPA machtiging
          </button>
          <button
            *ngIf="publicSettings.sepaEnabled"
            [class.active-list-item]="
              this.filterForm.value.filter ===
              filterOption.WarningUploadSepaMandate
            "
            mat-menu-item
            (click)="setFilter(filterOption.WarningUploadSepaMandate)"
          >
            Upload SEPA machtiging
          </button>
        </mat-menu>
      </mat-chip-list>
      <div>
        <button
          mat-icon-button
          (click)="
            this.filterForm.controls.showHidden.setValue(
              !this.filterForm.value.showHidden
            )
          "
          [matTooltip]="
            this.filterForm.value.showHidden
              ? 'Laat verstopte deelnemers zien'
              : 'Laat verstopte deelnemers niet meer zien'
          "
        >
          <mat-icon *ngIf="this.filterForm.value.showHidden">
            visibility_off
          </mat-icon>
          <mat-icon *ngIf="!this.filterForm.value.showHidden">
            visibility
          </mat-icon>
        </button>
      </div>
    </form>
    <mat-table
      #table
      [dataSource]="users"
    >
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef
          >Naam
          <button
            mat-icon-button
            (click)="
              this.filterForm.controls.aToZ.setValue(
                !this.filterForm.value.aToZ
              )
            "
            matTooltip="Sorteren omdraaien"
          >
            <mat-icon *ngIf="this.filterForm.value.aToZ">
              arrow_upward
            </mat-icon>
            <mat-icon *ngIf="!this.filterForm.value.aToZ">
              arrow_downward
            </mat-icon>
          </button></mat-header-cell
        >
        <mat-cell *matCellDef="let user">
          <div class="icons-wrapper">
            <span
              *ngIf="
                user.warnings.length > 1 &&
                user.warnings[0].description !==
                  'Er moet een SEPA machtiging verstuurd worden.'
              "
              matTooltip="De gebruiker heeft meerdere waarschuwingen."
              matTooltipPosition="right"
            >
              <div class="circle-icon red-bg">{{ user.warnings.length }}</div>
            </span>
            <span
              *ngIf="
                user.warnings.length > 1 &&
                user.warnings[0].description ===
                  'Er moet een SEPA machtiging verstuurd worden.'
              "
              [matTooltip]="user.warnings[0].description"
              matTooltipPosition="right"
            >
              <div
                class="circle-icon"
                [ngClass]="user.warnings[0].colorClass"
              >
                {{
                  user.warnings[0].type === 'text'
                    ? user.warnings[0].iconOrText
                    : ''
                }}
                <mat-icon *ngIf="user.warnings[0].type === 'icon'">{{
                  user.warnings[0].iconOrText
                }}</mat-icon>
              </div>
            </span>
            <span
              *ngIf="user.warnings.length === 1"
              [matTooltip]="user.warnings[0].description"
              matTooltipPosition="right"
            >
              <div
                class="circle-icon"
                [ngClass]="user.warnings[0].colorClass"
              >
                {{
                  user.warnings[0].type === 'text'
                    ? user.warnings[0].iconOrText
                    : ''
                }}
                <mat-icon *ngIf="user.warnings[0].type === 'icon'">{{
                  user.warnings[0].iconOrText
                }}</mat-icon>
              </div>
            </span>
          </div>
          <p class="text-truncate">
            {{ user.name }} {{ user.lastName }}
            <span *ngIf="user.credits">({{ user.credits }})</span>
          </p>
        </mat-cell>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef>E-mail</mat-header-cell>
        <mat-cell
          *matCellDef="let user"
          [ngClass]="{ 'no-payment-plan': !user.currentPlan }"
        >
          <p class="email text-truncate">
            {{ user.email }}
          </p>
        </mat-cell>
      </ng-container>

      <!-- DoB Column -->
      <ng-container matColumnDef="birthDate">
        <mat-header-cell
          *matHeaderCellDef
          fxHide
          fxShow.gt-md
        >
          Geboortedatum
        </mat-header-cell>
        <mat-cell
          *matCellDef="let user"
          [ngClass]="{ 'no-payment-plan': !user.currentPlan }"
          fxHide
          fxShow.gt-md
        >
          <p
            class="birthDate text-truncate"
            *ngIf="user.birthDate"
          >
            {{ user.birthDate.toDate() | date : 'mediumDate' }}
          </p>
        </mat-cell>
      </ng-container>

      <!-- Phone Column -->
      <ng-container matColumnDef="phone">
        <mat-header-cell
          *matHeaderCellDef
          fxHide
          fxShow.gt-md
        >
          Telefoonnummer
        </mat-header-cell>
        <mat-cell
          *matCellDef="let user"
          fxHide
          fxShow.gt-md
          [ngClass]="{ 'no-payment-plan': !user.currentPlan }"
        >
          <p class="phone text-truncate">
            {{ user.phoneNumber }}
          </p>
        </mat-cell>
      </ng-container>

      <!-- Plan Column -->
      <ng-container matColumnDef="plan">
        <mat-header-cell
          *matHeaderCellDef
          fxHide
          fxShow.gt-md
        >
          Plan
        </mat-header-cell>
        <mat-cell
          *matCellDef="let user"
          fxHide
          fxShow.gt-md
          [ngClass]="{ 'no-payment-plan': !user.currentPlan }"
        >
          <mat-chip-list
            cdkDropListOrientation="horizontal"
            *ngIf="user.currentPlan"
          >
            <mat-chip>
              {{ user.currentPlan.name }}
            </mat-chip>
          </mat-chip-list>
        </mat-cell>
      </ng-container>

      <!-- hiddenRemarks Column -->
      <ng-container matColumnDef="hiddenRemarks">
        <mat-header-cell *matHeaderCellDef>Opmerkingen</mat-header-cell>
        <mat-cell
          *matCellDef="let user"
          [ngClass]="{ 'no-payment-plan': !user.currentPlan }"
        >
          <p class="hiddenRemarks text-truncate">
            {{ user.hiddenRemarks }}
          </p>
        </mat-cell>
      </ng-container>

      <!-- RegDate Column -->
      <ng-container matColumnDef="registrationDate">
        <mat-header-cell
          *matHeaderCellDef
          fxHide
          fxShow.gt-md
        >
          Registratiedatum
        </mat-header-cell>
        <mat-cell
          *matCellDef="let user"
          fxHide
          fxShow.gt-md
          [ngClass]="{ 'no-payment-plan': !user.currentPlan }"
        >
          <p
            class="phone text-truncate"
            *ngIf="user.createdAt"
          >
            {{ user.createdAt | date : 'd/MM/yyyy' }}
          </p>
        </mat-cell>
      </ng-container>

      <!-- CardId Column -->
      <ng-container matColumnDef="cardId">
        <mat-header-cell *matHeaderCellDef>Kaartnummer</mat-header-cell>
        <mat-cell
          *matCellDef="let user"
          [ngClass]="{ 'no-payment-plan': !user.currentPlan }"
        >
          <p class="phone text-truncate">
            {{ user.cardId }}
          </p>
        </mat-cell>
      </ng-container>

      <!-- SEPA Column -->
      <ng-container matColumnDef="sepa">
        <mat-header-cell
          *matHeaderCellDef
          fxHide
          fxShow.gt-md
        >
          Openstaande betaling
        </mat-header-cell>
        <mat-cell
          *matCellDef="let user"
          [ngClass]="{ 'no-payment-plan': !user.currentPlan }"
        >
          <p
            class="phone text-truncate"
            *ngIf="user.sepaData && user.sepaData.hasOpenSepa; else hasNoSepa"
          >
            {{ user.sepaData.sepaSentOn | date : 'd/MM/yyyy' }}
          </p>
          <ng-template #hasNoSepa> </ng-template>
        </mat-cell>
      </ng-container>

      <!-- Buttons Column -->
      <ng-container matColumnDef="buttons">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let user">
          <div
            fxFlex="row"
            fxLayoutAlign="end center"
          >
            <button
              mat-icon-button
              [matMenuTriggerFor]="moreMenu"
              aria-label="More"
              (click)="$event.stopPropagation()"
            >
              <mat-icon class="secondary-text">more_vert</mat-icon>
            </button>

            <mat-menu #moreMenu="matMenu">
              <button
                mat-menu-item
                (click)="showCreditsHistory(user)"
              >
                <span>Credits historie</span>
              </button>
              <button
                mat-menu-item
                (click)="changeCredits('add', user)"
              >
                <span>Credits toevoegen</span>
              </button>
              <button
                mat-menu-item
                (click)="changeCredits('remove', user)"
              >
                <span>Credits verminderen</span>
              </button>
              <a href="mailto:{{ user.email }}">
                <button mat-menu-item>
                  <span>Verstuur e-mail</span>
                </button>
              </a>
              <button
                mat-menu-item
                (click)="copyEmail(user.email)"
              >
                <span>Kopieer e-mail</span>
              </button>
              <button
                *ngIf="!user.hidden"
                mat-menu-item
                (click)="changeHiddenStatus('hide', user)"
              >
                <span>Verstop deelnemer</span>
              </button>
              <button
                *ngIf="user.hidden"
                mat-menu-item
                (click)="changeHiddenStatus('show', user)"
              >
                <span>Deelnemer weer laten zien</span>
              </button>
              <button
                [disabled]="user.deleted"
                mat-menu-item
                (click)="deleteUser(user)"
              >
                <span>Verwijder deelnemer</span>
              </button>
            </mat-menu>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let user; columns: displayedColumns"
        class="user"
        (click)="editUser(user)"
        [ngClass]="{ 'no-payment-plan': !user.currentPlan }"
        matRipple
      >
      </mat-row>
    </mat-table>
    <mat-list
      class="load-more"
      *ngIf="totalUsers > amountToShow"
    >
      <mat-list-item (click)="loadMore()">Meer laden... </mat-list-item>
    </mat-list>
  </mat-card>
</div>
<button
  mat-fab
  class="fab-fixed"
  (click)="newUser()"
>
  <mat-icon>add</mat-icon>
</button>
