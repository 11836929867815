import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  NgZone,
} from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Observable, Subject, ReplaySubject, combineLatest } from 'rxjs';
import { map, debounceTime, take } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { UntypedFormGroup } from '@angular/forms';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  HistoryId,
  User,
  UserId,
  Vehicle,
  DateFilter,
  Month,
  Year,
  History,
  Setting,
} from 'src/app/interfaces';
import { logExport, redirectIfDriver } from '../../globals';
import { Router } from '@angular/router';
const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AuditComponent implements OnInit {
  usersCollection: AngularFirestoreCollection<User>;
  usersDocument: AngularFirestoreDocument<User>;
  $users: Observable<UserId[]>;
  filter: string;
  users: UserId[];
  userId: string;
  userData: Observable<User>;
  history: HistoryId[];
  charges: HistoryId[];
  chargesCollection: AngularFirestoreCollection<History>;
  payments: HistoryId[];
  paymentCollection: AngularFirestoreCollection<History>;
  displayedColumns = []; // add "buttons" to show the button column
  totalUsers: number;
  orderBy = 'name';
  orderByChanged: Subject<string> = new Subject<string>();
  orderDirection = 'asc';
  orderDirectionChanged: Subject<string> = new Subject<string>();
  searchQuery: string;
  searchQueryChanged: Subject<string> = new Subject<string>();
  $dataUpdated: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  filteredHistory: History[] = [];
  public $filteredHistory: ReplaySubject<History[]> = new ReplaySubject<
    History[]
  >(1);
  defaultAmountToShow = 25;
  amountToShow = 25;
  amountToShowChanged: Subject<number> = new Subject<number>();
  loading = true;
  dateForm: UntypedFormGroup;
  dateFilter: DateFilter[] = [
    { value: 'year', viewValue: 'Jaar' },
    { value: 'month', viewValue: 'Maand' },
    { value: 'week', viewValue: 'Week' },
  ];
  years: Year[] = [];
  currentYear;
  months: Month[] = [
    { value: 0, viewValue: 'Januari' },
    { value: 1, viewValue: 'Februari' },
    { value: 2, viewValue: 'Maart' },
    { value: 3, viewValue: 'April' },
    { value: 4, viewValue: 'Mei' },
    { value: 5, viewValue: 'Juni' },
    { value: 6, viewValue: 'Juli' },
    { value: 7, viewValue: 'Augustus' },
    { value: 8, viewValue: 'September' },
    { value: 9, viewValue: 'Oktober' },
    { value: 10, viewValue: 'November' },
    { value: 11, viewValue: 'December' },
  ];
  currentMonth: any;
  selectedYear: any;
  selectedMonth: any;
  selectedWeek: any;
  selectedFilter: any;
  weekArray: number[] = Array(53)
    .fill(0)
    .map((x, i) => i + 1);
  // dateFrom: Date;
  // dateTo: Date;
  organisationId = localStorage.getItem('orgId');
  vehicles: Map<string, Vehicle> = new Map();
  allAudits: number;
  characteristicsStatus: boolean;

  @ViewChild('TABLE', { static: false }) table: ElementRef;

  constructor(
    public db: AngularFirestore,
    private auth: AngularFireAuth,
    private snackBar: MatSnackBar,
    private zone: NgZone,
    private router: Router
  ) {}

  async ngOnInit() {
    this.userId = (await this.auth.currentUser).uid;
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
    const years: Year[] = [];
    let yearInStep = currentYear;
    for (let step = 0; step < 10; step++) {
      years.push({ value: yearInStep, viewValue: yearInStep.toString() });
      yearInStep = yearInStep - 1;
    }
    this.years = years;
    this.years.forEach((year) => {
      // console.log(year.value === currentYear);
      if (year.value === currentYear) {
        this.selectedYear = year.value;
      }
    });
    this.months.forEach((month) => {
      // console.log(month.value === currentMonth);
      if (month.value === currentMonth) {
        this.selectedMonth = month.value;
      }
    });
    this.selectedWeek = parseInt(moment().format('W'));

    this.selectedFilter = this.dateFilter[1].value;
    this.usersCollection = this.db.collection<User>(
      `organisations/${this.organisationId}/users`
    );
    this.usersDocument = this.db.doc<User>(
      `organisations/${this.organisationId}/users/${this.userId}`
    );

    const settingsDocument = (
      await this.db
        .collection<Setting>(`organisations/${this.organisationId}/settings`)
        .doc(`general`)
        .get()
        .toPromise()
    ).data();

    this.characteristicsStatus = settingsDocument.characteristicsStatus;

    if (this.characteristicsStatus) {
      this.displayedColumns = [
        'name',
        'credits',
        'date',
        'description',
        'vehicle',
        'characteristics',
        'remarks',
        'actionBy',
      ];
    } else {
      this.displayedColumns = [
        'name',
        'credits',
        'date',
        'description',
        'vehicle',
        'remarks',
        'actionBy',
      ];
    }

    this.$users = this.usersCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as User;
          // console.log('tag', data);
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      ),
      take(2)
    );

    const vehicleArray = await (
      await this.db
        .collection<Vehicle>(`organisations/${this.organisationId}/vehicles`)
        .get()
        .toPromise()
    ).docs;
    vehicleArray.forEach((vehicleDoc) => {
      const vehicle = vehicleDoc.data();
      this.vehicles.set(vehicleDoc.id, vehicle);
    });

    // Gets the filtered list
    this.getAuditData();

    // Changes the list when dateFrom changed
    this.combineObservables();
    this.amountToShowChanged.next(this.amountToShow);

    this.$filteredHistory.subscribe((history) => {
      console.log('visibleHistory', history);
    });

    // this.userData = this.usersDocument.valueChanges().pipe(take(1));
    // this.userData.subscribe((userData) => {
    //   if (userData.filterType === 'year') {
    //     this.setFilterType('year');
    //   } else if (userData.filterType === 'week') {
    //     this.setFilterType('week');
    //   } else {
    //     this.setFilterType('month');
    //   }
    // });
    const user = (await this.usersDocument.get().toPromise()).data() as User;
    if (user.filterObj?.filterValue === 'year') {
      this.setFilterType('month');
    } else if (user.filterObj?.filterValue === 'week') {
      this.setFilterType('week');
    } else {
      this.setFilterType('month');
    }

    this.zone.runOutsideAngular(() => {
      window.addEventListener('scroll', async (e) => {
        if (
          window.innerHeight + window.scrollY >=
          document.body.scrollHeight - 5
        ) {
          // you're at the bottom of the page
          console.log('loading more');
          this.zone.run(() => {
            this.loadMore();
          });
        }
      });
    });
    redirectIfDriver(this.db.firestore, this.router);
  }

  async setFilterType(filter) {
    this.selectedFilter = filter;
    console.log('filter', filter);
    const saveObj = {
      filterObj: {
        filterValue: this.selectedFilter,
      } as any,
    };
    this.getAuditData();
    await this.usersDocument.set(saveObj, { merge: true });
  }

  async combineObservables() {
    const searchFilter = combineLatest([
      this.$users,
      this.searchQueryChanged,
      this.orderByChanged,
      this.orderDirectionChanged,
      this.$dataUpdated,
      this.amountToShowChanged.pipe(debounceTime(300)),
    ]);
    searchFilter.pipe(debounceTime(300)).subscribe(async (values) => {
      console.log('values', values);
      let history: HistoryId[] = [];
      const users = values[0];
      const amountToShow = values[5];
      // const searchQuery = values[1];
      // const filters = {
      //   orderBy: values[2],
      //   orderDirection: values[3],
      // };
      if (!this.charges || !this.payments) {
        return;
      }
      this.charges.forEach((charge) => {
        users.forEach(async (user) => {
          if (user.id === charge.userRef.id) {
            charge.userData = user;
            history.push(charge);
          }
        });
      });
      this.payments.forEach((payment) => {
        users.forEach((user) => {
          if (user.id === payment.userRef.id) {
            payment.userData = user;
            history.push(payment);
          }
        });
      });
      history = history.sort((x, y) => {
        return x.date.toDate() - y.date.toDate();
      });
      for (let i = 0; i < history.length; i++) {
        // console.log('history', history[i]);
        if (history[i].rideId && history[i].rideId !== 'geen') {
          history[i].vehicleName = this.vehicles.get(history[i].vehicleId)
            ? this.vehicles.get(history[i].vehicleId).name
            : 'Bestaat niet meer';
        } else {
          history[i].vehicleName = '-';
        }
        if (!history[i].remarks) {
          history[i].remarks = '-';
        }
        if (!history[i].actionBy) {
          history[i].actionBy = '-';
        }
      }
      this.history = history.reverse();
      console.log('this.history', this.history);

      const filteredHistory = history.filter((item) => this.checkFilters(item));
      console.log('filteredHistoryConst', filteredHistory);
      this.allAudits = filteredHistory.length;
      this.totalUsers = filteredHistory.length;
      console.log('filteredHistory', filteredHistory);
      this.filteredHistory = [...filteredHistory];
      this.$filteredHistory.next([...filteredHistory].splice(0, amountToShow));
      this.loading = false;
    });
    this.searchQueryChanged.next('');
    this.orderByChanged.next(this.orderBy);
    this.orderDirectionChanged.next(this.orderDirection);
    this.searchQueryChanged.subscribe((value) => {
      console.log('searchQueryChanged', value);
    });
  }
  async getAuditData() {
    console.log('this.selectedFilter', this.selectedFilter);
    console.log('this.selectedYear', this.selectedYear);
    console.log('this.selectedMonth', this.selectedMonth);
    console.log('this.selectedWeek', this.selectedWeek);
    this.amountToShow = this.defaultAmountToShow;
    this.amountToShowChanged.next(this.amountToShow);
    this.chargesCollection = this.db.collection<History>(
      `organisations/${this.organisationId}/charges`,
      (ref) => ref.where('year', '==', this.selectedYear)
    );
    this.paymentCollection = this.db.collection<History>(
      `organisations/${this.organisationId}/payments`,
      (ref) => ref.where('year', '==', this.selectedYear)
    );
    if (this.selectedFilter === 'month') {
      this.chargesCollection = this.db.collection<History>(
        `organisations/${this.organisationId}/charges`,
        (ref) =>
          ref
            .where('year', '==', this.selectedYear)
            .where('month', '==', this.selectedMonth)
      );
      this.paymentCollection = this.db.collection<History>(
        `organisations/${this.organisationId}/payments`,
        (ref) =>
          ref
            .where('year', '==', this.selectedYear)
            .where('month', '==', this.selectedMonth)
      );
    }
    if (this.selectedFilter === 'week') {
      const dateStringToCompare = `${this.selectedYear}-${this.selectedWeek}`;
      this.chargesCollection = this.db.collection<History>(
        `organisations/${this.organisationId}/charges`,
        (ref) =>
          ref
            .where('year', '==', this.selectedYear)
            .where('week', '==', dateStringToCompare)
      );
      this.paymentCollection = this.db.collection<History>(
        `organisations/${this.organisationId}/payments`,
        (ref) =>
          ref
            .where('year', '==', this.selectedYear)
            .where('week', '==', dateStringToCompare)
      );
    }
    this.charges = await this.chargesCollection
      .get()
      .toPromise()
      .then((chargesSnap) => {
        const charges: HistoryId[] = [];
        chargesSnap.forEach((doc) => {
          charges.push(doc.data() as any);
        });
        this.$dataUpdated.next(true);
        return charges;
      });
    this.payments = await this.paymentCollection
      .get()
      .toPromise()
      .then((paymentsSnap) => {
        const payments: HistoryId[] = [];
        paymentsSnap.forEach((doc) => {
          payments.push(doc.data() as any);
        });
        this.$dataUpdated.next(true);
        return payments;
      });

    // TODO: !!!!this is triggered before any data is received... entire async of this page is broken!
    // just quick fix this with async gets for now..
  }

  onFilterChange(type, ev?, id?) {
    this.amountToShow = this.defaultAmountToShow;
    this.amountToShowChanged.next(this.amountToShow);
    if (type === 'search') {
      this.searchQueryChanged.next(ev);
    } else if (type === 'order') {
      this.orderByChanged.next(this.orderBy);
      this.orderDirectionChanged.next(this.orderDirection);
    }
  }
  checkFilters(history) {
    // let passesHiddenFilter = true;
    let passesSearchFilter = true;

    // if (filters.showHidden && history.hidden || !filters.showHidden && !history.hidden) {
    //   passesHiddenFilter = true;
    // } else {
    //   passesHiddenFilter = false;
    // }
    if (this.searchQuery) {
      passesSearchFilter = false;
      if (history.userData.name) {
        if (
          history.userData.name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        ) {
          // console.log('Passed name');
          passesSearchFilter = true;
        }
      }
      if (history.actionBy) {
        if (
          history.actionBy
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        ) {
          // console.log('Passed actionBy');
          passesSearchFilter = true;
        }
      }
      if (history.description) {
        if (
          history.description
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        ) {
          // console.log('Passed description');
          passesSearchFilter = true;
        }
      }
      if (history.remarks) {
        if (
          history.remarks.toLowerCase().includes(this.searchQuery.toLowerCase())
        ) {
          // console.log('Passed remarks');
          passesSearchFilter = true;
        }
      }
      if (history.vehicleName) {
        if (
          history.vehicleName
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        ) {
          // console.log('Passed vehicleName');
          passesSearchFilter = true;
        }
      }
      if (history.characteristics) {
        history.characteristics?.forEach((characteristic: string) => {
          if (
            characteristic
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase())
          ) {
            passesSearchFilter = true;
          }
        });
      }
    }

    if (passesSearchFilter) {
      // console.log('user passes filter:', user);
      return history;
    }
  }

  loadMore() {
    if (this.totalUsers <= this.amountToShow || this.loading) {
      return;
    }
    console.log('loadMore called');
    this.loading = true;
    this.amountToShow = this.amountToShow + 10;
    this.amountToShowChanged.next(this.amountToShow);
  }

  async exportFilteredAudit() {
    const exportHistory = [];
    let errorCounter = 0;

    console.log('this.filteredHistory', this.filteredHistory);

    this.filteredHistory.forEach(async (entry) => {
      try {
        const exportEntry: any = {};
        exportEntry.Naam = entry.userData.name;
        exportEntry.Datum = entry.date.toDate();
        if (entry['creditsSpent']) {
          exportEntry.Credits = -entry.creditsSpent;
        } else if (entry['creditsAdded']) {
          exportEntry.Credits = entry.creditsAdded;
        }
        exportEntry.Beschrijving = entry['description'];
        exportEntry.Voertuig = entry['vehicleName'];
        if (this.characteristicsStatus) {
          exportEntry.Kenmerken = entry.characteristics
            ? entry.characteristics.toString().replaceAll(',', ', ')
            : '-';
        }
        exportEntry.Opmerkingen = entry['remarks'];
        exportEntry.Behandelaar = entry['actionBy'];
        if (entry['rideId']) {
          exportEntry.Rit_ID = entry['rideId'];
        } else {
          exportEntry.Rit_ID = '-';
        }
        exportHistory.push(exportEntry);
      } catch (err) {
        errorCounter++;
      }
    });
    console.log('exportHistory', exportHistory);
    let msg;
    if (exportHistory.length > 0) {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportHistory, {
        cellDates: true,
        dateNF: 'dd/mm/yyyy',
      }); // converts a DOM TABLE element to a worksheet
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Audits');

      // /* save to file */
      const date = moment(new Date()).format('YY-MM-DD');
      XLSX.writeFile(wb, `Audit_Export_${date}.xlsx`);
      msg = 'Bestand geëxporteerd.';
      await logExport('audit');
    } else {
      // Nothing to export
      msg = 'Geen exporteerbare data binnen geselecteerde filters.';
    }
    if (errorCounter) {
      msg = msg + ` ${errorCounter} rijen konden niet worden verwerkt.`;
    }
    this.snackBar.open(msg, '', {
      duration: 5000,
    });
  }
}
