<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
  <p class="dialog-text">
    {{ text }}
  </p>
  <div
    class="focused-data-center"
    *ngIf="data.optionalData?.sepaMandateId"
  >
    {{ data.optionalData.sepaMandateId }}
  </div>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    [mat-dialog-close]="false"
  >
    Nee
  </button>
  <button
    mat-raised-button
    color="warn"
    [mat-dialog-close]="true"
    cdkFocusInitial
  >
    Ja
  </button>
</div>
