<h1
  mat-dialog-title
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  fxLayout="row"
  *ngIf="!loading"
>
  <mat-icon
    *ngIf="data.ride.hasCopy"
    class="ride-status-icon"
  >
    repeat
  </mat-icon>
  <span
    class="title"
    fxFlex
    *ngIf="data.ride.title"
    >{{ data.ride.title }}</span
  >
  <span
    class="title"
    fxFlex
    *ngIf="!data.ride.title"
    >{{ data.ride.availableSeats }} Vrij</span
  >
  <div class="buttons">
    <button
      mat-icon-button
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      *ngIf="!data.readOnly"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        (click)="viewEdits()"
      >
        Laat alle bewerkingen zien
      </button>
      <button
        mat-menu-item
        (click)="deleteRide()"
      >
        Rit verwijderen
      </button>
    </mat-menu>
    <button
      mat-icon-button
      (click)="generateRetour()"
      matTooltip="Retourrit maken"
      *ngIf="!data.readOnly"
    >
      <mat-icon>fast_rewind</mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="copyRide()"
      matTooltip="Rit kopiëren"
      *ngIf="!data.readOnly"
    >
      <mat-icon>file_copy</mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="editRide()"
      matTooltip="Rit aanpassen"
      *ngIf="!data.readOnly"
    >
      <mat-icon>edit</mat-icon>
    </button>
  </div>
</h1>
<div
  mat-dialog-content
  *ngIf="!loading"
>
  <div
    class="info"
    *ngIf="data.ride.remarkFromDriver"
  >
    <h4>Ritopmerkingen</h4>
    <p class="remark">{{ data.ride.remarkFromDriver }}</p>
  </div>

  <div
    class="info"
    *ngIf="data.vehicle"
  >
    <h4>Voertuig</h4>
    <p>{{ data.vehicle.name }}</p>
  </div>

  <div class="info">
    <h4>Begintijd</h4>
    <p>
      {{ data.ride.start | date : 'd/MM HH:mm' }} -
      {{ data.ride.end | date : 'd/MM HH:mm' }}
    </p>
  </div>

  <div
    class="info"
    *ngIf="showRideProduct && data.ride.rideProduct"
  >
    <h4>Ritprijs</h4>
    <p>
      {{ data.ride.rideProduct['name'] }} -
      {{ data.ride.rideProduct['cost'] }} Credits
    </p>
  </div>

  <div
    class="info"
    *ngIf="data.ride.comments"
  >
    <h4>Opmerkingen</h4>
    <p>{{ data.ride.comments }}</p>
  </div>

  <div
    class="info"
    *ngIf="data.ride.users"
  >
    <h4>Ophalen</h4>
    <div
      class="user"
      *ngFor="let user of users; let i = index"
    >
      <div class="name">
        {{ i + 1 }}. {{ user.title }}
        <span *ngIf="user.phoneNumber">
          (
          <a href="tel:{{ user.phoneNumber }}">{{ user.phoneNumber }}</a>
          )
        </span>
      </div>
      <div
        class="icons"
        fxLayout="row"
      >
        <div
          class="icon"
          [ngClass]="{ active: user[icon.id] === true }"
          [matTooltip]="icon.name"
          *ngFor="let icon of icons | async"
        >
          <mat-icon *ngIf="icon.iconType == 'material'">
            {{ icon.icon }}</mat-icon
          >
          <div
            class="mat-icon"
            *ngIf="icon.iconType == 'custom'"
            [inlineSVG]="'/assets/icons/' + icon.icon + '.svg'"
          ></div>
        </div>
      </div>
      <div *ngIf="user.commentsForDriver">
        <h5 class="comment-for-info">Opmerkingen voor de chauffeur:</h5>
        <p class="remark participant-remark">{{ user.commentsForDriver }}</p>
      </div>
      <div *ngIf="user.remarkFromDriver">
        <h5 class="comment-for-info">Opmerkingen door chauffeur:</h5>
        <p class="remark participant-remark">{{ user.remarkFromDriver }}</p>
      </div>
      <div class="location">
        <a
          *ngIf="user.from.address; else normalAddress"
          href="http://maps.google.com/?q={{ user.from.address }}"
          target="_blank"
        >
          <mat-icon>navigation</mat-icon>{{ user.from.name }} -
          {{ user.from.address }}
        </a>
        <ng-template #normalAddress>
          <a
            href="http://maps.google.com/?q={{ user.from }}"
            target="_blank"
          >
            <mat-icon>navigation</mat-icon>{{ user.from }}
          </a>
        </ng-template>
      </div>
    </div>
  </div>

  <div
    class="info"
    *ngIf="data.ride.users"
  >
    <h4>Bestemming</h4>
    <div
      class="user"
      *ngFor="let user of users; let i = index"
    >
      <div class="name">
        {{ i + 1 }}. {{ user.title }}
        <span *ngIf="user.phoneNumber">
          (
          <a href="tel:{{ user.phoneNumber }}">{{ user.phoneNumber }}</a>
          )
        </span>
      </div>
      <div
        class="icons"
        fxLayout="row"
      >
        <div
          class="icon"
          [ngClass]="{ active: user[icon.id] === true }"
          [matTooltip]="icon.name"
          *ngFor="let icon of icons | async"
        >
          <mat-icon *ngIf="icon.iconType == 'material'">
            {{ icon.icon }}</mat-icon
          >
          <div
            class="mat-icon"
            *ngIf="icon.iconType == 'custom'"
            [inlineSVG]="'/assets/icons/' + icon.icon + '.svg'"
          ></div>
        </div>
      </div>
      <div *ngIf="user.commentsForDriver">
        <h5 class="comment-for-info">Opmerkingen voor de chauffeur:</h5>
        <p class="remark participant-remark">{{ user.commentsForDriver }}</p>
      </div>
      <div *ngIf="user.remarkFromDriver">
        <h5 class="comment-for-info">Opmerkingen door chauffeur:</h5>
        <p class="remark participant-remark">{{ user.remarkFromDriver }}</p>
      </div>

      <div class="location">
        <a
          *ngIf="user.to.address; else normalAddress"
          href="http://maps.google.com/?q={{ user.to.address }}"
          target="_blank"
        >
          <mat-icon>navigation</mat-icon>{{ user.to.name }} -
          {{ user.to.address }}
        </a>
        <ng-template #normalAddress>
          <a
            href="http://maps.google.com/?q={{ user.to }}"
            target="_blank"
          >
            <mat-icon>navigation</mat-icon>{{ user.to }}
          </a>
        </ng-template>
      </div>
    </div>
  </div>

  <div
    class="info"
    *ngIf="characteristicsStatus"
  >
    <h4>Kenmerken</h4>
    <mat-chip-list class="chip-list">
      <mat-chip
        *ngFor="let characteristic of characteristics"
        color="primary"
        selected
      >
        {{ characteristic }}
      </mat-chip>
    </mat-chip-list>
  </div>

  <div
    style="margin-bottom: -10px"
    *ngIf="!ignoreDriverStatus"
  >
    <div *ngIf="showDriverPlanning" class="info">
      <h4>Chauffeur</h4>
      <span *ngIf="driver | async as driver; else no_driver">{{ driver.name }} 
        (
          <a href="tel:{{ driver.phone }}">{{ driver.phone }}</a>
        )
      </span> 
      <ng-template #no_driver
        >Er is nog geen chauffeur voor dit dagdeel gepland.</ng-template
      >
    </div>
  </div>
</div>
<div
  mat-dialog-content
  class="content-center"
  *ngIf="loading"
>
  <mat-spinner></mat-spinner>
</div>
