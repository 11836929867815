<div>
  <form
    fxLayout="column"
    (ngSubmit)="onSubmit()"
    autocomplete="off"
    [formGroup]="tariffForm"
  >
    <mat-form-field>
      <mat-label>Tariefnaam</mat-label>
      <input
        type="text"
        placeholder="Tariefnaam"
        formControlName="name"
        matInput
        required
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Credits kosten</mat-label>
      <input
        type="number"
        placeholder="Credits kosten"
        formControlName="cost"
        matInput
        required
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Agendakleur</mat-label>
      <input
        matInput
        placeholder="#FFFFFF"
        readonly="true"
        formControlName="color"
        [value]="tariffForm.value.color"
        #ignoredInput
        [cpIgnoredElements]="[ignoredButton, ignoredInput]"
        [(cpToggle)]="toggle"
        [(colorPicker)]="tariffForm.value.color"
        [cpAlphaChannel]="'disabled'"
        [cpOKButton]="true"
        [cpPosition]="'top'"
        [cpCancelButton]="true"
        [cpCancelButtonClass]="'mat-stroked-button'"
        [cpOKButtonClass]="'mat-stroked-button'"
        [cpCancelButtonText]="'Annuleren'"
      />
      <button
        matSuffix
        mat-icon-button
        #ignoredButton
        type="button"
        (click)="toggle = !toggle"
      >
        <mat-icon>colorize</mat-icon>
      </button>
    </mat-form-field>
    <div class="room-below">
      <mat-slide-toggle (change)="onChange($event)" formControlName="default">
        Standaard tarief
      </mat-slide-toggle>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="6px">
      <button mat-button type="button" (click)="close()">Annuleren</button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="tariffForm.invalid"
      >
        Opslaan
      </button>
    </div>
  </form>
</div>
