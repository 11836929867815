import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Ride, Vehicle } from 'src/app/interfaces';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { RideDetailsComponent } from 'src/app/planner/dialogs/ride-details/ride-details.component';
import moment from 'moment';

@Component({
  selector: 'app-daypart-ride',
  templateUrl: './daypart-ride.component.html',
  styleUrls: ['./daypart-ride.component.scss'],
})
export class DaypartRideComponent implements OnInit {
  organisationId = localStorage.getItem('orgId');
  rides: Ride[] = [];
  loading = true;
  firstUpcomingRide: Ride;

  constructor(
    private dialogRef: MatDialogRef<DaypartRideComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private db: AngularFirestore,
    private dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<any> {
    // console.log('data', this.data);
    const ridesDoc = this.db
      .collection<Ride>(`organisations/${this.organisationId}/rides`, (ref) =>
        ref
          .where('vehicleId', '==', this.data.vehicleId)
          .where('day', '==', this.data.dayNumber)
          .where('month', '==', this.data.monthNumber)
          .where('year', '==', this.data.yearNumber)
          .orderBy('start')
      )
      .valueChanges();
    ridesDoc.subscribe((rides) => {
      console.log('rides', rides);
      this.rides = [];
      this.loading = true;
      this.firstUpcomingRide = null;
      for (const ride of rides) {
        try {
          ride.start = ride.start.toDate();
          ride.end = ride.end.toDate();
        } catch (e: any) {
          console.error(e);
        }

        const isBetween = moment(ride.start).isBetween(
          this.data.start,
          this.data.end,
          null,
          '[)' // '[) = equals or between instead of only between
        );
        if (isBetween && !ride.finished) {
          ride.belongsToDaypart = true;
          if (!this.firstUpcomingRide) {
            ride.upcoming = true;
            ride.belongsToDaypart = false; // prevent overwrite in UI
            this.firstUpcomingRide = ride;
          }
        }
        // console.log('isBetween', isBetween, ride.title);

        const startTime = new Date(ride.start);
        const endTime = new Date(ride.start);

        if (startTime.getHours() < this.data.startTime) {
        } else if (endTime.getHours() > this.data.endTime) {
        } else {
          this.rides.push(ride);
        }
      }
      this.loading = false;
    });
  }

  async openRideDetails(ride: Ride) {
    const vehicle = (await (
      await this.db
        .doc(`organisations/${this.organisationId}/vehicles/${ride.vehicleId}`)
        .get()
        .toPromise()
    ).data()) as Vehicle;
    try {
      ride.start = ride.start.toDate();
      ride.end = ride.end.toDate();
    } catch (e) {
      console.error(e);
    }
    this.dialog.open(RideDetailsComponent, {
      width: '500px',
      data: { ride, vehicle, readOnly: true },
    });
  }
}
