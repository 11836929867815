<div
  class="login-wrapper"
  fxLayout="row"
  *ngIf="!loading"
>
  <div
    class="login-intro"
    fxHide.xs
  >
    <div
      [ngStyle]="{ 'background-image': 'url(' + loginImage + ')' }"
      class="banner"
    ></div>
  </div>
  <div
    class="login-form-wrapper"
    fxFlex
  >
    <mat-card
      class="login-form"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <div class="login-logo">
        <img
          [src]="logoImage"
          class="logo"
        />
      </div>

      <div class="title">Log nu in bij {{ organisationName }}!</div>

      <form
        [formGroup]="loginForm"
        class="email-login"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <mat-error
          class="full-width"
          *ngIf="errorLoginMessage"
        >
          {{ errorLoginMessage }}
        </mat-error>
        <mat-form-field
          class="full-width"
          [hideRequiredMarker]="true"
        >
          <mat-label>E-mail</mat-label>
          <input
            #focusMeLogin
            matInput
            type="email"
            formControlName="email"
            (keyup.Enter)="login()"
          />
          <mat-error
            *ngIf="
              loginForm.controls.email.errors?.email &&
              !loginForm.controls.email.errors?.required
            "
          >
            Vul een geldig e-mailadres in.
          </mat-error>
          <mat-error *ngIf="loginForm.controls.email.errors?.required">
            Je moet je e-mail invullen.
          </mat-error>
        </mat-form-field>
        <mat-form-field
          class="full-width"
          [hideRequiredMarker]="true"
        >
          <mat-label>Wachtwoord</mat-label>
          <input
            *ngIf="!showPasswords"
            matInput
            type="password"
            formControlName="password"
            (keyup.Enter)="login()"
          />
          <input
            *ngIf="showPasswords"
            matInput
            formControlName="password"
            (keyup.Enter)="login()"
          />
          <button
            *ngIf="!showPasswords"
            type="button"
            mat-button
            matSuffix
            mat-icon-button
            matTooltip="Wachtwoord laten zien"
            (click)="showPasswords = true"
          >
            <mat-icon>visibility</mat-icon>
          </button>
          <button
            *ngIf="showPasswords"
            type="button"
            mat-button
            matSuffix
            mat-icon-button
            matTooltip="Wachtwoord verbergen"
            (click)="showPasswords = false"
          >
            <mat-icon>visibility_off</mat-icon>
          </button>
        </mat-form-field>
        <button
          mat-raised-button
          [disabled]="!loginForm.valid"
          color="primary"
          class="login-button"
          (click)="login()"
        >
          Login
        </button>
        <a
          href="javascript:void(0);"
          style="margin-top: 5px"
          (click)="resetPassword()"
          >Wachtwoord vergeten?</a
        >
      </form>
    </mat-card>
  </div>
</div>
