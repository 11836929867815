import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { Ride, Product } from 'src/app/interfaces';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FuncsService } from 'src/app/services/funcs.services';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { getAuth } from 'firebase/auth';
import { deleteDoc, getDocs } from 'firebase/firestore';

export interface DialogData {
  oldRide: Ride;
  newRide: Ride;
  delete?: boolean;
  deleteReason?: string;
}
@Component({
  selector: 'app-copy-update',
  templateUrl: './copy-update.component.html',
  styleUrls: ['./copy-update.component.scss'],
})
export class CopyUpdateComponent implements OnInit {
  userId: string;
  userEmail: string;
  organisationId = localStorage.getItem('orgId') as string;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CopyUpdateComponent>,
    private funcs: FuncsService,
    private auth: AngularFireAuth
  ) {}

  async ngOnInit() {
    console.log('data', this.data);
    this.userId = getAuth().currentUser.uid;
    this.userEmail = getAuth().currentUser.email;
  }
  async updateCopies() {
    const oldRide = this.data.oldRide;
    const newRide = this.data.newRide;
    const startOffset = this.calculateDateDifference(
      oldRide.start,
      newRide.start
    );
    const endOffset = this.calculateDateDifference(oldRide.end, newRide.end);
    console.log('startOffset', startOffset);
    console.log('endOffset', endOffset);
    const products = (
      await this.db.firestore
        .collection(`organisations/${this.organisationId}/products`)
        .get()
    ).docs;
    console.log('products', products);
    const ridesToUpdate = await this.db
      .collection(`organisations/${this.organisationId}/rides`)
      .ref.where('parentRideId', '==', oldRide.parentRideId)
      .where('start', '>=', oldRide.start)
      .get()
      .then((collection) => {
        const rides = [];
        collection.forEach((doc) => {
          const ride = doc.data() as any;
          ride.id = doc.id;
          ride.start = ride.start.toDate(); // might be a good way to make sure all timestamp are always usable as dates in the app
          ride.end = ride.end.toDate(); // can always remove this if this seems like a bad idea later :)
          rides.push(ride);
        });
        return rides;
      });
    console.log('ridesToUpdate', ridesToUpdate);
    const batch = this.db.firestore.batch();

    ridesToUpdate.forEach(async (ride: Ride) => {
      let updatedRide: Ride;
      const oldStartDate = ride.start;
      const newStartDate = new Date(oldStartDate);
      newStartDate.setMonth(newStartDate.getMonth() + startOffset.months);
      newStartDate.setDate(newStartDate.getDate() + startOffset.days);
      newStartDate.setHours(newStartDate.getHours() + startOffset.hours);
      newStartDate.setMinutes(newStartDate.getMinutes() + startOffset.minutes);
      const oldEndDate = ride.end;
      const newEndDate = new Date(oldEndDate);
      newEndDate.setMonth(newEndDate.getMonth() + endOffset.months);
      newEndDate.setDate(newEndDate.getDate() + endOffset.days);
      newEndDate.setHours(newEndDate.getHours() + endOffset.hours);
      newEndDate.setMinutes(newEndDate.getMinutes() + endOffset.minutes);
      console.log('newRide', newRide);
      updatedRide = { ...newRide };
      updatedRide.start = newStartDate;
      updatedRide.end = newEndDate;
      updatedRide.id = ride.id;
      updatedRide.year = newStartDate.getFullYear();
      updatedRide.month = newStartDate.getMonth();
      updatedRide.week = this.funcs.getWeekString(newStartDate);
      updatedRide.day = newStartDate.getDate();
      updatedRide.parentRideId = newRide.parentRideId;
      console.log('updatedRide', updatedRide);
      // if (ride.rideProduct) {
      //   const selectedProduct = products.find((product) => {
      //     return product.id === ride.rideProduct.id;
      //   });
      //   if (selectedProduct) {
      //     ride.rideProduct = {
      //       ...selectedProduct.data(),
      //       id: selectedProduct.id,
      //     } as Product;
      //   }
      // }
      if (updatedRide.users) {
        for (const [userId, user] of Object.entries(updatedRide.users)) {
          // if (user.rideProduct) {
          //   const selectedProduct = products.find((product) => {
          //     return product.id === user.rideProduct.id;
          //   });
          //   if (selectedProduct) {
          //     user.rideProduct = {
          //       ...selectedProduct.data(),
          //       id: selectedProduct.id,
          //     } as Product;
          //   }
          // }
          delete user.paid;
          delete user.charge;
        }
      }
      updatedRide.title = this.funcs.getRideTitle(updatedRide);
      batch.set(
        this.db
          .collection(`organisations/${this.organisationId}/rides`)
          .doc(ride.id).ref,
        updatedRide,
        {
          merge: true,
        }
      );
      const rideRef = this.db
        .collection(`organisations/${this.organisationId}/rides`)
        .doc(ride.id);
    });
    await batch.commit();
    this.dialogRef.close('done');
  }

  async deleteCopies() {
    const oldRide = this.data.oldRide;
    const ridesToDelete = await this.db
      .collection(`organisations/${this.organisationId}/rides`)
      .ref.where('parentRideId', '==', oldRide.parentRideId)
      .where('start', '>', oldRide.start)
      .get()
      .then((collection) => {
        const rides = [];
        collection.forEach((doc) => {
          const ride = doc.data() as any;
          ride.id = doc.id;
          ride.ref = doc.ref;
          rides.push(ride);
        });
        return rides;
      });
    console.log('ridesToDelete', ridesToDelete);
    const batch = this.db.firestore.batch();

    for(const ride of ridesToDelete) {
      const backupRideRef = this.db.firestore
        .collection(`eventLogs/${this.organisationId}/deletedRides`)
        .doc(ride.id);
      
      const backupRide = {} as any;
      backupRide.date = new Date();
      backupRide.doneByUser = this.userEmail;
      backupRide.doneByUid = this.userId;
      backupRide.deleteReason = this.data.deleteReason;
      backupRide.rideData = { ...ride };
      batch.set(backupRideRef, backupRide);
      getDocs(
        this.db.firestore
        .collection(`organisations/${this.organisationId}/rides/${ride.id}/edits`)
      ).then((docs) => {
        docs.forEach((doc) => {
          const docRef = this.db.firestore
            .collection(`eventLogs/${this.organisationId}/deletedRides/${ride.id}/edits`)
            .doc(doc.id);
          batch.set(docRef, doc.data());
          deleteDoc(doc.ref);
        });
      });
      await getDocs(
        this.db.firestore
        .collection(`organisations/${this.organisationId}/rides/${ride.id}/edits`)
      ).then((docs) => {
        docs.forEach((doc) => {
          deleteDoc(doc.ref);
        });
      });
      batch.delete(ride.ref);
    }
    await batch.commit();
    this.dialogRef.close('done');
  }

  calculateDateDifference(oldDate: Date, newDate: Date) {
    const monthDiff = newDate.getMonth() - oldDate.getMonth();
    const dayDiff = newDate.getDate() - oldDate.getDate();
    const hourDiff = newDate.getHours() - oldDate.getHours();
    const minuteDiff = newDate.getMinutes() - oldDate.getMinutes();
    console.log('monthDiff', monthDiff);
    console.log('dayDiff', dayDiff);
    console.log('hourDiff', hourDiff);
    console.log('minuteDiff', minuteDiff);
    return {
      months: monthDiff,
      days: dayDiff,
      hours: hourDiff,
      minutes: minuteDiff,
    };
  }
}
