<h1 mat-dialog-title>Uitzondering toevoegen</h1>
<div mat-dialog-content>
  <form [formGroup]="exceptionForm">
    <h4>Ik ben niet beschikbaar</h4>
    <mat-form-field class="name">
      <mat-label>Titel</mat-label>
      <input matInput type="text" autocomplete="off" formControlName="name" />
    </mat-form-field>
    <div class="date">
      <h5>Van</h5>
      <mat-form-field>
        <mat-label>Selecteer een van datum</mat-label>
        <!-- <mat-label>Selecteer een begin datum</mat-label> -->
        <input
          matInput
          [matDatepicker]="pickerFrom"
          formControlName="dateFrom"
          placeholder="01-01-2021"
          autocomplete="off"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerFrom"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
        <mat-error *ngIf="exceptionForm.controls.dateFrom.errors?.required">
          Dit veld is verplicht
        </mat-error>
      </mat-form-field>

      <div class="time">
        <!-- <h4>Selecteer een begin tijd</h4> -->
        <div fxLayout="row">
          <mat-form-field>
            <mat-label>Uur</mat-label>
            <mat-select formControlName="startHour" required>
              <mat-option *ngFor="let hour of hours" [value]="hour.val">{{
                hour.text
              }}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="exceptionForm.controls.startHour.errors?.required"
            >
              Dit veld is verplicht
            </mat-error>
          </mat-form-field>
          <mat-form-field class="min">
            <mat-label>Minuten</mat-label>
            <mat-select formControlName="startMin" required>
              <mat-option *ngFor="let min of minutes" [value]="min.val">{{
                min.text
              }}</mat-option>
            </mat-select>
            <mat-error *ngIf="exceptionForm.controls.startMin.errors?.required">
              Dit veld is verplicht
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- </div> -->

      <h5>Tot</h5>
      <mat-form-field>
        <mat-label>Selecteer een tot datum</mat-label>
        <input
          matInput
          [matDatepicker]="pickerTo"
          formControlName="dateTo"
          placeholder="01-01-2021"
          autocomplete="off"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerTo"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
        <mat-error *ngIf="exceptionForm.controls.dateTo.errors?.required">
          Dit veld is verplicht
        </mat-error>
      </mat-form-field>
      <div class="time">
        <div fxLayout="row">
          <mat-form-field class="hour">
            <mat-label>Uur</mat-label>
            <mat-select formControlName="endHour" required>
              <mat-option *ngFor="let hour of hours" [value]="hour.val">{{
                hour.text
              }}</mat-option>
            </mat-select>
            <mat-error *ngIf="exceptionForm.controls.endHour.errors?.required">
              Dit veld is verplicht
            </mat-error>
          </mat-form-field>
          <mat-form-field class="min">
            <mat-label>Minuten</mat-label>
            <mat-select formControlName="endMin" required>
              <mat-option *ngFor="let min of minutes" [value]="min.val">{{
                min.text
              }}</mat-option>
            </mat-select>
            <mat-error *ngIf="exceptionForm.controls.endHour.errors?.required">
              Dit veld is verplicht
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]>ANNULEREN</button>
  <button
    mat-raised-button
    color="primary"
    (click)="addException()"
    cdkFocusInitial
    [disabled]="!exceptionForm.valid"
  >
    TOEVOEGEN
  </button>
</div>
