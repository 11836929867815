<app-nav-bar></app-nav-bar>
<div class="users" fxLayout="column">
  <div class="header" fxLayout="row wrap" fxLayoutAlign="space-between center">
    <div class="h-title" fxFlex>
      <mat-icon>tune</mat-icon>
      <span>Kenmerken</span>
    </div>
    <button mat-raised-button style="height: 40px" (click)="openImportExport()">
      <mat-icon>import_export</mat-icon
      ><span fxHide.xs>Importeren/Exporteren</span>
    </button>
  </div>
  <mat-card
    class="room-below-card"
    *ngIf="characteristics; else loading"
    style="padding: 24px"
  >
    <div class="title" fxLayout="row">
      <h3 fxFlex>
        Alle kenmerken <span>({{ totalCharacteristics }})</span>
      </h3>
      <div class="search" fxLayoutAlign=" center">
        <mat-form-field>
          <span matPrefix>
            <mat-icon>search</mat-icon>
          </span>
          <mat-label class="placeholder">Zoeken</mat-label>
          <input
            matInput
            type="search"
            autocomplete="off"
            placeholder="Zoeken"
            [(ngModel)]="searchQuery"
            (ngModelChange)="onFilterChange('search', $event)"
          />
        </mat-form-field>
      </div>
    </div>
    <div #TABLE>
      <mat-table
        mat-table
        id="tblData"
        #table
        [dataSource]="filteredCharacteristics"
      >
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>Kenmerk</mat-header-cell>
          <mat-cell *matCellDef="let characteristic">
            <p class="text-truncate" *ngIf="characteristic.name">
              {{ characteristic.name }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef>Omschrijving</mat-header-cell>
          <mat-cell *matCellDef="let characteristic">
            <p class="text-truncate" *ngIf="characteristic.description">
              {{ characteristic.description }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- button Column -->
        <ng-container matColumnDef="button">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let characteristic">
            <div fxFlex="row" fxLayoutAlign="end center">
              <button
                mat-icon-button
                [disabled]="!characteristic.active"
                [matMenuTriggerFor]="moreMenu"
                aria-label="More"
                (click)="$event.stopPropagation()"
              >
                <mat-icon class="secondary-text">more_vert</mat-icon>
              </button>

              <mat-menu #moreMenu="matMenu">
                <button
                  mat-menu-item
                  (click)="editCharacteristic(characteristic)"
                >
                  <span>Bewerken</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          [ngClass]="{ 'card-inactive': !characteristic.active }"
          *matRowDef="let characteristic; columns: displayedColumns"
          class="user"
          (click)="editCharacteristic(characteristic)"
          matRipple
        ></tr>
      </mat-table>
    </div>
  </mat-card>
  <ng-template #loading>
    <div id="loading" fxLayout="row" fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
  <input
    hidden
    type="file"
    accept=".xlsx"
    #uploader
    (change)="uploadedFile($event)"
  />
</div>
<button mat-fab class="fab-fixed" (click)="newCharacteristic()">
  <mat-icon>add</mat-icon>
</button>
