import { Injectable } from '@angular/core';
import moment from 'moment';
import { Ride } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class FuncsService {
  constructor() {}

  getWeekString(date: Date) {
    const weekYear = moment(date).isoWeekYear();
    const week = moment(date).isoWeek();
    return `${weekYear}-${week}`;
  }

  getRideTitle(ride: Ride) {
    let title = '';

    if (ride.isRetour) {
      title = `Retour `;
    }

    title =
      ride.availableSeats === 0
        ? `${title}VOL`
        : `${title}${ride.availableSeats} Vrij`;

    if (ride.comments) {
      title = `${title}, ${ride.comments}`;
    }

    if (!ride.users) ride.users = {} as Map<string, any>;
    const userIds = Object.keys(ride.users);
    const users = [];
    userIds.forEach((userId) => {
      const user = ride.users[userId];
      if (user.title) {
        users.push(user);
      }
    });
    users.sort((x: any, y: any) => {
      return x.order - y.order;
    });
    users.forEach((user) => {
      title = `${title}, ${user.title}`;
    });
    if (users.length > 0) {
      title = `${title}, van ${ride.fromLocation}, naar ${ride.toLocation}`;
    }

    return title;
  }
}
