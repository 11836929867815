<h1 mat-dialog-title>Superusers organisaties beheren</h1>

<div
  mat-dialog-content
  class="dialog-no-overflow"
>
  <form
    fxLayout="column"
    [formGroup]="superUserForm"
    autocomplete="off"
  >
    <mat-form-field>
      <mat-label>Superuser</mat-label>
      <mat-select formControlName="user">
        <mat-option
          *ngFor="let user of superUsers"
          [value]="user"
        >
          {{ user.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-checkbox
      *ngFor="let org of orgs"
      matInput
      (change)="orgChecked($event, org)"
      formControlName="{{ org.id }}"
      [checked]="org.checked"
    >
      {{ org.name }}
    </mat-checkbox>
  </form>
</div>
<div
  mat-dialog-actions
  align="end"
>
  <button
    mat-button
    mat-dialog-close
  >
    Annuleren
  </button>

  <button
    mat-raised-button
    color="primary"
    (click)="save()"
  >
    Opslaan
  </button>
</div>
