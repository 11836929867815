import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  DocumentReference,
} from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';

export interface User {
  name?: string;
  // organisation: object;
  // organisation?: {
  //   ref: DocumentReference;
  //   role: string;
  // };
  organisationRef?: DocumentReference;
  email?: string;
  id?: string;
}
export interface UserId extends User {
  id: string;
}

// export interface Organisation {
//   name: string;
//   organisation: {
//     ref: Reference;
//     role: string;
//   };
//   email: string;
// }
// export interface OrganisationId extends User { id: string; }

@Injectable({
  providedIn: 'root',
})
export class ConstantsService {
  userDoc: AngularFirestoreDocument<User>;
  user: Observable<User>;
  // organisationDoc: AngularFirestoreDocument<Organisation>;
  // organisation: Observable<OrganisationId>;
  userId: string;
  userEmail: string;
  testUser: {};
  constructor(public afAuth: AngularFireAuth, public afs: AngularFirestore) {
    // this.user = new Observable();
    // this.afAuth.user.subscribe( user => {
    //   this.userId = user.uid;
    //   this.userEmail = user.email;
    //   console.log('this.userEmail', this.userEmail);
    // });
    // console.log('currentUser =>', this.afAuth.auth.currentUser);
    // this.userDoc = afs.doc<User>('users/' + this.afAuth.auth.currentUser.uid);
    // // console.log('currentUser', this.afAuth.auth.currentUser);
    // this.user = this.userDoc.valueChanges().pipe(
    //   map(userData => {
    //     // console.log('userData =>', userData);
    //     if (!userData) {
    //       userData = {};
    //     }
    //     userData.email = this.afAuth.auth.currentUser.email;
    //     userData.id = this.afAuth.auth.currentUser.uid;
    //     if (userData.organisationRef) {
    //       userData.organisationRef = userData.organisationRef;
    //     }
    //     // console.log('userData =>', userData);
    //     return userData;
    //   })
    // );
    // this.user.subscribe( userDoc => {
    //   console.log('userDoc => ', userDoc);
    // });
  }
}
