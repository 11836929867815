<h1 mat-dialog-title>Weet je zeker dat je een incasso wilt aanmaken?</h1>
<div mat-dialog-content>
  Dit genereert, per Buurtvervoerder, een SEPA bestand van deze maand!
</div>
<div mat-dialog-content>
  <form
    fxLayout="column"
    [formGroup]="sepaForm"
    autocomplete="off"
  >
    <mat-form-field>
      <mat-label>Maand</mat-label>
      <mat-select formControlName="month">
        <mat-option
          *ngFor="let month of months"
          [value]="month"
        >
          {{ month.name }}
          {{ month.period }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
<div
  mat-dialog-actions
  align="end"
>
  <button
    mat-button
    mat-dialog-close
  >
    Annuleren
  </button>
  <button
    mat-raised-button
    (click)="createSepa()"
    color="primary"
    [class.spinner]="loading"
    [disabled]="!sepaForm.valid"
  >
    Incasso aanmaken
  </button>
</div>
