import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { RideEdits } from 'src/app/interfaces';
import { DialogData } from '../ride-details/ride-details.component';

@Component({
  selector: 'app-ride-edits',
  templateUrl: './ride-edits.component.html',
  styleUrls: ['./ride-edits.component.scss'],
})
export class RideEditsComponent implements OnInit {
  allEdits: Observable<RideEdits[]>;
  notEdited = false;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  async ngOnInit() {
    this.allEdits = await this.db
      .collection<RideEdits>(
        `organisations/${localStorage.getItem('orgId')}/rides/${
          this.data.ride.id
        }/edits`,
        (ref) => ref.orderBy('date', 'desc')
      )
      .valueChanges({
        idField: 'id',
      });
  }
}
