import {
  Pain00800102,
  Pain00800102Transaction,
  SepaCustomer,
} from '../create-sepa/sepa-interface';
import * as xmlbuilder from 'xmlbuilder2';

//Pain file documentation
//https://developer.rabobank.nl/pain-file-documentation
//https://www.abnamro.nl/nl/zakelijk/producten/betalen/sepa/downloads.html
//https://media.rabobank.com/m/48623a70b745318c/original/Format-description-Direct-Debit.pdf

export async function createSepaData(
  paymentReferenceId: string,
  date: Date,
  customers: SepaCustomer[],
  creditorName: string,
  creditorCountry: string,
  creditorAddress: string,
  creditorMunicipality: string,
  creditorPostal: string,
  creditorIban: string,
  creditorId: string,
  creditorBic: string
) {
  let totalAmountToEarn = 0;
  customers.forEach((customer) => {
    console.log('createSepaData customer', customer);
    totalAmountToEarn = totalAmountToEarn + customer.amount;
  });
  console.log('totalAmountToEarn', totalAmountToEarn);

  const formattedDate = `${date.getFullYear()}-${(
    '0' +
    (date.getMonth() + 1)
  ).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
  const sepaObj = {
    Document: {
      '@xmlns': 'urn:iso:std:iso:20022:tech:xsd:pain.008.001.02',
      '@xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
      CstmrDrctDbtInitn: {
        GrpHdr: {
          MsgId: paymentReferenceId,
          CreDtTm: `${date.toISOString()}`,
          NbOfTxs: `${customers.length}`,
          CtrlSum: totalAmountToEarn.toFixed(2),
          InitgPty: {
            Nm: creditorName,
          },
        },
        PmtInf: {
          PmtInfId: paymentReferenceId,
          PmtMtd: 'DD',
          BtchBookg: 'true',
          NbOfTxs: `${customers.length}`,
          CtrlSum: totalAmountToEarn.toFixed(2),
          PmtTpInf: {
            SvcLvl: {
              Cd: 'SEPA',
            },
            LclInstrm: {
              Cd: 'CORE',
            },
            SeqTp: 'RCUR',
          },
          ReqdColltnDt: formattedDate, // required execution date?
          Cdtr: {
            Nm: creditorName,
          },
          CdtrAcct: {
            Id: {
              IBAN: creditorIban,
            },
            Ccy: 'EUR',
          },
          CdtrAgt: {
            FinInstnId: {
              BIC: creditorBic,
            },
          },
          ChrgBr: 'SLEV',
          CdtrSchmeId: {
            Id: {
              PrvtId: {
                Othr: {
                  Id: creditorId,
                  SchmeNm: {
                    Prtry: 'SEPA',
                  },
                },
              },
            },
          },
          DrctDbtTxInf: [],
        },
      },
    },
  } as Pain00800102;
  customers.forEach((customer) => {
    const debtorAgent = customer.bic
      ? {
          BIC: customer.bic,
        }
      : {
          Othr: {
            Id: 'NOTPROVIDED',
          },
        };
    const transaction: Pain00800102Transaction = {
      PmtId: {
        EndToEndId: paymentReferenceId,
      },
      InstdAmt: {
        '@Ccy': 'EUR',
        '#': customer.amount.toFixed(2),
      },
      DrctDbtTx: {
        MndtRltdInf: {
          MndtId: customer.mandateId,
          DtOfSgntr: customer.dateOfSignature,
          AmdmntInd: 'false',
        },
      },
      DbtrAgt: {
        FinInstnId: debtorAgent,
      },
      Dbtr: {
        Nm: customer.name,
      },
      DbtrAcct: {
        Id: {
          IBAN: customer.iban,
        },
      },
      RmtInf: {
        Ustrd: 'Incasso',
      },
    };
    sepaObj.Document.CstmrDrctDbtInitn.PmtInf.DrctDbtTxInf.push(transaction);
  });

  const xmlSepaHeader = xmlbuilder.create(sepaObj);
  return xmlSepaHeader.end({ prettyPrint: true });
}
