import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notify-users-ride',
  templateUrl: './notify-users-ride.component.html',
  styleUrls: ['./notify-users-ride.component.scss'],
})
export class NotifyUsersRideDialog implements OnInit {
  constructor() {}

  ngOnInit() {}
}
