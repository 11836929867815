import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import tinycolor from 'tinycolor2';

export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  primaryColor = localStorage.getItem('primaryColor') ?? '#22398e';
  accentColor = localStorage.getItem('accentColor') ?? '#62ba91';
  orgName = localStorage.getItem('orgName') ?? 'Buurtvervoer';
  primaryColorPalette: Color[] = [];
  accentColorPalette: Color[] = [];

  constructor(private titleService: Title) {
    this.titleService.setTitle(this.orgName);
    this.savePrimaryColor();
    this.saveAccentColor();
  }

  savePrimaryColor() {
    this.primaryColorPalette = computeColors(this.primaryColor);
    updateTheme(this.primaryColorPalette, 'primary');
  }

  saveAccentColor() {
    this.accentColorPalette = computeColors(this.accentColor);
    updateTheme(this.accentColorPalette, 'accent');
  }
  ngOnInit(): void {}
}

function updateTheme(colors: Color[], theme: string) {
  colors.forEach((color) => {
    // console.log(`--theme-${theme}-${color.name}`, color.hex);
    if (color.name === '500') {
      document.documentElement.style.setProperty(`--${theme}`, color.hex);
      document.documentElement.style.setProperty(
        `--${theme}-contrast`,
        color.darkContrast ? 'rgba(black, 0.87)' : 'white'
      );
    }
    document.documentElement.style.setProperty(
      `--theme-${theme}-${color.name}`,
      color.hex
    );
    document.documentElement.style.setProperty(
      `--theme-${theme}-contrast-${color.name}`,
      color.darkContrast ? 'rgba(black, 0.87)' : 'white'
    );
  });
}

function computeColors(hex: string): Color[] {
  return [
    getColorObject(tinycolor(hex).lighten(52), '50'),
    getColorObject(tinycolor(hex).lighten(37), '100'),
    getColorObject(tinycolor(hex).lighten(26), '200'),
    getColorObject(tinycolor(hex).lighten(12), '300'),
    getColorObject(tinycolor(hex).lighten(6), '400'),
    getColorObject(tinycolor(hex), '500'),
    getColorObject(tinycolor(hex).darken(6), '600'),
    getColorObject(tinycolor(hex).darken(12), '700'),
    getColorObject(tinycolor(hex).darken(18), '800'),
    getColorObject(tinycolor(hex).darken(24), '900'),
    getColorObject(tinycolor(hex).lighten(50).saturate(30), 'A100'),
    getColorObject(tinycolor(hex).lighten(30).saturate(30), 'A200'),
    getColorObject(tinycolor(hex).lighten(10).saturate(15), 'A400'),
    getColorObject(tinycolor(hex).lighten(5).saturate(5), 'A700'),
  ];
}

function getColorObject(value, name): Color {
  const c = tinycolor(value);
  return {
    name: name,
    hex: c.toHexString(),
    darkContrast: c.isLight(),
  };
}
