<div class="select-org-wrapper">
  <div class="select-org-container">
    <h1 mat-dialog-title class="title">Organisaties</h1>
    <div mat-dialog-content>
      <form [formGroup]="organisationsForm">
        <mat-form-field>
          <mat-label>Selecteer een organisatie</mat-label>
          <mat-select formControlName="organisationId">
            <mat-option
              *ngFor="let organisation of organisations"
              value="{{ organisation.id }}"
            >
              {{ organisation.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div mat-dialog-actions align="end">
      <button
        mat-raised-button
        [disabled]="!organisationsForm.valid"
        color="primary"
        (click)="selectOrg()"
      >
        SELECTEER
      </button>
    </div>
  </div>
</div>
