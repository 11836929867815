import { Component, OnInit, Inject } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Setting } from '../../../interfaces';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  FormControl,
} from '@angular/forms';

export interface DialogData {
  type: string;
  user: any;
}

@Component({
  selector: 'app-change-credits',
  templateUrl: './change-credits.component.html',
  styleUrls: ['./change-credits.component.scss'],
})
export class ChangeCreditsComponent implements OnInit {
  creditForm: UntypedFormGroup;
  settingsDoc: AngularFirestoreDocument<Setting>;
  settings: Observable<Setting>;
  pinEnabled: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public db: AngularFirestore,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ChangeCreditsComponent>
  ) {}

  ngOnInit() {
    this.creditForm = this.fb.group({
      credits: [0, [Validators.required, Validators.min(0.5)]],
      pin: [],
      remarks: [],
    });
    this.settingsDoc = this.db.doc<Setting>(
      `organisations/${localStorage.getItem('orgId')}/settings/general`
    );
    this.settings = this.settingsDoc.valueChanges();
    this.settings.subscribe((organisation) => {
      this.pinEnabled = organisation.askPin;
      if (organisation.askPin) {
        this.creditForm
          .get('pin')
          .setValidators([
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(4),
          ]);
      }
    });
    console.log('data', this.data);
  }

  save() {
    if (this.creditForm.valid) {
      this.dialogRef.close(this.creditForm.value);
    }
  }
}
