import { Component, OnInit } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { Observable, Subject, ReplaySubject, combineLatest } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AdminUserComponent } from './dialogs/user/user.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { redirectIfNotAdmin } from '../globals';
import { User } from '../interfaces';

export interface UserId extends User {
  id: string;
}

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss'],
})
export class AdminUsersComponent implements OnInit {
  itemsToShow = 20;
  allUsers: Observable<UserId[]>;
  // filteredUsers: Observable<UserId[]>;
  public filteredUsers: ReplaySubject<UserId[]> = new ReplaySubject<UserId[]>(
    1
  );
  usersCollection: AngularFirestoreCollection<User>;
  displayedColumns = ['name', 'email', 'accountType'];
  searchQuery: string;
  searchQueryChanged: Subject<string> = new Subject<string>();
  // showHidden = false;
  // showHiddenChanged: Subject<boolean> = new Subject<boolean>();
  orderBy = 'name';
  orderByChanged: Subject<string> = new Subject<string>();
  orderDirection = 'asc';
  orderDirectionChanged: Subject<string> = new Subject<string>();

  totalUsers: number;
  plannerEmail: string;

  organisationId = localStorage.getItem('orgId');
  showHidden?: boolean = false;
  showHiddenChanged: Subject<boolean> = new Subject<boolean>();
  rights: string;

  constructor(
    public db: AngularFirestore,
    public dialog: MatDialog,
    public afAuth: AngularFireAuth,
    private router: Router
  ) {}

  ngOnInit() {
    this.afAuth.user.subscribe(async (user) => {
      if (user) {
        this.plannerEmail = user.email;
        const userdoc = this.db.doc<User>('users/' + user.uid);
        const loggedInUser = (await userdoc.get().toPromise()).data();

        if (loggedInUser.rights) {
          this.rights = loggedInUser.rights;
        }
      }
    });
    console.log('orgId', this.organisationId);
    this.usersCollection = this.db.collection<User>(
      `organisations/${this.organisationId}/users`,
      (ref) => ref.orderBy('accountType')
    );
    this.allUsers = this.usersCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as object;
          const userData = data as User;
          if (!userData.credits) {
            userData.credits = 0;
          }
          const id = a.payload.doc.id;
          return { id, ...userData };
        })
      )
    );
    const combinedObservable = combineLatest(
      this.allUsers,
      this.searchQueryChanged,
      this.orderByChanged,
      this.orderDirectionChanged,
      this.showHiddenChanged
    );
    combinedObservable
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        console.log('values', values);
        const users = values[0];
        const searchQuery = values[1];
        const filters = {
          orderBy: values[2],
          orderDirection: values[3],
          showHidden: values[4],
        };
        this.searchQuery = searchQuery;
        // const showHidden = values[4];
        console.log('users', users);
        let filteredUsers = users.filter((item) =>
          this.checkFilters(item, filters)
        );
        // this.orderDirection = 'desc';
        console.log('orderBy', filters.orderBy);
        filteredUsers = filteredUsers.sort((a, b) => {
          if (a[filters.orderBy] > b[filters.orderBy]) {
            return 1;
          } else {
            return -1;
          }
          // return 0;
        });
        if (filters.orderDirection === 'desc') {
          filteredUsers.reverse();
        }
        console.log('filteredUsers', filteredUsers);
        this.totalUsers = filteredUsers.length;
        this.filteredUsers.next(filteredUsers);
        // this.filteredUsers.next(this.allUsers.pipe(map(items => items.filter(item => this.checkFilters(item)))));
      });
    this.searchQueryChanged.next('');
    this.orderByChanged.next(this.orderBy);
    this.orderDirectionChanged.next(this.orderDirection);
    this.showHiddenChanged.next(this.showHidden);
    redirectIfNotAdmin(this.db.firestore, this.router);
  }
  onFilterChange(type, ev?, id?) {
    if (type === 'search') {
      this.searchQueryChanged.next(ev);
    } else if (type === 'order') {
      this.orderByChanged.next(this.orderBy);
      this.orderDirectionChanged.next(this.orderDirection);
    } else if (type === 'hidden') {
      this.showHiddenChanged.next(this.showHidden);
    }
  }

  checkFilters(user, filters) {
    this.itemsToShow = 20;
    let passesSearchFilter = true;
    // let passesStringCheck = true;
    console.log('searchQuery', this.searchQuery);
    if (this.searchQuery) {
      passesSearchFilter = false;
      // passesStringCheck = false;
      if (user.name) {
        if (user.name.toLowerCase().includes(this.searchQuery.toLowerCase())) {
          passesSearchFilter = true;
          // passesStringCheck = true;
        }
      }
      if (user.email) {
        if (user.email.toLowerCase().includes(this.searchQuery.toLowerCase())) {
          passesSearchFilter = true;
          // passesStringCheck = true;
        }
      }
    }
    // only users where the property exixsts should be filtered
    console.log('passesSearchFilter', passesSearchFilter, user);
    if (
      (this.showHidden &&
        user.accountType === 'inactive' &&
        passesSearchFilter) ||
      (!this.showHidden &&
        user.accountType !== 'inactive' &&
        passesSearchFilter)
    ) {
      passesSearchFilter = true;
    } else {
      passesSearchFilter = false;
    }

    if (this.rights !== 'admin') {
      if (user.superuser === true) {
        passesSearchFilter = false;
      }
    }

    if (passesSearchFilter) {
      console.log('user passes filter:', user);
      return user;
    }
  }

  async editUser(user?) {
    console.log('user', user);
    this.dialog.open(AdminUserComponent, {
      width: '500px',
      data: { user },
    });
  }
}
