import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  lastValueFrom,
  map,
} from 'rxjs';
import { redirectIfNotAdmin } from 'src/app/globals';
import { Setting, VehicleGroup } from './../../interfaces';
import { CreateEditVehicleGroupsComponent } from './dialogs/create-edit-vehicle-groups/create-edit-vehicle-groups.component';
import { redirectIfDriver } from 'src/app/globals';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
@Component({
  selector: 'app-vehicle-groups',
  templateUrl: './vehicle-groups.component.html',
  styleUrls: ['./vehicle-groups.component.scss'],
})
export class VehicleGroupsComponent implements OnInit {
  organisationId = localStorage.getItem('orgId');
  @ViewChild('TABLE', { static: false }) table: ElementRef;
  @ViewChild('uploader', { static: false }) uploader: ElementRef;
  vehicleGroupCollection: AngularFirestoreCollection<VehicleGroup>;
  vehicleGroups: Observable<VehicleGroup[]>;
  public filteredVehicleGroups: ReplaySubject<VehicleGroup[]> =
    new ReplaySubject<VehicleGroup[]>(1);
  displayedColumns = ['name', 'color', 'button'];
  importedData: Subject<VehicleGroup[]> = new ReplaySubject<VehicleGroup[]>();
  totalVehicleGroups: number;
  header = true;
  fileUploaded: File;
  worksheet: any;
  storeData: any;
  csvRecords: any[] = [];
  searchQuery: string;
  searchQueryChanged: Subject<string> = new Subject<string>();
  settingDoc: AngularFirestoreDocument<Setting>;
  setting: Observable<Setting>;

  constructor(
    public db: AngularFirestore,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private fns: AngularFireFunctions
  ) {}

  async ngOnInit() {
    redirectIfDriver(this.db.firestore, this.router);
    this.vehicleGroupCollection = this.db.collection<VehicleGroup>(
      `organisations/${this.organisationId}/vehicleGroups`,
      (ref) => ref.orderBy('name', 'asc')
    );
    this.vehicleGroups = this.vehicleGroupCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as VehicleGroup;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
    const combinedObservable = combineLatest(
      this.vehicleGroups,
      this.searchQueryChanged
    );
    combinedObservable
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        console.log('values', values);
        const vehicles = values[0];
        const searchQuery = values[1];
        let filteredVehicleGroups = vehicles.filter((item) =>
          this.checkFilters(item)
        );
        console.log('filteredVehicleGroups', filteredVehicleGroups);
        this.totalVehicleGroups = filteredVehicleGroups.length;
        this.filteredVehicleGroups.next(filteredVehicleGroups);
      });
    this.searchQueryChanged.next('');
    redirectIfNotAdmin(this.db.firestore, this.router);
  }

  onFilterChange(type, ev?, id?) {
    if (type === 'search') {
      this.searchQueryChanged.next(ev);
    }
  }

  checkFilters(vehicleGroup) {
    let passesSearchFilter = true;
    if (this.searchQuery) {
      passesSearchFilter = false;
      if (vehicleGroup.name) {
        if (
          vehicleGroup.name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        ) {
          passesSearchFilter = true;
        }
      }
    }

    if (passesSearchFilter) {
      return vehicleGroup;
    }
  }

  editVehicleGroup(vehicleGroup?: VehicleGroup) {
    const dialogRef = this.dialog.open(CreateEditVehicleGroupsComponent, {
      width: '300px',
      data: { vehicleGroup },
    });
    dialogRef.afterClosed().subscribe((result) => {
      let snackbarText = '';
      if (result?.type) {
        if (result.type === 'new') {
          snackbarText = 'Voertuiggroep aangemaakt';
        } else if (result.type === 'edit') {
          snackbarText = 'Voertuiggroep aangepast';
        }
        this.snackBar.open(`${snackbarText}`, 'X', {
          duration: 5000,
        });
      }
    });
  }

  async deleteVehicleGroup(vehicleGroup: VehicleGroup) {
    try {
      const callBody = {
        vehicleGroupId: vehicleGroup.id,
        organisationId: this.organisationId,
      };
      const callable = this.fns.httpsCallable('httpDeleteVehicleGroups');
      await lastValueFrom(callable(callBody));
    } catch (error) {
      this.snackBar.open(
        'Er is iets misgegaan bij het verwijderen van de voertuiggroep.',
        'X',
        {
          duration: 5000,
        }
      );
    }
  }
}
