import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Daypart, User } from 'src/app/interfaces';
import {
  arrayUnion,
  arrayRemove,
  collection,
  where,
  query,
  getDocs,
  getDoc,
  doc,
} from 'firebase/firestore';
import { ConfirmChangeAvailabilityComponent } from '../../dialogs/confirm-change-availability/confirm-change-availability.component';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import moment from 'moment';

@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss'],
})
export class AvailabilityComponent implements OnInit {
  // dayparts: Observable<Dayparts[]>;

  newDayparts: Daypart[] = [];

  organisationId = localStorage.getItem('orgId');
  userId: string;
  loading = true;
  userIdFromAdmin: string;
  changerName: string;

  @Input() set getUserId(userId: string) {
    this.userIdFromAdmin = userId ? userId : null;
    this.getDayparts();
  }
  @Input() isDriver: boolean;
  @Input() driverCanModify: boolean;

  constructor(
    private db: AngularFirestore,
    private auth: AngularFireAuth,
    private dialog: MatDialog,
    private fns: AngularFireFunctions
  ) {}

  ngOnInit(): void {
    this.auth.authState.subscribe((result) => {
      this.userId = this.userIdFromAdmin ? this.userIdFromAdmin : result.uid;
      if (this.userId) {
        this.getDayparts();
      }
    });
  }

  async getDayparts() {
    const daypartRef = this.db
      .collection<Daypart>(
        `organisations/${this.organisationId}/dayparts`,
        (ref) => ref.orderBy('daypart.day')
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Daypart;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );

    // using daynumbers instead of daystring for ordering
    daypartRef.subscribe((daypart) => {
      const newDayparts = [];
      daypart.forEach(async (oldDayPart: any) => {
        const daypart = oldDayPart.daypart;
        let daypartObj = { day: daypart.day } as any;
        switch (daypart.day) {
          case 0:
            daypartObj.dayString = 'Maandag';
            break;
          case 1:
            daypartObj.dayString = 'Dinsdag';
            break;
          case 2:
            daypartObj.dayString = 'Woensdag';
            break;
          case 3:
            daypartObj.dayString = 'Donderdag';
            break;
          case 4:
            daypartObj.dayString = 'Vrijdag';
            break;
          case 5:
            daypartObj.dayString = 'Zaterdag';
            break;
          case 6:
            daypartObj.dayString = 'Zondag';
            break;
        }

        // format time
        daypartObj.startHour =
          daypart.startHour.toString().length !== 1
            ? daypart.startHour
            : '0' + daypart.startHour.toString();
        daypartObj.endHour =
          daypart.endHour.toString().length !== 1
            ? daypart.endHour
            : '0' + daypart.endHour.toString();
        daypartObj.endMin =
          daypart.endMin.toString().length !== 1
            ? daypart.endMin
            : daypart.endMin.toString() + '0';
        daypartObj.startMin =
          daypart.startMin.toString().length !== 1
            ? daypart.startMin
            : daypart.startMin.toString() + '0';

        daypartObj.id = oldDayPart.id;
        daypartObj.name = daypart.name;

        if (oldDayPart.availableUsers) {
          console.log('oldDayPart.availableUsers', oldDayPart.availableUsers);
          oldDayPart.availableUsers.forEach((userRef) => {
            const userId = this.userIdFromAdmin
              ? this.userIdFromAdmin
              : this.userId;
            if (userRef.path.includes(userId)) {
              daypartObj.checked = true;
            }
          });
        }

        const hasIndexToRemove = this.newDayparts.findIndex((x) => {
          x.id === daypartObj.id;
        });
        if (hasIndexToRemove === -1) {
          newDayparts.push(daypartObj);
        }
      });
      this.newDayparts = newDayparts.sort((a, b) => {
        return (
          a.day - b.day ||
          Number(a.startHour) - Number(b.startHour) ||
          Number(a.startMin) - Number(b.startMin) ||
          Number(a.endHour) - Number(b.endHour) ||
          Number(a.endMin) - Number(b.endMin)
        );
      });
      console.log('this.newDayparts', this.newDayparts);
      this.loading = false;
    });
  }

  async toggleChange($event, daypartId: string) {
    const userId = this.userIdFromAdmin ? this.userIdFromAdmin : this.userId;
    console.log('userId', userId);
    const userRef = this.db.doc(
      `organisations/${this.organisationId}/users/${userId}`
    );
    console.log('event', $event);
    if ($event.checked) {
      await this.db
        .doc(`organisations/${this.organisationId}/dayparts/${daypartId}`)
        .set(
          {
            availableUsers: arrayUnion(userRef.ref),
          },
          { merge: true }
        );
    } else {
      const today = new Date();
      let filteredDaypartDays = [];

      const q = query(
        collection(
          this.db.firestore,
          `organisations/${this.organisationId}/daypartDays`
        ),
        where('year', '>=', today.getFullYear()),
        where('daypartId', '==', daypartId),
        where('driverId', '==', userId)
      );
      const daypartDays = await getDocs(q);

      if (!daypartDays.empty) {
        daypartDays.docs.forEach((daypartDay) => {
          if (
            daypartDay.data().month >= today.getMonth() &&
            daypartDay.data().day >= today.getDate()
          ) {
            filteredDaypartDays.push(daypartDay);
          }
        });
      }

      if (filteredDaypartDays.length > 0) {
        const dialogRef = this.dialog.open(ConfirmChangeAvailabilityComponent, {
          width: '375px',
          disableClose: true,
          data: { availabilityType: 'availability' },
        });
        dialogRef.afterClosed().subscribe(async (result) => {
          if (result) {
            this.auth.user.subscribe(async (authUser) => {
              if (authUser) {
                const newUser = (await (
                  await this.db
                    .doc(
                      `organisations/${this.organisationId}/users/${authUser.uid}`
                    )
                    .get()
                    .toPromise()
                ).data()) as User; //
                this.changerName = newUser.name;
              }
            });
            const user = (
              await getDoc(
                doc(
                  this.db.firestore,
                  `organisations/${this.organisationId}/users/`,
                  `${this.userId}`
                )
              )
            ).data();
            await this.db
              .doc(`organisations/${this.organisationId}/dayparts/${daypartId}`)
              .set(
                {
                  availableUsers: arrayRemove(userRef.ref),
                },
                { merge: true }
              );

            try {
              const callBody = {
                orgId: this.organisationId,
                userName: 'planner',
                mailType: 'scheduleAvailability',
                emailTo: 'self',
                optionalData: {
                  date: moment(new Date()).format('DD-MM-YYYY'),
                  changedBy: this.changerName,
                  userChanged: user.name,
                  mailPlanners: true,
                },
              };
              const callable = this.fns.httpsCallable('httpSendMail');
              const callRes = await lastValueFrom(callable(callBody));
              console.log(callRes);
            } catch (error) {
              console.log(error);
            }
          } else {
            $event.source.checked = true;
          }
        });
      } else {
        await this.db
          .doc(`organisations/${this.organisationId}/dayparts/${daypartId}`)
          .set(
            {
              availableUsers: arrayRemove(userRef.ref),
            },
            { merge: true }
          );
      }
    }
  }

  get getUserId() {
    return this.userIdFromAdmin;
  }
}
