<h1 mat-dialog-title>Incasso aanmaken</h1>
<div
  *ngIf="!loadingData && customers.length > 0"
  mat-dialog-content
  class="adjusted-dialog-content"
>
  Selecteer deelnemers om toe te voegen aan het incasso bestand.
  <form [formGroup]="customerForm" autocomplete="off" class="checkbox-form">
    <mat-checkbox
      class="adjusted-checkbox"
      *ngFor="let customer of customers"
      matInput
      (change)="customerChecked($event, customer)"
      formControlName="{{ customer.id }}"
      [checked]="customer.checked"
    >
      {{ customer.name }}
    </mat-checkbox>
  </form>
</div>
<div *ngIf="!loadingData && customers.length === 0" mat-dialog-content>
  Geen deelnemers gevonden die een incasso nodig hebben
</div>
<div class="loading-spinner" *ngIf="loadingData">
  <mat-spinner></mat-spinner>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Annuleren</button>
  <button
    mat-raised-button
    (click)="createSepa()"
    color="primary"
    [disabled]="customers.length === 0 || !checkboxChecker()"
    [class.spinner]="loading"
  >
    Incasso aanmaken
  </button>
</div>
