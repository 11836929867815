<h1 mat-dialog-title>Bevestiging versturen?</h1>
<div mat-dialog-content>
  <p>
    De rit is opgeslagen.<br />
    Wil je een bevestiging sturen naar de personen in de rit?
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Nee</button>
  <button
    mat-raised-button
    color="warn"
    [mat-dialog-close]="true"
    cdkFocusInitial
  >
    Ja
  </button>
</div>
