<app-nav-bar></app-nav-bar>
<div
  class="users"
  fxLayout="column"
>
  <div
    class="header"
    fxLayout="row wrap"
    fxLayoutAlign="space-between center"
  >
    <div
      class="h-title"
      fxFlex
    >
      <mat-icon>phone</mat-icon>
      <span>Telefoonnummers beheren</span>
    </div>
    <button
      mat-raised-button
      style="height: 40px"
      (click)="openImportExport()"
    >
      <mat-icon>import_export</mat-icon
      ><span fxHide.xs>Importeren/Exporteren</span>
    </button>
  </div>
  <mat-card
    class="room-below-card"
    *ngIf="numbers; else loading"
    style="padding: 24px"
  >
    <div
      class="title"
      fxLayout="row"
    >
      <h3 fxFlex>
        Alle telefoonnummers <span>({{ totalNumbers }})</span>
      </h3>
      <div
        class="search"
        fxLayoutAlign=" center"
      >
        <mat-form-field>
          <span matPrefix>
            <mat-icon>search</mat-icon>
          </span>
          <mat-label class="placeholder">Zoeken</mat-label>
          <input
            matInput
            type="search"
            autocomplete="off"
            placeholder="Zoeken"
            [(ngModel)]="searchQuery"
            (ngModelChange)="onFilterChange('search', $event)"
          />
        </mat-form-field>
      </div>
    </div>
    <div #TABLE>
      <mat-table
        mat-table
        id="tblData"
        #table
        [dataSource]="filteredNumbers"
      >
        <!-- Order Column -->
        <ng-container matColumnDef="order">
          <mat-header-cell *matHeaderCellDef>Volgorde</mat-header-cell>
          <mat-cell *matCellDef="let number">
            <p
              class="text-truncate"
              *ngIf="number.order !== undefined && number.order !== null"
            >
              {{ number.order }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>Naam</mat-header-cell>
          <mat-cell *matCellDef="let number">
            <p
              class="text-truncate"
              *ngIf="number.name"
            >
              {{ number.name }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- Phone Column -->
        <ng-container matColumnDef="phone">
          <mat-header-cell *matHeaderCellDef>Telefoonnummer</mat-header-cell>
          <mat-cell *matCellDef="let number">
            <p
              *ngIf="number.phone"
              class="email text-truncate"
            >
              {{ number.phone }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- Button Column -->
        <ng-container matColumnDef="button">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let number">
            <div
              fxFlex="row"
              fxLayoutAlign="end center"
            >
              <button
                mat-icon-button
                [matMenuTriggerFor]="moreMenu"
                aria-label="More"
                (click)="$event.stopPropagation()"
              >
                <mat-icon class="secondary-text">more_vert</mat-icon>
              </button>

              <mat-menu #moreMenu="matMenu">
                <button
                  mat-menu-item
                  (click)="editNumber(number)"
                >
                  <span>Bewerken</span>
                </button>
                <button
                  mat-menu-item
                  (click)="removeNumber(number)"
                >
                  <span>Verwijderen</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr
          mat-row
          *matRowDef="let number; columns: displayedColumns"
          class="user"
          (click)="editNumber(number)"
          matRipple
        ></tr>
      </mat-table>
    </div>
  </mat-card>
  <ng-template #loading>
    <div
      id="loading"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
  <input
    hidden
    type="file"
    accept=".xlsx"
    #uploader
    (change)="uploadedFile($event)"
  />
</div>
<button
  mat-fab
  class="fab-fixed"
  (click)="newNumber()"
>
  <mat-icon>add</mat-icon>
</button>
