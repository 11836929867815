<app-nav-bar></app-nav-bar>
<div class="users" fxLayout="column">
  <div class="header" fxLayout="row wrap" fxLayoutAlign="space-between center">
    <div class="h-title" fxFlex>
      <mat-icon>directions_car</mat-icon>
      <span>Voertuigen beheren</span>
    </div>
    <button mat-raised-button style="height: 40px" (click)="openImportExport()">
      <mat-icon>import_export</mat-icon
      ><span fxHide.xs>Importeren/Exporteren</span>
    </button>
  </div>
  <mat-card
    class="room-below-card"
    *ngIf="vehicles; else loading"
    style="padding: 24px"
  >
    <div class="title" fxLayout="row">
      <h3 fxFlex>
        Alle voertuigen <span>({{ totalVehicles }})</span>
      </h3>
      <div class="search" fxLayoutAlign=" center">
        <mat-form-field>
          <span matPrefix>
            <mat-icon>search</mat-icon>
          </span>
          <mat-label class="placeholder">Zoeken</mat-label>
          <input
            matInput
            type="search"
            autocomplete="off"
            placeholder="Zoeken"
            [(ngModel)]="searchQuery"
            (ngModelChange)="onFilterChange('search', $event)"
          />
        </mat-form-field>
      </div>
    </div>
    <div #TABLE>
      <mat-table mat-table id="tblData" #table [dataSource]="filteredVehicles">
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>Naam</mat-header-cell>
          <mat-cell *matCellDef="let vehicle">
            <p class="text-truncate" *ngIf="vehicle.name">{{ vehicle.name }}</p>
          </mat-cell>
        </ng-container>

        <!-- seats Column -->
        <ng-container matColumnDef="seats">
          <mat-header-cell *matHeaderCellDef>Zitplaatsen</mat-header-cell>
          <mat-cell *matCellDef="let vehicle">
            <p *ngIf="vehicle.seats" class="email text-truncate">
              {{ vehicle.seats }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- mileage Column -->
        <ng-container matColumnDef="currentMileage">
          <mat-header-cell *matHeaderCellDef>Kilometerstand</mat-header-cell>
          <mat-cell *matCellDef="let vehicle">
            <p *ngIf="vehicle.currentMileage" class="email text-truncate">
              {{ vehicle.currentMileage }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- color Column -->
        <ng-container matColumnDef="color">
          <mat-header-cell *matHeaderCellDef>Kleur</mat-header-cell>
          <mat-cell *matCellDef="let vehicle">
            <div
              class="dot"
              [ngStyle]="{ 'background-color': vehicle.calendarStyle.color }"
            ></div>
            <p class="phone text-truncate">
              {{ vehicle.calendarStyle.color }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- sortingNumber Column -->
        <ng-container matColumnDef="sortingNumber">
          <mat-header-cell *matHeaderCellDef>Volgordenummer</mat-header-cell>
          <mat-cell *matCellDef="let vehicle">
            <p class="phone text-truncate">
              {{ vehicle.calendarStyle.sortingNumber }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- colorEmpty Column -->
        <ng-container matColumnDef="backgroundColorEmpty">
          <mat-header-cell *matHeaderCellDef
            >Rit leeg achtergrond</mat-header-cell
          >
          <mat-cell *matCellDef="let vehicle">
            <div
              class="dot"
              [ngStyle]="{
                'background-color': vehicle.calendarStyle.backgroundColorEmpty
              }"
            ></div>
            <p class="phone text-truncate">
              {{ vehicle.calendarStyle.backgroundColorEmpty }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- colorFull Column -->
        <ng-container matColumnDef="backgroundColorFull">
          <mat-header-cell *matHeaderCellDef
            >Rit vol achtergrond</mat-header-cell
          >
          <mat-cell *matCellDef="let vehicle">
            <div
              class="dot"
              [ngStyle]="{
                'background-color': vehicle.calendarStyle.backgroundColorFull
              }"
            ></div>
            <p class="phone text-truncate">
              {{ vehicle.calendarStyle.backgroundColorFull }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- button Column -->
        <ng-container matColumnDef="button">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let vehicle">
            <div fxFlex="row" fxLayoutAlign="end center">
              <button
                mat-icon-button
                [disabled]="!vehicle.active"
                [matMenuTriggerFor]="moreMenu"
                aria-label="More"
                (click)="$event.stopPropagation()"
              >
                <mat-icon class="secondary-text">more_vert</mat-icon>
              </button>

              <mat-menu #moreMenu="matMenu">
                <button mat-menu-item (click)="editVehicle(vehicle)">
                  <span>Bewerken</span>
                </button>
                <!-- <button mat-menu-item (click)="deactivateVehicle(vehicle)">
                                    <span>Inactief</span>
                                </button> -->
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          [ngClass]="{ 'card-inactive': !vehicle.active }"
          *matRowDef="let vehicle; columns: displayedColumns"
          class="user"
          (click)="editVehicle(vehicle)"
          matRipple
        ></tr>
      </mat-table>
    </div>
  </mat-card>
  <ng-template #loading>
    <div id="loading" fxLayout="row" fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
  <input
    hidden
    type="file"
    accept=".xlsx"
    #uploader
    (change)="uploadedFile($event)"
  />
</div>
<button mat-fab class="fab-fixed" (click)="newVehicle()">
  <mat-icon>add</mat-icon>
</button>
