<app-nav-bar></app-nav-bar>
<div
  class="deleted-rides"
  fxLayout="column"
>
  <div class="header">
    <div class="header-internals">
      <div class="header-title">
        <mat-icon>delete</mat-icon>
        <div>Prullenbak</div>
      </div>
      <div class="header-button-search">
        <button
          class="header-button"
          mat-raised-button
          (click)="exportDeletedRides()"
        >
          <mat-icon>file_download</mat-icon>
          Export
        </button>
        <form [formGroup]="filterForm">
          <div class="search">
            <mat-form-field color="accent">
              <mat-label class="search-label">Zoek in</mat-label>
              <mat-select
                formControlName="searchOn"
                cdkFocusInitial
              >
                <mat-option value="all">Alles</mat-option>
                <mat-option value="start">Rit datum</mat-option>
                <mat-option value="vehicle">Voertuig</mat-option>
                <mat-option value="user">Deelnemer</mat-option>
                <mat-option value="doneByUser">Verwijderd door</mat-option>
                <mat-option value="deleteReason">Reden van verwijderen</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field color="accent">
              <mat-label class="search-label">Zoeken</mat-label>
              <input
                matInput
                type="search"
                autocomplete="off"
                formControlName="search"
                [(ngModel)]="searchQuery"
              />
              <button
                mat-icon-button
                matSuffix
                (click)="clearSearch()"
              >
                <mat-icon>clear</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="body">
    <mat-card class="room-below-card">
      <div
        fxLayout="row wrap"
        fxLayout.xs="column"
      >
        <h2 fxFlex="20">
          verwijderde ritten
          <span *ngIf="selectedDateFilter != 'year'">({{this.totalDeletedRides}})</span>
        </h2>
        <div
          fxFlex
          fxLayout="row wrap"
          fxLayout.xs="column"
          fxLayoutAlign.gt-xs="end center"
          fxLayoutGap="4px"
        >
          <mat-form-field>
            <mat-label>Selecteer filter</mat-label>
            <mat-select
              (selectionChange)="getDeletedRides()"
              [(ngModel)]="selectedDateFilter"
            >
              <mat-option
                *ngFor="let filter of dateFilter"
                [value]="filter.value"
                (click)="setDateFilterType(filter.value)"
              >
                {{ filter.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            *ngIf="
              selectedDateFilter == 'year' ||
              selectedDateFilter == 'month' ||
              selectedDateFilter === 'week'
            "
          >
            <mat-label>Jaar</mat-label>
            <mat-select
              (selectionChange)="getDeletedRides()"
              [(ngModel)]="selectedYear"
            >
              <mat-option
                *ngFor="let year of years"
                [value]="year.value"
                >{{ year.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="selectedDateFilter == 'month'">
            <mat-label>Maand</mat-label>
            <mat-select
              (selectionChange)="getDeletedRides()"
              [(ngModel)]="selectedMonth"
            >
              <mat-option
                *ngFor="let month of months"
                [value]="month.value"
                >{{ month.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="selectedDateFilter == 'week'">
            <mat-label>Week</mat-label>
            <mat-select
              (selectionChange)="getDeletedRides()"
              [(ngModel)]="selectedWeek"
            >
              <mat-option
                *ngFor="let week of weekArray"
                [value]="week"
                >{{ week }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <mat-table mat-table [dataSource]="this.visibleDeletedRides">
        <ng-container matColumnDef="ride-date">
          <mat-header-cell *matHeaderCellDef>
            Rit datum
            <button
              mat-icon-button
              (click)="
                this.sortByColumn = 'rideDate';
                this.filterForm.controls.rideDateAscToDesc.setValue(
                  !this.filterForm.value.rideDateAscToDesc
                );
                this.filterForm.controls.deleteDateAscToDesc.setValue(
                  false
                );
              "
              matTooltip="Sorteren omdraaien"
            >
            <mat-icon *ngIf="this.filterForm.value.rideDateAscToDesc && this.sortByColumn == 'rideDate'">
              arrow_upward
            </mat-icon>
            <mat-icon *ngIf="!this.filterForm.value.rideDateAscToDesc && this.sortByColumn == 'rideDate'">
              arrow_downward
            </mat-icon>
            <mat-icon *ngIf="this.sortByColumn == 'deleteDate'">
              compare_arrows
            </mat-icon>
          </button>
          </mat-header-cell>
          <mat-cell *matCellDef="let deletedRide">
            <p class="text-truncate">
              <span>{{ deletedRide.rideData.start.toDate() | date : 'd/MM/yyyy HH:mm' }} uur</span>
            </p>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="vehicle">
          <mat-header-cell *matHeaderCellDef>Voertuig</mat-header-cell>
          <mat-cell *matCellDef="let deletedRide">
            <p class="text-truncate">
              {{ deletedRide.vehicleName }}
            </p>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="deleted-date">
          <mat-header-cell *matHeaderCellDef>
            Verwijderd op
            <button
              mat-icon-button
              (click)="
                this.sortByColumn = 'deleteDate';
                this.filterForm.controls.deleteDateAscToDesc.setValue(
                  !this.filterForm.value.deleteDateAscToDesc
                );
                this.filterForm.controls.rideDateAscToDesc.setValue(
                  false
                );
              "
              matTooltip="Sorteren omdraaien"
            >
            <mat-icon *ngIf="this.filterForm.value.deleteDateAscToDesc && this.sortByColumn == 'deleteDate'">
              arrow_upward
            </mat-icon>
            <mat-icon *ngIf="!this.filterForm.value.deleteDateAscToDesc && this.sortByColumn == 'deleteDate'">
              arrow_downward
            </mat-icon>
            <mat-icon *ngIf="this.sortByColumn == 'rideDate'">
              compare_arrows
            </mat-icon>
          </button>
          </mat-header-cell>
          <mat-cell *matCellDef="let deletedRide">
            <p class="text-truncate">
              {{ deletedRide.date.toDate() | date : 'd/MM/yyyy' }}
            </p>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="deleted-by">
          <mat-header-cell *matHeaderCellDef>Verwijderd door</mat-header-cell>
          <mat-cell *matCellDef="let deletedRide">
            <p class="text-truncate">
              {{ deletedRide.doneByUser }}
            </p>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="reason-of-deletion-message">
          <mat-header-cell *matHeaderCellDef>Reden van verwijderen</mat-header-cell>
          <mat-cell *matCellDef="let deletedRide">
            <p class="text-truncate">
              {{ deletedRide.deleteReason && deletedRide.deleteReason != '' ? deletedRide?.deleteReason : '-' }}
            </p>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="no-header"></mat-header-cell>
            <mat-cell *matCellDef="let deletedRide" class="actions-cell">
              <button mat-icon-button>
                <mat-icon>visibility</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row (click)="moreInformationDeletedRide(deletedRide)" *matRowDef="let deletedRide; columns: displayedColumns;"></mat-row>
      </mat-table>
      <mat-list
        class="load-more"
        *ngIf="totalDeletedRides > amountToShow"
      >
        <mat-list-item (click)="loadMore()">Meer laden... </mat-list-item>
      </mat-list>
    </mat-card>
  </div>
</div>