<h1 mat-dialog-title>
  <span *ngIf="data && data.showDate; else default"
    >Ritten exporteren/importeren</span
  >
  <ng-template #default>Exporteren/importeren</ng-template>
</h1>
<div mat-dialog-content>
  <form [formGroup]="transferDataGroup">
    <mat-radio-group
      aria-label="Select an option"
      fxLayout="column"
      fxLayoutGap="8px"
      formControlName="type"
    >
      <mat-radio-button
        *ngFor="let option of options"
        [value]="option"
        [checked]="option.checked"
        >{{ option.name }}</mat-radio-button
      >
    </mat-radio-group>
    <mat-form-field
      *ngIf="data && data.showDate && selectedOption.value === 'Export'"
    >
      <mat-label
        >Ik wil {{ selectedOption.name.toLowerCase() }} vanaf</mat-label
      >
      <input matInput [matDatepicker]="picker" formControlName="startFrom" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-error
      *ngIf="data && data.showDate && selectedOption.value === 'Import'"
    >
      Let op: bij het importeren worden de ritten met hetzelfde ID overschreven.
    </mat-error>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Annuleren</button>
  <button
    mat-raised-button
    color="primary"
    (click)="save()"
    [disabled]="transferDataGroup.invalid"
  >
    {{ selectedOption.value === 'Export' ? 'Exporteren' : 'Importeren' }}
  </button>
</div>
