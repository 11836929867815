import { Component, OnInit, Inject } from '@angular/core';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
} from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { TariffsComponent } from '../../../tariffs/tariffs.component';
import { TariffId } from './../../../../interfaces';
import { DefaultTariffWarningComponent } from './dialogs/default-tariff-warning/default-tariff-warning.component';
import { deleteField } from 'firebase/firestore';

@Component({
  selector: 'app-create-edit-tariff',
  templateUrl: './create-edit-tariff.component.html',
  styleUrls: ['./create-edit-tariff.component.scss'],
})
export class CreateEditTariffComponent implements OnInit {
  tariffForm: UntypedFormGroup;
  tariffId: string;
  tariff: TariffId;
  toggle: boolean;
  isNew: boolean;

  organisationId = localStorage.getItem('orgId');

  constructor(
    private fb: UntypedFormBuilder,
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TariffsComponent>
  ) {}

  ngOnInit() {
    console.log('data', this.data);
    if (!this.data.tariff) {
      this.isNew = true;
      this.tariffId = this.db.createId();
    } else if (this.data.tariff) {
      this.tariff = this.data.tariff;
      console.log('tariff', this.tariff);
      this.tariffId = this.tariff.id;
    }
    this.tariffForm = this.fb.group({
      name: ['', Validators.required],
      cost: ['', Validators.required],
      color: [''],
      default: [],
    });
    if (this.tariff) {
      const formVal: any = { ...this.tariff };
      if (this.tariff.color) {
        formVal.color = `#${this.tariff.color}`;
      }

      console.log('formval', formVal);
      this.tariffForm.patchValue(formVal);
    }
    console.log('this.taskForm', this.tariffForm.value);
  }

  async onChange(ev) {
    this.data.tariffs.forEach((tariff: TariffId) => {
      if (ev.source.checked && tariff.id != this.tariffId && tariff.default) {
        console.log('defaultchanged', ev.source.checked);
        this.defaultTariffWarning(tariff.id);
      }
    });
  }

  async defaultTariffWarning(defaultTariffId) {
    const defaultRef = this.dialog.open(DefaultTariffWarningComponent, {
      width: '350px',
    });
    defaultRef.afterClosed().subscribe(async (defaultResult) => {
      console.log('The dialog was closed');
      if (defaultResult) {
        await this.db
          .doc(
            `organisations/${this.organisationId}/products/${defaultTariffId}`
          )
          .update({ default: false });
      } else {
        this.tariffForm.controls.default.setValue(false);
      }
    });
  }

  async onSubmit() {
    console.log('itemForm', this.tariffForm);
    const formValue = this.tariffForm.value;
    console.log('formValue', formValue);
    // tslint:disable-next-line: prefer-const
    if (formValue.color) {
      const correctCode = this.tariffForm.value.color
        .toString()
        .replace('#', '');
      formValue.color = correctCode;
    }

    const submitItem = formValue;
    if (!formValue.default) {
      submitItem.default = deleteField();
    }
    await this.saveItem(submitItem);
  }
  async saveItem(submitItem) {
    console.log('this.tariffId', this.tariffId);
    console.log('submitItem', submitItem);
    this.db
      .collection(`organisations/${this.organisationId}/products`)
      .doc(this.tariffId)
      .set(submitItem, { merge: true });
    this.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  close(): void {
    this.dialogRef.close();
  }
}
