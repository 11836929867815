import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { Observable, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Timestamp } from 'firebase/firestore';

export interface DialogData {
  user: any;
}

export interface History {
  description: string;
  date: Timestamp;
  creditsSpent: number;
  creditsAdded: number;
  actionBy: string;
}

export interface HistoryId extends History {
  id: string;
}

@Component({
  selector: 'app-credits-history',
  templateUrl: './credits-history.component.html',
  styleUrls: ['./credits-history.component.scss'],
})
export class CreditsHistoryComponent implements OnInit {
  history: HistoryId[];
  charges: Observable<HistoryId[]>;
  chargeCollection: AngularFirestoreCollection<History>;
  payments: Observable<HistoryId[]>;
  paymentCollection: AngularFirestoreCollection<History>;
  organisationId = localStorage.getItem('orgId');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public db: AngularFirestore
  ) {}

  ngOnInit() {
    console.log('data', this.data);
    this.chargeCollection = this.db.collection<History>(
      `organisations/${this.organisationId}/charges`,
      (ref) =>
        ref
          .where(
            'userRef',
            '==',
            this.db.collection(`users`).doc(this.data.user.id).ref
          )
          .orderBy('date', 'desc')
    );
    this.charges = this.chargeCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as History;
          // console.log('tag', data);
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      ),
      take(1)
    );
    this.paymentCollection = this.db.collection<History>(
      `organisations/${this.organisationId}/payments`,
      (ref) =>
        ref
          .where(
            'userRef',
            '==',
            this.db.collection(`users`).doc(this.data.user.id).ref
          )
          .orderBy('date', 'desc')
    );
    this.payments = this.paymentCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as History;
          // console.log('tag', data);
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      ),
      take(1)
    );
    const combinedObservable = combineLatest(this.charges, this.payments);
    combinedObservable.subscribe((values) => {
      let history = [];
      // console.log('values', values);
      const charges = values[0];
      const payments = values[1];
      charges.forEach((charge) => {
        console.log('charge', charge);
        history.push(charge);
      });
      payments.forEach((payment) => {
        console.log('payment', payment);
        history.push(payment);
      });
      history = history.sort((x, y) => {
        return x.date.toDate() - y.date.toDate();
      });

      this.history = history.reverse();
      console.log('this.history', this.history);
    });
  }
}
