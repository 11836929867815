export const environment = {
  production: false,
  userUrl: 'https://mijn.plusbusdeliemers.nl/',
  firebase: {
    apiKey: 'AIzaSyCU3N3fE5u6TFaCWkkyErDOBADAZff1uGw',
    authDomain: 'wemaron-buurt-vervoer-prod.firebaseapp.com',
    projectId: 'wemaron-buurt-vervoer-prod',
    storageBucket: 'wemaron-buurt-vervoer-prod.appspot.com',
    messagingSenderId: '474366941422',
    appId: '1:474366941422:web:912949546867333b343bff',
    measurementId: 'G-V76FFF9HJR',
  },
  functionsUrl:
    'https://europe-west1-wemaron-buurt-vervoer-prod.cloudfunctions.net',
  routes: [
    'login',
    'planner',
    'users',
    'adminUsers',
    'adminTools',
    'adminToolsSetupPaymentMethods',
    'frequentAddress',
    'characteristics',
    'audit',
    'deletedRides',
    'settings',
    'settingsVehicles',
    'settingsPhoneNumbers',
    'settingsTariffs',
    'availability',
    'dayparts',
    'settingsVehicleGroups',
    'selectingOrganisation',
    'settingsDebitOrders',
    'set',
  ],
  organisation: {
    id: 'plusbus-deliemers',
    image:
      'https://firebasestorage.googleapis.com/v0/b/wemaron-buurtvervoer.appspot.com/o/organisations%2Fplusbus%2Fplusbus-login.svg?alt=media&token=65bb091d-3437-4e71-a3ea-7dceab0937da',
  },
};
