import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { getAuth } from 'firebase/auth';
import {
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  Timestamp,
  where,
} from 'firebase/firestore';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { UserRideComponent } from './dialogs/user-ride/user-ride.component';
import { CopyUpdateComponent } from '../../dialogs/copy-update/copy-update.component';
import { DeleteRideComponent } from './dialogs/delete-ride/delete-ride.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import moment from 'moment';
import {
  Product,
  Ride,
  Vehicle,
  DaypartDay,
  Setting,
  User,
  Characteristic,
  Icon,
  IconId,
  TariffId,
} from 'src/app/interfaces';
import { FuncsService } from 'src/app/services/funcs.services';
import { getDateString } from 'src/app/globals';
import { NotifyUsersRideDialog } from './dialogs/notify-users-ride/notify-users-ride.component';
import { lastValueFrom } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { WarningDialog } from 'src/app/global-dialogs/warning/warning.component';
export interface DialogData {
  ride: Ride;
  defaultVehicle: string;
  dates: {
    start: Date;
    end: Date;
  };
  parentRideId?: string;
  retourGenerating?: boolean;
  users: any;
  timeStart: any;
  timeEnd: any;
  retourId: any;
  rideId: any;
}

@Component({
  selector: 'app-edit-ride',
  templateUrl: './edit-ride.component.html',
  styleUrls: ['./edit-ride.component.scss'],
})
export class EditRideComponent implements OnInit {
  rideId: string;
  newRide: boolean;
  rideForm: UntypedFormGroup;
  users: Array<any> = [];
  originalUserList: Array<any> = [];
  removedUsers: User[] = [];
  addedUsers: User[] = [];
  oldUserFromLocation?: any;
  oldUserToLocation?: any;
  saveObj: Map<string, any>;
  editObj: Map<string, any>;
  createObjArray: Map<string, any>;
  hours: number[] = [];
  minutes: any[] = [];

  products: Product[] = [];
  showRideProduct: boolean;
  vehicleCollection: AngularFirestoreCollection<Vehicle>;
  vehicles: Observable<Vehicle[]>;
  iconsCollection: AngularFirestoreCollection<Icon>;
  icons: Observable<IconId[]>;
  iconsArray: Array<IconId>;
  vehiclesArray: Array<object>;
  selectedVehicle: any;
  availableSeats: number;

  organisationId = localStorage.getItem('orgId');
  userId: string;
  userEmail: string;
  minuteStep = 5;
  vehicleMesage: string;
  drivers: any[] = [];

  special: boolean;
  driverId: string;

  signalColorIsPresent = false;
  activateSignalColor = false;
  ignoreDriverStatus: boolean;
  rideDuration: number;
  rideCreated: boolean;

  characteristicCtrl = new UntypedFormControl();
  filteredCharacteristics: Observable<string[]>;
  characteristics: string[] = [];
  allCharacteristics: string[] = [];
  characteristicsStatus: boolean;
  emailNotificationsParticipants: {
    enabled: boolean;
    emailNotificationsParticipantsRideConfirmed: boolean;
    emailNotificationsParticipantsRideChanged: boolean;
    emailNotificationsParticipantsRideDeleted: boolean;
  } = {
    enabled: false,
    emailNotificationsParticipantsRideConfirmed: false,
    emailNotificationsParticipantsRideChanged: false,
    emailNotificationsParticipantsRideDeleted: false,
  };
  smsNotificationsParticipants: {
    enabled: boolean;
    smsNotificationsParticipantsRideConfirmed: boolean;
    smsNotificationsParticipantsRideChanged: boolean;
    smsNotificationsParticipantsRideDeleted: boolean;
  } = {
    enabled: false,
    smsNotificationsParticipantsRideConfirmed: false,
    smsNotificationsParticipantsRideChanged: false,
    smsNotificationsParticipantsRideDeleted: false,
  };
  doubleBookingActivated: boolean = false;

  filteredRideProductOptions: Observable<Product[]>;
  loading: boolean = false;
  startTimeChanged: boolean = false;
  startTimes: any;
  showDriverPlanning: boolean;

  readonly separatorKeysCodes = [COMMA, ENTER, SPACE] as const;
  @ViewChild('characteristicInput')
  characteristicInput: ElementRef<HTMLInputElement>;
  retourAlreadyBooked: boolean;
  vehicleAlreadyBooked: boolean;
  generalSettings: Setting;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditRideComponent>,
    private _snackBar: MatSnackBar,
    private funcs: FuncsService,
    private fns: AngularFireFunctions
  ) {}

  async ngOnInit() {
    this.showDriverPlanning = JSON.parse(
      localStorage.getItem('usesDriverPlanning')
    );
    this.userId = getAuth().currentUser.uid;
    this.userEmail = getAuth().currentUser.email;

    let start;
    // new dates are created so the changed form value is not linked to input data object anymore, so we can calculate the offset.
    if (this.data.ride && this.data.ride.start) {
      start = new Date(this.data.ride.start);
    } else {
      start = this.data.dates.start;
    }

    if (this.data.timeStart) {
      start = new Date(this.data.timeStart);
    }
    let end;
    if (this.data.ride && this.data.ride.end) {
      end = new Date(this.data.ride.end);
    } else {
      end = this.data.dates.end;
    }
    if (this.data.timeEnd) {
      start = new Date(this.data.timeEnd);
    }

    this.rideForm = this.fb.group({
      comments: [this.data.ride.comments],
      start: [start, Validators.required],
      startHour: [start.getHours(), Validators.required],
      startMinute: [start.getMinutes(), Validators.required],
      end: [end, Validators.required],
      endHour: [end.getHours(), Validators.required],
      endMinute: [end.getMinutes(), Validators.required],
      rideProduct: [, Validators.required],
      special: [this.data.ride.special],
      vehicleId: [, Validators.required],
      driverId: [],
    });

    this.generalSettings = (
      await getDoc(
        doc(
          this.db.firestore,
          `organisations/${this.organisationId}/settings/general`
        )
      )
    ).data() as Setting;

    console.log('data', this.data);
    if (this.data.ride.id) {
      this.rideId = this.data.ride.id;
      this.rideCreated = true;
      console.log('rideId', this.rideId);
    } else {
      this.newRide = true;
      this.rideCreated = false;
      this.rideId = this.db.createId();
    }

    if (this.data.ride && this.data.ride.users) {
      this.users = Object.values(this.data.ride.users).sort(
        (x: any, y: any) => {
          return x.order - y.order;
        }
      );
      if (this.users.length > 0) {
        this.oldUserFromLocation = { ...this.users[0] };
        this.oldUserToLocation = { ...this.users[this.users.length - 1] };
      }
    }

    this.users.forEach((user, index) => {
      if (!user.order && !user.title) {
        this.users.splice(index, 1);
      } else {
        this.originalUserList.push(user);
      }
    });

    this.emailNotificationsParticipants.enabled =
      this.generalSettings.emailNotificationsParticipants ?? false;

    this.emailNotificationsParticipants.emailNotificationsParticipantsRideConfirmed =
      this.generalSettings.emailNotificationsParticipantsRideConfirmed ?? false;

    this.emailNotificationsParticipants.emailNotificationsParticipantsRideChanged =
      this.generalSettings.emailNotificationsParticipantsRideChanged ?? false;

    this.emailNotificationsParticipants.emailNotificationsParticipantsRideDeleted =
      this.generalSettings.emailNotificationsParticipantsRideDeleted ?? false;

    this.smsNotificationsParticipants.enabled =
      this.generalSettings.smsNotificationsParticipants ?? false;

    this.smsNotificationsParticipants.smsNotificationsParticipantsRideConfirmed =
      this.generalSettings.smsNotificationsParticipantsRideConfirmed ?? false;

    this.smsNotificationsParticipants.smsNotificationsParticipantsRideChanged =
      this.generalSettings.smsNotificationsParticipantsRideChanged ?? false;

    this.smsNotificationsParticipants.smsNotificationsParticipantsRideDeleted =
      this.generalSettings.smsNotificationsParticipantsRideDeleted ?? false;

    this.characteristicsStatus =
      this.generalSettings.characteristicsStatus ?? false;

    this.doubleBookingActivated =
      this.generalSettings.doubleBookingActivated ?? false;

    this.characteristicsStatus =
      this.generalSettings.characteristicsStatus ?? false;

    (
      await getDocs(
        query(
          collection(
            this.db.firestore,
            `organisations/${this.organisationId}/products`
          ),
          orderBy('name', 'asc')
        )
      )
    ).forEach((doc) => {
      const saveObj = { ...doc.data(), id: doc.id } as Product;
      this.products.push(saveObj);
    });

    let product = this.products.find((product) => {
      return product.default;
    });
    if (this.data.ride.rideProduct) {
      const selectedProduct = this.products.find((product) => {
        return product.id === this.data.ride.rideProduct.id;
      });
      if (selectedProduct) {
        product = selectedProduct;
      }
    }
    this.rideForm.patchValue({ rideProduct: product.name });

    const sortTariffs = (a: TariffId, b: TariffId) => {
      return a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    };

    this.filteredRideProductOptions =
      this.rideForm.controls.rideProduct.valueChanges.pipe(
        startWith(''),
        map((value) => {
          const name = typeof value === 'string' ? value : value?.name;
          return name
            ? this.filterRideProducts(name as string)
            : this.products.slice();
        }),
        map((options) => options.sort(sortTariffs))
      );

    this.vehicleCollection = this.db.collection<Vehicle>(
      `organisations/${this.organisationId}/vehicles`,
      (ref) => ref.orderBy('name', 'asc').where('active', '==', true)
    );
    this.vehicles = this.vehicleCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Vehicle;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      ),
      take(1)
    );
    this.iconsCollection = this.db.collection<Icon>(
      `organisations/${this.organisationId}/icons`,
      (ref) => ref.orderBy('name', 'asc')
    );
    this.icons = this.iconsCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Icon;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      ),
      take(1)
    );
    this.icons.subscribe((icons) => {
      this.iconsArray = icons;
    });
    this.vehicles.subscribe(async (vehicles) => {
      // console.log('vehicles', vehicles);
      this.vehiclesArray = vehicles;
      if (this.data.ride && this.data.ride.vehicleId) {
        this.selectedVehicle = vehicles.find((vehicle) => {
          return vehicle.id === this.data.ride.vehicleRef.id;
        });
      } else if (this.data.defaultVehicle !== 'all') {
        this.selectedVehicle = vehicles.find((vehicle) => {
          return vehicle.id === this.data.defaultVehicle;
        });
      }
      console.log('this.selectedVehicle', this.selectedVehicle);
      if (!this.selectedVehicle) {
        // IF SELECTEDVEHICLE IS STILL UNDEFINED USE DEFAULT
        this.selectedVehicle = vehicles[0];
      }
      if (!this.selectedVehicle) {
        this.vehicleMesage = 'Activeer eerst een voertuig bij instellingen';
      } else {
        this.vehicleMesage = 'Voertuigen voor deze rit';
      }
      console.log('this.selectedVehicle', this.selectedVehicle);

      this.rideDuration = this.generalSettings.customRideDuration ?? 15;
      if (!this.data.ride.end) {
        this.rideForm.value.endMinute =
          this.rideForm.value.startMinute + this.rideDuration;
        const calculatedMinutes = Math.floor(
          this.rideForm.value.endMinute / 60
        );
        const endMinute =
          this.rideForm.value.endMinute - 60 * calculatedMinutes;
        this.rideForm.value.endMinute = endMinute;
      }

      let startHour = 6;
      let endHour = 24;

      if (this.generalSettings.daypartSettings) {
        startHour = this.generalSettings.daypartSettings.startHour;
        endHour = this.generalSettings.daypartSettings.endHour;
      }

      if (this.generalSettings.signalColor) {
        this.signalColorIsPresent = true;
      }

      if (this.generalSettings.activateSignalColor) {
        this.activateSignalColor = true;
      }

      for (let i = startHour; i <= endHour; i++) {
        this.hours.push(i);
      }

      for (let i = 0; i < 60; i += this.minuteStep) {
        this.minutes.push({
          val: i,
          text: `0${i}`.slice(-2),
        });
      }

      this.ignoreDriverStatus = this.generalSettings.ignoreDriverStatus;
      this.rideForm.patchValue({
        vehicleId: this.selectedVehicle ? this.selectedVehicle.id : undefined,
      });

      if (this.selectedVehicle) {
        this.updateAvailableSeats();
        await this.getAvailableDrivers();
      }
    });

    this.showRideProduct = this.generalSettings.showRideProduct;

    if (this.data.ride.characteristics) {
      for (const characteristics of this.data.ride.characteristics) {
        const index = this.characteristics.indexOf(characteristics);
        if (index === -1) {
          this.characteristics.push(characteristics);
        }
      }
    }
    const characteristicArray = await getDocs(
      this.db.firestore.collection(
        `organisations/${this.organisationId}/characteristics`
      )
    );
    characteristicArray.forEach((characteristicDoc) => {
      const characteristic = characteristicDoc.data();
      this.allCharacteristics.push(characteristic.name);
    });
    this.filteredCharacteristics = this.characteristicCtrl.valueChanges.pipe(
      startWith(null),
      map((characteristic: string | null) =>
        characteristic
          ? this._filter(characteristic)
          : this.allCharacteristics.slice()
      )
    );
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.users, event.previousIndex, event.currentIndex);
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      const index = this.allCharacteristics.indexOf(value);
      if (index >= 0) {
        const index = this.characteristics.indexOf(value);
        if (index === -1) {
          this.characteristics.push(value);
        }
      }
    }
    event.input.value = '';

    this.characteristicCtrl.setValue(null);
  }

  remove(characteristic: string): void {
    const index = this.characteristics.indexOf(characteristic);
    if (index >= 0) {
      this.characteristics.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const index = this.characteristics.indexOf(event.option.viewValue);
    if (index === -1) {
      this.characteristics.push(event.option.viewValue);
    }
    this.characteristicInput.nativeElement.value = '';
    this.characteristicCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allCharacteristics.filter((characteristic) =>
      characteristic.toLowerCase().includes(filterValue)
    );
  }

  private filterRideProducts(name: string): Product[] {
    const filterValue = name.toLowerCase();

    return this.products.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  async dateUpdated(type) {
    const patchObj = {};
    if (type === 'start') {
      const startDate = new Date(0);
      startDate.setTime(
        this.rideForm.value.start.setHours(
          this.rideForm.value.startHour,
          this.rideForm.value.startMinute
        )
      );
      if (startDate >= this.rideForm.value.end) {
        const endDate = new Date(0);
        console.log(this.rideForm.value.start);
        console.log(this.rideForm.value.startHour);
        console.log(this.rideForm.value.startMinute + this.rideDuration);
        endDate.setTime(
          this.rideForm.value.start.setHours(
            this.rideForm.value.startHour,
            this.rideForm.value.startMinute + this.rideDuration
          )
        );
        console.log('endDate', endDate);
        patchObj['end'] = endDate;
        patchObj['endHour'] = endDate.getHours();
        patchObj['endMinute'] = endDate.getMinutes();
        this._snackBar.open(
          'De einddatum is veranderd omdat deze eerder was dan de begindatum.',
          'X',
          {
            duration: 5000,
          }
        );
      }
      patchObj['start'] = startDate;
    } else if (type === 'end') {
      const endDate = new Date(0);
      endDate.setTime(
        this.rideForm.value.end.setHours(
          this.rideForm.value.endHour,
          this.rideForm.value.endMinute
        )
      );
      patchObj['end'] = endDate;
      if (this.rideForm.value.start >= endDate) {
        const endDate = new Date(this.rideForm.value.start);
        const startDate = new Date(this.rideForm.value.start);
        endDate.setTime(
          startDate.setHours(
            this.rideForm.value.startHour,
            this.rideForm.value.startMinute + this.rideDuration
          )
        );
        patchObj['end'] = endDate;
        patchObj['endHour'] = endDate.getHours();
        patchObj['endMinute'] = endDate.getMinutes();
        this._snackBar.open(
          'De gekozen einddatum was eerder dan de begindatum daarom is hij veranderd.',
          'X',
          {
            duration: 5000,
          }
        );
      }
    }

    const newDriver = await this.getAvailableDrivers();
    this.rideForm.patchValue(patchObj);
  }
  moveUser(direction, i, user) {
    let newIndex = i;
    if (direction === 'up') {
      newIndex = newIndex - 1;
    }
    if (direction === 'down') {
      newIndex = newIndex + 1;
    }
    moveItemInArray(this.users, i, newIndex);
  }

  async vehicleChanged() {
    this.selectedVehicle = this.vehiclesArray.find((vehicle) => {
      return vehicle['id'] === this.rideForm.value.vehicleId;
    });
    this.updateAvailableSeats();
    const newDriver = await this.getAvailableDrivers();
    console.log('this.selectedVehicle', this.selectedVehicle);
    console.log('newDriver', newDriver);
  }

  updateAvailableSeats() {
    const totalSeats = this.selectedVehicle.seats;
    let seatsTaken = 0;
    this.users.forEach((user) => {
      console.log('user', user);
      seatsTaken = seatsTaken + 1;
      if (this.iconsArray && this.iconsArray.length === 0) {
        return;
      }
      this.iconsArray.forEach((icon) => {
        if (user[icon.id] && icon.takesSeat) {
          seatsTaken = seatsTaken + 1;
        }
      });
    });
    this.availableSeats = totalSeats - seatsTaken;
  }

  changeUser(i?, user?) {
    let newUser = false;
    let dialogRef;
    let dialogStart;
    let dialogEnd;
    if (!user) {
      user = {};
      newUser = true;
    }

    if (this.rideForm.value.start) {
      dialogStart = this.rideForm.value.start;
    }
    if (this.rideForm.value.end) {
      dialogEnd = this.rideForm.value.end;
    }

    let userRideData = {};

    if (this.newRide) {
      userRideData = { user, dialogStart, dialogEnd, icons: this.iconsArray };
    } else {
      userRideData = {
        user,
        dialogStart,
        dialogEnd,
        icons: this.iconsArray,
        rideId: this.data.ride.id,
      };
    }

    dialogRef = this.dialog.open(UserRideComponent, {
      width: '600px',
      data: userRideData,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      console.log('The dialog was closed with result: ', result);
      if (result) {
        if (newUser) {
          result.phoneNumber = '';
          const userDoc = this.db.doc(
            `organisations/${this.organisationId}/users/${result.id}`
          );
          const userData = userDoc.snapshotChanges().subscribe((actions) => {
            if (actions.payload.data()['phoneNumber'] != null) {
              result.phoneNumber = `${actions.payload.data()['phoneNumber']}`;
            }
            if (actions.payload.data()['phone'] != null) {
              result.phoneNumber = `${actions.payload.data()['phone']}`;
            }
          });
          this.addedUsers.push(result);
          this.users.push(result);
        } else {
          this.users[i] = result;
        }
        this.updateAvailableSeats();
        const newDriver = await this.getAvailableDrivers();
        console.log('this.users user changed', { ...this.users });
      }
    });
  }
  removeUser(index) {
    this.removedUsers.push(this.users[index]);
    this.users.splice(index, 1);
    this.updateAvailableSeats();
  }

  async deleteRide() {
    let notificationBool = false;

    if (
      this.emailNotificationsParticipants
        ?.emailNotificationsParticipantsRideDeleted
    ) {
      notificationBool =
        this.emailNotificationsParticipants
          .emailNotificationsParticipantsRideDeleted;
    }

    if (
      this.smsNotificationsParticipants.smsNotificationsParticipantsRideDeleted
    ) {
      notificationBool =
        this.smsNotificationsParticipants
          .smsNotificationsParticipantsRideDeleted;
    }

    if (this.users.length === 0) {
      notificationBool = false;
    }

    const noDeleteReason = this.generalSettings.noDeleteReason ?? false;
    const deleteRef = this.dialog.open(DeleteRideComponent, {
      width: '600px',
      data: {
        notification: notificationBool,
        noDeleteReason: noDeleteReason,
      },
    });

    deleteRef.afterClosed().subscribe(async (deleteResult: any) => {
      console.log('The dialog was closed');
      if (deleteResult !== false) {
        /////////////
        if (this.data.ride.parentRideId) {
          // ride is part of group, ask if users wants to delete all
          const updateRef = this.dialog.open(CopyUpdateComponent, {
            width: '600px',
            data: {
              oldRide: this.data.ride,
              delete: true,
              deleteReason: deleteResult,
            },
          });
          updateRef.afterClosed().subscribe(async (result) => {
            console.log('The dialog was closed with result: ', result);
            // sucessfully deleted copies (or an error!)
            await this.backupRide(deleteResult);

            const rideRef = this.db
              .collection(`organisations/${this.organisationId}/rides`)
              .doc(this.rideId);
            await rideRef.delete();
            this.notifyParticipants('delete');

            if (result) {
              //all rides of the parentId of the selected ride are removed
              this._snackBar.open('De ritten zijn verwijderd.', 'X', {
                duration: 5000,
              });
            } else if (result === false) {
              //only selected ride is deleted
              this._snackBar.open('De rit is verwijderd.', 'X', {
                duration: 5000,
              });
            }
            // result undefined / user cancelled dialog, do nothing
          });
        } else {
          await this.backupRide(deleteResult);

          const rideRef = this.db
            .collection(`organisations/${this.organisationId}/rides`)
            .doc(this.rideId);
          await rideRef.delete();
          this.notifyParticipants('delete');
          this._snackBar.open('De rit is verwijderd.', 'X', {
            duration: 5000,
          });
        }
      }
    });
  }

  async backupRide(deleteResult: string) {
    const backupRideRef = this.db.firestore
      .collection(`eventLogs/${this.organisationId}/deletedRides`)
      .doc(this.rideId);

    const backupRide = {} as any;
    backupRide.date = new Date();
    backupRide.doneByUser = this.userEmail;
    backupRide.doneByUid = this.userId;
    backupRide.deleteReason = deleteResult;
    backupRide.rideData = { ...this.data.ride };
    await backupRideRef.set(backupRide);
    await getDocs(
      this.db.firestore.collection(
        `organisations/${this.organisationId}/rides/${this.data.ride.id}/edits`
      )
    ).then((docs) => {
      docs.forEach((doc) => {
        const docRef = this.db.firestore
          .collection(
            `eventLogs/${this.organisationId}/deletedRides/${this.data.ride.id}/edits`
          )
          .doc(doc.id);
        setDoc(docRef, doc.data());
        deleteDoc(doc.ref);
      });
    });
    await getDocs(
      this.db.firestore.collection(
        `organisations/${this.organisationId}/rides/${this.data.ride.id}/scans`
      )
    ).then((docs) => {
      docs.forEach((doc) => {
        deleteDoc(doc.ref);
      });
    });
  }

  async save() {
    await this.checkIfRideIsValid(
      this.rideForm.value.start,
      this.rideForm.value.end,
      this.rideForm.value.vehicleId
    );

    let type: string = '';

    if (
      (this.retourAlreadyBooked && this.vehicleAlreadyBooked) ||
      (this.retourAlreadyBooked && !this.vehicleAlreadyBooked)
    ) {
      type = 'retourAlreadyBooked';
    } else if (
      !this.retourAlreadyBooked &&
      this.vehicleAlreadyBooked &&
      !this.doubleBookingActivated
    ) {
      type = 'vehicleAlreadyBooked';
    }

    if (
      (this.retourAlreadyBooked && !this.doubleBookingActivated) ||
      (this.vehicleAlreadyBooked && !this.doubleBookingActivated)
    ) {
      const dialogRef = this.dialog.open(WarningDialog, {
        width: '600px',
        data: { dialogType: type },
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          this.saveRide();
        }
      });
    } else {
      this.saveRide();
    }
  }

  async saveRide() {
    const rideRef = this.db
      .collection(`organisations/${this.organisationId}/rides`)
      .doc(this.rideId);
    const user = (
      await getDoc(
        doc(
          this.db.firestore,
          `organisations/${this.organisationId}/users/${this.userId}`
        )
      )
    ).data() as User;

    const saveObj = { ...(this.data.ride as any) };
    let editObj = [];
    let createObjArray = [];
    let editedRide: boolean;
    let changesObj = {};
    let createObj = {};

    let userFromLocation: string;
    let userToLocation: string;
    console.log('saveObj', saveObj);
    console.log('rideForm', this.rideForm.value);
    console.log('this.rideId', this.rideId);
    console.log('this.users in save() ', this.users);
    const usersObj = {};
    this.updateAvailableSeats();
    if (this.data.ride.users) {
      Object.keys(this.data.ride.users).forEach((id) => {
        usersObj[id] = deleteField();
      });
    }
    const userIds = [];
    this.users.forEach(async (user, i) => {
      console.log('user', user);
      if (typeof user.email === 'undefined') {
        user.email = null;
      }
      userIds.push(user.id);
      user.order = i;
      if (!user.rideProduct) {
        delete user.rideProduct;
        user.rideProduct = null;
      }
      usersObj[user.id] = user;
      if (i === 0) {
        userFromLocation = user.title;
        if (typeof user.from === 'string') {
          console.log('user.from', user.from);
          saveObj['fromLocation'] = user.from;
          saveObj['fullFromLocation'] = user.from;
        } else {
          console.log('user.from2', user.from);
          saveObj['fromLocation'] = user.from.address;
          saveObj[
            'fullFromLocation'
          ] = `${user.from.name} - ${user.from.address}`;
        }
      }
      if (i === this.users.length - 1) {
        userToLocation = user.title;
        if (typeof user.to === 'string') {
          console.log('user.to', user.to);
          saveObj['toLocation'] = user.to;
          saveObj['fullToLocation'] = user.to;
        } else {
          console.log('user.to2', user.to);
          saveObj['toLocation'] = user.to.address;
          saveObj['fullToLocation'] = `${user.to.name} - ${user.to.address}`;
        }
      }
      const passengerDoc = this.db.firestore
        .collection(`organisations/${this.organisationId}/users`)
        .doc(user.id);
      const passenger = (
        await getDoc(
          doc(
            this.db.firestore,
            `organisations/${this.organisationId}/users/${user.id}`
          )
        )
      ).data() as User;
      let destinations: string[] = passenger.recentDestinations
        ? passenger.recentDestinations
        : [];
      if (destinations.includes(saveObj['fromLocation'])) {
        destinations = this.arrayRemove(destinations, saveObj['fromLocation']);
      }
      destinations.unshift(saveObj['fromLocation']);
      if (destinations.includes(saveObj['toLocation'])) {
        destinations = this.arrayRemove(destinations, saveObj['toLocation']);
      }
      destinations.unshift(saveObj['toLocation']);
      if (destinations.length > 10) {
        destinations = destinations.slice(0, 10);
      }
      console.log('destinations', destinations.toString());
      passengerDoc.update({
        recentDestinations: destinations,
      });
    });
    saveObj['userIds'] = userIds;
    saveObj['special'] = this.special;
    saveObj['driverId'] = this.driverId; // await this.getAvailableDrivers(); //
    saveObj['start'] = this.rideForm.value.start;
    saveObj['end'] = this.rideForm.value.end;
    saveObj['comments'] = this.rideForm.value.comments;
    saveObj['special'] = this.rideForm.value.special;
    const selectedProduct = this.products.find((product) => {
      return product['name'] === this.rideForm.value.rideProduct;
    });
    saveObj['rideProduct'] = selectedProduct as Product;
    saveObj['vehicleId'] = this.rideForm.value.vehicleId;
    saveObj['vehicleRef'] = this.vehicleCollection.doc(
      this.rideForm.value.vehicleId
    ).ref;
    saveObj['availableSeats'] = this.availableSeats;
    console.log('characteristics', this.characteristics);
    saveObj['characteristics'] = this.characteristics;
    if (this.data.ride.isRetour) {
      saveObj['isRetourOf'] = this.data.rideId;
    }

    if (this.users.length !== 0) {
      saveObj['users'] = usersObj as Map<string, any>;
    } else {
      saveObj['users'] = deleteField();
      saveObj['fromLocation'] = deleteField();
      saveObj['fullFromLocation'] = deleteField();
      saveObj['toLocation'] = deleteField();
      saveObj['fullToLocation'] = deleteField();
    }
    if (this.data.ride['id'] && user) {
      if (this.data.ride['comments'] !== saveObj['comments']) {
        editObj.push({
          field: 'comments',
          from: this.data.ride['comments'],
          to: saveObj['comments'],
        });
        editedRide = true;
      }
      if (!moment(this.data.ride['start']).isSame(saveObj['start'])) {
        editObj.push({
          field: 'start',
          from: this.data.ride['start'],
          to: saveObj['start'],
        });
        editedRide = true;
        this.startTimes = {
          startBefore: this.data.ride['start'],
          startAfter: saveObj['start'],
        };
        this.startTimeChanged = true;
      }
      if (!moment(this.data.ride['end']).isSame(saveObj['end'])) {
        if (this.data.ride.retourId) {
          const rideDuration = this.generalSettings.customRideDuration ?? 15;
          const retourRef = this.db
            .collection(`organisations/${this.organisationId}/rides`)
            .doc(this.data.ride.retourId);
          const start = new Date(saveObj.end).setMinutes(
            saveObj.end.getMinutes() + 15
          );
          const end = new Date(saveObj.end).setMinutes(
            saveObj.end.getMinutes() + 15 + rideDuration
          );
          const timestampStart = Timestamp.fromDate(new Date(start)).toDate();
          const timestampEnd = Timestamp.fromDate(new Date(end)).toDate();
          retourRef.update({
            start: timestampStart,
            end: timestampEnd,
          });
        }
        editObj.push({
          field: 'end',
          from: this.data.ride['end'],
          to: saveObj['end'],
        });
        editedRide = true;
      }
      if (this.data.ride.rideProduct.id !== saveObj.rideProduct.id) {
        editObj.push({
          field: 'rideProduct',
          from: this.data.ride['rideProduct'],
          to: saveObj['rideProduct'],
        });
        editedRide = true;
      }
      if (this.data.ride['special'] !== saveObj['special']) {
        if (saveObj['special'] === true) {
          editObj.push({
            field: 'special',
            from: 'Normale rit',
            to: 'Bijzondere rit',
          });
          editedRide = true;
        } else if (saveObj['special'] === false) {
          editObj.push({
            field: 'special',
            from: 'Bijzondere rit',
            to: 'Normale rit',
          });
          editedRide = true;
        }
      }
      if (this.data.ride['vehicleId'] !== saveObj['vehicleId']) {
        const oldVehicle = (
          await getDoc(
            doc(
              this.db.firestore,
              `organisations/${this.organisationId}/vehicles/${this.data.ride.vehicleId}`
            )
          )
        ).data() as Vehicle;
        const newVehicle = (
          await getDoc(
            doc(
              this.db.firestore,
              `organisations/${this.organisationId}/vehicles/${saveObj['vehicleId']}`
            )
          )
        ).data() as Vehicle;
        editObj.push({
          field: 'vehicleId',
          from: oldVehicle['name'],
          to: newVehicle['name'],
        });
        editedRide = true;
      }
      let temporaryUserArray: User[] = [];
      for (const user of this.addedUsers) {
        temporaryUserArray.push(user);
        const index = this.removedUsers.findIndex(
          (x) => x.id === (user.id as string)
        );
        if (index !== -1) {
          this.addedUsers.splice(index, 1);
        }
      }
      for (const user of this.removedUsers) {
        const index = temporaryUserArray.findIndex(
          (x) => x.id === (user.id as string)
        );
        if (index !== -1) {
          this.removedUsers.splice(index, 1);
        }
      }
      if (this.addedUsers.length > 0) {
        editObj.push({
          field: 'addedUsers',
          users: this.addedUsers,
        });
        editedRide = true;
      }
      if (this.removedUsers.length > 0) {
        editObj.push({
          field: 'removedUsers',
          users: this.removedUsers,
        });
        editedRide = true;
      }
      if (this.data.ride['fromLocation']) {
        if (this.data.ride['fromLocation'] !== saveObj['fromLocation']) {
          editObj.push({
            field: 'fromLocation',
            from:
              typeof this.data.ride['fromLocation'] === 'string'
                ? this.data.ride['fromLocation'] +
                  ` (${this.oldUserFromLocation.title})`
                : 'Geen locatie',
            to:
              typeof saveObj['fromLocation'] === 'string'
                ? saveObj['fromLocation'] + ` (${userFromLocation})`
                : 'Geen locatie',
          });
          editedRide = true;
        }
      }
      if (this.data.ride['toLocation']) {
        if (this.data.ride['toLocation'] !== saveObj['toLocation']) {
          editObj.push({
            field: 'toLocation',
            from:
              typeof this.data.ride['toLocation'] === 'string'
                ? this.data.ride['toLocation'] +
                  ` (${this.oldUserToLocation.title})`
                : 'Geen locatie',
            to:
              typeof saveObj['toLocation'] === 'string'
                ? saveObj['toLocation'] + ` (${userToLocation})`
                : 'Geen locatie',
          });
          editedRide = true;
        }
      }
      changesObj = {
        changes: editObj,
        date: new Date(),
        editedByEmail: user.email,
        editedByUid: this.userId,
      };
      if (editedRide) {
        await rideRef.collection('edits').add(changesObj);
      }
    }
    saveObj.comments = saveObj.comments !== null ? saveObj.comments : '';
    console.log('saveObj', saveObj);
    Object.keys(saveObj).forEach((key) => {
      if (saveObj[key] == null) {
        saveObj[key] = deleteField();
      }
    });

    if (saveObj['start']) {
      saveObj['year'] = saveObj['start'].getFullYear();
      saveObj['month'] = saveObj['start'].getMonth();
      saveObj['week'] = getWeekString(saveObj['start']);
      saveObj['day'] = saveObj['start'].getDate();
    }

    saveObj.title = this.funcs.getRideTitle(saveObj);

    if (!this.rideCreated) {
      createObjArray.push({
        field: 'createdRide',
        text: 'Rit gecreëerd',
      });

      createObj = {
        changes: createObjArray,
        date: new Date(),
        editedByEmail: user.email,
        editedByUid: this.userId,
      };
      console.log('createObj', createObj);
      await rideRef.collection('edits').add(createObj);
    }

    if (this.data.ride.parentRideId) {
      // ride is part of group, ask if users wants to edit all
      // if yes, override all data in future rides (after recalculating the date)
      // all updated rides should get this new parentRideId so they are unlinked from past events
      saveObj['parentRideId'] = this.data.ride.id;
      console.log('newRide', saveObj);
      console.log('oldRide', this.data.ride);
      const dialogRef = this.dialog.open(CopyUpdateComponent, {
        width: '600px',
        data: {
          oldRide: this.data.ride,
          newRide: saveObj,
        },
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        console.log('The dialog was closed with result: ', result);
        if (result === 'done') {
          console.log('lets save the following obj', saveObj);
          console.log('save this.data', this.data);
          // sucessfully updated copies (or an error!)
          await rideRef.set(saveObj, {
            merge: true,
          });
          this.notifyParticipants('edit');
        } else if (result === false) {
          // result false / user said 'no'
          // if no, remove parentRideId from this ride, then continue saving.
          console.log('saveObj in else', saveObj);
          saveObj.parentRideId = deleteField();
          saveObj.hasCopy = deleteField();
          await rideRef.set(saveObj, {
            merge: true,
          });
          this.notifyParticipants('edit');
        }
        // result undefined / user cancelled dialog, do nothing, user can press save again if they want to.
      });
    } else {
      await rideRef.set(saveObj, {
        merge: true,
      });
      this.notifyParticipants('edit');
    }
  }

  arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele != value;
    });
  }

  // wrap function in promise so await is usefull on the subscribe
  async getAvailableDrivers() {
    // retrieve daypart where conditions == true
    const promise = await new Promise(async (resolve, reject) => {
      const dayNumber =
        this.rideForm.value.start.getDay() !== 0
          ? this.rideForm.value.start.getDay()
          : 7;
      const dateString = getDateString(this.rideForm.value.start);
      let startDate = this.rideForm.value.start;
      let endDate = this.rideForm.value.end;
      const startHours = this.rideForm.value.startHour;
      const endHours = this.rideForm.value.endHour;
      const startMin = this.rideForm.value.startMinute;
      const endMin = this.rideForm.value.endMinute;
      let validData = true;
      this.driverId = null;
      startDate = new Date(startDate.setHours(startHours, startMin));
      endDate = new Date(endDate.setHours(endHours, endMin));

      let driver;
      console.log('startDate', startDate);
      console.log('dayNumber', dayNumber);
      // const daypartsThatAreAvailable = this.db
      //   .collection(
      //     `organisations/${this.organisationId}/dayparts`,
      //     (ref) => ref.where('daypart.day', '==', dayNumber - 1) // limited to the querys firebase can handle
      //   )
      //   .valueChanges({ idField: 'id' });
      // console.log('daypartsThatAreAvailable', daypartsThatAreAvailable);
      // check on hour, minutes (for daypart) and if vehicle matches
      // daypartsThatAreAvailable.subscribe(async (dayparts) => {
      // .where('endHour', '<=', endHour)
      let vehicleId = this.rideForm.value.vehicleId;
      // dayparts.forEach(async (daypartData: any, index: number) => {
      // console.log('daypartData', daypartData);
      validData = true;
      const daypartDays = (
        await lastValueFrom(
          this.db
            .collection<DaypartDay>(
              `organisations/${this.organisationId}/daypartDays`,
              (ref) =>
                ref
                  .where('vehicleId', '==', vehicleId)
                  .where('startString', '==', dateString)
              // .where('startString', '==', this.getDateString(startDate))
            )
            .get()
        )
      ).docs;
      this.drivers = [];
      if (daypartDays.length < 1) {
        return resolve('failed');
      }
      daypartDays.forEach(async (daypart) => {
        const daypartDay = daypart.data() as DaypartDay;
        console.log('daypartDay.id', daypart.id);
        console.log('daypartDay', daypartDay);
        console.log('startDate', startDate);
        const isBetween = moment(startDate).isBetween(
          new Date(daypartDay.start.toDate()),
          new Date(daypartDay.end.toDate()),
          null,
          '[)' // '[) = equals or between instead of only between
        );
        console.log('isBetween', isBetween);
        if (isBetween) {
          console.log('driverId', daypartDay.driverId, daypartDay.driverName);
          this.drivers.push(daypartDay);
        }
      });

      console.log('all drivers', this.drivers);

      if (this.drivers.length !== 0) {
        for (let a = 0; a < this.drivers.length; a++) {
          const driverDoc = this.db.doc(
            `organisations/${this.organisationId}/users/${this.drivers[a].driverId}`
          );

          driverDoc.snapshotChanges().subscribe((actions) => {
            this.driverId = actions.payload.id;
            const driverData = actions.payload.data() as User;
            console.log('driverData', driverData);
            if (driverData.name) {
              this.rideForm.controls.driverId.setValue(driverData.name);
              return resolve(driverData.name);
            }
            if (driverData.phone) {
              this.rideForm.controls.driverId.setValue(driverData.phone);
              return resolve(driverData.phone);
            }
          });
        }
      } else {
        validData = false;
      }
      if (!validData) {
        reject('failed');
      } else {
        resolve(driver);
      }
    }).catch((e) => {
      console.error(e);
    });
    return promise;
  }

  async onChange(ev) {
    this.special = ev.source.checked;
  }

  async checkIfRideIsValid(startDate, endDate, vehicleId) {
    console.log('startDateRetour', startDate);
    console.log('endDateRetour', endDate);
    const day = startDate.getDate();
    const month = startDate.getMonth();
    const year = startDate.getFullYear();
    const q = query(
      collection(
        this.db.firestore,
        `organisations/${this.organisationId}/rides`
      ),
      where('day', '==', day),
      where('month', '==', month),
      where('year', '==', year)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((rideDoc) => {
      const ride = { ...rideDoc.data(), id: rideDoc.id } as Ride;
      const newRideStart = moment(startDate);
      const existingRideStart = moment(ride.start.toDate());
      const newRideEnd = moment(endDate);
      const existingRideEnd = moment(ride.end.toDate());

      //FOR ALL THAT IS HOLY, LEAVE THIS FILTER ALONE
      if (ride.isRetourOf) {
        if (ride.isRetourOf === this.data.rideId) {
          this.retourAlreadyBooked = true;
        }
      }
      if (
        newRideStart.isBetween(existingRideStart, existingRideEnd) ||
        newRideEnd.isBetween(existingRideStart, existingRideEnd) ||
        existingRideStart.isBetween(newRideStart, newRideEnd) ||
        existingRideEnd.isBetween(newRideStart, newRideEnd) ||
        (newRideStart.isSame(existingRideStart) &&
          newRideEnd.isSame(existingRideEnd))
      ) {
        if (ride.vehicleId === vehicleId) {
          if (ride.id !== this.rideId) {
            this.vehicleAlreadyBooked = true;
          }
        }
      }
    });
  }

  getDateString(date: Date) {
    const year = date.getFullYear().toString();
    let month = (date.getMonth() + 1).toString();
    if (month.length === 1) {
      month = `0${month}`;
    }
    let monthDay = date.getDate().toString();
    if (monthDay.length === 1) {
      monthDay = `0${monthDay}`;
    }
    return `${year}-${month}-${monthDay}`;
  }

  async notifyParticipants(type: string) {
    if (
      (this.emailNotificationsParticipants.enabled ||
        this.smsNotificationsParticipants.enabled) &&
      (this.users.length > 0 || this.originalUserList.length > 0)
    ) {
      if (type === 'edit') {
        const deletedUsers = [];
        const addedUsers = [];
        const retainedUsers = [];

        this.originalUserList.forEach((user) => {
          if (!this.users.find(({ title }) => title === user.title)) {
            deletedUsers.push(user);
          } else {
            retainedUsers.push(user);
          }
        });

        this.users.forEach((user) => {
          if (
            !this.originalUserList.find(({ title }) => title === user.title)
          ) {
            addedUsers.push(user);
          }
        });

        const dialogRef = this.dialog.open(NotifyUsersRideDialog, {
          width: '600px',
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe(async (result) => {
          if (result) {
            if (addedUsers.length === 0 && deletedUsers.length === 0) {
              if (
                this.emailNotificationsParticipants
                  .emailNotificationsParticipantsRideConfirmed ||
                this.smsNotificationsParticipants
                  .smsNotificationsParticipantsRideConfirmed
              ) {
                this.loading = true;
                for (const user of this.users) {
                  const from =
                    typeof user.from === 'string'
                      ? user.from
                      : user.from.address;
                  const to =
                    typeof user.to === 'string' ? user.to : user.to.address;
                  await this.planNotificationParticipant(
                    moment(new Date(this.rideForm.value.start)).format(
                      'DD/MM/YYYY'
                    ),
                    moment(new Date(this.rideForm.value.start)).format('HH:mm'),
                    from,
                    to,
                    this.organisationId,
                    user
                  );
                }
              }
            } else {
              if (
                this.emailNotificationsParticipants
                  .emailNotificationsParticipantsRideConfirmed ||
                this.smsNotificationsParticipants
                  .smsNotificationsParticipantsRideConfirmed
              ) {
                if (addedUsers.length > 0) {
                  this.loading = true;
                  for (const user of addedUsers) {
                    const from =
                      typeof user.from === 'string'
                        ? user.from
                        : user.from.address;
                    const to =
                      typeof user.to === 'string' ? user.to : user.to.address;
                    await this.planNotificationParticipant(
                      moment(new Date(this.rideForm.value.start)).format(
                        'DD/MM/YYYY'
                      ),
                      moment(new Date(this.rideForm.value.start)).format(
                        'HH:mm'
                      ),
                      from,
                      to,
                      this.organisationId,
                      user
                    );
                  }
                }
              }

              if (deletedUsers.length > 0) {
                if (
                  this.emailNotificationsParticipants
                    .emailNotificationsParticipantsRideDeleted ||
                  this.smsNotificationsParticipants
                    .smsNotificationsParticipantsRideDeleted
                ) {
                  this.loading = true;
                  for (const user of deletedUsers) {
                    await this.deleteRideNotificationParticipant(
                      moment(new Date(this.rideForm.value.start)).format(
                        'DD/MM/YYYY'
                      ),
                      moment(new Date(this.rideForm.value.start)).format(
                        'HH:mm'
                      ),
                      this.organisationId,
                      user
                    );
                  }
                }
              }

              if (retainedUsers.length > 0 && this.startTimeChanged) {
                if (
                  this.emailNotificationsParticipants
                    .emailNotificationsParticipantsRideChanged ||
                  this.smsNotificationsParticipants
                    .smsNotificationsParticipantsRideChanged
                ) {
                  this.loading = true;
                  for (const user of retainedUsers) {
                    const from =
                      typeof user.from === 'string'
                        ? user.from
                        : user.from.address;
                    const to =
                      typeof user.to === 'string' ? user.to : user.to.address;
                    await this.changedRideNotificationParticipant(
                      moment(new Date(this.startTimes.startBefore)).format(
                        'DD/MM/YYYY'
                      ),
                      moment(new Date(this.startTimes.startAfter)).format(
                        'DD/MM/YYYY'
                      ),
                      moment(new Date(this.rideForm.value.start)).format(
                        'HH:mm'
                      ),
                      from,
                      to,
                      this.organisationId,
                      user
                    );
                  }
                }
              }
            }

            this.loading = false;
            this.dialogRef.close();
          } else {
            this.dialogRef.close();
          }
        });
      } else if (type === 'delete') {
        if (
          this.emailNotificationsParticipants
            .emailNotificationsParticipantsRideDeleted ||
          this.smsNotificationsParticipants
            .smsNotificationsParticipantsRideDeleted
        ) {
          this.loading = true;
          for (const user of this.users) {
            await this.deleteRideNotificationParticipant(
              moment(new Date(this.rideForm.value.start)).format('DD/MM/YYYY'),
              moment(new Date(this.rideForm.value.start)).format('HH:mm'),
              this.organisationId,
              user
            );
          }
          this.loading = false;
          this.dialogRef.close();
        }
      }
    } else {
      this.dialogRef.close();
    }
  }

  async planNotificationParticipant(
    date: string,
    startRideTime: string,
    startRideLocation: string,
    endRideLocation: string,
    orgId: string,
    user
  ) {
    let sendMail = false;
    let sendSMS = false;

    const userData = (
      await getDoc(
        doc(
          this.db.firestore,
          `organisations/${this.organisationId}/users/${user.id}`
        )
      )
    ).data();

    if (!user?.smsNotificationPhoneNumber) {
      if (userData?.smsNotificationPhoneNumber) {
        user.smsNotificationPhoneNumber = userData.smsNotificationPhoneNumber;
      }
    }

    if (!user.email && !user?.smsNotificationPhoneNumber) {
      return;
    }

    if (user.notifyByEmail) {
      sendMail = true;
    } else {
      if (userData?.notifyByEmail) {
        sendMail = true;
      }
    }

    if (user.notifyBySMS) {
      sendSMS = true;
    } else {
      if (userData?.notifyBySMS) {
        sendSMS = true;
      }
    }

    if (
      !this.emailNotificationsParticipants
        .emailNotificationsParticipantsRideConfirmed
    ) {
      sendMail = false;
    }

    if (
      !this.smsNotificationsParticipants
        .smsNotificationsParticipantsRideConfirmed
    ) {
      sendSMS = false;
    }

    try {
      if (sendMail) {
        const callBody = {
          orgId: orgId,
          userName: user.title,
          mailType: 'rideConfirmation',
          emailTo: user.email,
          optionalData: {
            date: date,
            startTime: startRideTime,
            startLocation: startRideLocation,
            endLocation: endRideLocation,
          },
        };
        const callable = this.fns.httpsCallable('httpSendMail');
        const callRes = await lastValueFrom(callable(callBody));
        console.log(callRes);
      }

      if (sendSMS) {
        const callBody = {
          messageToSend: `Hierbij krijgt u een bevestiging voor uw rit op ${date} van ${startRideLocation} naar ${endRideLocation} om ${startRideTime}.`,
          phoneTo: user?.smsNotificationPhoneNumber,
        };
        const callable = this.fns.httpsCallable('smsSendToCentral');
        const callRes = await lastValueFrom(callable(callBody));
        console.log(callRes);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async changedRideNotificationParticipant(
    dateBefore: string,
    dateAfter: string,
    startRideTime: string,
    startRideLocation: string,
    endRideLocation: string,
    orgId: string,
    user
  ) {
    let sendMail = false;
    let sendSMS = false;

    const userData = (
      await getDoc(
        doc(
          this.db.firestore,
          `organisations/${this.organisationId}/users/${user.id}`
        )
      )
    ).data();

    if (!user?.smsNotificationPhoneNumber) {
      if (userData?.smsNotificationPhoneNumber) {
        user.smsNotificationPhoneNumber = userData.smsNotificationPhoneNumber;
      }
    }

    if (!user.email && !user?.smsNotificationPhoneNumber) {
      return;
    }

    if (user.notifyByEmail) {
      sendMail = true;
    } else {
      if (userData?.notifyByEmail) {
        sendMail = true;
      }
    }

    if (user.notifyBySMS) {
      sendSMS = true;
    } else {
      if (userData?.notifyBySMS) {
        sendSMS = true;
      }
    }

    if (
      !this.emailNotificationsParticipants
        .emailNotificationsParticipantsRideChanged
    ) {
      sendMail = false;
    }

    if (
      !this.smsNotificationsParticipants.smsNotificationsParticipantsRideChanged
    ) {
      sendSMS = false;
    }

    try {
      if (sendMail) {
        const callBody = {
          orgId: orgId,
          userName: user.title,
          mailType: 'rideChanged',
          emailTo: user.email,
          optionalData: {
            dateBefore: dateBefore,
            dateAfter: dateAfter,
            startTime: startRideTime,
            startLocation: startRideLocation,
            endLocation: endRideLocation,
          },
        };
        const callable = this.fns.httpsCallable('httpSendMail');
        const callRes = await lastValueFrom(callable(callBody));
        console.log(callRes);
      }
      if (sendSMS) {
        const callBody = {
          messageToSend: `Hierbij krijgt u een bevestiging dat uw rit op ${dateBefore} gewijzigd is naar ${dateAfter} van ${startRideLocation} naar ${endRideLocation} om ${startRideTime}. `,
          phoneTo: user?.smsNotificationPhoneNumber,
        };
        const callable = this.fns.httpsCallable('smsSendToCentral');
        const callRes = await lastValueFrom(callable(callBody));
        console.log(callRes);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async deleteRideNotificationParticipant(
    date: string,
    startRideTime: string,
    orgId: string,
    user
  ) {
    let sendMail = false;
    let sendSMS = false;

    const userData = (
      await getDoc(
        doc(
          this.db.firestore,
          `organisations/${this.organisationId}/users/${user.id}`
        )
      )
    ).data();

    if (!user?.smsNotificationPhoneNumber) {
      if (userData?.smsNotificationPhoneNumber) {
        user.smsNotificationPhoneNumber = userData.smsNotificationPhoneNumber;
      }
    }

    if (!user.email && !user?.smsNotificationPhoneNumber) {
      return;
    }

    if (user.notifyByEmail) {
      sendMail = true;
    } else {
      if (userData?.notifyByEmail) {
        sendMail = true;
      }
    }

    if (user.notifyBySMS) {
      sendSMS = true;
    } else {
      if (userData?.notifyBySMS) {
        sendSMS = true;
      }
    }

    if (
      !this.emailNotificationsParticipants
        .emailNotificationsParticipantsRideDeleted
    ) {
      sendMail = false;
    }

    if (
      !this.smsNotificationsParticipants.smsNotificationsParticipantsRideDeleted
    ) {
      sendSMS = false;
    }

    try {
      if (sendMail) {
        const callBody = {
          orgId: orgId,
          userName: user.title,
          mailType: 'rideDeleted',
          emailTo: user.email,
          optionalData: {
            date: date,
            startTime: startRideTime,
          },
        };
        const callable = this.fns.httpsCallable('httpSendMail');
        const callRes = await lastValueFrom(callable(callBody));
        console.log(callRes);
      }

      if (sendSMS) {
        const callBody = {
          messageToSend: `Uw geplande rit op ${date} om ${startRideTime} is verwijderd.`,
          phoneTo: user?.smsNotificationPhoneNumber,
        };
        const callable = this.fns.httpsCallable('smsSendToCentral');
        const callRes = await lastValueFrom(callable(callBody));
        console.log(callRes);
      }
    } catch (error) {
      console.log(error);
    }
  }

  addLeadingZero(data: any): any {
    const modifiedData: any = { ...data };
    const num = parseInt(modifiedData.name);
    if (!isNaN(num) && num >= 1 && num <= 9) {
      modifiedData.name = num < 10 ? '0' + num : num.toString();
    }
    return modifiedData;
  }
}

function getWeekString(date) {
  const weekYear = moment(date).isoWeekYear();
  const week = moment(date).isoWeek();
  return `${weekYear}-${week}`;
}
