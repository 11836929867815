import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { getAuth, User } from 'firebase/auth';
import { arrayUnion, doc, getDoc, setDoc } from 'firebase/firestore';
import { AccountType } from 'src/app/enums';

@Component({
  selector: 'app-new-organisation',
  templateUrl: './new-organisation.component.html',
  styleUrls: ['./new-organisation.component.scss'],
})
export class NewOrganisationComponent implements OnInit {
  user: User;
  newOrgForm: FormGroup;
  creating = false;

  constructor(
    private fb: FormBuilder,
    public db: AngularFirestore,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.user = getAuth().currentUser;
    this.newOrgForm = this.fb.group({
      organisationName: ['', [Validators.required]],
    });
  }

  async create() {
    if (!this.newOrgForm.valid || this.creating) {
      return this.newOrgForm.markAllAsTouched();
    }
    this.creating = true;
    const form = this.newOrgForm.value;
    console.log('form', form);
    const newOrgId = (form.organisationName as string)
      .replaceAll(/\W/g, '')
      .toLowerCase();
    console.log('newOrgId', newOrgId);
    const newOrgDoc = doc(this.db.firestore, `organisations/${newOrgId}`);
    const newOrgExists = (await getDoc(newOrgDoc)).exists();
    console.log('newOrgExists', newOrgExists);
    if (newOrgExists) {
      return this.snackBar.open('Deze organisatie bestaat al.', 'X', {
        duration: 5000,
      });
    }
    const generalSettingsDoc = doc(
      this.db.firestore,
      `organisations/${newOrgId}/settings/general`
    );
    const publicSettingsDoc = doc(
      this.db.firestore,
      `organisations/${newOrgId}/settings/public`
    );
    const rootUserDoc = doc(this.db.firestore, `users/${this.user.uid}`);
    const newOrgUserDoc = doc(
      this.db.firestore,
      `organisations/${newOrgId}/users/${this.user.uid}`
    );
    await setDoc(newOrgDoc, {
      name: form.organisationName,
      domain: 'hallobuurtvervoer',
    });
    await setDoc(generalSettingsDoc, {
      name: form.organisationName,
      primaryColor: '#484b99',
      accentColor: '#42af5a',
    });
    await setDoc(publicSettingsDoc, {
      name: form.organisationName,
      primaryColor: '#484b99',
      accentColor: '#42af5a',
      banner:
        'https://firebasestorage.googleapis.com/v0/b/wemaron-buurtvervoer.appspot.com/o/organisations%2Fwemaron%2Fbuurtvervoer-banner.svg?alt=media&token=fbf7dff4-fa8f-444b-afa1-8db51aa2d6d9',
      logo: 'https://firebasestorage.googleapis.com/v0/b/wemaron-buurtvervoer.appspot.com/o/organisations%2Fwemaron%2Forganisation_images%2Fwemaron-logo.png?alt=media&token=4db50ba1-b37d-4f01-978d-d7c5edced30f',
    });
    await setDoc(
      rootUserDoc,
      {
        organisations: arrayUnion({
          accountType: AccountType.Admin,
          id: newOrgId,
          name: form.organisationName,
        }),
      },
      { merge: true }
    );
    await setDoc(newOrgUserDoc, {
      email: this.user.email,
      accountType: AccountType.Admin,
      superuser: true,
    });
    this.router.navigate([`/set/${newOrgId}`]);
  }
}
