<app-nav-bar></app-nav-bar>
<div class="users" fxLayout="column">
  <div class="header" fxLayout="row wrap" fxLayoutAlign="space-between center">
    <div class="h-title" fxFlex>
      <mat-icon> emoji_transportation </mat-icon>
      <span>Voertuiggroepen beheren</span>
    </div>
  </div>
  <!-- <button mat-raised-button style="height: 40px" (click)="openImportExport()">
    <mat-icon>import_export</mat-icon
    ><span fxHide.xs>Importeren/Exporteren</span>
  </button> -->
  <mat-card
    class="room-below-card"
    *ngIf="vehicleGroups; else loading"
    style="padding: 24px"
  >
    <div class="title" fxLayout="row">
      <h3 fxFlex>
        Alle voertuiggroepen
        <span>({{ totalVehicleGroups > 0 ? totalVehicleGroups : 0 }})</span>
      </h3>
      <div class="search" fxLayoutAlign=" center">
        <mat-form-field>
          <span matPrefix>
            <mat-icon>search</mat-icon>
          </span>
          <mat-label class="placeholder">Zoeken</mat-label>
          <input
            matInput
            type="search"
            autocomplete="off"
            placeholder="Zoeken"
            [(ngModel)]="searchQuery"
            (ngModelChange)="onFilterChange('search', $event)"
          />
        </mat-form-field>
      </div>
    </div>
    <div #TABLE>
      <mat-table
        mat-table
        id="tblData"
        #table
        [dataSource]="filteredVehicleGroups"
      >
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>Naam</mat-header-cell>
          <mat-cell *matCellDef="let vehicleGroup">
            <p class="text-truncate" *ngIf="vehicleGroup.name">
              {{ vehicleGroup.name }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- color Column -->
        <ng-container matColumnDef="color">
          <mat-header-cell *matHeaderCellDef>Kleur</mat-header-cell>
          <mat-cell *matCellDef="let vehicleGroup">
            <div
              class="dot"
              [ngStyle]="{ 'background-color': vehicleGroup.color }"
            ></div>
            <p class="phone text-truncate">
              {{ vehicleGroup.color }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- button Column -->
        <ng-container matColumnDef="button">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let vehicleGroup">
            <div fxFlex="row" fxLayoutAlign="end center">
              <button
                mat-icon-button
                [matMenuTriggerFor]="moreMenu"
                aria-label="More"
                (click)="$event.stopPropagation()"
              >
                <mat-icon class="secondary-text">more_vert</mat-icon>
              </button>

              <mat-menu #moreMenu="matMenu">
                <button mat-menu-item (click)="editVehicleGroup(vehicleGroup)">
                  <span>Bewerken</span>
                </button>
                <button
                  mat-menu-item
                  (click)="deleteVehicleGroup(vehicleGroup)"
                >
                  <span>Verwijderen</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let vehicleGroup; columns: displayedColumns"
          class="user"
          (click)="editVehicleGroup(vehicleGroup)"
          matRipple
        ></tr>
      </mat-table>
    </div>
  </mat-card>
  <ng-template #loading>
    <div id="loading" fxLayout="row" fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
</div>
<button mat-fab (click)="editVehicleGroup()" class="fab-fixed">
  <mat-icon>add</mat-icon>
</button>
