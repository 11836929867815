<app-nav-bar></app-nav-bar>
<div
  class="users"
  fxLayout="column"
>
  <div
    class="header"
    fxLayout="row"
    fxLayoutAlign=" center"
  >
    <div
      class="h-title"
      fxFlex
    >
      <mat-icon>settings</mat-icon>
      <span>Algemene instellingen</span>
    </div>
  </div>
  <mat-card style="padding: 24px">
    <form
      fxLayout="column"
      [formGroup]="organisationForm"
    >
      <mat-tab-group
        class="settings"
        mat-align-tabs="left"
      >
        <mat-tab label="Organisatie">
          <div
            class="daypart-time"
            fxLayout="row"
          >
            <mat-form-field fxFlex>
              <mat-label>Organisatienaam</mat-label>
              <input
                formControlName="name"
                type="text"
                autocomplete="off"
                matInput
                (keydown.enter)="save()"
              />
            </mat-form-field>
          </div>
          <div
            class="daypart-time"
            fxLayout="row"
          >
            <mat-form-field fxFlex>
              <mat-label>Privacyvoorwaarden link</mat-label>
              <input
                formControlName="privacyLink"
                type="text"
                autocomplete="off"
                matInput
                (keydown.enter)="save()"
              />
            </mat-form-field>
          </div>
          <div
            class="daypart-time"
            fxLayout="row"
          >
            <mat-form-field fxFlex>
              <mat-label>Maximum aantal voertuigen</mat-label>
              <input
                formControlName="maxActiveVehicles"
                matInput
                type="number"
                autocomplete="off"
                (keydown.enter)="save()"
              />
            </mat-form-field>
          </div>
          <div
            class="daypart-time"
            fxLayout="row"
          >
            <mat-form-field>
              <mat-label>Dagdelen starttijd</mat-label>
              <mat-select formControlName="startHour">
                <mat-option
                  *ngFor="let hour of hours"
                  [value]="hour"
                  >{{ hour }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Dagdelen eindtijd</mat-label>
              <mat-select formControlName="endHour">
                <mat-option
                  *ngFor="let hour of hours"
                  [value]="hour"
                  >{{ hour }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div
            class="toggle-switch"
            fxLayoutAlign="start center"
          >
            <mat-slide-toggle
              formControlName="askPin"
              color="primary"
            >
              Om pincode vragen bij credits toevoegen/verminderen
            </mat-slide-toggle>
          </div>
          <p class="warning">
            Let op! Het niet gebruiken van een pincode is op eigen risico.
          </p>
          <div
            class="daypart-time"
            fxLayout="row"
            *ngIf="organisationForm.controls.askPin.value"
          >
            <mat-form-field>
              <mat-label>Pincode voor credits opboeken</mat-label>
              <input
                formControlName="pinAddCredits"
                type="number"
                autocomplete="off"
                #pin
                matInput
                minlength="4"
                maxlength="4"
                (keydown.enter)="save()"
              />
              <mat-error
                *ngIf="
                  organisationForm.controls.pinAddCredits.hasError('minlength')
                "
              >
                Pin moet 4 tekens zijn.
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Pincode voor credits afboeken</mat-label>
              <input
                formControlName="pinSubstractCredits"
                type="number"
                autocomplete="off"
                #pin
                matInput
                minlength="4"
                maxlength="4"
                (keydown.enter)="save()"
              />
              <mat-error
                *ngIf="
                  organisationForm.controls.pinSubstractCredits.hasError(
                    'minlength'
                  )
                "
              >
                Pin moet 4 tekens zijn.
              </mat-error>
            </mat-form-field>
          </div>
          <div
            class="toggle-switch"
            fxLayoutAlign="start center"
          >
            <mat-slide-toggle
              formControlName="usesSendgrid"
              color="primary"
            >
              Gebruik Sendgrid e-mail provider in plaats van Siteground
            </mat-slide-toggle>
          </div>
        </mat-tab>
        <mat-tab
          label="Ritten"
          class="ride-tab"
        >
          <div
            class="daypart-time"
            fxLayout="row"
          >
            <mat-form-field>
              <mat-label>Gemiddelde reistijd in minuten</mat-label>
              <mat-select formControlName="customRideDuration">
                <mat-option
                  *ngFor="let minute of travelMinutes"
                  [value]="minute"
                >
                  {{ minute.toString().length < 2 ? '0' + minute : minute }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            class="daypart-time"
            fxLayout="row"
          >
            <mat-form-field>
              <mat-label>Tijdssegmenten in minuten</mat-label>
              <mat-select formControlName="customSegment">
                <mat-option
                  *ngFor="let segment of segmentMinutes"
                  [value]="segment"
                >
                  {{ segment }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            class="daypart-time"
            fxLayout="row"
            *ngIf="organisationForm.value.activateSignalColor"
          >
            <mat-form-field>
              <mat-label>Signaalkleur bijzondere rit</mat-label>
              <input
                matInput
                formControlName="signalColor"
                [value]="organisationForm.value.signalColor"
                #ignoredInput
                [cpIgnoredElements]="[ignoredButton, ignoredInput]"
                [(cpToggle)]="toggleSignal"
                [(colorPicker)]="organisationForm.value.signalColor"
                [cpAlphaChannel]="'disabled'"
                [cpOKButton]="true"
                [cpPosition]="'bottom'"
                [cpCancelButton]="true"
                [cpCancelButtonClass]="'mat-stroked-button'"
                [cpOKButtonClass]="'mat-stroked-button'"
                [cpCancelButtonText]="'Annuleren'"
              />
              <button
                matSuffix
                mat-icon-button
                #ignoredButton
                type="button"
                (click)="changeHeight()"
              >
                <mat-icon>colorize</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div
            class="toggle-switch"
            fxLayout="row"
          >
            <mat-slide-toggle
              color="primary"
              formControlName="doubleBookingActivated"
            >
              Dubbele boeking melding uitzetten
            </mat-slide-toggle>
          </div>
          <div
            class="toggle-switch"
            fxLayout="row"
          >
            <mat-slide-toggle
              color="primary"
              formControlName="activateSignalColor"
            >
              Mogelijkheid om ritten als bijzonder te markeren
            </mat-slide-toggle>
          </div>
          <div
            class="toggle-switch"
            fxLayout="row"
          >
            <mat-slide-toggle
              formControlName="characteristicsStatus"
              color="primary"
            >
              Kenmerken gebruiken
            </mat-slide-toggle>
          </div>
          <div
            class="toggle-switch"
            fxLayout="row"
          >
            <mat-slide-toggle
              color="primary"
              formControlName="pCommentsToggle"
            >
              Opmerkingen voor planner bij deelnemers in ritten
            </mat-slide-toggle>
          </div>
          <div
            class="toggle-switch"
            fxLayout="row"
          >
            <mat-slide-toggle
              color="primary"
              formControlName="dCommentsToggle"
            >
              Opmerkingen voor chauffeur bij deelnemers in ritten
            </mat-slide-toggle>
          </div>
          <div
            class="toggle-switch"
            fxLayout="row"
          >
            <mat-slide-toggle
              formControlName="askKilometers"
              color="primary"
            >
              Kilometerstand vragen
            </mat-slide-toggle>
          </div>
          <div
            class="toggle-switch"
            fxLayout="row"
          >
            <mat-slide-toggle
              formControlName="showRideProduct"
              color="primary"
            >
              Ritprijs weergeven
            </mat-slide-toggle>
          </div>
          <div
            class="toggle-switch-last"
            fxLayout="row"
          >
            <mat-slide-toggle
              formControlName="noDeleteReason"
              color="primary"
            >
              Geen reden voor verwijderde ritten nodig
            </mat-slide-toggle>
          </div>
          <button
            mat-raised-button
            type="button"
            color="primary"
            (click)="exportRides()"
            class="export-rides"
            [class.spinner]="exportingRides"
            [disabled]="exportingRides"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <mat-icon>file_download</mat-icon>
            <span fxHide.xs> Exporteer alle uitgevoerde ritten </span>
          </button>
          <button
            mat-raised-button
            type="button"
            color="primary"
            class="export-rides"
            (click)="openImportExport()"
            [class.spinner]="transfering"
            [disabled]="transfering"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <mat-icon>import_export</mat-icon>
            <span fxHide.xs>Ritten importeren/exporteren</span>
          </button>
        </mat-tab>
        <mat-tab label="Chauffeurs">
          <div
            class="toggle-switch"
            fxLayout="row"
            *ngIf="usesDriverPlanning"
          >
            <mat-slide-toggle
              formControlName="driversModifyAvailability"
              color="primary"
            >
              Chauffeurs mogen hun beschikbaarheid zelf aanpassen
            </mat-slide-toggle>
          </div>
          <div
            class="toggle-switch"
            fxLayout="row"
          >
            <mat-slide-toggle
              formControlName="driversShowParticipantPhone"
              color="primary"
            >
              Chauffeurs mogen het telefoonnummer van deelnemers in de mobiele
              app zien.
            </mat-slide-toggle>
          </div>
          <div
            class="toggle-switch"
            fxLayout="row"
            *ngIf="usesDriverPlanning"
          >
            <mat-slide-toggle
              formControlName="ignoreDriverStatus"
              color="primary"
            >
              Negeer planstatus chauffeur
            </mat-slide-toggle>
          </div>
          <div
            class="toggle-switch"
            fxLayout="row"
            *ngIf="usesDriverRemarksInApp"
          >
            <mat-slide-toggle
              formControlName="driversAddRemarkInApp"
              color="primary"
            >
              Chauffeurs kunnen een opmerking toevoegen in de app
            </mat-slide-toggle>
          </div>
          <div
            class="daypart-time"
            fxLayout="column"
            *ngIf="
              !organisationForm.controls.ignoreDriverStatus.value &&
              usesDriverPlanning
            "
          >
            <p
              fxLayoutAlign="start center"
              style="margin-top: 15px; margin-bottom: -5px"
            >
              Kies een tijdstip waarop de chauffeurs een melding krijgen indien
              een rit verandert
            </p>
            <div fxLayout="row">
              <mat-form-field>
                <mat-label>Uren</mat-label>
                <mat-select formControlName="alertHour">
                  <mat-option
                    *ngFor="let hour of hours"
                    [value]="hour"
                    >{{ hour }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Minuten</mat-label>
                <mat-select formControlName="alertMinute">
                  <mat-option
                    *ngFor="let minute of minutes"
                    [value]="minute"
                  >
                    {{ minute.toString().length < 2 ? '0' + minute : minute }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Deelnemers">
          <div
            class="toggle-switch"
            fxLayout="row"
          >
            <mat-slide-toggle
              formControlName="askBirthDay"
              color="primary"
            >
              Deelnemers bij registratie om geboortedatum vragen
            </mat-slide-toggle>
          </div>
          <div
            class="toggle-switch-last"
            fxLayout="row"
          >
            <mat-slide-toggle
              formControlName="askGender"
              color="primary"
            >
              Deelnemers bij registratie om geslacht vragen
            </mat-slide-toggle>
          </div>
        </mat-tab>
        <mat-tab label="Notificaties">
          <div class="sub-title">Standaard e-mailaddressen</div>
          <div
            class="daypart-time"
            fxLayout="row"
          >
            <mat-form-field fxFlex>
              <mat-label>Standaard CC e-mailadressen</mat-label>
              <input
                formControlName="emailNotificationsCCAddress"
                type="text"
                autocomplete="off"
                matInput
                (keydown.enter)="save()"
              />
            </mat-form-field>
          </div>
          <div
            class="daypart-time"
            fxLayout="row"
          >
            <mat-form-field fxFlex>
              <mat-label>Standaard BCC e-mailadressen</mat-label>
              <input
                formControlName="emailNotificationsBCCAddress"
                type="text"
                autocomplete="off"
                matInput
                (keydown.enter)="save()"
              />
            </mat-form-field>
          </div>
          <div class="sub-title">Chauffeurs</div>
          <div
            class="toggle-switch"
            fxLayout="row"
          >
            <mat-slide-toggle
              formControlName="emailNotificationsDriversDayPart"
              color="primary"
            >
              E-mail notificaties
            </mat-slide-toggle>
          </div>
          <div class="sub-title">Deelnemers</div>
          <div
            class="toggle-switch"
            fxLayout="row"
            *ngIf="usesUserNotificationSystem"
          >
            <mat-slide-toggle
              formControlName="emailNotificationsParticipants"
              color="primary"
            >
              E-mail notificaties
            </mat-slide-toggle>
          </div>
          <div
            *ngIf="
              organisationForm.controls.emailNotificationsParticipants.value
            "
            class="checkbox-rows"
          >
            <mat-checkbox
              formControlName="emailNotificationsParticipantsRideConfirmed"
              >Ritbevestiging</mat-checkbox
            >
            <mat-checkbox
              formControlName="emailNotificationsParticipantsRideChanged"
              >Ritwijziging</mat-checkbox
            >
            <mat-checkbox
              formControlName="emailNotificationsParticipantsRideDeleted"
              >Rit verwijderd</mat-checkbox
            >
            <!-- <mat-checkbox
              formControlName="emailNotificationsParticipantsRideReminder"
              >Voor aanvang van rit</mat-checkbox
            > -->
          </div>

          <div
            class="toggle-switch"
            fxLayout="row"
            *ngIf="usesUserNotificationSystem"
          >
            <mat-slide-toggle
              formControlName="smsNotificationsParticipants"
              color="primary"
            >
              SMS notificaties
            </mat-slide-toggle>
          </div>
          <div
            class="checkbox-rows"
            *ngIf="organisationForm.controls.smsNotificationsParticipants.value"
          >
            <mat-checkbox
              formControlName="smsNotificationsParticipantsRideConfirmed"
              >Ritbevestiging</mat-checkbox
            >
            <mat-checkbox
              formControlName="smsNotificationsParticipantsRideChanged"
              >Ritwijziging</mat-checkbox
            >
            <mat-checkbox
              formControlName="smsNotificationsParticipantsRideDeleted"
              >Rit verwijderd</mat-checkbox
            >
            <!-- <mat-checkbox
              formControlName="smsNotificationsParticipantsRideReminder"
              >Voor aanvang van rit</mat-checkbox
            > -->
          </div>
        </mat-tab>
      </mat-tab-group>
    </form>
    <div fxLayoutAlign="end center">
      <button
        mat-raised-button
        (click)="save()"
        cdkFocusInitial
        color="accent"
      >
        Opslaan
      </button>
    </div>
  </mat-card>
  <input
    hidden
    type="file"
    accept=".json"
    #uploader
    (change)="uploadedFile($event)"
  />
</div>
