<div>
  <form fxLayout="column" (ngSubmit)="onSubmit()" autocomplete="off" [formGroup]="phoneForm">
    <mat-form-field>
      <mat-label>Volgorde</mat-label>
      <input
        type="number"
        placeholder="Volgorde"
        formControlName="order"
        matInput
        required
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Naam</mat-label>
      <input placeholder="Naam" type="text" formControlName="name" matInput required />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Telefoonnummer</mat-label>
      <input
        placeholder="Telefoonnummer"
        type="tel"
        formControlName="phone"
        matInput
        required
      />
    </mat-form-field>
    <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="6px">
      <button mat-button type="button" (click)="close()">Annuleren</button>
      <button
        mat-raised-button
        type="submit"
        color="primary"
        [disabled]="phoneForm.invalid"
      >
        Opslaan
      </button>
    </div>
  </form>
</div>
