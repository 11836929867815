import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  DocumentReference,
  deleteField,
  doc,
  getDoc,
  setDoc,
} from 'firebase/firestore';
import {
  StorageReference,
  getBytes,
  getDownloadURL,
  ref,
  uploadBytes,
} from 'firebase/storage';
import moment from 'moment';
import { redirectIfNotSuperUser } from 'src/app/globals';
import { PaymentMethodsConfig } from 'src/app/interfaces';
import { PDFDocument } from 'pdf-lib';

@Component({
  selector: 'app-setup-payment-methods',
  templateUrl: './setup-payment-methods.component.html',
  styleUrls: ['./setup-payment-methods.component.scss'],
})
export class SetupPaymentMethodsComponent implements OnInit {
  publicOrgSettingsRef: DocumentReference;
  paymentMethodConfigRef: DocumentReference;
  paymentMethodsConfig: PaymentMethodsConfig;
  organisationId = localStorage.getItem('orgId');
  mollieForm: FormGroup = new FormGroup({
    app_url: new FormControl('', [Validators.required]),
    client_id: new FormControl('', [Validators.required]),
    client_secret: new FormControl('', [Validators.required]),
    profile_id: new FormControl('', [Validators.required]),
    prod: new FormControl(false),
    mollie_enabled: new FormControl(false),
  });
  sepaForm: FormGroup = new FormGroup({
    creditor_name: new FormControl('', [Validators.required]),
    creditor_address: new FormControl('', [Validators.required]),
    creditor_postal: new FormControl('', [Validators.required]),
    creditor_municipality: new FormControl('', [Validators.required]),
    creditor_country: new FormControl('', [Validators.required]),
    creditor_iban: new FormControl('', [Validators.required]),
    creditor_id: new FormControl('', [Validators.required]),
    creditor_bic: new FormControl('', [Validators.required]),
    creditor_mandate_id: new FormControl({ value: '', disabled: true }),
    sepa_enabled: new FormControl(false),
  });
  mollieEnabled: boolean = false;
  sepaEnabled: boolean = false;
  sepaAllowed: any;
  sepaPath: string;
  mandateId: any;

  constructor(
    private router: Router,
    private db: AngularFirestore,
    private snackBar: MatSnackBar,
    private storage: AngularFireStorage
  ) {}

  async ngOnInit() {
    redirectIfNotSuperUser(this.db.firestore, this.router);

    this.paymentMethodConfigRef = doc(
      this.db.firestore,
      `payment-configs/${this.organisationId}`
    );
    this.publicOrgSettingsRef = doc(
      this.db.firestore,
      `organisations/${this.organisationId}/settings/public`
    );

    this.sepaAllowed = JSON.parse(localStorage.getItem('usesSEPASystem'));
    console.log('test', this.sepaAllowed);

    await this.getPaymentMethodsConfig();
    this.mollieForm.patchValue(this.paymentMethodsConfig);
    this.sepaForm.patchValue(this.paymentMethodsConfig);

    this.sepaPath = this.paymentMethodsConfig?.sepa_path
      ? this.paymentMethodsConfig?.sepa_path
      : 'sepa_authorization.pdf';

    this.paymentMethodsConfig?.mollie_enabled
      ? (this.mollieEnabled = true)
      : (this.mollieEnabled = false);

    this.paymentMethodsConfig?.sepa_enabled
      ? (this.sepaEnabled = true)
      : (this.sepaEnabled = false);

    console.log('paymentMethodsConfig', this.paymentMethodsConfig);
  }

  async getPaymentMethodsConfig() {
    this.paymentMethodsConfig = (
      await getDoc(this.paymentMethodConfigRef)
    ).data() as PaymentMethodsConfig;
  }

  connectMollie() {
    window.open(
      `https://europe-west1-wemaron-buurt-vervoer-prod.cloudfunctions.net/mollieAuthorizeRequest?organisationId=${this.organisationId}`,
      '_blank'
    );
  }

  async downloadSepa() {
    try {
      const pathRef = ref(this.storage.storage, this.sepaPath);
      if (this.sepaPath !== 'sepa_authorization.pdf') {
        // sepaPath is not the default path, no need to fill an uploaded mandate file
        const downloadUrl = await getDownloadURL(pathRef);
        window.open(downloadUrl, '_blank');
      } else {
        if (!this.sepaForm.controls.creditor_mandate_id.value) {
          const formattedDate = moment(new Date()).format('YYYYMMDDHHmmss');
          this.mandateId = `WEM${formattedDate}`;
          this.sepaForm.controls.creditor_mandate_id.setValue(this.mandateId);
          await setDoc(
            doc(this.db.firestore, `payment-configs/${this.organisationId}`),
            {
              creditor_mandate_id: this.mandateId,
            },
            { merge: true }
          );
        }
        if (this.sepaForm.controls.creditor_mandate_id.value) {
          this.mandateId = this.sepaForm.controls.creditor_mandate_id.value;
        }
        const pdfBytes = await this.fillPdfWithUserInfo(pathRef);
        const blob = new Blob([pdfBytes.buffer], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async fillPdfWithUserInfo(pathRef: StorageReference) {
    const formBytes = await getBytes(pathRef);
    const pdfDoc = await PDFDocument.load(formBytes);
    const form = pdfDoc.getForm();
    const sepaFormData = this.sepaForm.value;

    // Set company fields
    form.getTextField('machtigingBedrijfsnaam').setText('WeMaron B.V.');
    form.getTextField('machtigingBedrijfsadres').setText('Zonnebloemveld 3');
    form.getTextField('machtigingBedrijfspostcode').setText('6641TA');
    form.getTextField('machtigingBedrijfsplaats').setText('Beuningen');
    form.getTextField('machtigingBedrijfsland').setText('Nederland');
    form
      .getTextField('machtigingBedrijfsIncassantID')
      .setText('NL63ZZZ633934920000');
    form
      .getTextField('machtigingKenmerk')
      .setText(
        sepaFormData.creditor_mandate_id
          ? sepaFormData.creditor_mandate_id
          : this.mandateId
      );
    form
      .getTextField('machtigingRedenBetaling')
      .setText('Buurtvervoer SEPA Functionaliteit');
    form
      .getTextField('machtigingOndertekeningBedrijfsnaam')
      .setText('WeMaron B.V.');
    form.getTextField('machtigingAfschrijvenNaam').setText('WeMaron B.V.');

    // set user fields
    form
      .getTextField('machtigingNaam')
      .setText(sepaFormData.creditor_name ?? '');
    form.getTextField('machtigingAdres').setText(sepaFormData.creditor_address);
    form
      .getTextField('machtigingPostcode')
      .setText(sepaFormData.creditor_postal ?? '');
    form
      .getTextField('machtigingPlaats')
      .setText(sepaFormData.creditor_municipality ?? '');
    form
      .getTextField('machtigingLand')
      .setText(sepaFormData.creditor_country ?? '');
    const iban: string = sepaFormData.creditor_iban ?? '';
    form.getTextField('machtigingIBAN1').setText(iban.substring(0, 4));
    form.getTextField('machtigingIBAN2').setText(iban.substring(4, 8));
    form.getTextField('machtigingIBAN3').setText(iban.substring(8, 12));
    form.getTextField('machtigingIBAN4').setText(iban.substring(12, 16));
    form.getTextField('machtigingIBAN5').setText(iban.substring(16, 18));
    const pdfBytes = await pdfDoc.save({ updateFieldAppearances: false });
    return pdfBytes;
  }

  openFileInput(htmlId) {
    const element: HTMLElement = document.getElementById(htmlId) as HTMLElement;
    element.click();
  }

  async uploadSepa(event) {
    try {
      const file = event.target.files[0] as File;
      console.log(file);
      const formattedDate = moment(new Date()).format('YYYY-MM-DD-HHmmss');
      const pathRef = ref(
        this.storage.storage,
        `/organisations/${this.organisationId}/organisation_sepa_authorizations/sepa_authorization(${formattedDate}).pdf`
      );
      console.log('pathref', pathRef);
      const result = await uploadBytes(pathRef, file);
      const sepaDownloadUrl = await getDownloadURL(pathRef);
      this.sepaPath = result.metadata.fullPath;
      console.log('result', result);
      await setDoc(
        doc(this.db.firestore, `/payment-configs/${this.organisationId}`),
        {
          sepa_signature_date: new Date(),
          sepa_download_url: sepaDownloadUrl,
          sepa_path: result.metadata.fullPath,
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  async deleteSepa() {
    try {
      await setDoc(
        doc(this.db.firestore, `/payment-configs/${this.organisationId}`),
        {
          sepa_signature_date: deleteField(),
          sepa_download_url: deleteField(),
          sepa_path: deleteField(),
          creditor_mandate_id: deleteField(),
        },
        { merge: true }
      );

      this.sepaForm.controls.creditor_mandate_id.setValue('');
      this.sepaPath = 'sepa_authorization.pdf';
    } catch (error) {
      console.log(error);
    }
  }

  async saveMollie() {
    if (!this.mollieForm.valid) {
      return this.mollieForm.markAllAsTouched();
    }
    const saveObj = this.mollieForm.value;

    await setDoc(
      this.paymentMethodConfigRef,
      {
        ...saveObj,
      },
      {
        merge: true,
      }
    );
    await setDoc(
      this.publicOrgSettingsRef,
      {
        mollieEnabled: saveObj.mollie_enabled ?? false,
      },
      {
        merge: true,
      }
    );
    await this.getPaymentMethodsConfig();
    this.snackBar.open('Mollie opgeslagen', 'X', {
      duration: 5000,
    });
  }

  async saveSepa() {
    if (!this.sepaForm.valid) {
      return this.sepaForm.markAllAsTouched();
    }
    const saveObj = this.sepaForm.value;

    await setDoc(
      this.paymentMethodConfigRef,
      {
        ...saveObj,
      },
      {
        merge: true,
      }
    );
    await setDoc(
      this.publicOrgSettingsRef,
      {
        sepaEnabled: saveObj.sepa_enabled ?? false,
      },
      {
        merge: true,
      }
    );
    await this.getPaymentMethodsConfig();
    this.snackBar.open('Sepa opgeslagen', 'X', {
      duration: 5000,
    });
  }
}
