<app-nav-bar></app-nav-bar>
<!-- VEHICLE GROUP SELECTOR -->
<div
  class="vehicle-groups-bar"
  *ngIf="vehicleGroups.length > 0"
>
  <div
    class="vehicle-group-item"
    (click)="onFilterChange('vehicleGroup', 'all')"
    [class.active]="filterOnVehicleGroup === 'all'"
  >
    Alle groepen
  </div>
  <div
    class="vehicle-group-item"
    [class.active]="filterOnVehicleGroup === vehicleGroup.id"
    (click)="onFilterChange('vehicleGroup', vehicleGroup.id)"
    *ngFor="let vehicleGroup of vehicleGroups"
  >
    {{ vehicleGroup.name }}
  </div>
</div>
<!-- HEADER -->
<div
  [class.minimized-header]="minimized === 'in' ? true : false"
  [class.maximized-header]="minimized === 'out' ? true : false"
  [ngStyle]="{ 'background-image': 'url(' + publicSettingsBanner + ')' }"
>
  <div class="header-filter">
    <div class="header-content">
      <div
        class="header-content-top"
        [class.minimized-header-content-top]="minimized === 'in' ? true : false"
        [class.maximized-header-content-top]="
          minimized === 'out' ? true : false
        "
      >
        <div class="header-left">
          <div class="logo">
            <mat-icon class="logo-icon">calendar_month</mat-icon>
            <span class="logo-text">Planning</span>
          </div>
        </div>
        <div class="header-center">
          <div class="plantype-vehicle-selection">
            <mat-form-field
              class="plantype thin-form-field"
              *ngIf="ignoreDriverStatus === false"
            >
              <mat-select
                [(ngModel)]="planType"
                (selectionChange)="onFilterChange('planType', planType)"
                [disabled]="!showDriverPlanning"
                [ngClass]="{ 'no-arrow-icon': !showDriverPlanning }"
              >
                <mat-option value="rides">Ritplanning</mat-option>
                <mat-option value="drivers">Rooster chauffeurs</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field
              [ngClass]="{ planTypeVehicle: ignoreDriverStatus }"
              class="vehicle thin-form-field"
            >
              <mat-select
                [(ngModel)]="filterOnVehicle"
                required
                (selectionChange)="onFilterChange('vehicle', filterOnVehicle)"
              >
                <mat-option value="all">Alle voertuigen</mat-option>
                <mat-option
                  *ngFor="let vehicle of vehicles"
                  [value]="vehicle.id"
                >
                  <div
                    class="vehicle-dot"
                    [ngStyle]="{
                      'background-color': vehicle.calendarStyle.color
                    }"
                  ></div>
                  {{ vehicle.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <button
            mat-raised-button
            class="export-button"
            (click)="exportRides(view)"
            [class.spinner]="exportingRides"
            [disabled]="exportingRides"
            *ngIf="view === CalendarView.Day"
          >
            <mat-icon>print</mat-icon>
            <span class="span-fix">Planning exporteren</span>
          </button>
        </div>
        <div class="header-right">
          <div class="toolbar-search-export">
            <div class="toolbar-wrapper">
              <div class="toolbar">
                <button
                  matTooltip="Vandaag"
                  mat-icon-button
                  mwlCalendarToday
                  [(viewDate)]="viewDate"
                  (viewDateChange)="fetchEvents()"
                >
                  <mat-icon>today</mat-icon>
                </button>

                <button
                  mat-icon-button
                  (click)="setView(CalendarView.Day)"
                  matTooltip="Dagweergave"
                >
                  <mat-icon>view_day</mat-icon>
                </button>

                <button
                  mat-icon-button
                  (click)="setView(CalendarView.Week)"
                  matTooltip="Weekweergave"
                >
                  <mat-icon>view_week</mat-icon>
                </button>

                <button
                  mat-icon-button
                  (click)="setView(CalendarView.Month)"
                  matTooltip="Maandweergave"
                >
                  <mat-icon>view_module</mat-icon>
                </button>
              </div>
            </div>
            <mat-form-field class="search thin-form-field">
              <input
                placeholder="Zoeken"
                matInput
                type="search"
                autocomplete="off"
                data-lpignore="true"
                [(ngModel)]="searchQuery"
                (ngModelChange)="onFilterChange('search', $event)"
              />
              <button
                mat-icon-button
                matSuffix
                (click)="searchQuery = ''; searchQueryChanged.next('')"
              >
                <mat-icon>clear</mat-icon>
              </button>
            </mat-form-field>
            <button
              mat-flat-button
              class="export-button"
              (click)="exportRides(view)"
              [class.spinner]="exportingRides"
              [disabled]="exportingRides"
              *ngIf="view === CalendarView.Day"
            >
              <mat-icon>print</mat-icon>
              <span class="span-fix">Planning exporteren</span>
            </button>
          </div>
        </div>
      </div>
      <div class="header-content-bottom">
        <div class="header-bottom">
          <div
            *ngIf="filteredEvents$ | async"
            class="week-number"
          >
            <span
              class="span-fix"
              *ngIf="view === CalendarView.Week"
              >Week</span
            >
            <span *ngIf="view === CalendarView.Week">{{
              viewBeginDate | date : 'w'
            }}</span>
          </div>
          <div class="toolbar-date-button-wrapper">
            <div
              class="toolbar-date-button-spacer toolbar-date-button-spacer-toolbar"
            >
              <div class="toolbar-wrapper">
                <div class="toolbar">
                  <button
                    matTooltip="Vandaag"
                    mat-icon-button
                    mwlCalendarToday
                    [(viewDate)]="viewDate"
                    (viewDateChange)="fetchEvents()"
                  >
                    <mat-icon>today</mat-icon>
                  </button>

                  <button
                    mat-icon-button
                    (click)="setView(CalendarView.Day)"
                    matTooltip="Dagweergave"
                  >
                    <mat-icon>view_day</mat-icon>
                  </button>

                  <button
                    mat-icon-button
                    (click)="setView(CalendarView.Week)"
                    matTooltip="Weekweergave"
                  >
                    <mat-icon>view_week</mat-icon>
                  </button>

                  <button
                    mat-icon-button
                    (click)="setView(CalendarView.Month)"
                    matTooltip="Maandweergave"
                  >
                    <mat-icon>view_module</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <div
              class="toolbar-date-button-spacer toolbar-date-button-spacer-date"
            >
              <div class="date">
                <button
                  mat-icon-button
                  class="arrow"
                  mwlCalendarPreviousView
                  [view]="view"
                  [(viewDate)]="viewDate"
                  (viewDateChange)="fetchEvents()"
                >
                  <mat-icon class="chevron-fix">chevron_left</mat-icon>
                </button>

                <div
                  class="title"
                  (click)="plannerDatepicker.open()"
                >
                  <mat-label *ngIf="view === CalendarView.Day">
                    {{ weekdays[viewBeginDate.getDay()] }}
                    {{ viewBeginDate | date : 'd MMM y' }}
                  </mat-label>
                  <mat-label *ngIf="view === CalendarView.Week">
                    {{ viewBeginDate | date : 'd MMM' }} -
                    {{ viewEndDate | date : 'd MMM y' }}
                  </mat-label>
                  <mat-label *ngIf="view === CalendarView.Month">
                    {{ viewBeginDate | date : 'MMMM y' }}
                  </mat-label>
                  <mat-form-field class="title-dates">
                    <mat-datepicker
                      #plannerDatepicker
                      disabled="false"
                    >
                      <input
                        matInput
                        [matDatepicker]="plannerDatepicker"
                        (dateChange)="dateChanged($event)"
                        [(ngModel)]="viewDate"
                        disabled
                      />
                    </mat-datepicker>
                  </mat-form-field>

                  <!-- {{ viewDate | calendarDate:(view + 'ViewTitle'):'nl' }}  -->
                </div>
                <button
                  mat-icon-button
                  class="arrow"
                  mwlCalendarNextView
                  [view]="view"
                  [(viewDate)]="viewDate"
                  (viewDateChange)="fetchEvents()"
                >
                  <mat-icon class="chevron-fix">chevron_right</mat-icon>
                </button>
              </div>
            </div>
            <div
              class="toolbar-date-button-spacer toolbar-date-button-spacer-button"
            >
              <button
                mat-flat-button
                mat-icon-button
                color="accent"
                class="minimalize-button"
                (click)="toggleMenu()"
              >
                <mat-icon>{{
                  minimized === 'in'
                    ? 'keyboard_arrow_down'
                    : 'keyboard_arrow_up'
                }}</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Calendar -->
<ng-template #loading>
  <div
    id="loading"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
<div
  class="planning-main"
  [class.maximized-has-vehicle-group-tabs]="
    vehicleGroups.length > 0 && minimized === 'out' ? true : false
  "
  [class.minimized-has-vehicle-group-tabs]="
    vehicleGroups.length > 0 && minimized === 'in' ? true : false
  "
  [class.maximized-does-not-have-vehicle-group-tabs]="
    vehicleGroups.length <= 0 && minimized === 'out' ? true : false
  "
  [class.minimized-does-not-have-vehicle-group-tabs]="
    vehicleGroups.length <= 0 && minimized === 'in' ? true : false
  "
>
  <div
    class="inherit-class"
    *ngIf="filteredEvents$ | async; else loading; let events"
  >
    <div
      class="inherit-class"
      [ngSwitch]="view"
    >
      <!-- MONTH -->
      <div
        style="display: flex"
        *ngSwitchCase="'month'"
      >
        <div class="weeks-wrapper">
          <div class="space-reserver-top"></div>
          <div class="week-wrapper">
            <span *ngFor="let number of weekPerMonth">{{ number }}</span>
          </div>
        </div>
        <mwl-calendar-month-view
          style="width: 100%"
          weekStartsOn="1"
          [viewDate]="viewDate"
          [events]="events"
          [activeDayIsOpen]="activeDayIsOpen"
          (dayClicked)="dayClicked($event.day)"
          (eventClicked)="eventClicked($event.event)"
          (eventTimesChanged)="eventTimesChanged($event)"
          class="inherit-class"
        >
        </mwl-calendar-month-view>
      </div>
      <!-- WEEK -->
      <mwl-calendar-week-view
        [dayStartHour]="calendarStartHour"
        [dayEndHour]="calendarEndHour"
        weekStartsOn="1"
        [hourSegments]="hourSegments"
        *ngSwitchCase="'week'"
        [viewDate]="viewDate"
        [events]="events"
        (eventClicked)="eventClicked($event.event)"
        (hourSegmentClicked)="newRide($event.date)"
        (eventTimesChanged)="eventTimesChanged($event)"
        class="inherit-class week-view"
      >
      </mwl-calendar-week-view>
      <!-- DAY -->
      <div *ngSwitchCase="'day'">
        <div
          class="day-view"
          *ngIf="planType === 'drivers'"
        >
          <div
            class="row"
            style="width: 100%"
          >
            <mwl-calendar-day-view
              [dayStartHour]="calendarStartHour"
              [dayEndHour]="calendarEndHour"
              [hourSegments]="hourSegments"
              [viewDate]="viewDate"
              [events]="events"
              (eventClicked)="eventClicked($event.event)"
              (hourSegmentClicked)="newRide($event.date)"
              (eventTimesChanged)="eventTimesChanged($event)"
              class="inherit-class mwl-day-view"
            >
            </mwl-calendar-day-view>
          </div>
        </div>
        <div *ngIf="planType === 'rides' && filterOnVehicle !== 'all'">
          <div
            class="day-view"
            style="display: block"
            *ngIf="ridesPerVehicle$ | async; else loading; let ridesPerVehicle"
          >
            <div class="day-view-part1">
              <div class="day-view-part2">
                <div class="day-view-part3">
                  <div class="day-view-part4">
                    <div class="day-view-part5">
                      <div class="day-view-part6">
                        <div>
                          <div class="day-view-part7">
                            <div
                              class="row"
                              *ngIf="selectedVehicle"
                              style="width: 100%"
                            >
                              <div class="title">
                                {{ selectedVehicle.name }}
                              </div>
                              <mwl-calendar-day-view
                                [dayStartHour]="calendarStartHour"
                                [dayEndHour]="calendarEndHour"
                                [hourSegments]="hourSegments"
                                [viewDate]="viewDate"
                                [events]="
                                  ridesPerVehicle.get(selectedVehicle.id)
                                "
                                (eventClicked)="eventClicked($event.event)"
                                (hourSegmentClicked)="
                                  newRide($event.date, selectedVehicle.id)
                                "
                                (eventTimesChanged)="eventTimesChanged($event)"
                                class="inherit-class mwl-day-view"
                              >
                              </mwl-calendar-day-view>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="planType === 'rides' && filterOnVehicle === 'all'">
          <div
            class="day-view"
            style="display: block"
            *ngIf="ridesPerVehicle$ | async; else loading; let ridesPerVehicle"
          >
            <div class="day-view-part1">
              <div class="day-view-part2">
                <div class="day-view-part3">
                  <div class="day-view-part4">
                    <div class="day-view-part5">
                      <div class="day-view-part6">
                        <div>
                          <div class="day-view-part7">
                            <div
                              class="row"
                              *ngFor="let vehicle of filteredVehicles$ | async"
                            >
                              <div class="title">{{ vehicle.name }}</div>
                              <mwl-calendar-day-view
                                [dayStartHour]="calendarStartHour"
                                [dayEndHour]="calendarEndHour"
                                [hourSegments]="hourSegments"
                                [viewDate]="viewDate"
                                [events]="ridesPerVehicle.get(vehicle.id)"
                                (eventClicked)="eventClicked($event.event)"
                                (hourSegmentClicked)="
                                  newRide($event.date, vehicle.id)
                                "
                                (eventTimesChanged)="eventTimesChanged($event)"
                                class="inherit-class mwl-day-view"
                              >
                              </mwl-calendar-day-view>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <button
  mat-fab
  style="bottom: 80px !important"
  class="fab-fixed"
  (click)="refreshRides()"
  [class.spinner]="refreshingRides"
  [disabled]="refreshingRides"
  matTooltip="Ritten op scherm verversen"
  *ngIf="ignoreDriverStatus === false && planType === 'rides'"
>
  <mat-icon>refresh</mat-icon>
</button> -->

<button
  mat-fab
  class="fab-fixed"
  (click)="newRide()"
  *ngIf="planType === 'rides'"
  matTooltip="Nieuwe rit toevoegen"
>
  <mat-icon>add</mat-icon>
</button>
