<h2 
  mat-dialog-title
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  fxLayout="row"
>
  <mat-icon class="delete-status-icon">delete</mat-icon>
  <span
    class="title"
    fxFlex
  >{{ deletedRide.rideData.title }}</span>
  <button
    mat-icon-button
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</h2>

<mat-dialog-content>
  <div class="deleted-ride-badge">Verwijderde rit</div>

  <h4>Bewerkingen</h4>
  <h5 class="divider">Nieuwste - oudste</h5>
  <mat-divider></mat-divider>
  <div style="display: flex">
    <div>
      <p><b>Bewerker:</b></p>
      <p><b>Actie:</b></p>
      <p><b>Reden:</b></p>
    </div>
    <div class="action-list">
      <p class="edit-info">
        <span>{{ deletedRide.doneByUser }}</span>
        <span>{{ deletedRide.date.toDate() | date: 'short' }}</span>
      </p>
      <p>Rit verwijderd</p>
      <p>
        <b>{{ deletedRide.deleteReason && deletedRide.deleteReason != '' ? deletedRide.deleteReason : '-' }}</b>
      </p>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div *ngFor="let edit of allEdits | async">
    <div *ngFor="let change of edit.changes">
      <div *ngIf="change.field === 'addedUsers'">
        <div style="display: flex">
          <div>
            <p><b>Bewerker:</b></p>
            <p><b>Actie:</b></p>
            <p><b>Bewerking:</b></p>
          </div>
          <div class="action-list">
            <p class="edit-info">
              <span>{{ edit.editedByEmail }}</span>
              <span>{{ edit.date.toDate() | date: 'short' }}</span>
            </p>
            <p>Personen toegevoegd</p>
            <div *ngFor="let user of change.users">
              <p>
                <b>{{ user.title }}</b>
              </p>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>
      <div *ngIf="change.field === 'createdRide'">
        <div style="display: flex">
          <div>
            <p><b>Bewerker:</b></p>
            <p><b>Actie:</b></p>
          </div>
          <div class="action-list">
            <p class="edit-info">
              <span>{{ edit.editedByEmail }}</span>
              <span>{{ edit.date.toDate() | date: 'short' }}</span>
            </p>
            <p>{{ change.text }}</p>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>
      <div *ngIf="change.field === 'copiedRide'">
        <div style="display: flex">
          <div>
            <p><b>Bewerker:</b></p>
            <p><b>Actie:</b></p>
          </div>
          <div class="action-list">
            <p class="edit-info">
              <span>{{ edit.editedByEmail }}</span>
              <span>{{ edit.date.toDate() | date: 'short' }}</span>
            </p>
            <p>{{ change.text }}</p>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>
      <div *ngIf="change.field === 'removedUsers'">
        <div style="display: flex">
          <div>
            <p><b>Bewerker:</b></p>
            <p><b>Actie:</b></p>
            <p><b>Bewerking:</b></p>
          </div>
          <div class="action-list">
            <p class="edit-info">
              <span>{{ edit.editedByEmail }}</span>
              <span>{{ edit.date.toDate() | date: 'short' }}</span>
            </p>
            <p>Personen verwijderd</p>
            <div *ngFor="let user of change.users">
              <p>
                <b>{{ user.title }}</b>
              </p>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>
      <div
        *ngIf="change.field !== 'addedUsers' && change.field !== 'removedUsers'"
      >
        <div *ngIf="change.field === 'comments'">
          <div style="display: flex">
            <div>
              <p><b>Bewerker:</b></p>
              <p><b>Actie:</b></p>
              <p><b>Bewerking:</b></p>
            </div>
            <div class="action-list">
              <p class="edit-info">
                <span>{{ edit.editedByEmail }}</span>
                <span>{{ edit.date.toDate() | date: 'short' }}</span>
              </p>
              <p>Opmerkingen</p>
              <p>{{ change.from }}</p>
              <span style="display: flex">
                <mat-icon>arrow_downward</mat-icon>
                <span class="arrow-text"> is nu</span>
              </span>
              <p>
                <b>{{ change.to }}</b>
              </p>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div *ngIf="change.field === 'start'">
          <div style="display: flex">
            <div>
              <p><b>Bewerker:</b></p>
              <p><b>Actie:</b></p>
              <p><b>Bewerking:</b></p>
            </div>
            <div class="action-list">
              <p class="edit-info">
                <span>{{ edit.editedByEmail }}</span>
                <span>{{ edit.date.toDate() | date: 'short' }}</span>
              </p>
              <p>Begindatum</p>
              <p>{{ change.from.toDate() | date: 'medium' }}</p>
              <span style="display: flex">
                <mat-icon>arrow_downward</mat-icon>
                <span class="arrow-text"> is nu</span>
              </span>
              <p>
                <b>{{ change.to.toDate() | date: 'medium' }}</b>
              </p>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div *ngIf="change.field === 'end'">
          <div style="display: flex">
            <div>
              <p><b>Bewerker:</b></p>
              <p><b>Actie:</b></p>
              <p><b>Bewerking:</b></p>
            </div>
            <div class="action-list">
              <p class="edit-info">
                <span>{{ edit.editedByEmail }}</span>
                <span>{{ edit.date.toDate() | date: 'short' }}</span>
              </p>
              <p>Einddatum</p>
              <p>{{ change.from.toDate() | date: 'medium' }}</p>
              <span style="display: flex">
                <mat-icon>arrow_downward</mat-icon>
                <span class="arrow-text"> is nu</span>
              </span>
              <p>
                <b>{{ change.to.toDate() | date: 'medium' }}</b>
              </p>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div *ngIf="change.field === 'rideProduct'">
          <div style="display: flex">
            <div>
              <p><b>Bewerker:</b></p>
              <p><b>Actie:</b></p>
              <p><b>Bewerking:</b></p>
            </div>
            <div class="action-list">
              <p class="edit-info">
                <span>{{ edit.editedByEmail }}</span>
                <span>{{ edit.date.toDate() | date: 'short' }}</span>
              </p>
              <p>Ritprijs</p>
              <p>{{ change.from.name }} - {{ change.from.cost }} Credits</p>
              <span style="display: flex">
                <mat-icon>arrow_downward</mat-icon>
                <span class="arrow-text"> is nu</span>
              </span>
              <p>
                <b>{{ change.to.name }} - {{ change.to.cost }} Credits</b>
              </p>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div *ngIf="change.field === 'special'">
          <div style="display: flex">
            <div>
              <p><b>Bewerker:</b></p>
              <p><b>Actie:</b></p>
              <p><b>Bewerking:</b></p>
            </div>
            <div class="action-list">
              <p class="edit-info">
                <span>{{ edit.editedByEmail }}</span>
                <span>{{ edit.date.toDate() | date: 'short' }}</span>
              </p>
              <p>Rittype</p>
              <p>{{ change.from }}</p>
              <span style="display: flex">
                <mat-icon>arrow_downward</mat-icon>
                <span class="arrow-text"> is nu</span>
              </span>
              <p>
                <b>{{ change.to }}</b>
              </p>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div *ngIf="change.field === 'vehicleId'">
          <div style="display: flex">
            <div>
              <p><b>Bewerker:</b></p>
              <p><b>Actie:</b></p>
              <p><b>Bewerking:</b></p>
            </div>
            <div class="action-list">
              <p class="edit-info">
                <span>{{ edit.editedByEmail }}</span>
                <span>{{ edit.date.toDate() | date: 'short' }}</span>
              </p>
              <p>Voertuig</p>
              <p>{{ change.from }}</p>
              <span style="display: flex">
                <mat-icon>arrow_downward</mat-icon>
                <span class="arrow-text"> is nu</span>
              </span>
              <p>
                <b>{{ change.to }}</b>
              </p>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div *ngIf="change.field === 'fromLocation'">
          <div style="display: flex">
            <div>
              <p><b>Bewerker:</b></p>
              <p><b>Actie:</b></p>
              <p><b>Bewerking:</b></p>
            </div>
            <div class="action-list">
              <p class="edit-info">
                <span>{{ edit.editedByEmail }}</span>
                <span>{{ edit.date.toDate() | date: 'short' }}</span>
              </p>
              <p>Startpunt</p>
              <p>{{ change.from }}</p>
              <span style="display: flex">
                <mat-icon>arrow_downward</mat-icon>
                <span class="arrow-text"> is nu</span>
              </span>
              <p>
                <b>{{ change.to }}</b>
              </p>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div *ngIf="change.field === 'toLocation'">
          <div style="display: flex">
            <div>
              <p><b>Bewerker:</b></p>
              <p><b>Actie:</b></p>
              <p><b>Bewerking:</b></p>
            </div>
            <div class="action-list">
              <p class="edit-info">
                <span>{{ edit.editedByEmail }}</span>
                <span>{{ edit.date.toDate() | date: 'short' }}</span>
              </p>
              <p>Bestemming</p>
              <p>{{ change.from }}</p>
              <span style="display: flex">
                <mat-icon>arrow_downward</mat-icon>
                <span class="arrow-text"> is nu</span>
              </span>
              <p>
                <b>{{ change.to }}</b>
              </p>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div>
    </div>
  </div>

  <h4>Voertuig</h4>
  <p>{{ deletedRide.vehicleName }}</p>
  <h4>Begintijd / Eindtijd</h4>
  <p>{{ deletedRide.rideData.start.toDate() | date : 'd/MM/yyyy HH:mm' }} - {{ deletedRide.rideData.end.toDate() | date : 'd/MM/yyyy HH:mm' }}</p>
  
  <div
    *ngIf="!details"
    class="extra-info"
    (click)="details = !details"
  >
    <b>Toon volledige ritinformatie</b>
    <mat-icon>arrow_drop_down</mat-icon>
  </div>

  <div *ngIf="details">
    <div class="info">
      <h4>Opmerkingen</h4>
      <p class="remark">{{ deletedRide.rideData.comments }}</p>
    </div>
    
    <div
      class="info"
      *ngIf="deletedRide.rideData.users"
    >
      <h4>Ophalen</h4>
      <div
        class="user"
        *ngFor="let user of users; let i = index"
      >
        <div class="name">
          {{ i + 1 }}. {{ user.title }}
          <span *ngIf="user.phoneNumber">
            (
              {{ user.phoneNumber }}
            )
          </span>
        </div>
        <div
          class="icons"
          fxLayout="row"
        >
          <div
            class="icon"
            [ngClass]="{ active: user[icon.id] === true }"
            [matTooltip]="icon.name"
            *ngFor="let icon of icons | async"
          >
            <mat-icon *ngIf="icon.iconType == 'material'">
              {{ icon.icon }}</mat-icon
            >
            <div
              class="mat-icon"
              *ngIf="icon.iconType == 'custom'"
              [inlineSVG]="'/assets/icons/' + icon.icon + '.svg'"
            ></div>
          </div>
        </div>
        <div *ngIf="user.commentsForDriver">
          <h4 class="comment-for-info">Opmerkingen voor de chauffeur:</h4>
          <p class="remark participant-remark">{{ user.commentsForDriver }}</p>
        </div>
        <div *ngIf="user.remarkFromDriver">
          <h4 class="comment-for-info">Opmerkingen door chauffeur:</h4>
          <p class="remark participant-remark">{{ user.remarkFromDriver }}</p>
        </div>
        <div class="location">
          <p
            *ngIf="user.from.address; else normalAddress"
          >
            <mat-icon>navigation</mat-icon>{{ user.from.name }} -
            {{ user.from.address }}
          </p>
          <ng-template #normalAddress>
            <p>
              <mat-icon>navigation</mat-icon>{{ user.from }}
            </p>
          </ng-template>
        </div>
      </div>
    </div>

    <div
      class="info"
      *ngIf="deletedRide.rideData.users"
    >
      <h4>Bestemming</h4>
      <div
        class="user"
        *ngFor="let user of users; let i = index"
      >
        <div class="name">
          {{ i + 1 }}. {{ user.title }}
          <span *ngIf="user.phoneNumber">
            (
              {{ user.phoneNumber }}
            )
          </span>
        </div>
        <div
          class="icons"
          fxLayout="row"
        >
          <div
            class="icon"
            [ngClass]="{ active: user[icon.id] === true }"
            [matTooltip]="icon.name"
            *ngFor="let icon of icons | async"
          >
            <mat-icon *ngIf="icon.iconType == 'material'">
              {{ icon.icon }}</mat-icon
            >
            <div
              class="mat-icon"
              *ngIf="icon.iconType == 'custom'"
              [inlineSVG]="'/assets/icons/' + icon.icon + '.svg'"
            ></div>
          </div>
        </div>
        <div *ngIf="user.commentsForDriver">
          <h4 class="comment-for-info">Opmerkingen voor de chauffeur:</h4>
          <p class="remark participant-remark">{{ user.commentsForDriver }}</p>
        </div>
        <div *ngIf="user.remarkFromDriver">
          <h4 class="comment-for-info">Opmerkingen door chauffeur:</h4>
          <p class="remark participant-remark">{{ user.remarkFromDriver }}</p>
        </div>

        <div class="location">
          <p
            *ngIf="user.to.address; else normalAddress"
          >
            <mat-icon>navigation</mat-icon>{{ user.to.name }} -
            {{ user.to.address }}
          </p>
          <ng-template #normalAddress>
            <p>
              <mat-icon>navigation</mat-icon>{{ user.to }}
            </p>
          </ng-template>
        </div>
      </div>
    </div>

    <h4>Ritprijs</h4>
    <p>      
      {{ deletedRide.rideData.rideProduct.name }} -
      {{ deletedRide.rideData.rideProduct.cost }} Credits
    </p>
    <div class="info">
      <h4>Kenmerken</h4>
      <mat-chip-list class="chip-list">
        <mat-chip
          *ngFor="let characteristic of deletedRide.rideData.characteristics"
          color="primary"
          selected
        >
          {{ characteristic }}
        </mat-chip>
      </mat-chip-list>
    </div>
    <h4>Chauffeur</h4>
    <p>{{ deletedRide.driverName }}</p>

    <div 
      class="extra-info"
      (click)="details = !details"
    >
      <b>Verberg volledige ritinformatie</b>
      <mat-icon>arrow_drop_up</mat-icon>
    </div>
  </div>
</mat-dialog-content>  