<div
  mat-dialog-title
  *ngIf="!loadingData"
>
  {{ dialogTitle }}
</div>
<div
  mat-dialog-content
  *ngIf="!loadingData"
>
  <div
    class="notification-icons-wrapper"
    *ngIf="!newUser"
  >
    <div
      class="notification-icon-wrapper"
      *ngFor="let warning of data.user.warnings"
    >
      <div
        class="notification-circle-icon"
        [ngClass]="warning.colorClass"
      >
        {{ warning.type === 'text' ? warning.iconOrText : '' }}
        <mat-icon *ngIf="warning.type === 'icon'">
          {{ warning.iconOrText }}
        </mat-icon>
      </div>
      {{ warning.description }}
    </div>
  </div>
  <form
    autocomplete="off"
    [formGroup]="userForm"
  >
    <div class="form-field-group-wrapper">
      <div class="form-field-group-title">Account info</div>
      <mat-form-field>
        <mat-label>E-mail</mat-label>
        <input
          formControlName="email"
          autocomplete="one-time-code"
          matInput
          required
          type="email"
        />
        <button
          matSuffix
          mat-icon-button
          (click)="enableField('email')"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <mat-error> Dit e-mail is al in gebruik </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Telefoonnummer</mat-label>
        <input
          placeholder="06 11223344"
          type="tel-national"
          formControlName="phoneNumber"
          matInput
        />
      </mat-form-field>
      <mat-form-field *ngIf="newUser">
        <mat-label>Wachtwoord</mat-label>
        <input
          formControlName="password"
          autocomplete="new-password"
          matInput
          required
          type="password"
          minlength="6"
        />
      </mat-form-field>
      <mat-form-field *ngIf="newUser">
        <mat-label>Herhaal wachtwoord</mat-label>
        <input
          formControlName="confirmPassword"
          matInput
          required
          type="password"
          minlength="6"
        />
        <mat-error> Wachtwoord komt niet overeen </mat-error>
      </mat-form-field>
    </div>

    <div class="form-field-group-wrapper">
      <div class="form-field-group-title">Basis info</div>
      <mat-form-field>
        <mat-label>Naam</mat-label>
        <input
          formControlName="name"
          type="text"
          matInput
          required
        />
      </mat-form-field>

      <div class="form-field-group-divider">
        <mat-form-field *ngIf="askGender">
          <mat-label>Geslacht</mat-label>
          <mat-select formControlName="gender">
            <mat-option value="Man">Man</mat-option>
            <mat-option value="Vrouw">Vrouw</mat-option>
            <mat-option value="Anders">Anders</mat-option>
          </mat-select>
          <mat-error *ngIf="userForm.controls.gender.hasError('required')">
            Je moet dit veld invullen.
          </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="askBirthDate">
          <mat-label>Geboortedatum</mat-label>
          <input
            formControlName="birthDate"
            matInput
            [matDatepicker]="picker"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <mat-form-field>
        <mat-label>Straatnaam</mat-label>
        <input
          formControlName="streetName"
          matInput
          required
        />
      </mat-form-field>

      <div class="form-field-group-divider">
        <mat-form-field>
          <mat-label>Huisnummer</mat-label>
          <input
            formControlName="houseNumber"
            matInput
            required
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Toevoeging</mat-label>
          <input
            formControlName="houseNumberAddition"
            matInput
          />
        </mat-form-field>
      </div>

      <mat-form-field>
        <mat-label>Postcode</mat-label>
        <input
          formControlName="postal"
          matInput
          #postalCode
          required
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Woonplaats</mat-label>
        <input
          formControlName="city"
          matInput
          required
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Kaartnummer</mat-label>
        <input
          formControlName="cardId"
          matInput
        />
      </mat-form-field>
    </div>

    <div class="form-field-group-wrapper">
      <div class="form-field-group-title">
        Noodnummers ({{ emergencyPhoneNumbers.length }})
      </div>
      <div *ngFor="let phoneNumber of emergencyPhoneNumbers; let i = index">
        <div
          class="phone-number-info-wrapper"
          *ngIf="!phoneNumber.editing"
        >
          <div class="phone-number-info">
            <div class="phone-number-info-name">{{ phoneNumber.name }}</div>
            <div class="phone-number-info-number">
              {{ phoneNumber.phoneNumber }}
            </div>
            <div class="phone-number-info-description">
              {{ phoneNumber.description }}
            </div>
          </div>
          <div class="phone-number-buttons">
            <button
              mat-icon-button
              (click)="managePhoneNumber('edit', i)"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="managePhoneNumber('delete', i)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
        <form
          autocomplete="off"
          *ngIf="phoneNumber.editing"
          [formGroup]="phoneForm"
          class="phone-form phone-form-border"
        >
          <mat-form-field>
            <mat-label>Naam</mat-label>
            <input
              formControlName="name"
              matInput
            />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Telefoonnummer</mat-label>
            <input
              formControlName="phoneNumber"
              matInput
            />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Relatie</mat-label>
            <input
              formControlName="description"
              matInput
            />
          </mat-form-field>
          <div class="save-button-wrapper">
            <button
              mat-flat-button
              color="accent"
              class="save-phone-number-button"
              [disabled]="!phoneForm.valid"
              (click)="savePhoneNumber('edit', i)"
            >
              Noodnummer opslaan
            </button>
          </div>
        </form>
      </div>

      <button
        mat-flat-button
        color="accent"
        class="add-phone-number-button"
        (click)="managePhoneNumber('add')"
        *ngIf="!addingPhoneNumber"
      >
        <div class="phone-number-button-text">
          <mat-icon>add</mat-icon> Noodnummer toevoegen
        </div>
      </button>
      <form
        autocomplete="off"
        *ngIf="addingPhoneNumber"
        [formGroup]="phoneForm"
        class="phone-form"
      >
        <mat-form-field>
          <mat-label>Naam</mat-label>
          <input
            formControlName="name"
            matInput
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Telefoonnummer</mat-label>
          <input
            formControlName="phoneNumber"
            matInput
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Relatie</mat-label>
          <input
            formControlName="description"
            matInput
          />
        </mat-form-field>
        <div class="save-button-wrapper">
          <button
            mat-flat-button
            color="accent"
            class="save-phone-number-button"
            [disabled]="
              !phoneForm.controls.name.value ||
              !phoneForm.controls.phoneNumber.value ||
              !phoneForm.controls.phoneNumber.valid ||
              !phoneForm.controls.description.value
            "
            (click)="savePhoneNumber('add')"
          >
            Noodnummer opslaan
          </button>
        </div>
      </form>
    </div>

    <div class="form-field-group-wrapper">
      <div class="form-field-group-title">Opmerkingen</div>
      <mat-form-field>
        <mat-label> Algemene Opmerkingen</mat-label>
        <textarea
          formControlName="hiddenRemarks"
          matInput
        ></textarea>
      </mat-form-field>
      <mat-form-field *ngIf="planningComments">
        <mat-label>Opmerkingen voor planner</mat-label>
        <textarea
          formControlName="remarks"
          matInput
        ></textarea>
      </mat-form-field>
      <mat-form-field *ngIf="driverComments">
        <mat-label>Opmerkingen voor chauffeur</mat-label>
        <textarea
          formControlName="commentsForDriver"
          matInput
        ></textarea>
      </mat-form-field>
    </div>

    <div class="form-field-group-wrapper">
      <div class="form-field-group-title">Betaling</div>
      <mat-form-field>
        <mat-label>Standaard ritprijs</mat-label>
        <mat-select formControlName="rideProductId">
          <mat-option
            style="font-style: italic"
            [value]="'none'"
            >Geen
          </mat-option>
          <mat-option
            *ngFor="let product of productArray"
            [value]="product.id"
            >{{ product.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Betaalmethode</mat-label>

        <mat-select formControlName="paymentMethod">
          <mat-option
            style="font-style: italic"
            [value]="paymentMethod.NONE"
          >
            Geen
          </mat-option>
          <mat-option
            *ngIf="data.publicSettings.mollieEnabled"
            [disabled]="!data.user?.mollieCustomerID"
            [value]="paymentMethod.MOLLIE"
          >
            Mollie
          </mat-option>

          <mat-option
            *ngIf="data.publicSettings.sepaEnabled"
            [disabled]="!sepaPath"
            [value]="paymentMethod.SEPA"
          >
            SEPA
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="data.publicSettings.sepaEnabled">
        <mat-label>IBAN</mat-label>
        <input
          formControlName="iban"
          matInput
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Betaalplan</mat-label>
        <mat-select formControlName="currentPlan">
          <mat-option
            style="font-style: italic"
            [value]="'none'"
            >Geen
          </mat-option>
          <mat-option
            *ngFor="let plan of planArray"
            [value]="plan.id"
            >{{ plan.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <div
        *ngIf="
          data.publicSettings.sepaEnabled &&
          userForm.controls.paymentMethod.value === paymentMethod.SEPA &&
          userForm.controls.currentPlan.value !== 'none'
        "
        class="sepa-buttons"
      >
        <button
          *ngIf="data.publicSettings.sepaEnabled"
          mat-flat-button
          color="accent"
          class="sepa-download-button"
          (click)="downloadSepa()"
        >
          <mat-icon>download</mat-icon>Download SEPA formulier
        </button>
        <button
          *ngIf="
            data.publicSettings.sepaEnabled &&
            sepaPath === 'sepa_authorization.pdf'
          "
          color="accent"
          mat-flat-button
          class="sepa-upload-button"
          (click)="openFileInput('pdf')"
          [disabled]="!sepaMandateId"
        >
          <mat-icon>upload</mat-icon>Upload SEPA formulier
        </button>
        <div
          *ngIf="
            data.publicSettings.sepaEnabled &&
            sepaPath !== 'sepa_authorization.pdf'
          "
          class="sepa-alteration-buttons"
        >
          <button
            mat-stroked-button
            class="alter-sepa-button"
            (click)="openFileInput('pdf')"
          >
            <mat-icon>upload</mat-icon>Wijzig SEPA formulier
          </button>
          <div
            class="delete-sepa-button"
            (click)="deleteSepa()"
          >
            Verwijder
          </div>
        </div>
      </div>
    </div>

    <div class="form-field-group-wrapper">
      <div class="form-field-group-title">Bijzonderheden</div>
      <div></div>
      <div
        formGroupName="icons"
        class="icons-list"
      >
        <mat-slide-toggle
          *ngFor="let icon of iconArray"
          formControlName="{{ icon.id }}"
        >
          {{ icon.description }}
        </mat-slide-toggle>
      </div>
    </div>

    <div
      class="form-field-group-wrapper"
      *ngIf="emailNotificationsParticipants || smsNotificationsParticipants"
    >
      <div class="form-field-group-title">Notificaties</div>
      <div class="notifications-list">
        <mat-form-field *ngIf="smsNotificationsParticipants">
          <mat-label>Telefoonnummer voor SMS</mat-label>
          <input
            placeholder="06 11223344"
            type="tel-national"
            formControlName="smsNotificationPhoneNumber"
            matInput
          />
        </mat-form-field>
        <mat-checkbox
          formControlName="notifyBySMS"
          *ngIf="smsNotificationsParticipants"
        >
          Notificatie via SMS
        </mat-checkbox>
        <mat-checkbox
          formControlName="notifyByEmail"
          *ngIf="emailNotificationsParticipants"
        >
          Notificatie via E-mail
        </mat-checkbox>
      </div>
    </div>
    <div class="form-field-group-wrapper">
      <div class="form-field-group-title">Acties</div>
      <div class="actions-list">
        <mat-slide-toggle formControlName="inactive">
          Maak deelnemer inactief
        </mat-slide-toggle>
      </div>
    </div>

    <input
      style="visibility: hidden; height: 0; display: none"
      type="file"
      id="pdf"
      class="form-control"
      (change)="uploadSepa($event)"
      placeholder="Upload file"
      accept=".pdf"
    />
  </form>
</div>
<div
  class="loading-spinner"
  *ngIf="loadingData"
>
  <mat-spinner></mat-spinner>
</div>

<div
  mat-dialog-actions
  *ngIf="!loadingData"
>
  <button
    mat-button
    [mat-dialog-close]="false"
  >
    Annuleren
  </button>
  <button
    mat-raised-button
    [disabled]="!userForm.valid"
    (click)="save()"
    cdkFocusInitial
    color="primary"
    [class.spinner]="loading"
  >
    Opslaan
  </button>
</div>
