import { Component, OnInit, Inject } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { lastValueFrom } from 'rxjs';
import { VehiclesComponent } from '../../../vehicles/vehicles.component';

@Component({
  selector: 'app-adjust-password',
  templateUrl: './adjust-password.component.html',
  styleUrls: ['./adjust-password.component.scss'],
})
export class AdjustPasswordComponent implements OnInit {
  userForm: UntypedFormGroup;
  saving: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<VehiclesComponent>,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private fns: AngularFireFunctions
  ) {}

  ngOnInit() {
    this.userForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      passwordVerification: ['', Validators.required],
    });

    this.userForm.controls.passwordVerification.valueChanges.subscribe(
      (val) => {
        if (val !== this.userForm.controls.password.value) {
          this.userForm.controls.passwordVerification.setErrors({
            customError: true,
          });
        }
      }
    );
  }

  async save() {
    this.saving = true;
    try {
      const callable = this.fns.httpsCallable('httpUpdateUser');
      const result = await lastValueFrom(
        callable({
          type: 'password',
          email: this.userForm.controls.email.value,
          password: this.userForm.controls.passwordVerification.value,
        })
      );
      console.log('result', result);
      if (result == 'succes') {
        this.snackBar.open(
          'Het wachtwoord is veranderd, er wordt nu een mail verstuurd naar de gebruiker.',
          'X',
          {
            duration: 5000,
          }
        );
        this.saving = false;
        this.dialogRef.close();
      } else if (result.code == 'auth/user-not-found') {
        this.snackBar.open(
          'Er kan geen gebruiker worden gevonden met opgegeven e-mailadres',
          'X',
          {
            duration: 5000,
          }
        );
        this.saving = false;
      }
    } catch (e) {
      this.saving = false;
      console.error(e);
    }
  }

  close() {
    this.dialogRef.close();
  }
}
