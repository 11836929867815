import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VehiclesComponent } from '../../../vehicles/vehicles.component';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-delete-rides',
  templateUrl: './delete-rides.component.html',
  styleUrls: ['./delete-rides.component.scss'],
})
export class DeleteRidesComponent implements OnInit {
  deleteForm: UntypedFormGroup;
  text: string;
  suggestionText: string;
  deleteRideTxt: string;
  deleteRideTxtError: string;
  organisationId = localStorage.getItem('orgId');
  deletingRides: boolean;
  dateCheckPass: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<VehiclesComponent>,
    private fb: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.deleteForm = this.fb.group({
      deleteRidesFrom: [],
      deleteRidesTo: [],
    });
    const now = new Date();
    const oneMonthEarlier = new Date(now.setMonth(now.getMonth() - 1));
    const oneDayEarlier = new Date(
      new Date(now.setDate(now.getDate() - 1)).setMonth(now.getMonth() + 1)
    );
    this.deleteForm.patchValue({
      deleteRidesFrom: oneMonthEarlier,
      deleteRidesTo: oneDayEarlier,
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  async deleteRidesInRange() {
    this.deletingRides = true;
    const deleteRidesFrom = this.deleteForm.value.deleteRidesFrom;
    const deleteRidesTo = this.deleteForm.value.deleteRidesTo;
    console.log('deleteRidesFrom', deleteRidesFrom);
    try {
      const httpCall = (await this.http
        .post(`${environment.functionsUrl}/httpDeleteRidesFrom`, {
          organisationId: this.organisationId,
          deleteRidesFrom: deleteRidesFrom
            ? deleteRidesFrom.setHours(0, 0, 0, 0)
            : null,
          deleteRidesTo: deleteRidesTo
            ? deleteRidesTo.setHours(0, 0, 0, 0)
            : null,
        })
        .toPromise()) as { message: string };
      console.log('httpCall', httpCall);
      if (httpCall.message === 'succeed') {
        this._snackBar.open('Ritten succesvol verwijderd', 'X', {
          duration: 5000,
        });
        this.dialogRef.close();
      }
    } catch (e) {
      console.error(e);
    }
    this.dateCheckPass = true;
    this.deleteRideTxt = null;
    this.deleteRideTxtError = null;
    this.deletingRides = false;
  }

  checkRange() {
    console.log(
      'this.organisationForm.value.deleteRidesFrom',
      this.deleteForm.value.deleteRidesFrom
    );
    const deleteRidesFrom = this.deleteForm.value.deleteRidesFrom;
    const deleteRidesTo = this.deleteForm.value.deleteRidesTo;
    if (!deleteRidesFrom && !deleteRidesTo) {
      this.deletingRides = false;
      return this._snackBar.open(
        'Vul een range in om ritten te verwijderen',
        'X',
        {
          duration: 5000,
        }
      );
    }
    if (!deleteRidesFrom) {
      this.deleteRideTxtError = null;
      this.deleteRideTxt = `Tot ${deleteRidesTo.toLocaleDateString()}`;
    }
    if (!deleteRidesTo) {
      this.deleteRideTxtError = null;
      this.deleteRideTxt = `Vanaf ${deleteRidesFrom.toLocaleDateString()}`;
    }
    if (deleteRidesFrom && deleteRidesTo) {
      this.deleteRideTxtError = null;
      this.deleteRideTxt = `Vanaf ${deleteRidesFrom.toLocaleDateString()} tot ${deleteRidesTo.toLocaleDateString()}`;
      if (deleteRidesFrom > deleteRidesTo) {
        this.dateCheckPass = false;
        return (this.deleteRideTxtError = `De vanaf datum mag niet later zijn dan de tot datum`);
      }
    }
    this.dateCheckPass = true;
    console.log(
      'this.organisationForm.value.deleteRidesFrom2',
      this.deleteForm.value.deleteRidesFrom
    );
  }

  cancelRange() {
    this.deleteRideTxt = null;
    this.deleteRideTxtError = null;
    this.dateCheckPass = false;
    const now = new Date();
    const oneMonthEarlier = new Date(now.setMonth(now.getMonth() - 1));
    const oneDayEarlier = new Date(
      new Date(now.setDate(now.getDate() - 1)).setMonth(now.getMonth() + 1)
    );
    this.deleteForm.controls.deleteRidesFrom.setValue(oneMonthEarlier);
    this.deleteForm.controls.deleteRidesTo.setValue(oneDayEarlier);
  }

  close() {
    this.dialogRef.close();
  }
}
