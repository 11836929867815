import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Observable, Subject, ReplaySubject, combineLatest } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { ImportExportComponent } from '../dialogs/import-export/import-export.component';
import { NgxCsvParser } from 'ngx-csv-parser';
import { WarningDialog } from '../../global-dialogs/warning/warning.component';
import { Setting, Tariff, TariffId } from './../../interfaces';
import { CreateEditTariffComponent } from './dialogs/create-edit-tariff/create-edit-tariff.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { redirectIfNotAdmin } from 'src/app/globals';
@Component({
  selector: 'app-tariffs',
  templateUrl: './tariffs.component.html',
  styleUrls: ['./tariffs.component.scss'],
})
export class TariffsComponent implements OnInit {
  @ViewChild('TABLE', { static: false }) table: ElementRef;
  @ViewChild('uploader', { static: false }) uploader: ElementRef;
  tariffsCollection: AngularFirestoreCollection<Tariff>;
  tariffs: Observable<TariffId[]>;
  tariffsArray: TariffId[];
  public filteredTariffs: ReplaySubject<TariffId[]> = new ReplaySubject<
    TariffId[]
  >(1);
  displayedColumns = ['default', 'name', 'credits', 'color', 'button'];
  importedData: Subject<TariffId[]> = new ReplaySubject<TariffId[]>();
  totalTariffs: number;
  header = true;
  fileUploaded: File;
  worksheet: any;
  storeData: any;
  csvRecords: any[] = [];
  totalDefault: number;
  searchQuery: string;
  searchQueryChanged: Subject<string> = new Subject<string>();
  settingDoc: AngularFirestoreDocument<Setting>;
  setting: Observable<Setting>;
  maxTotalTariffs: number;
  organisationId = localStorage.getItem('orgId');
  constructor(
    public db: AngularFirestore,
    public dialog: MatDialog,
    private ngxCsvParser: NgxCsvParser,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit() {
    this.tariffsCollection = this.db.collection<Tariff>(
      `organisations/${this.organisationId}/products`,
      (ref) => ref.orderBy('name', 'asc')
    );

    this.tariffs = this.tariffsCollection.snapshotChanges().pipe(
      map((tariff) =>
        tariff
          .map((tariffDoc) => {
            const data = tariffDoc.payload.doc.data() as Tariff;
            const id = tariffDoc.payload.doc.id;
            return { id, ...data };
          })
          .sort(sortTariffs)
      )
    );

    const sortTariffs = (a: TariffId, b: TariffId) => {
      return a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'});
    };

    this.tariffs.subscribe((value) => {
      this.tariffsArray = value;
      console.log('tariffs', value);
      let totalDefault = 0;
      value.forEach((val) => {
        if (val.default === true) {
          totalDefault = totalDefault + 1;
        }
      });
      this.totalDefault = totalDefault;
      console.log('this.totalDefault', this.totalDefault);
    });
    const combinedObservable = combineLatest(
      this.tariffs,
      this.searchQueryChanged
    );
    combinedObservable
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        console.log('values', values);
        const tariffs = values[0];
        const searchQuery = values[1];
        // const filters = {
        //   showHidden: values[2],
        //   orderBy: values[3],
        //   orderDirection: values[4]
        // };
        let filteredTariffs = tariffs.filter((item) => this.checkFilters(item));
        // this.orderDirection = 'desc';
        // console.log('orderBy', filters.orderBy);
        // filteredVehicles = filteredVehicles.sort((a, b) => {
        //   if (a[filters.orderBy] > b[filters.orderBy]) {
        //     return 1;
        //   } else {
        //     return -1;
        //   }
        //   // return 0;
        // });
        // if (filters.orderDirection === 'desc') {
        //   filteredVehicles.reverse();
        // }
        console.log('filteredTariffs', filteredTariffs);
        this.totalTariffs = filteredTariffs.length;
        this.filteredTariffs.next(filteredTariffs);
        // this.filteredUsers.next(this.allUsers.pipe(map(items => items.filter(item => this.checkFilters(item)))));
      });
    this.searchQueryChanged.next('');
    redirectIfNotAdmin(this.db.firestore, this.router);
  }
  onFilterChange(type, ev?, id?) {
    if (type === 'search') {
      this.searchQueryChanged.next(ev);
    }
  }

  checkFilters(tariff) {
    // console.log('user', user);
    let passesSearchFilter = true;
    if (this.searchQuery) {
      passesSearchFilter = false;
      if (tariff.name) {
        if (
          tariff.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        ) {
          passesSearchFilter = true;
        }
      }
    }

    if (passesSearchFilter) {
      // console.log('user passes filter:', user);
      return tariff;
    }
  }

  openImportExport() {
    const dialogRef = this.dialog.open(ImportExportComponent, {
      width: '300px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      if (result.type === 'Export') {
        this.exportTableToExcel();
      }
      if (result.type === 'Import') {
        this.uploader.nativeElement.click();
      }
    });
  }
  uploadedFile(event) {
    if (this.uploader.nativeElement.value === null) {
      return;
    } else {
      console.log('event', event);
      console.log('uploading file');
      this.fileUploaded = event.target.files[0];
      this.readExcel();
    }
  }
  readExcel() {
    const readFile = new FileReader();
    const spreadsheet = {};
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[firstSheetName];

      Object.keys(this.worksheet).forEach((key) => {
        if (key !== '!ref' && key !== '!margins') {
          const rowId = key.match(/\d+/g).toString();
          const colId = key.match(/[a-zA-Z]+/g).toString();
          if (!spreadsheet[rowId]) {
            spreadsheet[rowId] = {};
          }
          spreadsheet[rowId][colId] = this.worksheet[key].w;
        }
      });
      const columnNames = spreadsheet[1];
      Object.keys(columnNames).forEach((key) => {
        key = key;
        const val = columnNames[key].toLowerCase();
        switch (val) {
          default:
            delete columnNames[key];
            break;
          case 'naam':
            columnNames[key] = 'name';
            break;
          case 'aantal credits':
            columnNames[key] = 'cost';
            break;
          case 'kleur':
            columnNames[key] = 'color';
            break;
          case 'standaard':
            columnNames[key] = 'default';
            break;
          // case 'type':
          //   columnNames[key] = 'type';
          //   break;
        }
      });
      delete spreadsheet[1];
      const importedData = [];
      let totalDefaults = 0;
      Object.keys(spreadsheet).forEach((key) => {
        const rowObj = {};
        Object.keys(spreadsheet[key]).forEach((colKey) => {
          const colName = columnNames[colKey];
          // console.log(spreadsheet[key][colKey]);
          // console.log('colName', colName);
          if (colName) {
            if (colName === 'name') {
              spreadsheet[key][colKey] = spreadsheet[key][colKey];
            }
            if (colName === 'cost') {
              spreadsheet[key][colKey] = Number(spreadsheet[key][colKey]);
            }
            if (colName === 'color') {
              spreadsheet[key][colKey] = spreadsheet[key][colKey];
            }
            if (colName === 'default') {
              spreadsheet[key][colKey] = Boolean(spreadsheet[key][colKey]);
              if (spreadsheet[key][colKey] === true) {
                totalDefaults = totalDefaults + 1;
              }
            }
            // if (colName === 'type') {
            //   spreadsheet[key][colKey] = spreadsheet[key][colKey];
            // }
            rowObj[colName] = spreadsheet[key][colKey];
          }
        });
        console.log('rowObj', rowObj);
        importedData.push(rowObj);
      });
      if (totalDefaults > 1) {
        console.log('tomuch active vehicles');
        const dialogRef = this.dialog.open(WarningDialog, {
          width: '600px',
          data: {
            dialogType: 'importFailedTariffs',
          },
        });
        dialogRef.afterClosed().subscribe((result) => {});
        return;
      }
      this.importedData.next(importedData);
      this.fileChangeListener();
    };
    readFile.readAsArrayBuffer(this.fileUploaded);
  }

  async exportTableToExcel() {
    const tariffsRef = this.db.collection<Tariff>(
      `organisations/${this.organisationId}/products`
    );
    const tariffsArray = [];
    const tariffsObservable = await tariffsRef.get();
    await tariffsObservable.forEach((tariffs) => {
      // console.log('vouchers', vouchers);
      tariffs.forEach((vehicleDoc) => {
        const tariff = vehicleDoc.data() as Tariff;
        const exportTariffObj = {};
        exportTariffObj['Naam'] = tariff.name ? tariff.name : '';
        exportTariffObj['Aantal Credits'] = tariff.cost ? tariff.cost : '';
        exportTariffObj['Kleur'] = tariff.color ? tariff.color : '';
        exportTariffObj['Standaard'] = tariff.default ? tariff.default : '';
        tariffsArray.push(exportTariffObj);
      });
    });
    if (tariffsArray.length > 0) {
      console.log('vouchers', tariffsArray);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(tariffsArray); // converts a DOM TABLE element to a worksheet
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'tariffs');

      // /* save to file */
      XLSX.writeFile(wb, 'tarieven.xlsx');
    } else {
      // Nothing to export
    }
  }

  // Your applications input change listener for the CSV File
  fileChangeListener() {
    this.tariffs = this.tariffsCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Tariff;
          // console.log('tag', data);
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
    this.tariffs.subscribe((value) => {
      console.log('tariffs', value);
    });
    this.importedData.subscribe((value) => {
      console.log('importedData', value);
    });
    try {
      const combinedObservable = combineLatest(this.tariffs, this.importedData);
      combinedObservable
        .pipe(debounceTime(300), distinctUntilChanged())
        .subscribe((res: [TariffId[], Array<any>]) => {
          console.log('res', res);
          const existingTariffs = res[0];
          const importedTariffs = res[1];
          if (importedTariffs === null) {
            return;
          }
          // check for existingVolunteers with importedVolunteers' data, update their info.
          // create new entry for all newly imported volunteers, process account creation in functions trigger.
          importedTariffs.forEach(async (tariffData) => {
            console.log('vehicleData', tariffData);
            let isDefault;
            let cost;
            let name;
            // tslint:disable-next-line: prefer-const
            let color;
            // this section looks for a recognized column title and matches it with the correct userData
            if (tariffData.name) {
              name = tariffData.name;
            }
            if (tariffData.cost) {
              cost = tariffData.cost;
            } else {
              cost = 0;
            }
            if (tariffData.color) {
              color = tariffData.color;
            }
            if (tariffData.default) {
              isDefault = tariffData.default;
            } else {
              isDefault = false;
            }
            const importData: Tariff = {
              cost,
              name,
              color,
              default: isDefault,
            };
            //
            const existingData = existingTariffs.find((element) => {
              return element.name === importData.name;
            });

            console.log('existingData', existingData);
            if (existingData) {
              // update existing doc

              await this.tariffsCollection
                .doc(existingData.id)
                .set(importData, { merge: true });
            } else {
              // create new doc
              await this.tariffsCollection.add(importData);
            }
            this.importedData.next(null);
            this.uploader.nativeElement.value = null;
          });

          this._snackBar.open('De gegevens zijn geïmporteerd', '', {
            duration: 5000,
          });
        });
    } catch (err) {
      console.error(err);
    }
  }
  editTariff(tariff?) {
    const dialogRef = this.dialog.open(CreateEditTariffComponent, {
      width: '300px',
      data: { tariff, tariffs: this.tariffsArray },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  newTariff() {
    const dialogRef = this.dialog.open(CreateEditTariffComponent, {
      width: '300px',
      data: { tariffs: this.tariffsArray },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
}
