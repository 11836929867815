import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { User } from '../interfaces';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-selecting-organisation',
  templateUrl: './selecting-organisation.component.html',
  styleUrls: ['./selecting-organisation.component.scss'],
})
export class SelectingOrganisationComponent implements OnInit {
  userData: User;
  organisationsForm: UntypedFormGroup;
  organisations: any;
  constructor(
    private fb: UntypedFormBuilder,
    private db: AngularFirestore,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.organisationsForm = this.fb.group({
      organisationId: [, Validators.required],
    });

    onAuthStateChanged(getAuth(), async (user) => {
      if (user) {
        this.userData = {
          ...((
            await getDoc(doc(this.db.firestore, `users/${user.uid}`))
          ).data() as User),
          id: `${user.uid}`,
        };

        this.organisations = this.userData.organisations.filter((org) => {
          return org.accountType;
        });
      }
    });
  }

  async selectOrg() {
    const orgId = this.organisationsForm.value.organisationId;
    await setDoc(
      doc(this.db.firestore, `users`, `${this.userData.id}`),
      {
        selectedOrgId: orgId,
      },
      { merge: true }
    );
    this.router.navigate([`/set/${orgId}`]);
  }

  signOutBackToLogin() {
    signOut(getAuth())
      .then(() => {
        return this.router.navigate(['/login']);
      })
      .catch((error) => {
        console.log('logoutError', error);
      });
  }

  showSnackbar(txt: string) {
    this.snackBar.open(txt, 'X', {
      duration: 5000,
    });
  }
}
