<mat-card-title>Ritten in range verwijderen</mat-card-title>

<div class="delete-rides">
  <form [formGroup]="deleteForm">
    <div class="date-from-to">
      <mat-form-field class="rides-from">
        <mat-label>Van</mat-label>
        <input
          matInput
          [matDatepicker]="from"
          formControlName="deleteRidesFrom"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="from"
        ></mat-datepicker-toggle>
        <mat-datepicker #from></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="rides-to">
        <mat-label>Tot en met</mat-label>
        <input
          matInput
          [matDatepicker]="to"
          formControlName="deleteRidesTo"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="to"
        ></mat-datepicker-toggle>
        <mat-datepicker #to></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="warning no-margin">
      <p
        class="no-margin-p"
        *ngIf="deleteRideTxt && !deleteRideTxtError"
      >
        {{ deleteRideTxt }}
      </p>
      <p
        class="no-margin-p"
        *ngIf="deleteRideTxt && !deleteRideTxtError"
      >
        worden alle ritten verwijderd.
      </p>
      <p
        class="agree"
        *ngIf="deleteRideTxt && !deleteRideTxtError"
      >
        Ga je hiermee akkoord?
      </p>
      <p
        class="no-margin-p"
        *ngIf="deleteRideTxtError"
      >
        {{ deleteRideTxtError }}
      </p>
      <span
        fxLayout="row"
        *ngIf="dateCheckPass"
      >
        <mat-icon
          class="green"
          (click)="deleteRidesInRange()"
          >check</mat-icon
        >
        <mat-icon
          class="red"
          (click)="cancelRange()"
          >close</mat-icon
        >
      </span>
    </div>

    <div
      mat-dialog-actions
      align="end"
    >
      <button
        mat-button
        type="button"
        fxLayout="column"
        (click)="close()"
      >
        ANNULEREN
      </button>
      <button
        mat-raised-button
        type="button"
        color="primary"
        fxLayout="column"
        (click)="checkRange()"
        [class.spinner]="deletingRides"
        [disabled]="deletingRides"
      >
        VERWIJDEREN
      </button>
    </div>
  </form>
</div>
