<div class="height-saver"></div>
<mat-toolbar class="p-0 mat-elevation-z1">
  <div
    fxFlex
    fxFill
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <div
      fxFlex="1 0 auto"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <div
        #toggleButton
        *ngIf="accountType !== 'driver'"
      >
        <button
          mat-icon-button
          (click)="sidenav.toggle()"
        >
          <mat-icon class="s-16">menu</mat-icon>
        </button>
      </div>

      <button
        mat-button
        [matMenuTriggerFor]="orgMenu"
        class="user-button"
        *ngIf="organisation"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <div
            *ngIf="organisation.name"
            class="primary-bg avatar-placeholder"
          >
            {{ organisation.name[0] }}
          </div>
          <span
            *ngIf="organisation.name"
            class="username mr-0 mr-sm-16"
            fxHide
            fxShow.gt-xs
          >
            {{ organisation.name }}
          </span>
          <mat-icon
            class="s-16"
            *ngIf="organisations.length > 1"
            >keyboard_arrow_down</mat-icon
          >
        </div>
      </button>

      <mat-menu
        #orgMenu="matMenu"
        [overlapTrigger]="false"
      >
        <div *ngFor="let org of organisations">
          <button
            (click)="setOrganisation(org, true)"
            class="org-button"
            mat-menu-item
            *ngIf="org.accountType"
          >
            <div class="primary-bg avatar-placeholder small-placeholder">
              {{ org.name[0] }}
            </div>
            <span>{{ org.name }}</span>
          </button>
        </div>
      </mat-menu>
    </div>

    <div
      class="navbar-separator"
      fxHide.gt-xs
    ></div>

    <div
      class=""
      fxFlex="0 1 auto"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <button
        mat-button
        [matMenuTriggerFor]="userMenu"
        class="user-button"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <div
            *ngIf="userData?.email"
            class="accent-bg avatar-placeholder"
          >
            {{ userData.email[0] }}
          </div>
          <span
            *ngIf="userData?.email"
            class="username mr-0 mr-sm-16"
            fxHide
            fxShow.gt-xs
          >
            {{ userData.email }}
          </span>
          <mat-icon class="s-16">keyboard_arrow_down</mat-icon>
        </div>
      </button>

      <mat-menu
        #userMenu="matMenu"
        [overlapTrigger]="false"
      >
        <button
          mat-menu-item
          (click)="logout()"
        >
          <mat-icon>exit_to_app</mat-icon>
          <span>Uitloggen</span>
        </button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>
<mat-sidenav-container *ngIf="accountType !== 'driver'">
  <mat-sidenav
    #sidenav
    mode="side"
    closed
    class="sidenav"
    [fixedInViewport]="true"
    [fixedTopGap]="0"
    [fixedBottomGap]="0"
  >
    <div
      #menu
      class="side-nav"
      fxLayout="column"
    >
      <div
        class="side-nav-top"
        fxFlex
        fxFill
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <div
          class="header-logo"
          fxFlex="1 0 auto"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <img
            class="logo"
            [src]="publicSettingsPlanningMenuImage"
            class="logo"
          />
        </div>
        <div
          class="menu-button"
          fxFlex="0 1 auto"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <button
            mat-icon-button
            (click)="sidenav.toggle()"
          >
            <mat-icon class="s-16">menu</mat-icon>
          </button>
        </div>
      </div>
      <div class="side-nav-content">
        <div
          class="content"
          *ngIf="showDriverPlanning"
        >
          <button
            class="button"
            mat-flat-button
            routerLink="/availability"
          >
            <mat-icon>perm_contact_calendar</mat-icon>
            <span>Rooster</span>
          </button>
        </div>
        <div class="content">
          <button
            class="button"
            mat-flat-button
            routerLink="/users"
          >
            <mat-icon>people</mat-icon>
            <span>Deelnemers</span>
          </button>
        </div>
        <div class="content">
          <button
            class="button"
            mat-flat-button
            routerLink="/planner"
          >
            <mat-icon>calendar_month</mat-icon>
            <span>Planning</span>
          </button>
        </div>
        <div class="content">
          <button
            class="button"
            mat-flat-button
            routerLink="/deletedRides"
          >
            <mat-icon>delete</mat-icon>
            <span>Prullenbak</span>
          </button>
        </div>
        <div class="content">
          <mat-expansion-panel
            mat-flat-button
            class="button"
            style="box-shadow: none"
          >
            <mat-expansion-panel-header
              class="side-expension"
              [collapsedHeight]="'37.51px'"
              [expandedHeight]="'37.51px'"
            >
              <mat-panel-title
                class="button"
                fxLayoutAlign="start center"
              >
                <mat-icon>settings</mat-icon>
                Instellingen
              </mat-panel-title>
            </mat-expansion-panel-header>
            <button
              *ngIf="userData?.rights === 'admin'"
              class="button-expanded"
              mat-flat-button
              routerLink="/admin-tools"
            >
              <span>Admin tools</span>
            </button>
            <button
              *ngIf="accountType === 'admin'"
              class="button-expanded"
              mat-flat-button
              routerLink="/settings"
            >
              <span>Algemeen</span>
            </button>
            <button
              *ngIf="accountType === 'admin' || accountType === 'planner'"
              class="button-expanded"
              mat-flat-button
              routerLink="/audit"
            >
              <span>Audit</span>
            </button>
            <button
              *ngIf="accountType === 'admin' && !ignoreDriverStatus"
              class="button-expanded"
              mat-flat-button
              routerLink="/dayparts"
            >
              <span>Dagdelen</span>
            </button>
            <button
              *ngIf="accountType === 'admin'"
              class="button-expanded"
              mat-flat-button
              routerLink="/admin-users"
            >
              <span>Gebruikers</span>
            </button>
            <button
              *ngIf="accountType === 'admin'"
              class="button-expanded"
              mat-flat-button
              routerLink="/settingsDebitOrders"
            >
              <span>Incasso historie</span>
            </button>
            <button
              *ngIf="accountType === 'admin' && characteristicsStatus"
              class="button-expanded"
              mat-flat-button
              routerLink="/characteristics"
            >
              <span>Kenmerken</span>
            </button>
            <button
              *ngIf="accountType === 'admin'"
              class="button-expanded"
              mat-flat-button
              routerLink="/settingsTariffs"
            >
              <span>Tarieven</span>
            </button>
            <button
              *ngIf="accountType === 'admin'"
              class="button-expanded"
              mat-flat-button
              routerLink="/settingsPhoneNumbers"
            >
              <span>Telefoonnummers</span>
            </button>
            <button
              *ngIf="accountType === 'admin'"
              class="button-expanded"
              mat-flat-button
              routerLink="/frequentAddress"
            >
              <span>Veelvoorkomende adressen</span>
            </button>
            <button
              *ngIf="accountType === 'admin'"
              class="button-expanded"
              mat-flat-button
              routerLink="/settingsVehicles"
            >
              <span>Voertuigen</span>
            </button>
            <button
              *ngIf="accountType === 'admin'"
              class="button-expanded"
              mat-flat-button
              routerLink="/settingsVehicleGroups"
            >
              <span>Voertuiggroepen</span>
            </button>
          </mat-expansion-panel>
        </div>
      </div>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
