import { NgModule } from '@angular/core';
import { Routes, Router, RouterModule } from '@angular/router';
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
} from '@angular/fire/compat/auth-guard';
import { LoginComponent } from './auth/login/login.component';
import { PlannerComponent } from './planner/planner.component';
import { UsersComponent } from './users/users.component';
import { AuditComponent } from '../app/settings/audit/audit.component';
import { environment } from 'src/environments/environment';
import { FrequentAddressComponent } from '../app/settings/frequent-address/frequent-address.component';
import { CharacteristicsComponent } from '../app/settings/characteristics/characteristics.component';
import { SettingsComponent } from './settings/general-settings/settings.component';
import { VehiclesComponent } from './settings/vehicles/vehicles.component';
import { TariffsComponent } from './settings/tariffs/tariffs.component';
import { AdminUsersComponent } from './admin-users/admin-users.component';
import { AvailabilityDriverComponent } from './availability-driver/availability-driver.component';
import { DayPartsComponent } from './settings/day-parts/day-parts.component';
import { PhoneNumbersComponent } from './settings/phone-numbers/phone-numbers.component';
import { AdminToolsComponent } from './settings/admin-tools/admin-tools.component';
import { VehicleGroupsComponent } from './settings/vehicle-groups/vehicle-groups.component';
import { SetupPaymentMethodsComponent } from './settings/admin-tools/tools/setup-payment-methods/setup-payment-methods.component';
import { SelectingOrganisationComponent } from './selecting-organisation/selecting-organisation.component';
import { DebitOrdersComponent } from './settings/debit-orders/debit-orders.component';
import { SetOrganisationComponent } from './set-organisation/set-organisation.component';
import { DeletedRidesComponent } from './deleted-rides/deleted-rides.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedIn = () => redirectLoggedInTo(['planner']);
// const adminOnly = () =>
//   pipe(
//     customClaims,
//     map((claims) => {
//       console.log('claims', claims);
//       if (claims.accountType === 'admin') {
//         return true;
//       } else {
//         return [redirectUnauthorizedToLogin];
//       }
//     })
//   );
// const plannerAndAdmin = () =>
//   pipe(
//     customClaims,
//     map((claims) => {
//       console.log('claims', claims);
//       if (claims.accountType === 'admin' || claims.accountType === 'planner') {
//         return true;
//       } else {
//         return [redirectUnauthorizedToLogin];
//       }
//     })
//   );
// tslint:disable-next-line: prefer-const
let routes: Routes = [];
const allRoutes = {
  dayparts: {
    path: 'dayparts',
    component: DayPartsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedTo },
  },
  availability: {
    path: 'availability',
    component: AvailabilityDriverComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedTo },
  },
  login: {
    path: 'login',
    component: LoginComponent,
  },
  planner: {
    path: 'planner',
    component: PlannerComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  users: {
    path: 'users',
    component: UsersComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  adminUsers: {
    path: 'admin-users',
    component: AdminUsersComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  adminTools: {
    path: 'admin-tools',
    component: AdminToolsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  adminToolsSetupPaymentMethods: {
    path: 'admin-tools/setup-payment-methods',
    component: SetupPaymentMethodsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  frequentAddress: {
    path: 'frequentAddress',
    component: FrequentAddressComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  characteristics: {
    path: 'characteristics',
    component: CharacteristicsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  audit: {
    path: 'audit',
    component: AuditComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  deletedRides: {
    path: 'deletedRides',
    component: DeletedRidesComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  settings: {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  settingsVehicles: {
    path: 'settingsVehicles',
    component: VehiclesComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  settingsPhoneNumbers: {
    path: 'settingsPhoneNumbers',
    component: PhoneNumbersComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  settingsTariffs: {
    path: 'settingsTariffs',
    component: TariffsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  settingsDebitOrders: {
    path: 'settingsDebitOrders',
    component: DebitOrdersComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  settingsVehicleGroups: {
    path: 'settingsVehicleGroups',
    component: VehicleGroupsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  selectingOrganisation: {
    path: 'selectingOrganisation',
    component: SelectingOrganisationComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  set: {
    path: 'set/:orgId',
    component: SetOrganisationComponent,
  },
};

// environment.routes.forEach(route => {
//   routes.push(allRoutes[route]);
// });
// routes.push({
//   path: '**',
//   redirectTo: 'profile',
//   canActivate: [AngularFireAuthGuard],
//   data: { authGuardPipe: redirectUnauthorizedToLogin },
// });

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(router: Router) {
    const config = router.config;
    environment.routes.forEach((route) => {
      config.push(allRoutes[route]);
    });
    config.push({
      path: '**',
      redirectTo: 'planner',
      // canActivate: [AngularFireAuthGuard],
      data: { authGuardPipe: redirectUnauthorizedToLogin },
    });
    router.resetConfig(config);
  }
}
