import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { User } from '../interfaces';
import { saveOrgInfoLocal } from '../globals';
import { getAuth, signOut } from 'firebase/auth';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-set-organisation',
  templateUrl: './set-organisation.component.html',
  styleUrls: ['./set-organisation.component.scss'],
})
export class SetOrganisationComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public auth: AngularFireAuth,
    public db: AngularFirestore,
    private snackBar: MatSnackBar
  ) {}

  async ngOnInit() {
    // to set organisation use: /set-organisation/wemaron
    const orgId = this.route.snapshot.paramMap.get('orgId');
    if (orgId) {
      localStorage.setItem('orgId', orgId);
      const shouldReload = await saveOrgInfoLocal(orgId);
      console.log('shouldReload', shouldReload);
      if (shouldReload) {
        window.location.reload();
      }
      console.log('setting org:', orgId);
      this.auth.onAuthStateChanged(async (user) => {
        console.log('user', user);
        if (user) {
          const userRef = doc(this.db.firestore, `users/${user.uid}`);
          console.log('userRef', userRef.path);
          console.log('user uid', user.uid);
          let userData = (await getDoc(userRef)).data() as User;
          console.log('userData', userData);
          if (!userData) {
            userData = {};
          }
          if (!userData.organisations) {
            userData.organisations = [];
          }
          console.log('userData.organisations', userData.organisations);
          const userOrg = userData.organisations.find((x) => x.id === orgId);

          if (!userOrg?.accountType) {
            this.showSnackbar(
              'Het is niet mogelijk om als gebruiker in te loggen op de plan omgeving'
            );
            return this.signOutBackToLogin;
          }

          if (userOrg?.accountType === 'inactive') {
            this.showSnackbar('Je hebt geen rechten om in te loggen');
            return this.signOutBackToLogin;
          }
          localStorage.setItem('accType', userOrg.accountType);
          await setDoc(
            doc(this.db.firestore, `users`, `${user.uid}`),
            {
              selectedOrgId: orgId,
            },
            { merge: true }
          );

          console.log('userOrg', userOrg);
          switch (userOrg.accountType) {
            case 'driver':
              this.router.navigate(['/availability']);
              break;
            case 'planner':
            case 'admin':
              this.router.navigate(['/planner']);
              break;
          }
        } else {
          localStorage.setItem('refresh', '0');
          this.router.navigate(['/login']);
        }
      });
    }
  }

  signOutBackToLogin() {
    signOut(getAuth())
      .then(() => {
        return this.router.navigate(['/login']);
      })
      .catch((error) => {
        console.log('logoutError', error);
      });
  }

  showSnackbar(txt: string) {
    this.snackBar.open(txt, 'X', {
      duration: 5000,
    });
  }
}
