<h1 mat-dialog-title>
  Bewerkingen
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</h1>
<p><b>Nieuwste - oudste</b></p>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <div *ngFor="let edit of allEdits | async">
    <div *ngFor="let change of edit.changes">
      <div *ngIf="change.field === 'addedUsers'">
        <div style="display: flex">
          <div>
            <p><b>Bewerker:</b></p>
            <p><b>Actie:</b></p>
            <p><b>Bewerking:</b></p>
          </div>
          <div class="action-list">
            <p class="edit-info">
              <span>{{ edit.editedByEmail }}</span>
              <span>{{ edit.date.toDate() | date: 'short' }}</span>
            </p>
            <p>Personen toegevoegd</p>
            <div *ngFor="let user of change.users">
              <p>
                <b>{{ user.title }}</b>
              </p>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>
      <div *ngIf="change.field === 'createdRide'">
        <div style="display: flex">
          <div>
            <p><b>Bewerker:</b></p>
            <p><b>Actie:</b></p>
          </div>
          <div class="action-list">
            <p class="edit-info">
              <span>{{ edit.editedByEmail }}</span>
              <span>{{ edit.date.toDate() | date: 'short' }}</span>
            </p>
            <p>{{ change.text }}</p>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>
      <div *ngIf="change.field === 'copiedRide'">
        <div style="display: flex">
          <div>
            <p><b>Bewerker:</b></p>
            <p><b>Actie:</b></p>
          </div>
          <div class="action-list">
            <p class="edit-info">
              <span>{{ edit.editedByEmail }}</span>
              <span>{{ edit.date.toDate() | date: 'short' }}</span>
            </p>
            <p>{{ change.text }}</p>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>
      <div *ngIf="change.field === 'removedUsers'">
        <div style="display: flex">
          <div>
            <p><b>Bewerker:</b></p>
            <p><b>Actie:</b></p>
            <p><b>Bewerking:</b></p>
          </div>
          <div class="action-list">
            <p class="edit-info">
              <span>{{ edit.editedByEmail }}</span>
              <span>{{ edit.date.toDate() | date: 'short' }}</span>
            </p>
            <p>Personen verwijderd</p>
            <div *ngFor="let user of change.users">
              <p>
                <b>{{ user.title }}</b>
              </p>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>
      <div
        *ngIf="change.field !== 'addedUsers' && change.field !== 'removedUsers'"
      >
        <div *ngIf="change.field === 'comments'">
          <div style="display: flex">
            <div>
              <p><b>Bewerker:</b></p>
              <p><b>Actie:</b></p>
              <p><b>Bewerking:</b></p>
            </div>
            <div class="action-list">
              <p class="edit-info">
                <span>{{ edit.editedByEmail }}</span>
                <span>{{ edit.date.toDate() | date: 'short' }}</span>
              </p>
              <p>Opmerkingen</p>
              <p>{{ change.from }}</p>
              <span style="display: flex">
                <mat-icon>arrow_downward</mat-icon>
                <span class="arrow-text"> is nu</span>
              </span>
              <p>
                <b>{{ change.to }}</b>
              </p>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div *ngIf="change.field === 'start'">
          <div style="display: flex">
            <div>
              <p><b>Bewerker:</b></p>
              <p><b>Actie:</b></p>
              <p><b>Bewerking:</b></p>
            </div>
            <div class="action-list">
              <p class="edit-info">
                <span>{{ edit.editedByEmail }}</span>
                <span>{{ edit.date.toDate() | date: 'short' }}</span>
              </p>
              <p>Begindatum</p>
              <p>{{ change.from.toDate() | date: 'medium' }}</p>
              <span style="display: flex">
                <mat-icon>arrow_downward</mat-icon>
                <span class="arrow-text"> is nu</span>
              </span>
              <p>
                <b>{{ change.to.toDate() | date: 'medium' }}</b>
              </p>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div *ngIf="change.field === 'end'">
          <div style="display: flex">
            <div>
              <p><b>Bewerker:</b></p>
              <p><b>Actie:</b></p>
              <p><b>Bewerking:</b></p>
            </div>
            <div class="action-list">
              <p class="edit-info">
                <span>{{ edit.editedByEmail }}</span>
                <span>{{ edit.date.toDate() | date: 'short' }}</span>
              </p>
              <p>Einddatum</p>
              <p>{{ change.from.toDate() | date: 'medium' }}</p>
              <span style="display: flex">
                <mat-icon>arrow_downward</mat-icon>
                <span class="arrow-text"> is nu</span>
              </span>
              <p>
                <b>{{ change.to.toDate() | date: 'medium' }}</b>
              </p>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div *ngIf="change.field === 'rideProduct'">
          <div style="display: flex">
            <div>
              <p><b>Bewerker:</b></p>
              <p><b>Actie:</b></p>
              <p><b>Bewerking:</b></p>
            </div>
            <div class="action-list">
              <p class="edit-info">
                <span>{{ edit.editedByEmail }}</span>
                <span>{{ edit.date.toDate() | date: 'short' }}</span>
              </p>
              <p>Ritprijs</p>
              <p>{{ change.from.name }} - {{ change.from.cost }} Credits</p>
              <span style="display: flex">
                <mat-icon>arrow_downward</mat-icon>
                <span class="arrow-text"> is nu</span>
              </span>
              <p>
                <b>{{ change.to.name }} - {{ change.to.cost }} Credits</b>
              </p>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div *ngIf="change.field === 'special'">
          <div style="display: flex">
            <div>
              <p><b>Bewerker:</b></p>
              <p><b>Actie:</b></p>
              <p><b>Bewerking:</b></p>
            </div>
            <div class="action-list">
              <p class="edit-info">
                <span>{{ edit.editedByEmail }}</span>
                <span>{{ edit.date.toDate() | date: 'short' }}</span>
              </p>
              <p>Rittype</p>
              <p>{{ change.from }}</p>
              <span style="display: flex">
                <mat-icon>arrow_downward</mat-icon>
                <span class="arrow-text"> is nu</span>
              </span>
              <p>
                <b>{{ change.to }}</b>
              </p>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div *ngIf="change.field === 'vehicleId'">
          <div style="display: flex">
            <div>
              <p><b>Bewerker:</b></p>
              <p><b>Actie:</b></p>
              <p><b>Bewerking:</b></p>
            </div>
            <div class="action-list">
              <p class="edit-info">
                <span>{{ edit.editedByEmail }}</span>
                <span>{{ edit.date.toDate() | date: 'short' }}</span>
              </p>
              <p>Voertuig</p>
              <p>{{ change.from }}</p>
              <span style="display: flex">
                <mat-icon>arrow_downward</mat-icon>
                <span class="arrow-text"> is nu</span>
              </span>
              <p>
                <b>{{ change.to }}</b>
              </p>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div *ngIf="change.field === 'fromLocation'">
          <div style="display: flex">
            <div>
              <p><b>Bewerker:</b></p>
              <p><b>Actie:</b></p>
              <p><b>Bewerking:</b></p>
            </div>
            <div class="action-list">
              <p class="edit-info">
                <span>{{ edit.editedByEmail }}</span>
                <span>{{ edit.date.toDate() | date: 'short' }}</span>
              </p>
              <p>Startpunt</p>
              <p>{{ change.from }}</p>
              <span style="display: flex">
                <mat-icon>arrow_downward</mat-icon>
                <span class="arrow-text"> is nu</span>
              </span>
              <p>
                <b>{{ change.to }}</b>
              </p>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div *ngIf="change.field === 'toLocation'">
          <div style="display: flex">
            <div>
              <p><b>Bewerker:</b></p>
              <p><b>Actie:</b></p>
              <p><b>Bewerking:</b></p>
            </div>
            <div class="action-list">
              <p class="edit-info">
                <span>{{ edit.editedByEmail }}</span>
                <span>{{ edit.date.toDate() | date: 'short' }}</span>
              </p>
              <p>Bestemming</p>
              <p>{{ change.from }}</p>
              <span style="display: flex">
                <mat-icon>arrow_downward</mat-icon>
                <span class="arrow-text"> is nu</span>
              </span>
              <p>
                <b>{{ change.to }}</b>
              </p>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="(allEdits | async)?.length === 0">
  <p><i>Er zijn geen bewerkingen aanwezig</i></p>
</div>
