import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';

export interface DialogData {
  notification: boolean;
  noDeleteReason: boolean;
}
@Component({
  selector: 'app-delete-ride',
  templateUrl: './delete-ride.component.html',
  styleUrls: ['./delete-ride.component.scss'],
})
export class DeleteRideComponent implements OnInit {
  deleteForm: UntypedFormGroup = this.fb.group({
    deleteReason: ['', [Validators.required]],
  });
  radioOptions = [
    'Geannuleerd door deelnemer',
    'Geannuleerd door organisatie',
    'Geen voertuig/chauffeur beschikbaar',
    'Anders:',
  ];
  defaultOption = 'Anders:';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private deleteRef: MatDialogRef<DeleteRideComponent>,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    if (this.data.noDeleteReason) {
      this.deleteForm.controls.deleteReason.removeValidators(Validators.required);
      this.deleteForm.controls.deleteReason.updateValueAndValidity();
    }
  }

  checkState(el: MatRadioChange) {
    if (el.value == 'Anders:') {
      this.deleteForm.controls.deleteReason.setValue('');
      this.deleteForm.controls.deleteReason.enable();
    } else {
      this.deleteForm.controls.deleteReason.setValue(el.value);
      this.deleteForm.controls.deleteReason.disable();
    }
  }
}
