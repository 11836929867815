import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../interfaces';

@Component({
  selector: 'app-availability-driver',
  templateUrl: './availability-driver.component.html',
  styleUrls: ['./availability-driver.component.scss'],
})
export class AvailabilityDriverComponent implements OnInit {
  organisationId = localStorage.getItem('orgId');
  name: string;
  accountType: string;

  generalSettings: any;
  isDriver: boolean;
  drivers: User[] = [];
  userId: string;
  driverObs: Observable<User[]>;
  driversModifyAvailability: boolean;

  selectedName: string;
  currentUser: User;

  public newDrivers: Subject<User[]> = new Subject();
  constructor(
    private afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit() {
    this.redirectIfNotUsingDriverPlanning();
    this.userId = this.route.snapshot.paramMap.get('userId');
    console.log('this.userId', this.userId);
    this.generalSettings = (
      await this.db
        .doc(`organisations/${this.organisationId}/settings/general`)
        .get()
        .toPromise()
    ).data();
    this.driversModifyAvailability =
      this.generalSettings.driversModifyAvailability;
    console.log('driversModifyAvailability', this.driversModifyAvailability);
    this.afAuth.user.subscribe(async (authUser) => {
      if (authUser) {
        console.log('authUser', authUser.uid);
        const newUser = (await (
          await this.db
            .doc(`organisations/${this.organisationId}/users/${authUser.uid}`)
            .get()
            .toPromise()
        ).data()) as User; //
        this.name = newUser.name;
        switch (newUser.accountType) {
          case 'driver':
            this.isDriver = true;
            this.userId = authUser.uid;
            this.accountType = 'Chauffeur';
            break;
          case 'planner':
            this.isDriver = false;
            this.accountType = 'Planner';
            break;
          case 'admin':
            this.isDriver = false;
            this.accountType = 'Administrator';
            break;
        }
        console.log('isDriver', this.isDriver);
        if (newUser.accountType !== 'driver') {
          this.driverObs = this.db
            .collection<any>(
              `organisations/${this.organisationId}/users`,
              (ref) => ref.where('accountType', '==', 'driver').orderBy('name')
            )
            .snapshotChanges()
            .pipe(
              map((actions) =>
                actions.map((a, index) => {
                  const data = a.payload.doc.data() as any;
                  const id = a.payload.doc.id;
                  if (!this.userId) {
                    if (index === 0) {
                      data.selected = true;
                      this.userId = id;
                      this.selectedName = data.name;
                    } else {
                      data.selected = false;
                    }
                  }

                  if (this.userId === id) {
                    this.selectedName = data.name;
                    this.currentUser = data;
                    console.log('data', data);
                  }
                  let dupFound = false;
                  if (this.drivers.find((x) => x.id === id)) {
                    dupFound = true;
                  }

                  if (!dupFound) {
                    this.drivers.push({ id, ...data });
                  }
                  return { id, ...data };
                })
              )
            );
          this.driverObs.subscribe((driver) => {
            console.log('driver in subscribe', driver);
            // this.newDrivers.next([]);
            this.newDrivers.next(driver);
            if (!this.currentUser) {
              this.currentUser = driver[0];
            }
          });
        }
      }
    });
  }

  changeUser(driver: any) {
    this.userId = driver.id;
    this.selectedName = driver.name;
    this.drivers.map((driver: any) =>
      driver.id === this.userId
        ? (driver.selected = true)
        : (driver.selected = false)
    );
    console.log('drivers', this.drivers);
    console.log('driver', driver);
    this.currentUser = driver;
    // this.newDrivers.next([]);
    this.newDrivers.next(this.drivers);
  }

  redirectIfNotUsingDriverPlanning() {
    const usesDriverPlanning = JSON.parse(
      localStorage.getItem('usesDriverPlanning')
    );
    if (!usesDriverPlanning) {
      this.router.navigateByUrl('/planner');
    }
  }
}
