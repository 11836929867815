<h1 mat-dialog-title>Deelnemers importeren/exporteren</h1>
<div mat-dialog-content>
  <form [formGroup]="formGroup">
    <mat-radio-group
      fxLayout="column"
      fxLayoutGap="8px"
      formControlName="type"
    >
      <mat-radio-button
        *ngFor="let option of typeOptions"
        [value]="option.value"
        [disabled]="option.disabled"
      >
        {{ option.name }}
      </mat-radio-button>
    </mat-radio-group>
    <mat-form-field *ngIf="formGroup.value.type === 'export'">
      <mat-label>Welke deelnemers wil je exporteren?</mat-label>
      <mat-select formControlName="allOrSome">
        <mat-option
          *ngFor="let option of allOrSomeOptions"
          [value]="option.value"
        >
          {{ option.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-error *ngIf="formGroup.value.type === 'import'">
      Let op: wanneer er wordt geimporteerd wordt er een match gemaakt met de
      "Deelnemer ID", als deze niet bestaat gaan wij ervan uit dat het gaat om
      een nieuwe deelnemer, wanneer dit gebeurt proberen wij het account eerst
      aan te maken gebaseerd op e-mail, indien deze niet bestaat gebruiken wordt
      het telefoonnummer gebruikt, hierbij ondersteunen we: 0611223344 &
      +31611223344. Ook zijn er een aantal velden die niet overschreven worden
      door deze import functionaliteit, hieronder vallen: Plan naam, Gemaakt op,
      Account type, Credits.
    </mat-error>
    <app-file-upload
      *ngIf="formGroup.value.type === 'import'"
      formControlName="importFile"
    ></app-file-upload>
  </form>
</div>
<div
  mat-dialog-actions
  align="end"
>
  <button
    mat-button
    mat-dialog-close
  >
    Annuleren
  </button>
  <button
    *ngIf="formGroup.value.type === 'export'"
    mat-raised-button
    color="primary"
    (click)="export()"
    [disabled]="formGroup.invalid"
  >
    Exporteren
  </button>
  <button
    *ngIf="formGroup.value.type === 'import'"
    mat-raised-button
    color="primary"
    (click)="import()"
    [disabled]="formGroup.invalid && !importFileWorksheet"
    [class.spinner]="processing"
  >
    Importeren
  </button>
</div>
