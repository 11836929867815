import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { PhoneNumbersComponent } from '../../../phone-numbers/phone-numbers.component';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
  FormControl,
} from '@angular/forms';
import { Observable } from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
// import { ThemePalette } from '@angular/material';
import { Setting, Number } from './../../../../interfaces';
import { CustomValidators } from 'src/app/validators/custom-validators';

@Component({
  selector: 'app-create-edit-phone-numbers',
  templateUrl: './create-edit-phone-numbers.component.html',
  styleUrls: ['./create-edit-phone-numbers.component.scss'],
})
export class CreateEditPhoneNumbersComponent implements OnInit {
  phoneForm: UntypedFormGroup;
  number: Number;
  isNew: boolean;
  settingDoc: AngularFirestoreDocument<Setting>;
  setting: Observable<Setting>;
  organisationsId = localStorage.getItem('orgId');
  constructor(
    private fb: UntypedFormBuilder,
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PhoneNumbersComponent>
  ) {}

  async ngOnInit() {
    this.phoneForm = this.fb.group({
      order: [],
      name: ['', Validators.required],
      phone: ['', Validators.required, CustomValidators.phoneValidator],
    });
    this.settingDoc = this.db.doc<Setting>(
      `organisations/${this.organisationsId}/settings/general`
    );
    this.setting = this.settingDoc.valueChanges();
    this.setting.subscribe((val) => {
      console.log('this.settings', val);
    });
    if (this.data.number) {
      this.number = this.data.number;
      console.log('number', this.number);
      this.phoneForm.controls.order.setValue(this.number.order);
      this.phoneForm.controls.name.setValue(this.number.name);
      this.phoneForm.controls.phone.setValue(this.number.phone);
    } else {
      this.isNew = true;
      const numberCount = (
        await this.db
          .collection(`organisations/${this.organisationsId}/numbers`)
          .get()
          .toPromise()
      ).docs.length;
      this.phoneForm.controls.order.setValue(numberCount + 1);
    }
    console.log('this.taskForm', this.phoneForm.value);
  }

  async onSubmit() {
    console.log('itemForm', this.phoneForm);
    const formValue = this.phoneForm.value;
    let itemId;
    console.log('formValue', formValue);
    // tslint:disable-next-line: prefer-const
    if (this.isNew) {
      itemId = this.db.createId();
    } else {
      itemId = this.number.id;
    }

    let submitItem = formValue;

    await this.saveItem(itemId, submitItem);
  }
  async saveItem(itemId, submitItem) {
    console.log('submitItem', submitItem);
    if (submitItem.order) {
      submitItem.order = Number(submitItem.order);
    }
    await this.db
      .collection(`organisations/${this.organisationsId}/numbers`)
      .doc(itemId)
      .set(submitItem, { merge: true });
    this.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  close(): void {
    this.dialogRef.close();
  }
}
