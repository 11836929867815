<div class="wrapper">
  <mat-card>
    <mat-card-content>
      <mat-list role="list">
        <h3>
          Selecteer de tijdvakken waarin jij normaal gezien beschikbaar bent
        </h3>
        <mat-list-item
          fxLayout="row"
          fxLayoutAlign="space-between center"
          *ngFor="let daypart of newDayparts"
        >
          <div
            fxLayout="row"
            class="driver-planned-dayparts"
            fxLayoutAlign="space-between center"
          >
            <div class="startTime" fxLayout="column">
              <h4>{{ daypart.name }}</h4>
              <div
                fxLayout="row"
                class="dayparts"
                fxLayoutAlign="space-between center"
              >
                <div fxLayout="row" class="availibility-driver">
                  <h5>
                    Van:
                    {{ daypart.startHour }}:{{ daypart.startMin }}
                  </h5>
                  <h5>
                    Tot:
                    {{ daypart.endHour }}:{{ daypart.endMin }}
                  </h5>
                </div>
                <div fxFlexAlign="center">
                  <mat-slide-toggle
                    (change)="toggleChange($event, daypart.id)"
                    [disabled]="isDriver && !driverCanModify"
                    [checked]="daypart.checked"
                  >
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item *ngIf="newDayparts.length === 0 && loading === false">
          <p>Er zijn nog geen dagdelen aangemaakt.</p>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>
</div>
