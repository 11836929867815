import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Ride, Product, User } from 'src/app/interfaces';
import { FuncsService } from 'src/app/services/funcs.services';
import { getAuth } from 'firebase/auth';
import { getDoc, doc, deleteField } from 'firebase/firestore';

export interface DialogData {
  ride: Ride;
}

@Component({
  selector: 'app-copy-ride',
  templateUrl: './copy-ride.component.html',
  styleUrls: ['./copy-ride.component.scss'],
})
export class CopyRideComponent implements OnInit {
  copyForm: UntypedFormGroup;
  copyInProgress: boolean;
  organisationId = localStorage.getItem('orgId') as string;
  parentRideId: string;
  userId: string;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CopyRideComponent>,
    private fb: UntypedFormBuilder,
    private funcs: FuncsService
  ) {}

  ngOnInit() {
    this.userId = getAuth().currentUser.uid;
    this.parentRideId = this.data.ride.parentRideId
      ? this.data.ride.parentRideId
      : this.data.ride.id;
    this.copyInProgress = false;
    console.log('ride', this.data.ride);
    this.copyForm = this.fb.group({
      type: ['weekly', Validators.required],
      times: [1, Validators.required],
    });
  }

  async copy() {
    if (!this.copyInProgress) {
      this.copyInProgress = true;
      let i;
      const batch = this.db.firestore.batch();
      batch.update(
        this.db
          .collection(`organisations/${this.organisationId}/rides`)
          .doc(this.data.ride.id).ref,
        {
          parentRideId: this.parentRideId,
          hasCopy: true,
        }
      );
      const user = (
        await getDoc(
          doc(
            this.db.firestore,
            `organisations/${this.organisationId}/users/${this.userId}`
          )
        )
      ).data() as User;
      const products = (
        await this.db.firestore
          .collection(`organisations/${this.organisationId}/products`)
          .get()
      ).docs;
      console.log('products', products);
      for (i = 0; i < this.copyForm.value.times; i++) {
        console.log('i while copying', i);
        const newRideRef = this.db
          .collection(`organisations/${this.organisationId}/rides`)
          .doc(this.db.createId());
        // const ride = Object.assign({}, this.data.ride);
        const ride = { ...this.data.ride };
        delete ride.remarkFromDriver;
        ride.hasCopy = true;
        const rideStartDate = ride.start;
        const rideEndDate = ride.end;
        const rideStartDay = rideStartDate.getDate();
        const rideEndDay = rideEndDate.getDate();
        if (this.copyForm.value.type === 'daily') {
          const startDate = rideStartDate.setDate(rideStartDay + 1);
          const endDate = rideEndDate.setDate(rideEndDay + 1);
          ride.start = new Date(startDate);
          ride.end = new Date(endDate);
        } else if (this.copyForm.value.type === 'weekly') {
          const startDate = rideStartDate.setDate(rideStartDay + 7);
          const endDate = rideEndDate.setDate(rideEndDay + 7);
          ride.start = new Date(startDate);
          ride.end = new Date(endDate);
        } else if (this.copyForm.value.type === 'everyotherweek') {
          const startDate = rideStartDate.setDate(rideStartDay + 14);
          const endDate = rideEndDate.setDate(rideEndDay + 14);
          ride.start = new Date(startDate);
          ride.end = new Date(endDate);
        }

        let createObjArray = [];
        let createObj = {};

        createObjArray.push({
          field: 'copiedRide',
          text: 'Rit aangemaakt door middel van kopiëren',
        });

        createObj = {
          changes: createObjArray,
          date: new Date(),
          editedByEmail: user.email,
          editedByUid: this.userId,
        };
        console.log('createObj', createObj);
        await newRideRef.collection('edits').add(createObj);

        ride.year = ride.start.getFullYear();
        ride.month = ride.start.getMonth();
        ride.week = this.funcs.getWeekString(ride.start);
        ride.day = ride.start.getDate();
        if (ride.rideProduct) {
          const selectedProduct = products.find((product) => {
            return product.id === ride.rideProduct.id;
          });
          if (selectedProduct) {
            ride.rideProduct = {
              ...selectedProduct.data(),
              id: selectedProduct.id,
            } as Product;
          }
        }
        for (const [userId, user] of Object.entries(ride.users)) {
          delete user.remarkFromDriver;
          if (user.rideProduct) {
            const selectedProduct = products.find((product) => {
              return product.id === user.rideProduct.id;
            });
            if (selectedProduct) {
              user.rideProduct = {
                ...selectedProduct.data(),
                id: selectedProduct.id,
              } as Product;
            }
          }
          delete user.paid;
          delete user.charge;
        }
        console.log('ride', ride);
        ride.parentRideId = this.parentRideId;
        delete ride['id'];
        batch.set(newRideRef.ref, ride);
      }
      await batch.commit();
      this.dialogRef.close();
    }
  }
}
