import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { CharacteristicsComponent } from '../../../characteristics/characteristics.component';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Setting, Characteristic } from './../../../../interfaces';

@Component({
  selector: 'app-create-edit-characteristics',
  templateUrl: './create-edit-characteristics.component.html',
  styleUrls: ['./create-edit-characteristics.component.scss'],
})
export class CreateEditCharacteristicComponent implements OnInit {
  characteristicForm: UntypedFormGroup;
  characteristic: Characteristic;
  toggle: boolean;
  toggle2: boolean;
  toggle3: boolean;
  isNew: boolean;
  isActive: boolean;
  totalActive: number;
  settingDoc: AngularFirestoreDocument<Setting>;
  setting: Observable<Setting>;
  organisationsId = localStorage.getItem('orgId');
  constructor(
    private fb: UntypedFormBuilder,
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CharacteristicsComponent>
  ) {}

  ngOnInit() {
    this.settingDoc = this.db.doc<Setting>(
      `organisations/${this.organisationsId}/settings/general`
    );
    this.setting = this.settingDoc.valueChanges();
    if (this.data.characteristic) {
      this.characteristic = this.data.characteristic;
      this.isActive = this.data.characteristic.active;
    } else {
      this.isActive = false;
      this.isNew = true;
    }
    this.totalActive = this.data.totalActive;
    this.characteristicForm = this.fb.group({
      name: ['', Validators.required],
      description: [],
      active: [],
    });
    if (this.characteristic) {
      let formVal: any = this.characteristic;
      if (this.characteristic.description) {
        formVal.description = this.characteristic.description;
      }
      this.characteristicForm.patchValue(formVal);
    }
  }

  async onSubmit() {
    const formValue = this.characteristicForm.value;
    if (this.isNew) {
      formValue.id = this.db.createId();
    } else {
      formValue.id = this.characteristic.id;
    }
    if (formValue.description) {
      formValue.description = formValue.description;
    }
    let submitItem = formValue;
    await this.saveItem(submitItem);
  }
  async saveItem(submitItem) {
    console.log('submitItem', submitItem);
    const batch = this.db.firestore.batch();
    batch.set(
      this.db
        .collection(`organisations/${this.organisationsId}/characteristics`)
        .doc(submitItem.id).ref,
      submitItem,
      { merge: true }
    );

    await batch.commit();
    this.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  close(): void {
    this.dialogRef.close();
  }
}
