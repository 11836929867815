<h1
  mat-dialog-title
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  *ngIf="!loading"
>
  <span *ngIf="newRide">Nieuwe rit</span>
  <span *ngIf="!newRide && !data.retourGenerating && !data.ride.isRetour">
    Rit bewerken
  </span>
  <span *ngIf="!newRide && data.retourGenerating && data.ride.isRetour">
    Retour rit aanmaken
  </span>
  <span *ngIf="!newRide && data.ride.isRetour && !data.retourGenerating">
    Retourrit bewerken
  </span>
  <button
    *ngIf="!data.retourGenerating"
    mat-icon-button
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
  <button
    *ngIf="!newRide && !data.retourGenerating"
    mat-icon-button
    (click)="deleteRide()"
    matTooltip="Rit verwijderen"
  >
    <mat-icon>delete</mat-icon>
  </button>
</h1>
<div
  mat-dialog-content
  *ngIf="!loading"
>
  <form
    fxLayout="column"
    [formGroup]="rideForm"
    *ngIf="rideForm"
  >
    <mat-form-field>
      <mat-label>Voertuigen voor deze rit</mat-label>
      <mat-select
        formControlName="vehicleId"
        (selectionChange)="vehicleChanged()"
        required
        cdkFocusInitial
      >
        <mat-option
          *ngFor="let vehicle of vehicles | async"
          [value]="vehicle.id"
          >{{ vehicle.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <div
      class="users"
      *ngIf="icons | async"
    >
      <div>Personen in rit</div>
      <button
        type="button"
        mat-icon-button
        matTooltip="Nieuwe deelnemer aan rit toevoegen."
        (click)="changeUser()"
      >
        <mat-icon>person_add</mat-icon>
      </button>
      <div
        *ngIf="users.length === 0"
        class="no-users-yet"
      >
        Er zijn nog geen deelnemers in de rit.
      </div>
      <div
        *ngIf="users.length != 0"
        cdkDropList
        class="users-list"
        (cdkDropListDropped)="drop($event)"
      >
        <div
          class="user-box"
          *ngFor="let user of users; let i = index"
          cdkDrag
          fxLayout="row"
        >
          <div
            class="main"
            fxLayout="column"
          >
            <div class="title">
              <p>{{ user.title }}</p>
              <span *ngIf="user.phoneNumber != '' && user.phoneNumber != null"
                ><a href="tel:{{ user.phoneNumber }}">{{
                  user.phoneNumber
                }}</a></span
              >
            </div>
            <div
              *ngIf="user.remarks"
              class="remarks"
            >
              {{ user.remarks }}
            </div>
            <div
              class="icons"
              fxLayout="row"
            >
              <div
                class="icon"
                [ngClass]="{ active: user[icon.id] === true }"
                [matTooltip]="icon.name"
                *ngFor="let icon of icons | async"
              >
                <mat-icon *ngIf="icon.iconType == 'material'">
                  {{ icon.icon }}</mat-icon
                >
                <div
                  class="mat-icon"
                  *ngIf="icon.iconType == 'custom'"
                  [inlineSVG]="'/assets/icons/' + icon.icon + '.svg'"
                ></div>
              </div>
            </div>
          </div>
          <div class="buttons">
            <button
              type="button"
              [disabled]="i == 0"
              mat-icon-button
              (click)="moveUser('up', i, user)"
            >
              <mat-icon>arrow_drop_up</mat-icon>
            </button>
            <button
              type="button"
              [disabled]="i == users.length - 1"
              mat-icon-button
              (click)="moveUser('down', i, user)"
            >
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <button
              type="button"
              mat-icon-button
              (click)="changeUser(i, user)"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              type="button"
              mat-icon-button
              (click)="removeUser(i)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div
        class="seats"
        *ngIf="selectedVehicle && availableSeats !== undefined"
      >
        Vrije plaatsen in de rit
        <span>{{ availableSeats }}/{{ selectedVehicle['seats'] }}</span>
      </div>
    </div>
    <mat-form-field>
      <mat-label>Opmerkingen</mat-label>
      <textarea
        formControlName="comments"
        matInput
      ></textarea>
    </mat-form-field>
    <div
      class="datetime"
      fxLayout="row"
    >
      <mat-form-field class="date">
        <mat-label>Begindatum</mat-label>
        <input
          matInput
          [matDatepicker]="start"
          formControlName="start"
          autocomplete="off"
          required
          (dateChange)="dateUpdated('start')"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="start"
        ></mat-datepicker-toggle>
        <mat-datepicker #start></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="hour">
        <mat-label>Uur</mat-label>
        <mat-select
          formControlName="startHour"
          required
          (selectionChange)="dateUpdated('start')"
        >
          <mat-option
            *ngFor="let hour of hours"
            [value]="hour"
            >{{ hour }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field class="minute">
        <mat-label>Minuut</mat-label>
        <mat-select
          formControlName="startMinute"
          required
          (selectionChange)="dateUpdated('start')"
        >
          <mat-option
            *ngFor="let minute of minutes"
            [value]="minute.val"
            >{{ minute.text }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div
      class="datetime"
      fxLayout="row"
    >
      <mat-form-field class="date">
        <mat-label>Einddatum</mat-label>
        <input
          matInput
          [matDatepicker]="end"
          formControlName="end"
          autocomplete="off"
          required
          (dateChange)="dateUpdated('end')"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="end"
        ></mat-datepicker-toggle>
        <mat-datepicker #end></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="hour">
        <mat-label>Uur</mat-label>
        <mat-select
          formControlName="endHour"
          required
          (selectionChange)="dateUpdated('end')"
        >
          <mat-option
            *ngFor="let hour of hours"
            [value]="hour"
            >{{ hour }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field class="minute">
        <mat-label>Minuut</mat-label>
        <mat-select
          formControlName="endMinute"
          required
          (selectionChange)="dateUpdated('end')"
        >
          <mat-option
            *ngFor="let minute of minutes"
            [value]="minute.val"
            >{{ minute.text }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <mat-form-field *ngIf="showRideProduct">
      <mat-label>Standaard ritprijs</mat-label>
      <input
        type="text"
        placeholder="Zoeken"
        matInput
        formControlName="rideProduct"
        [matAutocomplete]="products"
      />
      <mat-icon matSuffix>arrow_drop_down</mat-icon>
      <mat-autocomplete #products="matAutocomplete">
        <mat-option
          *ngFor="let product of filteredRideProductOptions | async"
          [value]="product.name"
        >
          {{ product.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div
      class="room-below"
      *ngIf="activateSignalColor"
    >
      <mat-slide-toggle
        (change)="onChange($event)"
        formControlName="special"
        [disabled]="!signalColorIsPresent"
      >
        Bijzondere rit
      </mat-slide-toggle>
      <mat-error *ngIf="!signalColorIsPresent"
        >Kies een signaal kleur bij algemene instellingen</mat-error
      >
    </div>

    <mat-form-field *ngIf="characteristicsStatus">
      <mat-label>Kenmerken</mat-label>
      <mat-chip-list
        #chipList
        required
      >
        <mat-chip
          *ngFor="let characteristic of characteristics"
          (removed)="remove(characteristic)"
        >
          {{ characteristic }}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input
          placeholder="Nieuw kenmerk..."
          #characteristicInput
          [formControl]="characteristicCtrl"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)"
        />
      </mat-chip-list>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selected($event)"
      >
        <mat-option
          *ngFor="let characteristic of filteredCharacteristics | async"
          [value]="characteristic"
        >
          {{ characteristic }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field
      *ngIf="driverId && !ignoreDriverStatus"
      appearance="legacy"
    >
      <mat-label>Chauffeur</mat-label>
      <input
        type="text"
        autocomplete="off"
        formControlName="driverId"
        matInput
        readonly
      />
    </mat-form-field>
    <div *ngIf="!driverId && !ignoreDriverStatus && showDriverPlanning">
      <p>Er is nog geen chauffeur voor dit dagdeel gepland.</p>
    </div>
  </form>
</div>
<div
  mat-dialog-actions
  *ngIf="!loading"
>
  <button
    mat-button
    [mat-dialog-close]="false"
  >
    Annuleren
  </button>
  <button
    mat-raised-button
    [disabled]="
      !rideForm.valid ||
      this.availableSeats < 0 ||
      (characteristicsStatus && this.characteristics.length < 1)
    "
    (click)="save()"
    color="primary"
  >
    Opslaan
  </button>
</div>

<div
  mat-dialog-content
  class="content-center"
  *ngIf="loading"
>
  <mat-spinner></mat-spinner>
</div>
