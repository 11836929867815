import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '../../../interfaces';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { CustomValidators } from 'src/app/validators/custom-validators';
export interface DialogData {
  user: any;
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class AdminUserComponent implements OnInit {
  user: User;
  userForm: UntypedFormGroup;
  newUser = true;
  userSelected = false;
  saving = false;

  organisationId = localStorage.getItem('orgId');

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AdminUserComponent>,
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.userForm = this.fb.group({
      email: ['', [Validators.required, CustomValidators.emailValidator]],
      name: ['', Validators.required],
      accountType: ['driver', Validators.required],
      phone: ['', [Validators.required, CustomValidators.phoneValidator]],
      withdrawRights: [],
    });

    this.userForm.controls.email.valueChanges.subscribe((val) => {
      if (this.userForm.controls.email.hasError('customError')) {
        this.userForm.controls.email.setErrors(null);
        this.userForm.controls.email.updateValueAndValidity();
      }
    });

    this.userForm.controls.withdrawRights.valueChanges.subscribe((val) => {
      if (val) {
        this.userForm.controls.accountType.disable();
      } else {
        this.userForm.controls.accountType.enable();
      }
    });

    if (this.data.user) {
      console.log('data user', this.data.user);
      this.newUser = false;
      this.user = this.data.user;
      this.userSelected = true;
      this.userForm.patchValue(this.data.user);

      if (this.user.accountType === 'inactive') {
        this.userForm.controls.withdrawRights.setValue(true);
        this.userForm.controls.accountType.setValue('none');
      }
    } else {
      this.userForm.registerControl(
        'email',
        new UntypedFormControl('', [Validators.email, Validators.required])
      );
      this.userForm.registerControl(
        'password',
        new UntypedFormControl('', Validators.required)
      );
    }
  }

  async save() {
    if (this.userForm.valid && !this.saving) {
      this.saving = true;
      console.log('lets sent http req');
      await this.setNewRights();
    }
  }

  async setNewRights() {
    const form = this.userForm.value;
    console.log('userForm', this.userForm.value);
    const httpRequest = this.http.post(
      `${environment.functionsUrl}/httpGrantRights`,
      {
        email: form.email,
        password: this.newUser ? form.password : null,
        name: form.name,
        accountType: form.accountType,
        organisationId: this.organisationId,
        phone: form.phone,
        hidden: true,
        withdrawRights: form.withdrawRights,
      },
      { responseType: 'text' }
    );
    httpRequest.subscribe(
      (result: string) => {
        console.log('result', result);
        if (result === 'success') {
          if (form.withdrawRights) {
            this.snackBar.open(
              'Rechten van de gebruiker zijn ingetrokken',
              'X',
              {
                duration: 5000,
              }
            );
          } else {
            this.snackBar.open('De gebruiker heeft rechten gekregen.', '', {
              duration: 5000,
            });
          }
          this.dialogRef.close();
        } else if (result === 'email_already_exists') {
          this.snackBar.open(
            'Dit e-mailadres heeft al een account en kan daarom niet gebruikt worden.',
            '',
            {
              duration: 5000,
            }
          );
        } else if (result === 'error_creating_user') {
          this.userForm.controls.email.setErrors({ customError: true });
        }
        this.saving = false;
      },
      (error) => {
        console.log('error', error);
        // setTimeout(() => this.setNewRights(), 2000);
      }
    );
  }
}

// pipelinet test
