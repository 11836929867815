<div>
  <form
    fxLayout="column"
    [formGroup]="vehicleGroupForm"
    autocomplete="off"
  >
    <mat-form-field>
      <mat-label>Groep naam</mat-label>
      <input
        placeholder="Voertuiggroep naam"
        formControlName="name"
        autocomplete="off"
        type="text"
        matInput
        required
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Groep kleur</mat-label>
      <input
        matInput
        placeholder="#FFFFFF"
        readonly="true"
        formControlName="color"
        [value]="color"
        requiredsortingNumber
        #ignoredInput
        [cpIgnoredElements]="[ignoredButton, ignoredInput]"
        [(cpToggle)]="colorToggle"
        [(colorPicker)]="color"
        [cpAlphaChannel]="'disabled'"
        [cpOKButton]="true"
        [cpPosition]="'bottom'"
        [cpCancelButton]="true"
        [cpCancelButtonClass]="'mat-stroked-button'"
        [cpOKButtonClass]="'mat-stroked-button'"
        [cpCancelButtonText]="'Annuleren'"
      />
      <button
        matSuffix
        mat-icon-button
        #ignoredButton
        type="button"
        (click)="colorToggle = !colorToggle"
      >
        <mat-icon
          *ngIf="color !== '#A52A2A'"
          [ngStyle]="{
            color: color
          }"
          >circle
        </mat-icon>
        <mat-icon *ngIf="color === '#A52A2A'">colorize </mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Volgorde nummer</mat-label>
      <input
        placeholder="Volgorde nummer"
        formControlName="sortingNumber"
        autocomplete="off"
        type="number"
        matInput
      />
    </mat-form-field>
    <mat-checkbox
      *ngFor="let vehicle of vehicles"
      matInput
      (change)="vehicleChecked($event, vehicle)"
      formControlName="{{ vehicle.id }}"
      >{{ vehicle.name }}</mat-checkbox
    >

    <div
      fxLayout="row wrap"
      fxLayoutAlign="end center"
      fxLayoutGap="6px"
    >
      <button
        mat-button
        type="button"
        (click)="close()"
      >
        Annuleren
      </button>
      <button
        mat-raised-button
        type="submit"
        color="primary"
        [disabled]="vehicleGroupForm.invalid"
        (click)="save()"
      >
        Opslaan
      </button>
    </div>
  </form>
  <ng-template #loading>
    <div
      id="loading"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
</div>
