import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { redirectIfNotAdmin } from 'src/app/globals';
import { PaymentReference } from 'src/app/interfaces';
import moment from 'moment';
import { FileSaverService } from 'ngx-filesaver';
import sha1 from 'crypto-js/sha1';
import sha256 from 'crypto-js/sha256';
import { createSepaData } from 'src/app/users/dialogs/create-sepa/sepa-helpers';

@Component({
  selector: 'app-debitorders',
  templateUrl: './debit-orders.component.html',
  styleUrls: ['./debit-orders.component.scss'],
  animations: [
    trigger('slide', [
      state(
        'in',
        style({
          opacity: '0',
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state(
        'out',
        style({
          overflow: 'hidden',
          height: '*',
        })
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
  ],
})
export class DebitOrdersComponent implements OnInit {
  organisationId = localStorage.getItem('orgId');
  loadingData: boolean = true;
  debitOrders: PaymentReference[] = [];
  isCollapsed: string[] = [];

  constructor(
    public db: AngularFirestore,
    private router: Router,
    private _FileSaverService: FileSaverService
  ) {}

  async ngOnInit() {
    const paymentReferenceCollection = await getDocs(
      query(
        collection(
          this.db.firestore,
          `organisations/${this.organisationId}/paymentReferences`
        ),
        orderBy('date', 'desc')
      )
    );

    paymentReferenceCollection.forEach((doc) => {
      const paymentReference = {
        ...doc.data(),
        id: doc.id,
      } as PaymentReference;

      paymentReference.date = paymentReference.date.toDate();
      paymentReference['shownDate'] = moment(paymentReference.date).format(
        'DD-MM-YYYY'
      );
      this.debitOrders.push(paymentReference);
      this.isCollapsed.push('in');
    });

    this.loadingData = false;
    redirectIfNotAdmin(this.db.firestore, this.router);
  }

  toggleMenu(index: number): void {
    this.isCollapsed[index] = this.isCollapsed[index] === 'out' ? 'in' : 'out';
  }

  async downloadDebitOrder(index: number) {
    const debitOrderToDownload = this.debitOrders[index];
    const sepaData = await createSepaData(
      debitOrderToDownload.id,
      debitOrderToDownload.date,
      debitOrderToDownload.customers,
      debitOrderToDownload.creditorName,
      debitOrderToDownload.creditorCountry,
      debitOrderToDownload.creditorAddress,
      debitOrderToDownload.creditorMunicipality,
      debitOrderToDownload.creditorPostal,
      debitOrderToDownload.creditorIban,
      debitOrderToDownload.creditorId,
      debitOrderToDownload.creditorBic
    );
    console.log('sepaData', sepaData);
    this.createXmlFile(
      debitOrderToDownload.id,
      sepaData,
      `${debitOrderToDownload.id}.xml`
    );
  }

  createXmlFile(paymentReferenceId: string, data: string, fileName: string) {
    const checkSumSha1 = sha1(data);
    const checkSumSha256 = sha256(data);
    this._FileSaverService.save(new Blob([data]), fileName);
    const checkSumString = `Checksum SHA-1: ${checkSumSha1}\nChecksum SHA-256: ${checkSumSha256}`;
    this._FileSaverService.save(
      new Blob([checkSumString]),
      `Checksum ${paymentReferenceId}.txt`
    );
  }
}
