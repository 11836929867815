import { Component, Inject, OnInit } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Daypart, DaypartDay, User, Vehicle } from 'src/app/interfaces';
import * as _moment from 'moment';
import { Router } from '@angular/router';
import { CopyDaypartComponent } from '../../dialogs/copy-daypart/copy-daypart.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { getDateString } from 'src/app/globals';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { WarningDialog } from 'src/app/global-dialogs/warning/warning.component';

export interface DialogData {
  retourRide: any;
  ride: any;
  start: Date;
  end: Date;
  dayPart: Daypart;
  vehicle: Vehicle;
  dayPartDay?: DaypartDay;
}

export interface PlanningUser {
  id: string;
  user: User;
  priority: number;
  recommended?: boolean;
  available?: boolean;
  ridesVehicle?: boolean;
  hasException?: boolean;
}

@Component({
  selector: 'app-plan-daypart',
  templateUrl: './plan-daypart.component.html',
  styleUrls: ['./plan-daypart.component.scss'],
})
export class PlanDaypartComponent implements OnInit {
  loading = true;
  newItem = false;
  saveItem: DaypartDay = {};
  date: Date;
  startString: string;
  organisationId = localStorage.getItem('orgId');
  planDayPartDayForm: UntypedFormGroup;
  doubleBookingActivated: boolean = false;

  hours: number[] = [];
  minutes: any[] = [];
  minuteStep = 5;

  driverEmail: string;
  driverPhone: string;

  usersCollection: AngularFirestoreCollection<PlanningUser>;
  allUsers: Observable<PlanningUser[]>;
  allUsersArray: PlanningUser[];
  searchQueryChanged: Subject<string> = new Subject<string>();
  totalUsers: number;
  public filteredUsers: ReplaySubject<PlanningUser[]> = new ReplaySubject<
    PlanningUser[]
  >(1);

  planDayPartDoc: AngularFirestoreDocument<DaypartDay>;
  currentUser: PlanningUser;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<PlanDaypartComponent>,
    private dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  async ngOnInit() {
    this.planDayPartDayForm = this.fb.group({
      driverId: ['', Validators.required],
      customStart: [false],
      startHour: [],
      startMinute: [],
      customEnd: [false],
      endHour: [],
      endMinute: [],
    });

    const settings = (await (
      await this.db
        .doc(`organisations/${this.organisationId}/settings/general`)
        .get()
        .toPromise()
    ).data()) as any;

    if (!settings.daypartSettings) {
      this.snackBar.open(
        'Er zijn nog geen van tot uren ingesteld bij instellingen',
        'X'
      );
      return;
    }

    // this.hours = settings.dapartSettings.hours;
    // this.minutes = settings.minutes;
    this.doubleBookingActivated = settings.doubleBookingActivated;
    const startHourSettings = settings.daypartSettings.startHour;
    const endHourSettings = settings.daypartSettings.endHour;

    for (let i = startHourSettings; i <= endHourSettings; i++) {
      this.hours.push(i);
    }

    for (let i = 0; i < 60; i += this.minuteStep) {
      this.minutes.push({
        val: i,
        text: `0${i}`.slice(-2),
      });
    }
    this.planDayPartDayForm.controls.customStart.valueChanges.subscribe(
      (value) => {
        if (value) {
          this.planDayPartDayForm.controls.startHour.setValidators(
            Validators.required
          );
          this.planDayPartDayForm.controls.startMinute.setValidators(
            Validators.required
          );
          this.planDayPartDayForm.controls.startHour.updateValueAndValidity();
          this.planDayPartDayForm.controls.startMinute.updateValueAndValidity();
        } else {
          this.planDayPartDayForm.controls.startHour.clearValidators();
          this.planDayPartDayForm.controls.startMinute.clearValidators();
          this.planDayPartDayForm.controls.startHour.updateValueAndValidity();
          this.planDayPartDayForm.controls.startMinute.updateValueAndValidity();
        }
      }
    );
    console.log('data', this.data);
    if (!this.data.dayPartDay) {
      this.newItem = true;
      this.date = this.data.start;
      this.startString = getDateString(this.data.start);

      // WARNING
      // The values below should NEVER be changed or they will unlink on the calendar
      this.saveItem.date = this.data.start;
      this.saveItem.startString = getDateString(this.data.start);
      this.saveItem.year = this.data.start.getFullYear();
      this.saveItem.month = this.data.start.getMonth();
      this.saveItem.week = this.getWeekString(this.data.start);
      this.saveItem.day = this.data.start.getDate();
      this.saveItem.vehicleId = this.data.vehicle.id;
      this.saveItem.daypartId = this.data.dayPart.id;
      // The values above should NEVER be changed or they will unlink on the calendar

      this.saveItem.lastAlerted = null;
    } else {
      this.date = this.data.dayPartDay.date.toDate();
      this.startString = this.data.dayPartDay.startString;
      this.planDayPartDayForm.patchValue(this.data.dayPartDay);
    }
    this.planDayPartDoc = this.db.doc(
      `organisations/${this.organisationId}/daypartDays/${this.startString}.${this.data.dayPart.id}.${this.data.vehicle.id}`
    );
    this.usersCollection = this.db.collection<PlanningUser>(
      `organisations/${this.organisationId}/users`,
      (ref) => ref.orderBy('accountType')
    );
    this.allUsers = this.usersCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const user = a.payload.doc.data() as User;
          const id = a.payload.doc.id;
          const planningUser: PlanningUser = {
            id,
            user: user,
            priority: 0,
          };
          if (this.data.dayPart.availableUsers) {
            const available = this.data.dayPart.availableUsers.find(
              (userRef) => userRef.id === a.payload.doc.ref.id
            );
            if (available) {
              planningUser.available = true;
              planningUser.priority = planningUser.priority + 1;
            }
          }
          if (user.vehiclesToRide) {
            const ridesVehicle = user.vehiclesToRide.find(
              (vehicleId) => vehicleId === this.data.vehicle.id
            );
            if (ridesVehicle) {
              planningUser.ridesVehicle = true;
            }
          }
          if (user.exceptions) {
            const exception = user.exceptions?.find((exception) => {
              return _moment(this.date).isBetween(
                exception.dateFrom.toDate(),
                exception.dateTo.toDate()
              );
            });
            if (exception) {
              planningUser.hasException = true;
              planningUser.priority = -1;
            }
          }
          // if(!planningUser.available) {
          //   planningUser.isAvailable = false;
          // } else {
          //   planningUser.isAvailable = true;
          // }
          // if(!planningUser.ridesVehicle) {
          //   planningUser.hasRidesVehicle = false;
          // } else {
          //   planningUser.hasRidesVehicle = true;
          // }
          // if(!planningUser.hasException) {
          //   planningUser.hasNewException = false;
          // } else {
          //   planningUser.isAvailable = true;
          // }
          if (
            planningUser.available &&
            planningUser.ridesVehicle &&
            !planningUser.hasException
          ) {
            planningUser.recommended = true;
          } else {
            planningUser.recommended = false;
          }
          return planningUser;
        })
      )
    );
    this.allUsers.subscribe((users) => {
      this.allUsersArray = users;
      if (this.data && this.data.dayPartDay) {
        this.getCurrentDriver(this.data.dayPartDay.driverId);
      }
    });
    this.planDayPartDayForm.controls.driverId.valueChanges.subscribe(
      (driverId) => {
        this.getCurrentDriver(driverId);
      }
    );
    const combinedObservable = combineLatest([
      this.allUsers,
      this.searchQueryChanged,
    ]);
    combinedObservable
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        const users = values[0];
        const searchQuery = values[1];
        let filteredUsers = users.filter((planningUser) => {
          let included = false;
          if (
            planningUser.user.name &&
            planningUser.user.name
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
          ) {
            included = true;
          }
          if (planningUser.user.accountType !== 'driver') {
            included = false;
          }
          if (!planningUser.ridesVehicle) {
            included = false;
          }
          return included;
        });
        filteredUsers.sort((a, b) => {
          if (a.priority < b.priority) {
            return 1;
          } else if (a.priority === b.priority && a.user.name > b.user.name) {
            return 1;
          } else {
            return -1;
          }
        });
        this.totalUsers = filteredUsers.length;
        console.log('filteredUsers', filteredUsers);
        this.filteredUsers.next(filteredUsers);
        if (this.planDayPartDayForm.valid) {
          this.changedDriver(this.planDayPartDayForm.value.driverId);
        }
        this.loading = false;
      });
    this.searchQueryChanged.next('');
    console.log(
      'planDayPartDayForm.value.driverId',
      this.planDayPartDayForm.value
    );

    const timeObj = {
      startHour: this.data.start.getHours(),
      startMinute: this.data.start.getMinutes(),
      endHour: this.data.end.getHours(),
      endMinute: this.data.end.getMinutes(),
    };
    this.planDayPartDayForm.patchValue(timeObj);
  }

  getCurrentDriver(driverId: string) {
    if (this.data && this.data.dayPartDay) {
      const userFound = this.allUsersArray.find((user) => {
        return user.id === driverId;
      });
      console.log('userFound', userFound);
      if (userFound) {
        this.currentUser = userFound;
      }
    }
  }

  changedDriver(driverId) {
    const userDoc = this.db.doc(
      `organisations/${this.organisationId}/users/${driverId}`
    );
    userDoc.snapshotChanges().subscribe((actions) => {
      this.driverEmail = '';
      this.driverPhone = '';
      if (!actions.payload.data()) {
        return;
      }
      if (actions.payload.data()['email'] != null) {
        this.driverEmail = actions.payload.data()['email'];
      }
      if (actions.payload.data()['phoneNumber'] != null) {
        this.driverPhone = actions.payload.data()['phoneNumber'];
      } else if (actions.payload.data()['phone'] != null) {
        this.driverPhone = actions.payload.data()['phone'];
      }
    });
  }

  getWeekString(date) {
    const weekYear = moment(date).isoWeekYear();
    const week = moment(date).isoWeek();
    return `${weekYear}-${week}`;
  }

  async save() {
    if (!this.planDayPartDayForm.valid) {
      this.planDayPartDayForm.markAllAsTouched();
      return;
    }
    console.log('saveItem', this.saveItem);
    console.log('data', this.data);
    const form = this.planDayPartDayForm.value;
    console.log('form', form);
    if (form.driverId === 'none') {
      console.log('this.planDayPartDoc', this.planDayPartDoc);
      await this.planDayPartDoc.delete();
      console.log('deleted');
      return this.dialogRef.close();
    }
    const selectedDriver = this.allUsersArray.find(
      (user) => user.id === form.driverId
    );
    console.log('selectedDriver', selectedDriver);
    this.saveItem.driverId = form.driverId;
    this.saveItem.driverName = selectedDriver.user.name;
    this.saveItem.lastUpdated = new Date();
    this.saveItem.customStart = form.customStart;
    this.saveItem.customEnd = form.customEnd;
    this.saveItem.startHour = form.startHour;
    this.saveItem.startMinute = form.startMinute;
    this.saveItem.endHour = form.endHour;
    this.saveItem.endMinute = form.endMinute;

    const planDayPartData = (
      await this.db.firestore
        .collection(`organisations/${this.organisationId}/dayparts`)
        .doc(this.data.dayPart.id)
        .get()
    ).data();
    if (
      planDayPartData.startHour != form.startHour ||
      planDayPartData.customStart
    ) {
      this.saveItem.customStart = true;
    }
    if (planDayPartData.endHour != form.endHour || planDayPartData.customEnd) {
      this.saveItem.customEnd = true;
    }

    this.saveItem.start = new Date(
      new Date(this.date).setHours(form.startHour, form.startMinute)
    );
    this.saveItem.startHour = form.startHour;
    this.saveItem.startMinute = form.startMinute;

    this.saveItem.end = new Date(
      new Date(this.date).setHours(form.endHour, form.endMinute)
    );
    this.saveItem.endHour = form.endHour;
    this.saveItem.endMinute = form.endMinute;

    const alreadyBooked = await this.checkIfDriverAlreadyBooked(
      this.saveItem.driverId,
      this.saveItem.start,
      this.saveItem.end
    );
    console.log('alreadyBooked', alreadyBooked);

    Object.keys(this.saveItem).forEach((key) => {
      if (this.saveItem[key] == null || this.saveItem[key] == undefined) {
        delete this.saveItem[key];
      }
    });

    console.log('saveItem', this.saveItem);
    if (alreadyBooked && !this.doubleBookingActivated) {
      //Throw up dialogue confirming addition of user.
      const dialogRef = this.dialog.open(WarningDialog, {
        width: '600px',
        data: { dialogType: 'driverInVehicle' },
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          await this.planDayPartDoc.set(this.saveItem, { merge: true });
          this.dialogRef.close();
        }
      });
    } else {
      await this.planDayPartDoc.set(this.saveItem, { merge: true });
      this.dialogRef.close();
    }
  }

  async checkIfDriverAlreadyBooked(
    driverId: string,
    startDate: Date,
    endDate: Date
  ) {
    const start = moment(startDate);
    const end = moment(endDate);
    const day = startDate.getDate();
    const month = startDate.getMonth();
    const year = startDate.getFullYear();
    const ref = collection(
      this.db.firestore,
      `organisations/${this.organisationId}/daypartDays`
    );
    const q = query(
      ref,
      where('driverId', '==', driverId),
      where('day', '==', day),
      where('month', '==', month),
      where('year', '==', year)
    );
    // console.log('ref', ref.path);
    const querySnapshot = await getDocs(q);
    // console.log('docs', querySnapshot.docs);
    let alreadyBooked = false;
    querySnapshot.forEach((daypartDayDoc) => {
      const daypartDay = daypartDayDoc.data() as DaypartDay;
      const existingStart = moment(daypartDay.start.toDate());
      const existingEnd = moment(daypartDay.end.toDate());
      if (
        start.isBetween(existingStart, existingEnd, null, '[]') ||
        end.isBetween(existingStart, existingEnd, null, '[]') ||
        existingStart.isBetween(start, end, null, '[]') ||
        existingEnd.isBetween(start, end, null, '[]')
      ) {
        if (daypartDayDoc.id !== this.planDayPartDoc.ref.id) {
          alreadyBooked = true;
        }
      }
    });
    return alreadyBooked;
  }

  navigateToUser(id: string) {
    window.open(
      `${window.location.origin}/availability?userId=${id}`,
      '_blank'
    );
  }

  openCopyDialog() {
    const dialogRef = this.dialog.open(CopyDaypartComponent, {
      width: '535px',
      data: {
        daypartData: this.data,
        driverId: this.planDayPartDayForm.value.driverId,
        vehicleId: this.data.vehicle.id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result);
    });
  }
}
