import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Validators,
  UntypedFormGroup,
  UntypedFormBuilder,
} from '@angular/forms';

import { environment } from '../../../environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { PublicSettings, User } from 'src/app/interfaces';
import {
  User as FirebaseUser,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { saveOrgInfoLocal } from 'src/app/globals';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  orgId: string = localStorage.getItem('orgId');
  env = environment;
  publicSettings: PublicSettings;
  organisationName: string = 'Buurtvervoer';
  loginImage: string = '../../../assets/default-images/default-login-image.svg';
  logoImage: string = '../../../assets/default-images/default-logo.png';
  loading: boolean = this.orgId ? true : false;
  loginForm: UntypedFormGroup;
  userData: User;
  showPasswords = false;
  errorLoginMessage: string;

  @ViewChild('focusMeLogin', { static: false }) refLogin: ElementRef;

  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private snackBar: MatSnackBar,
    private db: AngularFirestore,
    private fb: UntypedFormBuilder
  ) {}

  async ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });

    if (this.orgId) {
      this.publicSettings = (
        await getDoc(
          doc(this.db.firestore, `organisations/${this.orgId}/settings/public`)
        )
      ).data() as PublicSettings;
      this.organisationName = this.publicSettings.name;
      this.loginImage = this.publicSettings.loginImage;
      this.logoImage = this.publicSettings.logo;
      const favicon: HTMLLinkElement = document.querySelector('#favicon');
      favicon.href = this.publicSettings.favicon;
      this.loading = false;
    } else {
      if (this.env.organisation.id) {
        localStorage.setItem('orgId', this.env.organisation.id);
        saveOrgInfoLocal(this.env.organisation.id);
        window.location.reload();
      }
    }

    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      this.afterSignIn(user);
    }
  }

  async login() {
    if (!this.loginForm.valid) {
      this.loginForm.markAllAsTouched();
      return;
    }

    signInWithEmailAndPassword(
      getAuth(),
      this.loginForm.controls.email.value,
      this.loginForm.controls.password.value
    )
      .then(async (userCredential) => this.afterSignIn(userCredential.user))
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode === 'auth/user-not-found' || 'auth/wrong-password') {
          this.errorLoginMessage =
            'De combinatie e-mail en wachtwoord is niet correct';
        } else {
          this.errorLoginMessage =
            'Er is een fout opgetreden, probeer het later opnieuw';
        }
        this.loginForm.controls.password.reset();
        console.log(errorCode, errorMessage);
      });
  }

  async afterSignIn(user: FirebaseUser) {
    try {
      this.userData = {
        ...((
          await getDoc(doc(this.db.firestore, `users/${user.uid}`))
        ).data() as User),
        id: `${user.uid}`,
      };
    } catch (error) {
      this.showSnackbar('Je hebt geen rechten om in te loggen');
      return this.signOutBackToLogin;
    }

    if (this.userData.organisations.length > 1) {
      return this.router.navigate(['/selectingOrganisation']);
    } else if (this.userData.organisations.length === 1) {
      return this.router.navigate([
        `/set/${this.userData.organisations[0].id}`,
      ]);
    } else if (this.userData.organisations.length < 1) {
      this.showSnackbar('Je hebt geen organisatie');
      return this.signOutBackToLogin;
    }
  }

  async resetPassword() {
    if (!this.loginForm.controls.email.value) {
      this.errorLoginMessage =
        'Voer het mailadres van een account in om het wachtwoord te resetten.';
      return;
    }
    try {
      (await this.afAuth.app).auth().languageCode = 'nl';
      (await this.afAuth.app)
        .auth()
        .sendPasswordResetEmail(this.loginForm.controls.email.value);
      this.errorLoginMessage = '';
      this.snackBar.open(
        'Er wordt een wachtwoord reset link verstuurd indien er een account bekend is op het mailadres',
        'X',
        {
          duration: 5000,
        }
      );
    } catch (err) {
      this.errorLoginMessage =
        'Er is iets fout gegaan, probeer het later opnieuw.';
    }
  }

  signOutBackToLogin() {
    signOut(getAuth())
      .then(() => {
        return this.router.navigate(['/login']);
      })
      .catch((error) => {
        console.log('logoutError', error);
      });
  }

  showSnackbar(txt: string) {
    this.snackBar.open(txt, 'X', {
      duration: 5000,
    });
  }
}
