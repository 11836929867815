<h2 mat-dialog-title>Let op!</h2>
<mat-dialog-content class="mat-typography">
  <h3 *ngIf="availabilityType === 'availability'">
    U bent al ingeroosterd in deze periode, wilt u doorgaan?
  </h3>
  <h3 *ngIf="availabilityType === 'vehicles'">
    Dit voertuig en u staan al ingepland, wilt u doorgaan?
  </h3>
  <h3 *ngIf="availabilityType === 'exceptions'">
    U bent al ingeroostered binnen deze periode, wilt u doorgaan?
  </h3>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Annuleren</button>
  <button
    autofocus
    mat-raised-button
    color="primary"
    [mat-dialog-close]="true"
    cdkFocusInitial
  >
    Wijzigen
  </button>
</mat-dialog-actions>
