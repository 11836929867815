import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Daypart, DaypartDay, Exception, User } from 'src/app/interfaces';
import moment from 'moment';
import { getDateString } from 'src/app/globals';

@Component({
  selector: 'app-copy-daypart',
  templateUrl: './copy-daypart.component.html',
  styleUrls: ['./copy-daypart.component.scss'],
})
export class CopyDaypartComponent implements OnInit {
  copyForm: UntypedFormGroup;
  dayPartData: Daypart;

  customMessage: string;
  driverId: string;

  organisationId = localStorage.getItem('orgId');
  hasGlobalException = false;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CopyDaypartComponent>,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    console.log('this.data', this.data);
    this.copyForm = this.fb.group({
      copyType: ['weekly', Validators.required],
      copyTimes: [1, Validators.required],
    });
    this.dayPartData = this.data.daypartData.dayPart;
    this.driverId = this.data.driverId;
    if (this.driverId === '') {
      this.customMessage = 'Let op: Je hebt geen chauffeur geselecteerd';
      // this.copyForm.controls.copyType.setErrors({
      //   notMatched: true,
      // });
      // this.copyForm.controls.copyType.markAsTouched();
      console.log('customMessage', this.customMessage);
    }
  }

  getWeekString(date) {
    const weekYear = moment(date).isoWeekYear();
    const week = moment(date).isoWeek();
    return `${weekYear}-${week}`;
  }

  async copyDaypart() {
    if (this.copyForm.valid) {
      const copyType = this.copyForm.value.copyType;
      const copyTimes = this.copyForm.value.copyTimes;

      const copyValue = copyType === 'weekly' ? 7 : 14;
      const batch = this.db.firestore.batch();
      let hasException = false;
      this.hasGlobalException = false;
      for (let i = 1; i <= copyTimes; i++) {
        // start with 1 instead of 0 because of the date calculation

        console.log('this.driverId', this.driverId);

        const saveObj: DaypartDay = {};
        const startDate = new Date(this.data.daypartData.start);
        const endDate = new Date(this.data.daypartData.end);

        console.log('startDate', startDate);
        console.log('endDate', endDate);

        saveObj.start = new Date(
          startDate.setDate(startDate.getDate() + copyValue * i)
        );

        saveObj.end = new Date(
          endDate.setDate(endDate.getDate() + copyValue * i)
        );

        console.log('newStartDate', saveObj.start);
        console.log('newEndDate', saveObj.end);

        saveObj.date = saveObj.start;
        saveObj.startString = getDateString(saveObj.start);
        saveObj.year = saveObj.start.getFullYear();
        saveObj.month = saveObj.start.getMonth();
        saveObj.week = this.getWeekString(saveObj.start);
        saveObj.day = saveObj.start.getDate();
        saveObj.vehicleId = this.data.vehicleId;
        saveObj.daypartId = this.dayPartData.id;
        saveObj.lastAlerted = null;
        saveObj.lastUpdated = new Date();
        saveObj.driverId = this.driverId;
        saveObj.startHour = saveObj.start.getHours();
        saveObj.startMinute = saveObj.start.getMinutes();
        saveObj.endHour = saveObj.end.getHours();
        saveObj.endMinute = saveObj.end.getMinutes();

        if (this.driverId && this.driverId !== 'none') {
          const driverData = (await (
            await this.db
              .doc(
                `organisations/${this.organisationId}/users/${this.driverId}`
              )
              .get()
              .toPromise()
          ).data()) as User;
          saveObj.driverName = driverData.name;

          console.log('driverData', driverData);

          // handle exceptions
          let customMessage = '';
          let firstException: Exception;
          let exceptionName: string;
          if (driverData.exceptions) {
            const exceptions = driverData.exceptions.sort(
              (a: Exception, b: Exception) => {
                return (
                  new Date(b.dateFrom.toDate()).getTime() -
                  new Date(a.dateTo.toDate()).getTime()
                );
              }
            );
            exceptions.forEach((exception: Exception) => {
              const exceptionStartDate = new Date(exception.dateFrom.toDate());
              const exceptionEndDate = new Date(exception.dateTo.toDate());

              if (saveObj.start >= exceptionStartDate) {
                if (saveObj.end <= exceptionEndDate) {
                  console.log('start excpetion triggered', exceptionStartDate);
                  console.log('end excpetion triggered', exceptionEndDate);
                  // customMessage =
                  //   customMessage +
                  //   `De chauffeur is niet in te plannen op ${saveObj.start
                  //     .toISOString()
                  //     .slice(0, 10)} vanwege ${exception.name}`;
                  // this.customMessage = customMessage;
                  console.log('this.customMessage', this.customMessage);
                  hasException = true;
                  this.hasGlobalException = true;
                  if (!firstException) {
                    firstException = exception;
                  }
                  return;
                }
              }
            });
          }

          if (hasException) {
            // const startDate = new Date(firstException.dateFrom.toDate());
            // var dd = String(startDate.getDate()).padStart(2, '0');
            // var mm = String(startDate.getMonth() + 1).padStart(2, '0'); //January is 0!
            // var yyyy = startDate.getFullYear();
            this.customMessage = `De ritten zijn niet volledig gekopieerd omdat de chauffeur niet op alle dagdelen aanwezig is`;
            // return (this.customMessage = message);
            // return this.snackBar.open(message, 'X', {
            //   duration: 5000,
            // });
          }
          if (!hasException) {
            const dbRef = this.db
              .collection(`organisations/${this.organisationId}/daypartDays`)
              .doc(
                `${saveObj.startString}.${saveObj.daypartId}.${this.data.vehicleId}`
              ).ref;
            console.log('dbRef', dbRef);
            // if (!hasException) {
            batch.set(dbRef, saveObj);
          }
          // }
          hasException = false;
        } else {
          // there is no driver selected, remove all
          const dbRef = this.db
            .collection(`organisations/${this.organisationId}/daypartDays`)
            .doc(
              `${saveObj.startString}.${saveObj.daypartId}.${this.data.vehicleId}`
            ).ref;
          console.log('dbRef', dbRef);
          // if (!hasException) {
          batch.delete(dbRef);
        }
      }
      await batch.commit();
      if (this.driverId && this.driverId !== 'none') {
        if (!this.hasGlobalException) {
          this.snackBar.open('Kopiëen succesvol aangemaakt', 'X', {
            duration: 8000,
          });
        } else {
          this.snackBar.open(
            'Kopiëen succesvol deels aangemaakt. Zie foutmelding',
            'X',
            {
              duration: 10000,
            }
          );
        }
      } else {
        this.snackBar.open('Kopiëen succesvol verwijderd', 'X', {
          duration: 5000,
        });
      }
      this.dialogRef.close();
    }
  }
}
// vehicle id change to vehicle id and not driverid
