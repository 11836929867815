<app-nav-bar></app-nav-bar>
<div class="payment-method-cards-wrapper">
  <mat-card class="mollie">
    <form
      [formGroup]="mollieForm"
      class="payment-form"
    >
      <div class="header">
        <div class="payment-type">
          <mat-icon [class.enabled]="mollieEnabled">verified</mat-icon>
          Mollie
        </div>
        <mat-slide-toggle formControlName="mollie_enabled">
          Actief
        </mat-slide-toggle>
      </div>
      <mat-form-field>
        <mat-label>Webadres BVV mijn-omgeving</mat-label>
        <input
          formControlName="app_url"
          type="text"
          matInput
          required
        />
        <mat-error *ngIf="mollieForm.controls.app_url.errors?.required">
          Je moet dit veld invullen
        </mat-error>
      </mat-form-field>
      De "Applicatie ID" & "Beveiligingstoken" zijn te vinden in onze Mollie
      omgeving onder (Developers -> Jouw Apps) en hier moeten we een Applicatie
      toevoegen om een nieuwe Buurtvervoerder toe te voegen.
      <mat-form-field>
        <mat-label>Applicatie ID</mat-label>
        <input
          formControlName="client_id"
          type="text"
          matInput
          required
        />
        <mat-error *ngIf="mollieForm.controls.client_id.errors?.required">
          Je moet dit veld invullen
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Beveiligingstoken</mat-label>
        <input
          formControlName="client_secret"
          type="text"
          matInput
          required
        />
        <mat-error *ngIf="mollieForm.controls.client_secret.errors?.required">
          Je moet dit veld invullen
        </mat-error>
      </mat-form-field>
      De "Profile ID" is te vinden in de Mollie omgeving van onze klant
      (Developers -> API-Keys)
      <mat-form-field>
        <mat-label>Profile ID</mat-label>
        <input
          formControlName="profile_id"
          type="text"
          matInput
          required
        />
        <mat-error *ngIf="mollieForm.controls.profile_id.errors?.required">
          Je moet dit veld invullen
        </mat-error>
      </mat-form-field>
      <mat-slide-toggle formControlName="prod">
        Productie mode
      </mat-slide-toggle>
      <div class="mollie-coupling">
        <div class="warning-text">
          LET OP: Na het opslaan van de instellingen is het belangrijk om de
          Mollie omgeving van onze klant te koppelen aan onze eigen Mollie
          omgeving, dit kan door in te loggen in de Mollie omgeving van onze
          klant, en daarna op Mollie koppelen te drukken.
        </div>
        <button
          mat-flat-button
          color="accent"
          type="button"
          (click)="connectMollie()"
          class="secondary-buttons"
        >
          Mollie koppelen
        </button>
      </div>

      <button
        mat-raised-button
        color="primary"
        (click)="saveMollie()"
      >
        Mollie instellingen opslaan
      </button>
    </form>
  </mat-card>
  <mat-card
    class="sepa"
    *ngIf="sepaAllowed"
  >
    <form
      [formGroup]="sepaForm"
      class="payment-form"
    >
      <div class="header">
        <div class="payment-type">
          <mat-icon [class.enabled]="sepaEnabled">verified</mat-icon>
          SEPA
        </div>
        <mat-slide-toggle formControlName="sepa_enabled">
          actief
        </mat-slide-toggle>
      </div>

      <mat-form-field>
        <mat-label>Incassant naam</mat-label>
        <input
          formControlName="creditor_name"
          type="text"
          matInput
          required
        />
        <mat-error *ngIf="sepaForm.controls.creditor_name.errors?.required">
          Je moet dit veld invullen
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Incassant adres</mat-label>
        <input
          formControlName="creditor_address"
          type="text"
          matInput
          required
        />
        <mat-error *ngIf="sepaForm.controls.creditor_address.errors?.required">
          Je moet dit veld invullen
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Incassant postcode</mat-label>
        <input
          formControlName="creditor_postal"
          type="text"
          matInput
          required
        />
        <mat-error *ngIf="sepaForm.controls.creditor_postal.errors?.required">
          Je moet dit veld invullen
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Incassant plaatsnaam</mat-label>
        <input
          formControlName="creditor_municipality"
          type="text"
          matInput
          required
        />
        <mat-error
          *ngIf="sepaForm.controls.creditor_municipality.errors?.required"
        >
          Je moet dit veld invullen
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Incassant land</mat-label>
        <input
          formControlName="creditor_country"
          type="text"
          matInput
          required
        />
        <mat-error *ngIf="sepaForm.controls.creditor_country.errors?.required">
          Je moet dit veld invullen
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Incassant IBAN</mat-label>
        <input
          formControlName="creditor_iban"
          type="text"
          matInput
          required
        />
        <mat-error *ngIf="sepaForm.controls.creditor_iban.errors?.required">
          Je moet dit veld invullen
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Incassant ID</mat-label>
        <input
          formControlName="creditor_id"
          type="text"
          matInput
          required
        />
        <mat-error *ngIf="sepaForm.controls.creditor_id.errors?.required">
          Je moet dit veld invullen
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Incassant BIC</mat-label>
        <input
          formControlName="creditor_bic"
          type="text"
          matInput
          required
        />
        <mat-error *ngIf="sepaForm.controls.creditor_bic.errors?.required">
          Je moet dit veld invullen
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Machtigingskenmerk</mat-label>
        <input
          formControlName="creditor_mandate_id"
          type="text"
          matInput
          required
        />
        <mat-error
          *ngIf="sepaForm.controls.creditor_mandate_id.errors?.required"
        >
          Je moet dit veld invullen
        </mat-error>
      </mat-form-field>
      <div class="sepa-file-buttons">
        <button
          mat-flat-button
          color="accent"
          type="button"
          class="secondary-buttons"
          (click)="downloadSepa()"
        >
          SEPA Formulier Downloaden
        </button>
        <button
          *ngIf="sepaPath === 'sepa_authorization.pdf'"
          mat-flat-button
          color="accent"
          type="button"
          class="secondary-buttons"
          (click)="openFileInput('pdf')"
          [disabled]="sepaForm.controls.creditor_mandate_id.value.length === 0"
        >
          SEPA Formulier uploaden
        </button>
        <div
          *ngIf="sepaPath !== 'sepa_authorization.pdf'"
          class="sepa-alteration-buttons"
        >
          <button
            mat-stroked-button
            class="alter-sepa-button"
            (click)="openFileInput('pdf')"
          >
            <mat-icon>upload</mat-icon>Wijzig SEPA formulier
          </button>
          <div
            class="delete-sepa-button"
            (click)="deleteSepa()"
          >
            Verwijder
          </div>
        </div>
      </div>
      <button
        mat-raised-button
        color="primary"
        (click)="saveSepa()"
      >
        SEPA instellingen opslaan
      </button>
      <input
        style="visibility: hidden; height: 0; display: none"
        type="file"
        id="pdf"
        (change)="uploadSepa($event)"
        placeholder="Upload file"
        accept=".pdf"
      />
    </form>
  </mat-card>
</div>
