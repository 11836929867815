import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { redirectIfNotSuperUser } from 'src/app/globals';
import { AdjustPasswordComponent } from './dialog/adjust-password/adjust-password.component';
import { DeleteRidesComponent } from './dialog/delete-rides/delete-rides.component';
import { CreateSepaForOrgsComponent } from './tools/create-sepa-for-orgs/create-sepa-for-orgs.component';
import { AddUserToOrgsComponent } from './dialog/add-user-to-orgs/add-user-to-orgs.component';
import { ManagePaidFunctionalityComponent } from './dialog/manage-paid-functionality/manage-paid-functionality.component';
import { NewOrganisationComponent } from './dialog/new-organisation/new-organisation.component';

@Component({
  selector: 'app-admin-tools',
  templateUrl: './admin-tools.component.html',
  styleUrls: ['./admin-tools.component.scss'],
})
export class AdminToolsComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private db: AngularFirestore
  ) {}

  ngOnInit(): void {
    redirectIfNotSuperUser(this.db.firestore, this.router);
  }

  async newOrganisation() {
    const dialogRef = this.dialog.open(NewOrganisationComponent, {
      width: '375px',
    });
  }

  async deleteRides() {
    const dialogRef = this.dialog.open(DeleteRidesComponent, {
      width: '375px',
    });
  }

  async adjustPassword() {
    const dialogRef = this.dialog.open(AdjustPasswordComponent, {
      width: '375px',
    });
  }

  async generateSepas() {
    const dialogRef = this.dialog.open(CreateSepaForOrgsComponent, {
      width: '375px',
    });
  }

  async addUserToOrgs() {
    const dialogRef = this.dialog.open(AddUserToOrgsComponent, {
      width: '375px',
    });
  }

  async managePaidFunctionalities() {
    const dialogRef = this.dialog.open(ManagePaidFunctionalityComponent, {
      width: '375px',
    });
  }
}
