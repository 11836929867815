<h1 mat-dialog-title *ngIf="newAddress">Nieuw adres</h1>
<h1 mat-dialog-title *ngIf="!newAddress">Adres bewerken</h1>
<div mat-dialog-content>
  <form
    fxLayout="column"
    (ngSubmit)="save()"
    autocomplete="off"
    [formGroup]="addressForm"
  >
    <mat-form-field>
      <mat-label>Locatienaam</mat-label>
      <input
        matInput
        formControlName="name"
        type="text"
        placeholder="Locatienaam"
        required
      />
      <mat-error> Naam is verplicht. </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Adres</mat-label>
      <input
        matInput
        formControlName="address"
        placeholder="mijn straat 1, mijn dorp"
        required
      />
      <mat-error> Adres is verplicht. </mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions fxLayoutAlign="end center">
  <button mat-button [mat-dialog-close]="false">Annuleren</button>
  <button
    mat-raised-button
    [disabled]="!addressForm.valid"
    (click)="save()"
    color="accent"
  >
    Opslaan
  </button>
</div>
