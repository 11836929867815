import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  QueryDocumentSnapshot,
  collection,
  getDocs,
  where,
  query,
  orderBy,
  limit
} from 'firebase/firestore';
import { MatTableDataSource } from '@angular/material/table';
import { Vehicle, User, DeletedRide, Year, DateFilter, Month } from '../interfaces';
import { DeletedRideInformationComponent } from './dialogs/deleted-ride-information/deleted-ride-information.component';
import { ExportDeletedRidesComponent } from './dialogs/export-deleted-rides/export-deleted-rides.component';
import * as XLSX from 'xlsx';
import {
  ReplaySubject,
  Subject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs';
import { FilterOption } from '../enums';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';

@Component({
  selector: 'app-deleted-rides',
  templateUrl: './deleted-rides.component.html',
  styleUrls: ['./deleted-rides.component.scss']
})
export class DeletedRidesComponent implements OnInit {
  datasource = new MatTableDataSource<any>();
  organisationId = localStorage.getItem('orgId');
  vehicleDocData: Vehicle[] = [];
  driverDocData: User[] = [];
  displayedColumns: string[] = ['ride-date', 'vehicle', 'deleted-date', 'deleted-by', 'reason-of-deletion-message', 'actions'];
  dataSource = new MatTableDataSource<any>();

  public filteredDeletedRides: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public visibleDeletedRides: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  dateFilter: DateFilter[] = [
    { value: 'year', viewValue: 'Jaar' },
    { value: 'month', viewValue: 'Maand' },
    { value: 'week', viewValue: 'Week' },
  ];
  years: Year[] = [];
  currentYear;
  months: Month[] = [
    { value: 1, viewValue: 'Januari' },
    { value: 2, viewValue: 'Februari' },
    { value: 3, viewValue: 'Maart' },
    { value: 4, viewValue: 'April' },
    { value: 5, viewValue: 'Mei' },
    { value: 6, viewValue: 'Juni' },
    { value: 7, viewValue: 'Juli' },
    { value: 8, viewValue: 'Augustus' },
    { value: 9, viewValue: 'September' },
    { value: 10, viewValue: 'Oktober' },
    { value: 11, viewValue: 'November' },
    { value: 12, viewValue: 'December' },
  ];
  currentMonth: any;
  selectedYear: any;
  selectedMonth: any;
  selectedWeek: any;
  selectedDateFilter: any;
  weekArray: number[] = Array(53)
    .fill(0)
    .map((x, i) => i + 1);

  filterForm: FormGroup = new FormGroup({
    searchOn: new FormControl('all'),
    search: new FormControl(''),
    deleteDateAscToDesc: new FormControl(true),
    rideDateAscToDesc: new FormControl(true),
  });
  sortByColumn = 'rideDate';
  defaultAmountToShow: number = 25;
  amountToShow: number = 25;
  amountToShowChanged: Subject<number> = new Subject<number>();
  filterFormChanged: Subject<any> = new Subject<any>();
  allDeletedRides: any[];
  totalDeletedRides: any;
  allDeletedRidesList: any[] = [];
  filteredDeletedRidesList: any[] = [];
  searchQuery: string = '';
  filterOption = FilterOption;

  deletedRidesToExport: any;

  constructor(
    public db: AngularFirestore,
    public dialog: MatDialog,
    private zone: NgZone,
    private _snackBar: MatSnackBar,
  ) { }

  async ngOnInit(): Promise<any> {
    const currentYear = parseInt(moment().format('Y'));
    const currentMonth = parseInt(moment().format('M'));
    let yearInStep = parseInt(moment().add(3, 'years').format('Y'));
    for (let step = 0; step < 7; step++) {
      this.years.push({ value: yearInStep, viewValue: yearInStep.toString() });
      yearInStep = yearInStep - 1;
    }
    this.years.forEach((year) => {
      if (year.value === currentYear) {
        this.selectedYear = year.value;
      }
    });
    this.months.forEach((month) => {
      if (month.value === currentMonth) {
        this.selectedMonth = month.value;
      }
    });
    this.selectedWeek = parseInt(moment().format('W'));
    this.selectedDateFilter = this.dateFilter[1].value;

    const vehicleDocRef = collection(
      this.db.firestore,
      `organisations/${this.organisationId}/vehicles`
    );
    const vehicleDocs = await getDocs(vehicleDocRef);
    vehicleDocs.forEach((doc: QueryDocumentSnapshot<Vehicle>) => {
      const vehicleDoc = {...doc.data(), id: doc.id};
      this.vehicleDocData.push(vehicleDoc);
    });

    const driverQuery = query(
      collection(
        this.db.firestore,
       `organisations/${this.organisationId}/users`
      ),
      where('accountType', '==', 'driver')
    );
    const driverDocs = await getDocs(driverQuery);
    driverDocs.forEach((doc) => {
      let driver = doc.data() as User;
      driver.id = doc.id;
      this.driverDocData.push(driver);
    });

    await this.getDeletedRides();
    this.filterForm.valueChanges.subscribe((value) => {
      this.amountToShow = this.defaultAmountToShow;
      this.amountToShowChanged.next(this.amountToShow);
      this.filterFormChanged.next(value);
    });
    const combinedObservable = combineLatest([
      this.filterFormChanged,
      this.amountToShowChanged,
    ]);
    combinedObservable
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        const filters = values[0];
        const amountToShow = values[1];

        let filteredDeletedRides = this.allDeletedRides.filter((item) =>
          this.checkFilters(item, filters)
        );
        filteredDeletedRides = this.sortByColumns(filteredDeletedRides, filters);
        this.totalDeletedRides = filteredDeletedRides.length;
        this.allDeletedRidesList = this.allDeletedRides;
        this.filteredDeletedRides.next(filteredDeletedRides);
        this.filteredDeletedRidesList = filteredDeletedRides;

        this.visibleDeletedRides.next(filteredDeletedRides.slice(0, amountToShow));
      });
    this.filterFormChanged.next(this.filterForm.value);
    this.amountToShowChanged.next(this.amountToShow);

    this.zone.runOutsideAngular(() => {
      window.addEventListener('scroll', async () => {
        if (window.innerHeight + Math.ceil(window.scrollY) >= document.body.scrollHeight) {
          // you're at the bottom of the page
          this.zone.run(() => {
            this.loadMore();
          });
        }
      });
    });
  }

  async getDeletedRides() {
    this.amountToShow = this.defaultAmountToShow;
    this.amountToShowChanged.next(this.amountToShow);
    
    let deletedRidesQuery: any = 'none';
    if (this.selectedDateFilter === 'year') {
      deletedRidesQuery = query(
        collection(
          this.db.firestore,
          `eventLogs/${this.organisationId}/deletedRides`
        ),
        where('rideData.year', '==', this.selectedYear),
        orderBy('date', 'desc'),
        limit(1000)
      );
    }
    if (this.selectedDateFilter === 'month') {
      deletedRidesQuery = query(
        collection(
          this.db.firestore,
          `eventLogs/${this.organisationId}/deletedRides`
        ),
        where('rideData.year', '==', this.selectedYear),
        where('rideData.month', '==', this.selectedMonth - 1),
        orderBy('date', 'desc'),
        limit(1000)
      );
    }
    if (this.selectedDateFilter === 'week') {
      const weekString = `${this.selectedYear}-${this.selectedWeek}`;
      deletedRidesQuery = query(
        collection(
          this.db.firestore,
          `eventLogs/${this.organisationId}/deletedRides`
        ),
        where('rideData.year', '==', this.selectedYear),
        where('rideData.week', '==', weekString),
        orderBy('date', 'desc'),
        limit(1000)
      );
    }

    if (deletedRidesQuery !== 'none') {
      let deletedRidesData: any[] = [];
      getDocs(deletedRidesQuery).then((docs) => {
        docs.forEach((doc) => {
          const data = doc.data() as object;
          data['driverName'] = this.getDriverName(data);
          data['vehicleName'] = this.getVehicleName(data);
          const id = doc.id;
          deletedRidesData.push({id, ...data});
        });
      });
      this.allDeletedRides = deletedRidesData;
    }
  }

  setDateFilterType(filter) {
    this.totalDeletedRides = '';
    this.selectedDateFilter = filter;
  }

  checkFilters(deletedRide: any, filters: any) {
    let passesSearchFilter = true;
    if (filters.search) {
      const searchQuery = filters.search.toLowerCase();
      passesSearchFilter = false;
      if (deletedRide.rideData.start && (filters.searchOn == 'all' || filters.searchOn == 'start')) {
        let date = new Date(deletedRide.rideData.start.toDate()).toLocaleDateString('en-GB')
        if (date.includes(searchQuery)) {
          passesSearchFilter = true;
        }
      }
      if(deletedRide.vehicleName && (filters.searchOn == 'all' || filters.searchOn == 'vehicle'))
      {
        if (deletedRide.vehicleName.toLowerCase().includes(searchQuery)) {
          passesSearchFilter = true;
        }
      }
      if (deletedRide.doneByUser && (filters.searchOn == 'all' || filters.searchOn == 'doneByUser')) {
        if (deletedRide.doneByUser.toLowerCase().includes(searchQuery)) {
          passesSearchFilter = true;
        }
      }
      if (deletedRide.deleteReason && (filters.searchOn == 'all' || filters.searchOn == 'deleteReason')) {
        if (deletedRide.deleteReason.toLowerCase().includes(searchQuery)) {
          passesSearchFilter = true;
        }
      }
      if (deletedRide.rideData.users && (filters.searchOn == 'all' || filters.searchOn == 'user')) {
        const userIds = Object.keys(deletedRide.rideData.users);
        if (userIds.length > 0) {
          for (let key of userIds) {
            const userObject = deletedRide.rideData.users[key];
            if (userObject.title && userObject.title.toLowerCase().includes(searchQuery)) {
              passesSearchFilter = true;
              break;
            }
          }
        }
      }
    }

    if (passesSearchFilter) {
      return deletedRide;
    }
  }

  sortByColumns(filteredDeletedRides: any, filters: any) {
    if (this.sortByColumn == 'deleteDate') {
      filteredDeletedRides = filteredDeletedRides.sort((a, b) => {
        if (a.date < b.date) {
          return 1;
        } else {
          return -1;
        }
      });
      if (filters.deleteDateAscToDesc == false) {
        filteredDeletedRides.reverse();
      }
    } else if (this.sortByColumn == 'rideDate') {
      filteredDeletedRides = filteredDeletedRides.sort((a, b) => {
        if (a.rideData.start < b.rideData.start) {
          return 1;
        } else {
          return -1;
        }
      });
      if (filters.rideDateAscToDesc == false) {
        filteredDeletedRides.reverse();
      }
    }
    return filteredDeletedRides;
  }

  clearSearch() {
    this.searchQuery = '';
  }

  loadMore() {
    this.amountToShow = this.amountToShow + 25;
    this.amountToShowChanged.next(this.amountToShow);
  }
  
  getVehicleName(deletedRidesDoc: any) {
    let vehicleName: string;
    
    this.vehicleDocData.forEach((doc) => {
      if (doc.id === deletedRidesDoc.rideData.vehicleId) {
        vehicleName = doc.name;
      }
    });
    return vehicleName;
  }

  getDriverName(deletedRidesDoc: any) {
    let driverName: string;
    
    this.driverDocData.forEach((doc) => {
      if (doc.id === deletedRidesDoc.rideData.driverId) {
        driverName = doc.name;
      }
    });
    return driverName;
  }
  
  moreInformationDeletedRide(deletedRide: any) {
    this.dialog.open(DeletedRideInformationComponent, {
      width: '500px',
      data: {
        deletedRide: deletedRide
      }
    }) 
  }

  exportDeletedRides() {
    const dialogRef = this.dialog.open(ExportDeletedRidesComponent, {
      width: '400px',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (!result) {
        return false;
      }
      const deletedRidesToExport = [];
      const allVehicles: Vehicle[] = [];
      const allUsers: User[] = [];
      const vehicleDocs = await getDocs(
        collection(
          this.db.firestore,
          `organisations/${this.organisationId}/vehicles`
        )
      );
      vehicleDocs.forEach((doc) => {
        allVehicles.push(doc.data() as Vehicle);
      });
      const userDocs = await getDocs(
        collection(
          this.db.firestore,
          `organisations/${this.organisationId}/users`
        )
      );
      userDocs.forEach((doc) => {
        let user = doc.data() as User;
        user = { ...user, id: doc.id };
        allUsers.push(user);
      });
      let queryConstraints = [];
      switch (result.type) {
        case 'year':
          queryConstraints.push(where('rideData.year', '==', result.year));
          break;
        case 'month':
          queryConstraints.push(where('rideData.year', '==', result.year));
          queryConstraints.push(where('rideData.month', '==', result.month));
          break;
        case 'week':
          queryConstraints.push(where('rideData.week', '==', result.week));
          break;
        case 'day':
          queryConstraints.push(where('rideData.year', '==', result.year));
          queryConstraints.push(where('rideData.month', '==', result.month));
          queryConstraints.push(where('rideData.day', '==', result.day));
          break;
      }
      //constraint used to prevent old incompatible
      queryConstraints.push(orderBy('date', 'desc'));
      const q = query(
        collection(
          this.db.firestore,
          `eventLogs/${this.organisationId}/deletedRides`
        ),
        ...queryConstraints
      );
      const deletedRidesDocs = await getDocs(q);
      const deletedRides: DeletedRide[] = [];
      deletedRidesDocs.forEach((doc) => {
        let deletedRide = doc.data() as DeletedRide;
        deletedRides.push({id: doc.id, ...deletedRide});
      });

      try {
        for (const deletedRide of deletedRides) {
          const exportDeletedRideObj = {};

          exportDeletedRideObj['Ritdatum'] = '-';
          exportDeletedRideObj['Voertuig'] = '-';
          exportDeletedRideObj['Deelnemers'] = '-';
          exportDeletedRideObj['Kenmerken'] = '-';
          exportDeletedRideObj['Verwijderd op'] = '-';
          exportDeletedRideObj['Verwijderd door'] = '-';
          exportDeletedRideObj['Reden van verwijderen'] = '-';
          
          if (deletedRide.rideData.start) {
            exportDeletedRideObj['Ritdatum'] = `${moment(deletedRide.rideData.start.toDate()).format(
              'YYYY-MM-DD HH:mm'
            )}`;
          }

          if (deletedRide.date) {
            exportDeletedRideObj['Verwijderd op'] = `${moment(deletedRide.date.toDate()).format(
              'YYYY-MM-DD HH:mm'
            )}`;
          }

          if (deletedRide.doneByUser) {
            exportDeletedRideObj['Verwijderd door'] = deletedRide.doneByUser;
          }

          if (deletedRide.doneByUser) {
            exportDeletedRideObj['Reden van verwijderen'] = deletedRide.deleteReason;
          }

          if (deletedRide.rideData.vehicleId) {
            allVehicles.find((vehicle) => {
              if (vehicle.id === deletedRide.rideData.vehicleId) {
                exportDeletedRideObj['Voertuig'] = vehicle.name;
              }
            });
          }

          if (deletedRide.rideData.characteristics && deletedRide.rideData.characteristics.length > 0) {
            exportDeletedRideObj['Kenmerken'] = deletedRide.rideData.characteristics
              .toString()
              .replaceAll(',', ', ');
          }

          if (deletedRide.rideData.users && Object.keys(deletedRide.rideData.users).length > 0) {
            for (const userInRide of Object.values(deletedRide.rideData.users)) {
              const currentUser = allUsers.find((user) => {
                return user.id === userInRide.id;
              });
              const newexportDeletedRideObj = { ...exportDeletedRideObj };

              newexportDeletedRideObj['Deelnemers'] = currentUser
                ? currentUser.name
                : userInRide.title;
              deletedRidesToExport.push(newexportDeletedRideObj);
            }
          } else {
            deletedRidesToExport.push(exportDeletedRideObj);
          }
        }
      } catch (error) {
        console.error(error);
      }
      if (deletedRidesToExport.length > 0) {
        let fileName = 'Verwijderde Ritten.xlsx';
        let monthName;
        moment.locale('nl');
        switch (result.type) {
          case 'year':
            fileName = `Verwijderde Ritten ${result.year}.xlsx`;
            break;
          case 'month':
            monthName = moment.months(result.month);
            fileName = `Verwijderde Ritten ${monthName} ${result.year}.xlsx`;
            break;
          case 'week':
            fileName = `Verwijderde Ritten week ${result.week}.xlsx`;
            break;
          case 'day':
            monthName = moment.months(result.month);
            fileName = `Verwijderde Ritten ${result.day} ${monthName} ${result.year}.xlsx`;
            break;
        }
        this.deletedRidesToExport = deletedRidesToExport;
        this.sortExportDeletedRidesOutput(this.deletedRidesToExport);
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.deletedRidesToExport); // converts a DOM TABLE element to a worksheet
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Verwijderde ritten');
        // /* save to file */
        XLSX.writeFile(wb, fileName);
      } else {
        // Nothing to export
        this._snackBar.open('Er zijn geen ritten om te exporteren', 'X', {
          duration: 5000,
        });
      }
    });
  }

  sortExportDeletedRidesOutput(deletedRidesArray) {
    deletedRidesArray.sort((row1, row2) => {
      const a = row1.Ritdatum;
      const b = row2.Ritdatum;
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
  }
}
