export enum AccountType {
  Admin = 'admin',
  Planner = 'planner',
  Driver = 'driver',
  Inactive = 'inactive',
}

export enum PaymentMethod {
  NONE = 'none',
  SEPA = 'sepa',
  MOLLIE = 'mollie',
}

export enum FilterOption {
  NoCards = 'noCards',
  NoPlan = 'noPlan',
  WarningLowCredits = 'lowCredits',
  WarningPaymentError = 'paymentError',
  WarningSendSepaMandate = 'sendSepaMandate',
  WarningUploadSepaMandate = 'uploadSepaMandate',
  Name = 'name',
  Email = 'email',
  Address = 'address',
  PhoneNumber = 'phoneNumber',
}
