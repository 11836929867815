import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { VehiclesComponent } from '../../../vehicles/vehicles.component';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
  FormControl,
} from '@angular/forms';
import { Observable } from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
// import { ThemePalette } from '@angular/material';
import { Setting, Vehicle } from './../../../../interfaces';

@Component({
  selector: 'app-create-edit-vehicles',
  templateUrl: './create-edit-vehicles.component.html',
  styleUrls: ['./create-edit-vehicles.component.scss'],
})
export class CreateEditVehiclesComponent implements OnInit {
  vehicleForm: UntypedFormGroup;
  vehicle: Vehicle;
  color: string;
  backgroundColorEmpty: string;
  backgroundColorFull: string;
  toggle: boolean;
  toggle2: boolean;
  toggle3: boolean;
  isNew: boolean;
  isActive: boolean;
  totalActive: number;
  settingDoc: AngularFirestoreDocument<Setting>;
  setting: Observable<Setting>;
  maxTotalVehicle: number;
  organisationsId = localStorage.getItem('orgId');
  constructor(
    private fb: UntypedFormBuilder,
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<VehiclesComponent>
  ) {}

  ngOnInit() {
    this.settingDoc = this.db.doc<Setting>(
      `organisations/${this.organisationsId}/settings/general`
    );
    this.setting = this.settingDoc.valueChanges();
    this.setting.subscribe((val) => {
      this.maxTotalVehicle = val.maxActiveVehicles ? val.maxActiveVehicles : 10;
      console.log('this.settings', val);
    });
    if (this.data.vehicle) {
      this.vehicle = this.data.vehicle;
      this.isActive = this.data.vehicle.active;
      this.color = this.color ? this.color : '#ffffff';
      this.backgroundColorEmpty = this.backgroundColorEmpty
        ? this.backgroundColorEmpty
        : '#efefef';
      this.backgroundColorFull = this.backgroundColorFull
        ? this.backgroundColorFull
        : '#efefef';
      console.log('vehicle', this.vehicle);
    } else {
      this.isActive = false;
      this.isNew = true;
      this.color = '#ffffff';
      this.backgroundColorEmpty = '#efefef';
      this.backgroundColorFull = '#efefef';
    }
    this.totalActive = this.data.totalActive;
    this.vehicleForm = this.fb.group({
      name: ['', Validators.required],
      seats: ['', Validators.required],
      color: [this.color, Validators.required],
      sortingNumber: ['', Validators.required],
      backgroundColorEmpty: [this.backgroundColorEmpty],
      backgroundColorFull: [this.backgroundColorFull],
      description: [],
      active: [],
    });
    this.vehicleForm.get('color').valueChanges.subscribe((value) => {
      console.log('value changed', value);
      if (value === '') {
        console.log('value empty');
        value = '#';
        this.color = '#';
      }
    });
    this.vehicleForm
      .get('backgroundColorFull')
      .valueChanges.subscribe((value) => {
        console.log('value changed', value);
        if (value === '') {
          console.log('value empty');
          value = '#';
          this.backgroundColorFull = '#';
        }
      });
    this.vehicleForm
      .get('backgroundColorEmpty')
      .valueChanges.subscribe((value) => {
        console.log('value changed', value);
        if (value === '') {
          console.log('value empty');
          value = '#';
          this.backgroundColorEmpty = '#';
        }
      });
    if (this.vehicle) {
      let formVal: any = this.vehicle;
      if (this.vehicle.calendarStyle.color) {
        formVal.color = this.vehicle.calendarStyle.color;
        this.color = this.vehicle.calendarStyle.color;
      }

      if (this.vehicle.calendarStyle.backgroundColorEmpty) {
        formVal.backgroundColorEmpty =
          this.vehicle.calendarStyle.backgroundColorEmpty;
        this.backgroundColorEmpty =
          this.vehicle.calendarStyle.backgroundColorEmpty;
      } else {
        formVal.backgroundColorEmpty = this.backgroundColorEmpty;
      }

      if (this.vehicle.calendarStyle.backgroundColorFull) {
        formVal.backgroundColorFull =
          this.vehicle.calendarStyle.backgroundColorFull;
        this.backgroundColorFull =
          this.vehicle.calendarStyle.backgroundColorFull;
      } else {
        formVal.backgroundColorFull = this.backgroundColorFull;
      }

      if (this.vehicle.calendarStyle.sortingNumber) {
        formVal.sortingNumber = this.vehicle.calendarStyle.sortingNumber;
      }

      console.log('formval', formVal);
      this.vehicleForm.patchValue(formVal);
    }
    console.log('this.taskForm', this.vehicleForm.value);
  }

  async onSubmit() {
    console.log('itemForm', this.vehicleForm);
    const formValue = this.vehicleForm.value;
    console.log('formValue', formValue);
    // tslint:disable-next-line: prefer-const
    if (this.isNew) {
      formValue.id = this.db.createId();
    } else {
      formValue.id = this.vehicle.id;
    }

    formValue.calendarStyle = {};
    if (formValue.color) {
      formValue.calendarStyle.color = this.color;
      delete formValue.color;
    }
    if (formValue.backgroundColorEmpty) {
      formValue.calendarStyle.backgroundColorEmpty = this.backgroundColorEmpty;
      delete formValue.backgroundColorEmpty;
    }
    if (formValue.backgroundColorFull) {
      formValue.calendarStyle.backgroundColorFull = this.backgroundColorFull;
      delete formValue.backgroundColorFull;
    }
    if (formValue.sortingNumber) {
      formValue.calendarStyle.sortingNumber = formValue.sortingNumber;
      delete formValue.sortingNumber;
    }

    let submitItem = formValue;

    await this.saveItem(submitItem);
  }
  async saveItem(submitItem) {
    console.log('submitItem', submitItem);
    // try {
    const batch = this.db.firestore.batch();
    batch.set(
      this.db
        .collection(`organisations/${this.organisationsId}/vehicles`)
        .doc(submitItem.id).ref,
      submitItem,
      { merge: true }
    );

    await batch.commit();
    this.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  close(): void {
    this.dialogRef.close();
  }
}
