import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { VehiclesComponent } from '../../../vehicles/vehicles.component';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormControl,
  FormGroup,
  UntypedFormControl,
} from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { VehicleGroup } from './../../../../interfaces';
import {
  collection,
  getDocs,
  setDoc,
  doc,
  arrayRemove,
  arrayUnion,
} from 'firebase/firestore';
import { MatCheckboxChange } from '@angular/material/checkbox';

export interface DialogData {
  vehicleGroup?: VehicleGroup;
}

@Component({
  selector: 'app-create-edit-vehicle-groups',
  templateUrl: './create-edit-vehicle-groups.component.html',
  styleUrls: ['./create-edit-vehicle-groups.component.scss'],
})
export class CreateEditVehicleGroupsComponent implements OnInit {
  vehicleGroupForm: UntypedFormGroup;
  color: string = '#A52A2A';
  colorToggle: boolean;
  isNew: boolean = false;
  vehicles: any[] = [];
  vehicleGroupId: string;
  organisationId = localStorage.getItem('orgId');

  constructor(
    private fb: UntypedFormBuilder,
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<VehiclesComponent>
  ) {}

  async ngOnInit() {
    if (!this.data.vehicleGroup) {
      this.isNew = true;
      this.vehicleGroupId = this.db.createId();
    } else {
      this.vehicleGroupId = this.data.vehicleGroup.id;
    }

    this.vehicleGroupForm = this.fb.group({
      name: ['', Validators.required],
      color: [this.color, Validators.required],
      sortingNumber: [, Validators.required],
    });

    this.vehicleGroupForm.get('color').valueChanges.subscribe((value) => {
      if (value === '') {
        value = '#';
        this.color = '#';
      }
    });
    if (!this.isNew) {
      console.log('vehicleGroup.color', this.data.vehicleGroup.color);
      this.color = this.data.vehicleGroup.color;
      this.vehicleGroupForm.patchValue(this.data.vehicleGroup);
    }

    await this.getVehicles();

    console.log('vehicleArray', this.vehicles);
    console.log('this.taskForm', this.vehicleGroupForm.value);
  }

  async getVehicles() {
    const vehicleDocs = await getDocs(
      collection(
        this.db.firestore,
        `organisations/${this.organisationId}/vehicles`
      )
    );

    vehicleDocs.forEach((vehicle) => {
      const vehicleData = vehicle.data();

      let vehicleGroupFound: boolean = false;
      if (vehicleData.vehicleGroups) {
        if (
          vehicleData.vehicleGroups.find(
            (vehicleGroup) => vehicleGroup === this.vehicleGroupId
          )
        ) {
          vehicleGroupFound = true;
        }
      }

      this.vehicles.push({
        id: vehicle.id,
        name: vehicleData.name,
        checked: vehicleGroupFound,
      });
    });
    // sort vehicles alphabetically
    this.vehicles.sort((a, b) => a.name.localeCompare(b.name));

    this.vehicles.forEach((vehicle) => {
      console.log('vehicle', vehicle);

      this.vehicleGroupForm.addControl(
        vehicle.id,
        new UntypedFormControl(vehicle.checked)
      );
    });
  }

  vehicleChecked(event: MatCheckboxChange, value: any) {
    const index = this.vehicles.indexOf(value, 0);
    this.vehicles[index]['checked'] = event.checked;
  }

  async save() {
    const selectedVehicles = [];
    const nonSelectedVehicles = [];
    const promises: any[] = [];
    this.vehicles.forEach((vehicle) => {
      if (vehicle.checked) {
        selectedVehicles.push(vehicle.id);
      } else {
        nonSelectedVehicles.push(vehicle.id);
      }
    });
    console.log('save', this.vehicleGroupForm.value);
    promises.push(
      await setDoc(
        doc(
          this.db.firestore,
          `/organisations/${this.organisationId}/vehicleGroups/${this.vehicleGroupId}`
        ),
        {
          name: this.vehicleGroupForm.value.name,
          color: this.color,
          sortingNumber: this.vehicleGroupForm.value.sortingNumber,
          vehicles: selectedVehicles,
        },
        { merge: true }
      )
    );
    selectedVehicles.forEach((vehicle) => {
      promises.push(
        setDoc(
          doc(
            this.db.firestore,
            `/organisations/${this.organisationId}/vehicles/${vehicle}`
          ),
          {
            vehicleGroups: arrayUnion(this.vehicleGroupId),
          },
          { merge: true }
        )
      );
    });
    nonSelectedVehicles.forEach((vehicle) => {
      promises.push(
        setDoc(
          doc(
            this.db.firestore,
            `/organisations/${this.organisationId}/vehicles/${vehicle}`
          ),
          {
            vehicleGroups: arrayRemove(this.vehicleGroupId),
          },
          { merge: true }
        )
      );
    });
    await Promise.all(promises);
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }
}
