<app-nav-bar></app-nav-bar>
<div class="header" fxLayout="row" fxLayoutAlign=" center">
  <div class="h-title" fxFlex>
    <mat-icon>perm_contact_calendar</mat-icon>
    <span>Rooster</span>
  </div>
</div>
<div class="sub-header" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxLayoutAlign="start center">
    <span class="accountType"> {{ accountType }}:</span>
    <span> {{ name }}</span>

    <div *ngIf="!isDriver" class="driverSelection">
      <button
        mat-raised-button
        color="primary"
        [matMenuTriggerFor]="driverMenu"
        class="user-button"
      >
        {{ selectedName }}
        <mat-icon class="s-16">keyboard_arrow_down</mat-icon>
      </button>

      <mat-menu #driverMenu="matMenu" [overlapTrigger]="false">
        <button
          class="driver-button"
          mat-menu-item
          *ngFor="let driver of newDrivers | async"
        >
          <div fxLayout="row" (click)="changeUser(driver)">
            <span> {{ driver.name }}</span>
          </div>
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="driver-info" *ngIf="currentUser" fxLayoutAlign="end center">
    <span class="content-info">
      E-mail:
      <a href="mailto:{{ currentUser.email }}">{{ currentUser.email }}</a>
    </span>
    <span class="content-info" *ngIf="currentUser.phone">
      Tel:
      <a href="tel:{{ currentUser.phone }}">{{ currentUser.phone }}</a>
    </span>
  </div>
</div>
<div class="wrapper" *ngIf="userId">
  <mat-tab-group animationDuration="500ms" mat-stretch-tabs>
    <mat-tab label="PLANNING">
      <app-planning [getUserId]="userId"></app-planning>
    </mat-tab>
    <mat-tab label="BESCHIKBAAR">
      <app-availability
        [getUserId]="userId"
        [isDriver]="isDriver"
        [driverCanModify]="driversModifyAvailability"
      ></app-availability>
    </mat-tab>
    <mat-tab label="UITZONDERINGEN">
      <app-exceptions
        [getUserId]="userId"
        [isDriver]="isDriver"
        [driverCanModify]="driversModifyAvailability"
      ></app-exceptions>
    </mat-tab>
    <mat-tab label="VOERTUIGEN">
      <app-availability-vehicles
        [getUserId]="userId"
        [isDriver]="isDriver"
        [driverCanModify]="driversModifyAvailability"
      >
      </app-availability-vehicles>
    </mat-tab>
  </mat-tab-group>
</div>
<!-- <app-planning
  *ngIf="isDriver && !driversModifyAvailability"
  [getUserId]="userId"
></app-planning> -->
