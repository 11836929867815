import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { User, Vehicle } from 'src/app/interfaces';
import {
  arrayUnion,
  arrayRemove,
  getDoc,
  doc,
  query,
  collection,
  where,
  getDocs,
} from 'firebase/firestore';
import { ConfirmChangeAvailabilityComponent } from '../../dialogs/confirm-change-availability/confirm-change-availability.component';
import { lastValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import moment from 'moment';

@Component({
  selector: 'app-availability-vehicles',
  templateUrl: './availability-vehicles.component.html',
  styleUrls: ['./availability-vehicles.component.scss'],
})
export class AvailabilityVehiclesComponent implements OnInit {
  vehicles: Vehicle[] = [];
  organisationId = localStorage.getItem('orgId');

  userId: string;
  userIdFromAdmin: string;
  changerName: string;

  @Input() set getUserId(userId: string) {
    this.userIdFromAdmin = userId ? userId : null;
    this.getVehicles();
    // console.log('value', value);
  }
  @Input() isDriver: boolean;
  @Input() driverCanModify: boolean;

  constructor(
    private db: AngularFirestore,
    private auth: AngularFireAuth,
    private fns: AngularFireFunctions,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    console.log('isDriver', this.isDriver);
    console.log('driverCanModify', this.driverCanModify);
    this.getVehicles();
  }

  async toggleChange($event, vehicleId) {
    console.log('id', $event);
    if ($event.checked) {
      await this.db
        .doc(`organisations/${this.organisationId}/users/${this.userId}`)
        .set(
          {
            vehiclesToRide: arrayUnion(vehicleId),
          },
          { merge: true }
        );
    } else {
      const today = new Date();
      let filteredRides = [];

      const q = query(
        collection(
          this.db.firestore,
          `organisations/${this.organisationId}/daypartDays`
        ),
        where('year', '>=', today.getFullYear()),
        where('vehicleId', '==', vehicleId),
        where('driverId', '==', this.userId)
      );
      const daypartDays = await getDocs(q);

      if (!daypartDays.empty) {
        daypartDays.docs.forEach((daypartDay) => {
          if (
            daypartDay.data().month >= today.getMonth() &&
            daypartDay.data().day >= today.getDate()
          ) {
            filteredRides.push(daypartDay);
          }
        });
      }

      if (filteredRides.length > 0) {
        const dialogRef = this.dialog.open(ConfirmChangeAvailabilityComponent, {
          width: '375px',
          disableClose: true,
          data: { availabilityType: 'vehicles' },
        });
        dialogRef.afterClosed().subscribe(async (result) => {
          if (result) {
            this.auth.user.subscribe(async (authUser) => {
              if (authUser) {
                const newUser = (await (
                  await this.db
                    .doc(
                      `organisations/${this.organisationId}/users/${authUser.uid}`
                    )
                    .get()
                    .toPromise()
                ).data()) as User; //
                this.changerName = newUser.name;
              }
            });
            const user = (
              await getDoc(
                doc(
                  this.db.firestore,
                  `organisations/${this.organisationId}/users/`,
                  `${this.userId}`
                )
              )
            ).data();
            await this.db
              .doc(`organisations/${this.organisationId}/users/${this.userId}`)
              .set(
                {
                  vehiclesToRide: arrayRemove(vehicleId),
                },
                { merge: true }
              );

            try {
              const callBody = {
                orgId: this.organisationId,
                userName: 'planner',
                mailType: 'scheduleVehicles',
                emailTo: 'self',
                optionalData: {
                  date: moment(new Date()).format('DD-MM-YYYY'),
                  changedBy: this.changerName,
                  userChanged: user.name,
                  mailPlanners: true,
                },
              };
              const callable = this.fns.httpsCallable('httpSendMail');
              const callRes = await lastValueFrom(callable(callBody));
              console.log(callRes);
            } catch (error) {
              console.log(error);
            }
          } else {
            $event.source.checked = true;
          }
        });
      } else {
        await this.db
          .doc(`organisations/${this.organisationId}/users/${this.userId}`)
          .set(
            {
              vehiclesToRide: arrayRemove(vehicleId),
            },
            { merge: true }
          );
      }
    }
  }

  getVehicles() {
    this.auth.authState.subscribe((user) => {
      this.userId = this.userIdFromAdmin ? this.userIdFromAdmin : user.uid;
      const vehiclesData = this.db
        .collection(`organisations/${this.organisationId}/vehicles`, (ref) =>
          ref.where('active', '==', true).orderBy('name')
        )
        .valueChanges();
      vehiclesData.subscribe(async (vehicles) => {
        const userData = (await (
          await this.db
            .doc(`organisations/${this.organisationId}/users/${this.userId}`)
            .get()
            .toPromise()
        ).data()) as User;
        this.vehicles = [];
        console.log('vehicles cleared', this.vehicles);
        vehicles.forEach((vehicle: any) => {
          if (
            userData.vehiclesToRide &&
            userData.vehiclesToRide.find((x) => x === vehicle.id)
          ) {
            vehicle.checked = true;
          } else {
            vehicle.checked = false;
          }
          this.vehicles.push(vehicle);
        });
      });
    });
  }
}
