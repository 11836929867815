import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  deleteField,
  getDoc,
  addDoc,
} from 'firebase/firestore';
import { CustomValidators } from '../../../validators/custom-validators';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import {
  StorageReference,
  getBytes,
  getDownloadURL,
  ref,
  uploadBytes,
} from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';
import { PDFDocument } from 'pdf-lib';
import moment from 'moment';
import {
  PaymentMethodsConfig,
  UserWithWarnings,
  EmergencyPhoneInfo,
  User,
} from 'src/app/interfaces';
import { PaymentMethod } from 'src/app/enums';
import { openSnackbar } from '../../../globals';
import { WarningDialog } from 'src/app/global-dialogs/warning/warning.component';
import { getAuth } from 'firebase/auth';

export interface DialogData {
  publicSettings: any;
  generalSettings: any;
  accountType: string;
  user?: UserWithWarnings;
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  functions: any = getFunctions(
    initializeApp(environment.firebase),
    'europe-west1'
  );

  paymentMethod = PaymentMethod;
  organisationId: string = localStorage.getItem('orgId');
  currentUserAccountType: string = this.data.accountType;
  userForm: UntypedFormGroup;
  phoneForm: UntypedFormGroup;
  iconsFormGroup: UntypedFormGroup = this.fb.group({});
  newUser: boolean = this.data.user ? false : true;
  loading: boolean = false;
  loadingData: boolean = true;
  askGender: boolean = false;
  askBirthDate: boolean = false;
  emailNotificationsParticipants: boolean = false;
  smsNotificationsParticipants: boolean = false;
  planningComments: boolean = this.data.generalSettings.pCommentsToggle
    ? true
    : false;
  driverComments: boolean = this.data.generalSettings.dCommentsToggle
    ? true
    : false;
  sameUser: boolean = false;
  dialogTitle: string = this.newUser
    ? 'Nieuwe deelnemer'
    : `${this.data.user.name} (${this.data.user.credits})`;
  planArray: any[] = [];
  iconArray: any[] = [];
  productArray: any[] = [];
  sepaPath: string = this.data.user?.sepaPath
    ? this.data.user?.sepaPath
    : 'sepa_authorization.pdf';
  sepaMandateId: string = this.data.user?.sepaMandateId ?? null;
  releaseEmailField: boolean = false;
  emergencyPhoneNumbers: EmergencyPhoneInfo[] = [];
  addingPhoneNumber: boolean = false;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<UserComponent>,
    public dialog: MatDialog,
    private storage: AngularFireStorage,
    private snackBar: MatSnackBar
  ) {}

  async ngOnInit() {
    console.log('data:', this.data);
    this.userForm = this.fb.group({
      email: ['', [Validators.required, CustomValidators.emailValidator]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
      name: [, Validators.required],
      streetName: [, Validators.required],
      houseNumber: [
        ,
        [Validators.required, CustomValidators.numberInput(false, false, 0)],
      ],
      postal: [, [CustomValidators.postalValidator, Validators.required]],
      city: [, Validators.required],
      phoneNumber: [''],
      phoneNumberHome: ['', [CustomValidators.phoneValidator]],
      houseNumberAddition: [],
      currentPlan: ['none'],
      cardId: [, CustomValidators.cannotContainSpace],
      remarks: [],
      hiddenRemarks: [],
      commentsForDriver: [],
      rideProductId: [],
      icons: this.iconsFormGroup,
      inactive: [],
      paymentMethod: [PaymentMethod.NONE],
    });
    this.phoneForm = this.fb.group({
      name: ['', Validators.required],
      phoneNumber: ['', [Validators.required, CustomValidators.phoneValidator]],
      description: ['', Validators.required],
    });

    await this.getIcons();
    await this.getPlans();
    await this.getProducts();

    if (!this.newUser) {
      this.userForm.controls.email.disable();
      this.userForm.removeControl('password');
      this.userForm.removeControl('confirmPassword');
      this.userForm.patchValue(this.data.user);
      if (this.data.user.emergencyPhoneNumbers) {
        this.data.user.emergencyPhoneNumbers.forEach((phoneInfo) => {
          this.emergencyPhoneNumbers.push({ ...phoneInfo, editing: false });
        });
      }
      if (this.data.user.birthDate) {
        try {
          this.userForm.controls.birthDate.setValue(
            new Date(this.data.user.birthDate.toDate())
          );
        } catch {}
      }

      if (this.data.user.currentPlan?.id) {
        this.planArray.find((plan) => {
          if (plan.id === this.data.user.currentPlan.id) {
            this.userForm.patchValue({ currentPlan: plan.id });
          }
        });
      }

      if (!this.data.user.paymentMethod) {
        this.userForm.get('currentPlan').disable();
      }

      if (this.data.user.rideProductId) {
        this.productArray.find((product) => {
          if (product.id === this.data.user.rideProductId) {
            this.userForm.patchValue({ rideProductId: product.id });
          }
        });
      } else {
        this.userForm.patchValue({ rideProductId: 'none' });
      }

      if (this.data.user.icons) {
        const iconArray: any = Object.entries(this.data.user.icons);
        iconArray.forEach((iconData) => {
          this.iconsFormGroup.registerControl(
            iconData[0],
            new UntypedFormControl(
              this.data.user.icons[iconData[0]]
                ? this.data.user.icons[iconData[1]]
                : false
            )
          );
        });
      }
    } else {
      this.userForm.patchValue({ currentPlan: 'none' });
      this.userForm.get('currentPlan').disable();
      this.userForm.patchValue({ rideProductId: 'none' });
    }

    this.getPublicSettingsFormControls();
    this.userForm.controls.paymentMethod.valueChanges.subscribe((value) => {
      if (value === PaymentMethod.SEPA) {
        this.userForm.get('iban').enable();
      } else {
        this.userForm.get('iban').disable();
      }
      if (
        value !== PaymentMethod.NONE &&
        this.currentUserAccountType === 'admin'
      ) {
        this.userForm.get('currentPlan').enable();
      } else {
        this.userForm.get('currentPlan').disable();
      }
    });

    if (this.data.publicSettings.emailNotificationsParticipants) {
      this.userForm.controls.email.valueChanges.subscribe(() => {
        if (
          this.userForm.controls.email.valid &&
          this.userForm.controls.email.value
        ) {
          this.userForm.get('notifyByEmail').enable();
        } else {
          this.userForm.get('notifyByEmail').disable();
        }
      });
    }

    if (this.data.publicSettings.smsNotificationsParticipants) {
      this.userForm.controls.smsNotificationPhoneNumber.valueChanges.subscribe(
        () => {
          if (
            this.userForm.controls.smsNotificationPhoneNumber.valid &&
            this.userForm.controls.smsNotificationPhoneNumber.value
          ) {
            this.userForm.get('notifyBySMS').enable();
          } else {
            this.userForm.get('notifyBySMS').disable();
          }
        }
      );
    }

    this.loadingData = false;
  }

  async save() {
    if (this.userForm.valid) {
      this.loading = true;

      if (this.userForm.controls.cardId.value) {
        const q = query(
          collection(
            this.db.firestore,
            `organisations/${this.organisationId}/users/`
          ),
          where('cardId', '==', this.userForm.controls.cardId.value)
        );
        const users = await getDocs(q);

        if (!this.newUser) {
          users.forEach((doc) => {
            if (doc.id === this.data.user.id) {
              this.sameUser = true;
            }
          });
        }

        if (!users.empty && this.sameUser === false) {
          openSnackbar(this.snackBar, 'Dit kaartnummer is al in gebruik');
          this.loading = false;
          return;
        }
      }

      if (this.newUser) {
        if (
          this.userForm.controls.password.value !==
          this.userForm.controls.confirmPassword.value
        ) {
          this.userForm.controls.confirmPassword.setErrors({
            notMatched: true,
          });
          this.loading = false;
          return;
        }
      }

      const saveObj = this.userForm.value;
      if (this.userForm.controls.iban?.status === 'DISABLED') {
        saveObj.iban = this.userForm.controls.iban.value;
      }
      if (this.userForm.controls.currentPlan?.status === 'DISABLED') {
        saveObj.currentPlan = this.userForm.controls.currentPlan.value;
      }
      console.log('saveObj:', saveObj);

      Object.keys(saveObj).forEach((key) => {
        if (
          saveObj[key] === '' ||
          saveObj[key] === 'none' ||
          saveObj[key] === null ||
          saveObj[key] === undefined
        ) {
          this.newUser ? delete saveObj[key] : (saveObj[key] = deleteField());
        }
      });

      delete saveObj.password;
      delete saveObj.confirmPassword;

      if (saveObj.postal) {
        saveObj.postal = saveObj.postal.replace(/\s/g, '').toUpperCase();
      }

      if (saveObj.iban && saveObj.iban._methodName !== 'deleteField') {
        saveObj.iban = saveObj.iban.replace(/\s/g, '').toUpperCase();
      }

      if (
        saveObj.smsNotificationPhoneNumber &&
        saveObj.smsNotificationPhoneNumber._methodName !== 'deleteField'
      ) {
        saveObj.smsNotificationPhoneNumber =
          saveObj.smsNotificationPhoneNumber.replace(/[\s-]/g, '');
        if (saveObj.smsNotificationPhoneNumber.startsWith('0')) {
          saveObj.smsNotificationPhoneNumber =
            saveObj.smsNotificationPhoneNumber.replace('0', '+31');
        }
      }

      console.log('saveObj cleaned:', saveObj);

      if (this.emergencyPhoneNumbers.length > 0) {
        saveObj.emergencyPhoneNumbers = [];
        this.emergencyPhoneNumbers.forEach((phoneInfo) => {
          const pushObj = {
            name: phoneInfo.name,
            phoneNumber: phoneInfo.phoneNumber,
            description: phoneInfo.description,
          };
          pushObj.phoneNumber = pushObj.phoneNumber.replace(/[\s-]/g, '');
          if (pushObj.phoneNumber.startsWith('0')) {
            pushObj.phoneNumber = pushObj.phoneNumber.replace('0', '+31');
          }
          saveObj.emergencyPhoneNumbers.push(pushObj);
        });
      }

      if (this.data.user) {
        if (
          this.data.user.email !== saveObj.email &&
          saveObj.email !== undefined
        ) {
          const httpUpdateUser = httpsCallable(
            this.functions,
            'httpUpdateUser'
          );
          try {
            await httpUpdateUser({
              type: 'email',
              userId: this.data.user.id,
              orgId: this.organisationId,
              emailNew: saveObj.email,
              emailOld: this.data.user.email,
            });
            this.logEmailChange(saveObj.email);
          } catch (error) {
            openSnackbar(
              this.snackBar,
              'Er is iets misgegaan, probeer het later opnieuw of neem contact op met de beheerder'
            );
            console.log('error', error);
            this.loading = false;
            return;
          }
        }
        if (this.data.user.phoneNumber !== saveObj.phoneNumber) {
          console.log('soon');
        }
      }

      if (saveObj.inactive === true) {
        saveObj['hidden'] = true;
        openSnackbar(
          this.snackBar,
          'Let op: deelnemers die inactief worden gemaakt worden automatisch naar verstopte deelnemers verplaatst.'
        );
      } else {
        if (!this.newUser) {
          if (this.data.user.inactive) {
            saveObj['hidden'] = false;
            openSnackbar(this.snackBar, 'De deelnemer is weer actief');
          }
        }
      }

      if (
        saveObj.paymentMethod &&
        saveObj.paymentMethod._methodName !== 'deleteField'
      ) {
        if (
          !saveObj.currentPlan ||
          saveObj.currentPlan._methodName === 'deleteField'
        ) {
          openSnackbar(
            this.snackBar,
            'Je kunt alleen een betaalmethode instellen als je ook een betaalplan hebt ingevuld.'
          );
          this.loading = false;
          return;
        }
        if (
          saveObj.paymentMethod === 'SEPA' &&
          (!saveObj.iban || saveObj.iban._methodName === 'deleteField')
        ) {
          openSnackbar(
            this.snackBar,
            'Als een deelnemer gebruik wil maken van een SEPA machtigingsformulier dan moet het IBAN invoerveld verplicht ingevuld worden.'
          );
          this.loading = false;
          return;
        }
      }

      if (saveObj.currentPlan) {
        this.planArray.find((plan) => {
          if (plan.id === saveObj.currentPlan) {
            saveObj.currentPlan = plan;
          }
        });
      }

      if (!this.newUser) {
        if (
          saveObj.paymentMethod !== this.data.user.paymentMethod &&
          saveObj.paymentMethod !== undefined
        ) {
          this.logPaymentMethodChange(saveObj.paymentMethod);
        }

        if (
          saveObj.iban !== this.data.user.iban &&
          saveObj.iban !== undefined
        ) {
          this.logIbanChange(saveObj.iban);
        }

        if (
          saveObj.currentPlan?.id !== this.data.user.currentPlan?.id &&
          saveObj.currentPlan !== undefined
        ) {
          this.logPaymentPlanChange(saveObj.currentPlan);
        }

        await updateDoc(
          doc(
            this.db.firestore,
            `organisations/${this.organisationId}/users`,
            `${this.data.user.id}`
          ),
          {
            ...saveObj,
          }
        );
        openSnackbar(this.snackBar, 'De wijzigingen zijn opgeslagen');
        this.dialogRef.close();
      } else {
        const httpAddUser = httpsCallable(this.functions, 'httpAddUser');
        try {
          const result: any = await httpAddUser({
            email: saveObj.email,
            password: this.userForm.controls.password.value,
            organisationId: this.organisationId,
            saveObj,
          });
          switch (result.data.message) {
            case 'succeed':
              this.dialogRef.close();
              break;
            case 'error-occured':
              openSnackbar(
                this.snackBar,
                'Er is iets misgegaan, probeer het later opnieuw of neem contact op met de beheerder'
              );
              break;
            case 'auth/email-already-exists':
              openSnackbar(this.snackBar, 'Dit e-mailadres is al in gebruik');
              break;
            default:
              openSnackbar(this.snackBar, 'Er is iets misgegaan');
              break;
          }
        } catch (error) {
          console.log('error', error);
        }
      }
      this.loading = false;
    }
  }

  async getIcons() {
    const iconCollection = await getDocs(
      collection(
        this.db.firestore,
        `organisations/${this.organisationId}/icons`
      )
    );
    iconCollection.forEach((icon) => {
      const iconData = icon.data();
      iconData['id'] = icon.id;
      this.iconArray.push(iconData);
    });

    this.iconArray.forEach((icon) => {
      this.iconsFormGroup.addControl(icon.id, new UntypedFormControl(false));
    });
  }

  async getPlans() {
    const planCollection = await getDocs(
      collection(
        this.db.firestore,
        `organisations/${this.organisationId}/plans`
      )
    );
    planCollection.forEach((plan) => {
      const planData = plan.data();
      planData['id'] = plan.id;
      this.planArray.push(planData);
    });
  }

  async getProducts() {
    const productCollection = await getDocs(
      collection(
        this.db.firestore,
        `organisations/${this.organisationId}/products`
      )
    );
    productCollection.forEach((product) => {
      const productData = product.data();
      productData['id'] = product.id;
      this.productArray.push(productData);
    });
  }

  getPublicSettingsFormControls() {
    if (this.data.publicSettings.sepaEnabled) {
      this.userForm.addControl(
        'iban',
        new UntypedFormControl(
          {
            value: this.data.user?.iban ?? '',
            disabled:
              this.userForm.value.paymentMethod !== PaymentMethod.SEPA
                ? true
                : false,
          },
          [CustomValidators.ibanValidator]
        )
      );
    }
    if (this.data.publicSettings.askBirthDay) {
      this.userForm.addControl(
        'birthDate',
        new UntypedFormControl('', [Validators.required])
      );
      this.askBirthDate = true;
      if (
        this.data.user?.birthDate !== null &&
        this.data.user?.birthDate !== undefined
      ) {
        console.log('data.user', this.data.user?.birthDate);
        this.userForm.controls.birthDate.setValue(
          new Date(this.data.user.birthDate.toDate())
        );
      }
    }
    if (this.data.publicSettings.askGender) {
      this.userForm.addControl(
        'gender',
        new UntypedFormControl('', [Validators.required])
      );
      this.askGender = true;
      if (
        this.data.user?.gender !== null &&
        this.data.user?.gender !== undefined
      ) {
        this.userForm.controls.gender.setValue(this.data.user.gender);
      }
    }
    if (this.data.publicSettings.emailNotificationsParticipants) {
      this.userForm.addControl(
        'notifyByEmail',
        new UntypedFormControl(
          {
            value: false,
            disabled: !this.userForm.controls.email.value,
          },
          []
        )
      );
      this.emailNotificationsParticipants = true;
      if (this.data.user?.notifyByEmail) {
        this.userForm.controls.notifyByEmail.setValue(
          this.data.user.notifyByEmail
        );
      } else {
        this.userForm.controls.notifyByEmail.setValue(false);
      }
    }
    if (this.data.publicSettings.smsNotificationsParticipants) {
      this.userForm.addControl(
        'smsNotificationPhoneNumber',
        new UntypedFormControl('', [CustomValidators.phoneValidator])
      );
      this.smsNotificationsParticipants = true;
      if (this.data.user?.smsNotificationPhoneNumber !== undefined) {
        this.userForm.controls.smsNotificationPhoneNumber.setValue(
          this.data.user.smsNotificationPhoneNumber
        );
      }

      this.userForm.addControl(
        'notifyBySMS',
        new UntypedFormControl(
          {
            value: false,
            disabled: !this.userForm.controls.smsNotificationPhoneNumber.value,
          },
          []
        )
      );
      if (this.data.user?.notifyBySMS) {
        this.userForm.controls.notifyBySMS.setValue(this.data.user.notifyBySMS);
      } else {
        this.userForm.controls.notifyBySMS.setValue(false);
      }
    }
  }

  enableField(field: string) {
    this.userForm.controls[field].enable();
  }

  managePhoneNumber(type: string, id?: number) {
    this.emergencyPhoneNumbers.forEach((phoneInfo) => {
      phoneInfo.editing = false;
    });
    this.addingPhoneNumber = false;
    this.phoneForm.reset();
    switch (type) {
      case 'edit':
        this.phoneForm.patchValue(this.emergencyPhoneNumbers[id]);
        this.emergencyPhoneNumbers[id].editing = true;
        break;
      case 'add':
        this.addingPhoneNumber = true;
        break;
      case 'delete':
        this.emergencyPhoneNumbers.splice(id, 1);
        break;
    }
  }

  savePhoneNumber(type: string, id?: number) {
    const saveObj = {
      ...this.phoneForm.value,
      editing: false,
    };
    switch (type) {
      case 'edit':
        this.emergencyPhoneNumbers[id] = saveObj;
        break;
      case 'add':
        this.emergencyPhoneNumbers.push(saveObj);
        this.addingPhoneNumber = false;
        break;
    }
  }

  async downloadSepa() {
    try {
      const pathRef = ref(this.storage.storage, this.sepaPath);

      if (this.sepaPath !== 'sepa_authorization.pdf') {
        // sepaPath is not the default path, no need to fill an uploaded mandate file
        const downloadUrl = await getDownloadURL(pathRef);
        console.log(downloadUrl);
        window.open(downloadUrl, '_blank');
      } else {
        if (!this.sepaMandateId) {
          const formattedDate = moment(new Date()).format('YYYYMMDDHHmmss');
          const orgNameMandate = this.data.generalSettings.name.substring(0, 3);
          const mandateId = `${orgNameMandate.toUpperCase()}${formattedDate}`;
          this.sepaMandateId = mandateId;
          await setDoc(
            doc(
              this.db.firestore,
              `/organisations/${this.organisationId}/users/${this.data.user.id}`
            ),
            {
              sepaMandateId: this.sepaMandateId,
            },
            { merge: true }
          );
        }
        const paymentMethodsConfig = (
          await getDoc(
            doc(this.db.firestore, `payment-configs/${this.organisationId}`)
          )
        ).data() as PaymentMethodsConfig;
        const pdfBytes = await this.fillPdfWithUserInfo(
          pathRef,
          this.sepaMandateId,
          paymentMethodsConfig
        );
        const blob = new Blob([pdfBytes.buffer], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async fillPdfWithUserInfo(
    pathRef: StorageReference,
    sepaMandateId: string,
    paymentMethodConfig: PaymentMethodsConfig
  ) {
    const formBytes = await getBytes(pathRef);
    const pdfDoc = await PDFDocument.load(formBytes);
    const form = pdfDoc.getForm();
    const userFormData = this.userForm.value;

    // Set company fields
    form
      .getTextField('machtigingBedrijfsnaam')
      .setText(paymentMethodConfig.creditor_name ?? '');
    form
      .getTextField('machtigingBedrijfsadres')
      .setText(paymentMethodConfig.creditor_address ?? '');
    form
      .getTextField('machtigingBedrijfspostcode')
      .setText(paymentMethodConfig.creditor_postal ?? '');
    form
      .getTextField('machtigingBedrijfsplaats')
      .setText(paymentMethodConfig.creditor_municipality ?? '');
    form
      .getTextField('machtigingBedrijfsland')
      .setText(paymentMethodConfig.creditor_country ?? '');
    form
      .getTextField('machtigingBedrijfsIncassantID')
      .setText(paymentMethodConfig.creditor_id ?? '');
    form.getTextField('machtigingKenmerk').setText(sepaMandateId);
    form
      .getTextField('machtigingRedenBetaling')
      .setText('Buurtvervoer Credits');
    form
      .getTextField('machtigingOndertekeningBedrijfsnaam')
      .setText(paymentMethodConfig.creditor_name ?? '');
    form
      .getTextField('machtigingAfschrijvenNaam')
      .setText(paymentMethodConfig.creditor_name ?? '');

    // set user fields
    form.getTextField('machtigingNaam').setText(userFormData.name ?? '');
    let address =
      userFormData.streetName && userFormData.houseNumber
        ? `${userFormData.streetName} ${userFormData.houseNumber}`
        : '';
    if (address && userFormData.houseNumberAddition) {
      address = `${address} ${userFormData.houseNumberAddition}`;
    }
    form.getTextField('machtigingAdres').setText(address);
    form.getTextField('machtigingPostcode').setText(userFormData.postal ?? '');
    form.getTextField('machtigingPlaats').setText(userFormData.city ?? '');
    form
      .getTextField('machtigingLand')
      .setText(paymentMethodConfig.creditor_country ?? '');

    const iban: string = userFormData.iban
      ? userFormData.iban.replace(/\s/g, '').toUpperCase()
      : '';

    form.getTextField('machtigingIBAN1').setText(iban.substring(0, 4));
    form.getTextField('machtigingIBAN2').setText(iban.substring(4, 8));
    form.getTextField('machtigingIBAN3').setText(iban.substring(8, 12));
    form.getTextField('machtigingIBAN4').setText(iban.substring(12, 16));
    form.getTextField('machtigingIBAN5').setText(iban.substring(16, 18));

    const pdfBytes = await pdfDoc.save({ updateFieldAppearances: false });
    return pdfBytes;
  }

  async openFileInput(htmlId) {
    const element: HTMLElement = document.getElementById(htmlId) as HTMLElement;
    element.click();
  }

  async uploadSepa(event) {
    if (this.sepaMandateId) {
    }
    const dialogRef = this.dialog.open(WarningDialog, {
      width: '600px',
      data: {
        dialogType: 'uploadSepaMandateIdCheck',
        optionalData: { sepaMandateId: this.sepaMandateId },
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          const file = event.target.files[0] as File;
          const formattedDate = moment(new Date()).format('YYYY-MM-DD-HHmmss');
          const pathRef = ref(
            this.storage.storage,
            `/organisations/${this.organisationId}/users/${this.data.user.id}/sepa_authorization(${formattedDate}).pdf`
          );
          console.log('pathref', pathRef);
          const result = await uploadBytes(pathRef, file);
          const sepaDownloadUrl = await getDownloadURL(pathRef);
          this.sepaPath = result.metadata.fullPath;
          console.log('result', result);
          await setDoc(
            doc(
              this.db.firestore,
              `/organisations/${this.organisationId}/users/${this.data.user.id}`
            ),
            {
              sepaSignatureDate: new Date(),
              sepaDownloadUrl: sepaDownloadUrl,
              sepaPath: result.metadata.fullPath,
            },
            { merge: true }
          );
        } catch (error) {
          console.log(error);
        }
      } else {
        this.sepaMandateId = '';
        this.sepaPath = 'sepa_authorization.pdf';
        event.target.value = null;

        await setDoc(
          doc(
            this.db.firestore,
            `/organisations/${this.organisationId}/users/${this.data.user.id}`
          ),
          {
            sepaMandateId: deleteField(),
          },
          { merge: true }
        );
      }
    });
  }

  async deleteSepa() {
    try {
      // const pathRef = ref(this.storage.storage, this.sepaPath);
      // await deleteObject(pathRef);

      // Note Jarik: I'm convinced that I said that we shouldn't delete it from the storage,
      // so that we can look back any changes or when someone accidentally removes it that we'll still have it in our storage
      await setDoc(
        doc(
          this.db.firestore,
          `/organisations/${this.organisationId}/users/${this.data.user.id}`
        ),
        {
          sepaSignatureDate: deleteField(),
          sepaDownloadUrl: deleteField(),
          sepaPath: deleteField(),
          sepaMandateId: deleteField(),
        },
        { merge: true }
      );
      this.sepaMandateId = '';
      this.sepaPath = 'sepa_authorization.pdf';
      console.log('sepa deleted');
    } catch (error) {
      console.log(error);
    }
  }

  async logEmailChange(email) {
    await addDoc(
      collection(
        this.db.firestore,
        `eventLogs/${this.organisationId}/changedUserSettings`
      ),
      {
        date: new Date(),
        doneByUser: getAuth().currentUser.email,
        doneByUid: getAuth().currentUser.uid,
        oldData: this.data.user.email,
        newData: email,
      }
    );
  }

  async logPaymentMethodChange(paymentMethod) {
    const data = {
      date: new Date(),
      doneByUser: getAuth().currentUser.email,
      doneByUid: getAuth().currentUser.uid,
      doneToUid: this.data.user.id,
      oldData: this.data.user.paymentMethod
        ? this.data.user.paymentMethod
        : 'none',
      newData:
        paymentMethod._methodName !== 'deleteField' ? paymentMethod : 'none',
    };
    console.log('paymentMethodLog', data);

    await addDoc(
      collection(
        this.db.firestore,
        `eventLogs/${this.organisationId}/changedUserSettings`
      ),
      data
    );
  }

  async logIbanChange(iban) {
    const data = {
      date: new Date(),
      doneByUser: getAuth().currentUser.email,
      doneByUid: getAuth().currentUser.uid,
      doneToUid: this.data.user.id,
      oldData: this.data.user.iban ? this.data.user.iban : 'none',
      newData: iban._methodName !== 'deleteField' ? iban : 'none',
    };
    console.log('ibanLog', data);

    await addDoc(
      collection(
        this.db.firestore,
        `eventLogs/${this.organisationId}/changedUserSettings`
      ),
      data
    );
  }

  async logPaymentPlanChange(paymentPlan) {
    const data = {
      date: new Date(),
      doneByUser: getAuth().currentUser.email,
      doneByUid: getAuth().currentUser.uid,
      doneToUid: this.data.user.id,
      oldData: this.data.user.currentPlan ? this.data.user.currentPlan : 'none',
      newData:
        paymentPlan?._methodName !== 'deleteField' ? paymentPlan : 'none',
    };
    console.log('paymentPlanLog', data);

    await addDoc(
      collection(
        this.db.firestore,
        `eventLogs/${this.organisationId}/changedUserSettings`
      ),
      data
    );
  }
}
